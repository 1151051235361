import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Merge Tailwind classes
 * Inspired by: shadcn-ui
 * https://github.com/shadcn-ui/ui/blob/48e3a4a326ab1292ac5e334e112bbb8487985e1b/apps/www/lib/utils.ts
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
