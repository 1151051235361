import { StandoutBorder } from '@/Borders';
import { Produce } from '~/types/types';
import { cn } from '~/utils/cn';

interface ProduceImageProps {
  produce: Produce;
  className?: string;
}

export const ProduceImage = ({ produce, className }: ProduceImageProps) => {
  return (
    <StandoutBorder>
      <img
        className={cn('object-cover rounded-full w-7 h-7', className)}
        src={produce.image_url}
        alt={produce.name}
      />
    </StandoutBorder>
  );
};
