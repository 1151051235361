interface FormatRatioEntryProps {
  numberA: number;
  numberB: number;
}

export const FormatRatioEntry = ({ numberA, numberB }: FormatRatioEntryProps) => {
  if (numberA === null || !numberB === null) {
    return null;
  }

  return (
    <div>
      ~ {numberA.toFixed(2)} : {numberB.toFixed(2)}
    </div>
  );
};
