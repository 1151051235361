import { useTranslation } from 'react-i18next';

import { Card, CardBody } from '@/Card';
import {
  Timeline,
  TimelineAvatar,
  TimelineContent,
  TimelineDescription,
  TimelineHeader,
  WithTimelineDateGroup,
} from '@/Timeline';
import SpecVersion = App.Models.SpecVersion;

import { WithGenericFallback } from '@/Fallback';
import {
  TimelineIconIcon,
} from '@/Icon';
import { getStatusFromMap } from '@/Labels/Status';
import { specVersionStatusMap } from '~/Components/Spec/SpecVersions/SpecVersionStatusMap';

interface SpecChangeCardProps {
  changes: SpecVersion[];
  onViewVersion?: (versionId: number) => void;
}

export const SpecTimelineItem = ({ change }: { change: SpecVersion }) => {
  const { t } = useTranslation();

  const changeVariant = getStatusFromMap(change.type, specVersionStatusMap);
  if (!changeVariant) {
    return null;
  }

  return (
    <>
      <TimelineAvatar variant={changeVariant.theme}>
        <TimelineIconIcon icon={changeVariant.icon}/>
      </TimelineAvatar>
      <TimelineContent>
        <TimelineHeader date={change.created_at as unknown as Date}>
          {t(changeVariant.textKey as string)}
        </TimelineHeader>
        <TimelineDescription>
          {t(changeVariant.descriptionKey as string, {
            userName: change.user?.name ?? 'system',
            fieldName: change?.field_name,
            fromFieldName: change?.field_from_name,
            toFieldName: change?.field_to_name,
          })}
        </TimelineDescription>
      </TimelineContent>
    </>
  );
};

export const SpecChangeCard = ({ changes }: SpecChangeCardProps) => {
  return (
    <WithGenericFallback>
      <Card>
        <CardBody>
          <Timeline>
            <WithTimelineDateGroup items={changes} dateKey="created_at" groupBy="day">
              {(change) => (
                <SpecTimelineItem change={change}/>
              )}
            </WithTimelineDateGroup>
          </Timeline>
        </CardBody>
      </Card>
    </WithGenericFallback>

  );
};
