import React, { useCallback, useMemo } from 'react';

import { OptionBaseType } from '@/Pickers/GenericPicker';
import { useAction } from '~/hooks/useAction';
import { usePageProps } from '~/hooks/usePageProps';
import { formatPicker } from '~/utils/formatPicker';

interface PickerFetcherProps {
  reloadKey: string;
  optionsKey: string;
  parseInitialOptionsConfig?: {
    key: string;
    labelKey: string;
    valueKey: string;
  }
  parseInitialOptionsKey?: string;
  children: (props: {
    options: OptionBaseType[];
    loading: boolean;
    initialCountSelected: number;
    onOpen: () => Promise<void>;
  }) => React.ReactNode;
}

// Helper function to safely access nested properties
const getNestedValue = (obj: any, path: string): any => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

/**
 * PickerFetcher
 *
 * A wrapper component that manages option fetching and state for picker components,
 * particularly designed for async use with GenericPicker.
 *
 * @component
 * @param {Object} props
 * @param {string} props.reloadKey - Key used to trigger option reloading.
 * @param {string} props.optionsKey - Path to options in pageProps.
 * @param {Object} [props.parseInitialOptionsConfig] - Configuration for parsing initial options.
 * @param {string} props.parseInitialOptionsConfig.key - Path to initial selected items in pageProps.
 * @param {string} props.parseInitialOptionsConfig.labelKey - Key for option label.
 * @param {string} props.parseInitialOptionsConfig.valueKey - Key for option value.
 * @param {OptionBaseType[]} [props.initialOptions] - Initial set of options.
 * @param {Function} props.children - Render prop function that receives fetched data and handlers.
 *
 * @example
 * <PickerFetcher
 *   reloadKey="userOptions"
 *   optionsKey="pageProps.userOptions"
 *   parseInitialOptionsConfig={{
 *     key: 'pageProps.selectedUsers',
 *     labelKey: 'name',
 *     valueKey: 'id'
 *   }}
 * >
 *   {({ options, loading, initialCountSelected, onOpen }) => (
 *     <GenericPicker
 *       options={options}
 *       loading={loading}
 *       initialCountSelected={initialCountSelected}
 *       onOpen={onOpen}
 *       // ... other GenericPicker props
 *     />
 *   )}
 * </PickerFetcher>
 */
export function PickerFetcher({
  reloadKey,
  optionsKey,
  parseInitialOptionsConfig,
  children,
  ...props
}: PickerFetcherProps) {
  const pageProps = usePageProps();
  const { resetCurrentPage, loading } = useAction<any, any>('', {
    withParams: true,
    only: [reloadKey],
  });

  const handleOpen = useCallback(async () => {
    resetCurrentPage();
  }, [resetCurrentPage]);

  const initialCountSelected = useMemo(() => {
    if (parseInitialOptionsConfig?.key) {
      return (getNestedValue(pageProps, parseInitialOptionsConfig?.key) || []).length;
    }
    return 0;
  }, [parseInitialOptionsConfig, pageProps]);

  const options = useMemo(() => {
    let serverOptions = getNestedValue(pageProps, optionsKey) || [];
    serverOptions = formatPicker(serverOptions);

    return serverOptions ?? [];
  }, [optionsKey, pageProps]);

  return (
    <>
      {children({
        options,
        loading,
        initialCountSelected,
        onOpen: handleOpen,
        ...props,
      })}
    </>
  );
}
