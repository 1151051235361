import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Input } from "@/Input";
import { Pane, PaneContent, PaneHeader, PaneTitle, PaneTrigger } from "@/Pane";
import { useAction } from "~/hooks/useAction";
import { usePageProps } from "~/hooks/usePageProps";
import { Comment, Order } from "~/types/types";

import { ReplyComment } from "./ReplyComment";
interface CommentsPaneProps {
  Trigger: React.ReactNode;
  comments: Comment[];
  commentable: { id: string; type: string };
  path: string;
  order?: Order;
}

export const CommentsPane = ({
  Trigger,
  comments,
  commentable,
  path,
}: CommentsPaneProps) => {
  const { t } = useTranslation();
  const { organization, order } = usePageProps();

  const { post: createComment } = useAction(path, {
    preserveState: true,
    preserveScroll: true,
  });

  const { handleSubmit, register, reset, formState } = useForm({
    defaultValues: {
      text: "",
    },
  });

  const onSubmit = (input: typeof formState.defaultValues) => {
    if (!input?.text) return; // Don't submit empty comments

    const data = {
      commentable_id: commentable?.id,
      commentable_type: commentable?.type,
      text: input?.text ?? "",
      organizationId: organization.id,
      orderId: order?.id ?? null,
    };

    createComment(data);
    reset();
  };

  return (
    <>
      <Pane>
        <PaneTrigger>{Trigger}</PaneTrigger>
        <PaneContent className="flex flex-col gap-0">
          <PaneHeader className="space-y-0">
            <PaneTitle className="px-3 py-4">{t("comments")}</PaneTitle>
            <hr />
          </PaneHeader>
          <div className="flex flex-col flex-1 space-y-2 overflow-y-auto">
            {comments.length > 0 ? (
              <div className="flex flex-col">
                {comments.map((comment) => (
                  <ReplyComment isRootReply={false} comment={comment} />
                ))}
              </div>
            ) : (
              <div className="grid h-full text-sm place-items-center text-token-muted">
                {t("empty_states.no_comments")}
              </div>
            )}
          </div>
          <div className="px-3 py-6">
            <form
              id="commentable-footer"
              onSubmit={handleSubmit(onSubmit)}
              className="mt-auto"
            >
              <Input
                autoFocus
                className=""
                placeholder={t("placeholders.write_a_reply")}
                type="text"
                {...register("text")}
              />
            </form>
          </div>
        </PaneContent>
      </Pane>
    </>
  );
};
