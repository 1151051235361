import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CompoundButton, CompoundButtonFooter, CompoundButtonItem } from '@/Button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/Command';
import { ConstraintsLabel } from '@/Labels/ConstraintsLabel';
import { RangeLabel } from '@/Labels/RangeLabel';
import { PopoverContent, PopoverRoot, PopoverTrigger } from '@/Popover';
import { FieldIcon } from '~/Components/Fields/FieldIcon';
import { Field, FieldConstraint, Spec } from '~/types/types';
import { cn } from '~/utils/cn';
import { makeMap } from '~/utils/collections';

interface FieldPickerProps {
  options: Field[];
  selected: string;
  onChange: (fieldId: string) => void;
  title?: string;
  spec?: Spec;
  disabled?: boolean;
  showConstraintInSelected?: boolean;
}

export const FieldPicker = ({
  disabled,
  options,
  selected,
  spec,
  onChange,
  title = 'Select field...',
  showConstraintInSelected,
}: FieldPickerProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const selectedField = useMemo(
    () => options.find((option) => option.id === selected),
    [options, selected]
  );

  const constraintsByField = useMemo(() => {
    if (!spec) return {};

    return makeMap(spec.constraints ?? [], 'field_id');
  }, [spec]);

  return (
    <PopoverRoot open={open} onOpenChange={setOpen}>
      <PopoverTrigger disabled={disabled} asChild>
        <CompoundButton
          className="w-full"
          footer={(
            <>
              {showConstraintInSelected && constraintsByField[selectedField.id] ? (
                <ConstraintsLabel
                  fractionToPercentage
                  compact
                  constraint={constraintsByField[selectedField.id] as FieldConstraint}
                  field={selectedField}
                />
              ) : null}
            </>
          )}>
          <CompoundButtonItem type="button" role="combobox" className="w-full">
            <div className="flex flex-wrap">
              {selectedField ? (
                <div className="flex flex-wrap items-center gap-2">
                  {t(selectedField.translation_key_ref as string, {
                    defaultValue: selectedField.name,
                  })}
                </div>
              ) : (
                title
              )}
            </div>
          </CompoundButtonItem>
        </CompoundButton>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0">
        <Command
          filter={(value, search) => {
            const label = options.find((option) => option.id === value)?.name;
            if (label?.toLowerCase().includes(search.toLowerCase())) {
              return 1;
            }
            return -1;
          }}
        >
          <CommandInput placeholder={t('search_field') as string}/>
          <CommandEmpty>No field found.</CommandEmpty>
          <CommandList>
            <CommandGroup>
              {options.map((field) => (
                <CommandItem
                  key={field.id}
                  className="w-full"
                  value={field.id}
                  onSelect={(fieldId) => {
                    onChange(fieldId);
                    setOpen(false);
                  }}
                >
                  <div className="flex flex-wrap items-center justify-between w-full">
                    <div className="flex items-center">
                      <CheckIcon
                        className={cn(
                          'mr-2 h-4 w-4',
                          selectedField?.id === field.id
                            ? 'opacity-100'
                            : 'opacity-0'
                        )}
                      />
                      <FieldIcon field={field}/>
                      {t(field.translation_key_ref as string, {
                        defaultValue: field.name,
                      })}
                    </div>
                    <div className="ml-auto">
                      {constraintsByField[field.id] ? (
                        <ConstraintsLabel constraint={constraintsByField[field.id] as FieldConstraint} field={field}/>
                      ) : null}
                    </div>
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </PopoverRoot>
  );
};
