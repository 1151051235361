import { Collapsible } from '@radix-ui/react-collapsible';
import { motion } from 'framer-motion';
import { cloneElement, forwardRef, ReactElement, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingLabel } from '@/Label';
import { LabeledField } from '@/LabeledField';
import { Helper } from '@/Text';
import { cn } from '~/utils/cn';

interface FilterConfig {
  component: ReactElement;
  fieldKey: string;
  label: string;
  description?: string;
  group?: string;
  valueAccessor?: (eventOrValue: any) => any;
}

interface FilterColumnProps {
  values?: Record<string, any>;
  setValues: (values: Record<string, any>) => void;
  title?: string;
  onChange?: (values: Record<string, any>) => void;
  onClear?: () => void;
  filters: FilterConfig[];
  className?: string;
  isLoading?: boolean;
  hideGroups?: boolean;
}

const springTransition = {
  type: 'spring',
  bounce: 0.15,
  duration: 0.5,
};

export interface FilterColumnRef {
  reset: () => void;
}

export const FilterColumn = forwardRef<FilterColumnRef, FilterColumnProps>(({
  values = {},
  setValues,
  title,
  filters,
  onChange,
  className,
  isLoading = false,
  hideGroups = false,
}, ref) => {
  const { t } = useTranslation();
  const filterValues = values;
  const setFilterValues = setValues;

  const handleFilterChange = (fieldKey: string, value: any, valueAccessor?: (eventOrValue: any) => any) => {
    const actualValue = valueAccessor ? valueAccessor(value) : value;

    const newValues = {
      ...filterValues,
      [fieldKey]: actualValue,
    };
    setFilterValues(newValues);
    onChange?.(newValues);
  };

  useImperativeHandle(ref, () => ({
    reset: () => {
      setFilterValues({});
    },
  }));

  const groupedFilters = filters.reduce((acc, filter) => {
    const group = filter.group || 'default';
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(filter);
    return acc;
  }, {} as Record<string, FilterConfig[]>);

  return (
    <motion.div
      initial={{ x: -20, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={springTransition}
      className={cn(className)}
    >
      <div className="flex flex-col">
        <div className="flex items-center justify-between">
          {isLoading && (
            <LoadingLabel isLoading={true}>
              {t('loading')}
            </LoadingLabel>
          )}
        </div>

        <div className="flex flex-col space-y-6">
          {Object.entries(groupedFilters).map(([group, groupFilters], groupIndex) => (
            <motion.div
              key={group}
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ ...springTransition, delay: groupIndex * 0.1 }}
              className="flex flex-col space-y-4"
            >
              {group !== 'default' && !hideGroups && (
                <h3 className="text-sm font-medium text-zinc-500">{group}</h3>
              )}
              {groupFilters.map((filter, index) => (
                <motion.div
                  key={filter.fieldKey}
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ ...springTransition, delay: index * 0.05 }}
                >
                  <LabeledField
                    label={filter.label}
                    subLabel={filter.description}
                    renderValue={
                      cloneElement(filter.component, {
                        value: filterValues[filter.fieldKey] || '',
                        onChange: (value: any) =>
                          handleFilterChange(filter.fieldKey, value, filter.valueAccessor),
                      })
                    }
                  />
                </motion.div>
              ))}
              {groupIndex < Object.keys(groupedFilters).length - 1 && (
                <hr className="border-zinc-200"/>
              )}
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );
});
FilterColumn.displayName = 'FilterColumn';
