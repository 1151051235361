import { useTranslation } from 'react-i18next';
import FieldSummary = App.Models.FieldSummary;
import MeasurementFieldSummary = App.Models.MeasurementFieldSummary;
import BooleanFieldSummary = App.Models.BooleanFieldSummary;
import { MultiLabel } from '@/Label';
import { FormatCount } from '~/Components/Orders/FormatCount';
import { FormatRatioFieldSummary } from '~/Components/Quality/FieldSummaries/Ratio/FormatRatioFieldSummary';
import { useFormat } from '~/hooks/useFormat';
import { PercentageFieldSummary, UnitFieldSummary } from '~/types/types';

interface SummaryUnitProps {
  fieldSummary: FieldSummary | UnitFieldSummary;
}

/**
 * FormatMeasurement is a component that displays the summarizable (boolean / measurement) of a field with its unit.
 * @param fieldSummary
 * @constructor
 */
export const FormatSummarizable = ({ fieldSummary }: SummaryUnitProps) => {
  const { t } = useTranslation();
  const { formatFractionPercentage } = useFormat();

  if ('average_ratio' in fieldSummary.summarizable) {
    return <FormatRatioFieldSummary fieldSummary={fieldSummary.summarizable}/>;
  }

  if ('mean' in fieldSummary.summarizable) {
    const measurementFieldSummary =
      fieldSummary.summarizable as MeasurementFieldSummary;

    if (!measurementFieldSummary.mean) {
      return 'N/A';
    }

    return (
      <>
        {/* TODO: Put unit in field */}~{' '}
        {measurementFieldSummary.mean?.toFixed(2)} {fieldSummary.field?.unit}
      </>
    );
  }

  if ('count_true' in fieldSummary.summarizable) {
    const booleanFieldSummary =
      fieldSummary.summarizable as BooleanFieldSummary;

    return (
      <>
        {booleanFieldSummary.count_true} {t('yes')}
      </>
    );
  }

  if ('total' in fieldSummary.summarizable) {
    return <></>;
  }

  if (fieldSummary.summarizable_type === 'PercentageFieldSummary') {
    const summary = fieldSummary.summarizable as PercentageFieldSummary;

    const showByFraction = summary.fraction_major_defects || summary.fraction_minor_defects;

    if (showByFraction) {
      return (
        <div className="flex space-x-1">
          {!!summary.fraction_major_defects && (
            <MultiLabel theme="red" label={t('major')}>
              <FormatCount
                count={summary.individual_counts ?? 0} unitType={summary.unit_type ?? ''}/>
            </MultiLabel>
          )}
          {!!summary.fraction_minor_defects && (
            <MultiLabel theme="gray" label={t('minor')}>
              {formatFractionPercentage(summary.fraction_minor_defects)}
            </MultiLabel>
          )}
        </div>
      );
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const percentage = formatFractionPercentage(fieldSummary.value);

    return (
      <MultiLabel theme="gray" label={t('defects')}>
        {percentage}
      </MultiLabel>
    );
  }

  return <div>N/A supported</div>;
};
