import React from 'react';
import { useForm } from 'react-hook-form';

import { Button } from '@/Button';
import { SideLabeledField } from '@/SideLabeledField';
import { Input, InputGroup, LeftAddon } from '@/Input';
import { CardTitle, PageDescription, PageTitle } from '@/Text';

import { usePageProps } from '../../../../Shared/hooks/usePageProps';
import { PageProps } from '../../../../Shared/types/types';
import { SettingsHeader } from '../SettingsHeader';
import SettingsLayout from '../SettingsLayout';

type AboutOrganizationSettingsProps = PageProps

const AboutOrganizationSettingsPage = () => {
  const { organization } = usePageProps<AboutOrganizationSettingsProps>();

  const { register } = useForm({
    defaultValues: {
      title: organization.title,
      url: `/b/${organization.id}`,
      country: organization.country,
    },
  });

  return (
    <div>
      <div className="space-y-6">
        <SettingsHeader>
          <PageTitle>
            About <i>{organization.title}</i>
          </PageTitle>
          <PageDescription>
            Manage your organization settings
          </PageDescription>
        </SettingsHeader>

        <hr />

        <div className="space-y-8">
          <CardTitle>
            General
          </CardTitle>

          <div className="space-y-4">
            <div>
              <SideLabeledField
                label="Name"
                renderValue={(
                  <Input status="disabled" type="text" {...register('title')} disabled/>
                )}
              />
            </div>
            <div>
              <SideLabeledField
                label="Link"
                renderValue={(
                  <InputGroup>
                    <LeftAddon>app.agstacked.com/</LeftAddon>
                    <Input rounded="right" status="disabled" type="url" {...register('url')} disabled/>
                  </InputGroup>
                )}
              />
            </div>
            <div>
              <SideLabeledField
                label="Country"
                renderValue={(
                  <Input status="disabled" type="text" {...register('country')} disabled/>
                )}
              />
            </div>
          </div>

          <div>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Button disabled={true}>
              Update
            </Button>
          </div>
        </div>

        <hr />
      </div>
    </div>
  );
};

AboutOrganizationSettingsPage.layout = (page: React.ReactNode) => <SettingsLayout>{page}</SettingsLayout>;

export default AboutOrganizationSettingsPage;
