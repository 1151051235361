// Inspired by react-hot-toast library
import { Errors } from '@inertiajs/inertia';
import * as React from 'react';

import { CheckIcon, ErrorIcon, ToastIcon } from '@/Icon';
import {
  type ToastActionElement,
  type ToastProps,
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from '@/Toast';

const TOAST_LIMIT = 1;
const TOAST_REMOVE_DELAY = 10000;

type ToasterToast = ToastProps & {
  id: string
  title?: React.ReactNode | string
  description?: React.ReactNode | string
  action?: ToastActionElement
  error?: Error | Errors;
  callToActions?: React.ReactNode;
  variant?: 'default' | 'destructive'
}

const actionTypes = {
  ADD_TOAST: 'ADD_TOAST',
  UPDATE_TOAST: 'UPDATE_TOAST',
  DISMISS_TOAST: 'DISMISS_TOAST',
  REMOVE_TOAST: 'REMOVE_TOAST',
} as const;

let count = 0;

function genId() {
  count = (count + 1) % Number.MAX_VALUE;
  return count.toString();
}

type ActionType = typeof actionTypes

type Action =
  | {
  type: ActionType['ADD_TOAST']
  toast: ToasterToast
}
  | {
  type: ActionType['UPDATE_TOAST']
  toast: Partial<ToasterToast>
}
  | {
  type: ActionType['DISMISS_TOAST']
  toastId?: ToasterToast['id']
}
  | {
  type: ActionType['REMOVE_TOAST']
  toastId?: ToasterToast['id']
}

interface State {
  toasts: ToasterToast[]
}

const toastTimeouts = new Map<string, ReturnType<typeof setTimeout>>();

const addToRemoveQueue = (toastId: string) => {
  if (toastTimeouts.has(toastId)) {
    return;
  }

  const timeout = setTimeout(() => {
    toastTimeouts.delete(toastId);
    dispatch({
      type: 'REMOVE_TOAST',
      toastId: toastId,
    });
  }, TOAST_REMOVE_DELAY);

  toastTimeouts.set(toastId, timeout);
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
  case 'ADD_TOAST':
    return {
      ...state,
      toasts: [action.toast, ...state.toasts].slice(0, TOAST_LIMIT),
    };

  case 'UPDATE_TOAST':
    return {
      ...state,
      toasts: state.toasts.map((t) =>
        t.id === action.toast.id ? { ...t, ...action.toast } : t
      ),
    };

  case 'DISMISS_TOAST': {
    const { toastId } = action;

    // ! Side effects ! - This could be extracted into a dismissToast() action,
    // but I'll keep it here for simplicity
    if (toastId) {
      addToRemoveQueue(toastId);
    } else {
      state.toasts.forEach((toast) => {
        addToRemoveQueue(toast.id);
      });
    }

    return {
      ...state,
      toasts: state.toasts.map((t) =>
        t.id === toastId || toastId === undefined
          ? {
            ...t,
            open: false,
          }
          : t
      ),
    };
  }
  case 'REMOVE_TOAST':
    if (action.toastId === undefined) {
      return {
        ...state,
        toasts: [],
      };
    }
    return {
      ...state,
      toasts: state.toasts.filter((t) => t.id !== action.toastId),
    };
  }
};

const listeners: Array<(state: State) => void> = [];

let memoryState: State = { toasts: [] };

function dispatch(action: Action) {
  memoryState = reducer(memoryState, action);
  listeners.forEach((listener) => {
    listener(memoryState);
  });
}

type Toast = Omit<ToasterToast, 'id'>

export function toast({ ...props }: Toast) {
  const id = genId();

  const update = (props: ToasterToast) =>
    dispatch({
      type: 'UPDATE_TOAST',
      toast: { ...props, id },
    });
  const dismiss = () => dispatch({ type: 'DISMISS_TOAST', toastId: id });

  dispatch({
    type: 'ADD_TOAST',
    toast: {
      ...props,
      id,
      open: true,
      onOpenChange: (open) => {
        if (!open) dismiss();
      },
    },
  });

  return {
    id: id,
    dismiss,
    update,
  };
}

export function useToast() {
  const [state, setState] = React.useState<State>(memoryState);

  React.useEffect(() => {
    listeners.push(setState);
    return () => {
      const index = listeners.indexOf(setState);
      if (index > -1) {
        listeners.splice(index, 1);
      }
    };
  }, [state]);

  return {
    ...state,
    toast,
    dismiss: (toastId?: string) => dispatch({ type: 'DISMISS_TOAST', toastId }),
  };
}

export function getErrorOutput(error: Error | Errors | string | undefined) {
  if (error instanceof Error) {
    return error.message;
  }

  if (!!error && typeof error === 'object') {
    return JSON.stringify(error, null, 2);
  }

  if (!error) return null;

  return error;
}

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, error, callToActions, variant, ...props }) {
        return (
          <Toast key={id} {...props}>
            <div className="grid gap-1">
              <div className="flex w-full gap-2">
                {variant === 'destructive' && (
                  <ToastIcon className="mt-0.5 stroke-red-500 bg-red-50" icon={ErrorIcon}/>
                )}

                {(!variant || variant === 'default') && (
                  <ToastIcon
                    className="mt-0.5 stroke-emerald-500 border !border-emerald-100 bg-emerald-50 !w-auto !h-auto"
                    icon={CheckIcon}/>
                )}
                <div className="w-full">
                  {title && <ToastTitle>{title}</ToastTitle>}
                  {description && (
                    <ToastDescription>{description}</ToastDescription>
                  )}
                  {error && (
                    <ToastDescription className="px-2 overflow-scroll whitespace-pre-wrap bg-gray-100">
                      <pre className="whitespace-pre-wrap">{getErrorOutput(error)}</pre>
                    </ToastDescription>
                  )}
                  {callToActions && (
                    <div>
                      <hr/>
                      <div className="mt-1">{callToActions}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {action}
            <ToastClose/>
          </Toast>
        );
      })}
      <ToastViewport/>
    </ToastProvider>
  );
}
