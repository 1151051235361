/* eslint-disable @typescript-eslint/ban-ts-comment */
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { z } from 'zod';

import { Button } from '@/Button';
import { Card, CardBody } from '@/Card';
import { Container } from '@/Container';
import { FormGrid, FormItem, InputLabel } from '@/Form';
import { Breadcrumbs } from '@/Layout';
import { PinInput } from '@/PinInput';
import { Spinner } from '@/Spinner';
import { PageDescription, PageTitle } from '@/Text';
import { useAction } from '~/hooks/useAction';
import { usePageProps } from '~/hooks/usePageProps';
import { PageProps } from '~/types/types';

import { AuthLayout } from './AuthLayout';

interface VeryifyLoginPageProps extends PageProps {
  email: string;
  issue?: string;
}

const pinCodeSchema = z.object({
  pin_code: z.string({ required_error: 'Pin code is required' }).min(6, { message: 'Pin code should be 6 characters.' }).max(6, { message: 'Pin code should be 6 characters.' }),
  email: z.string({ required_error: 'Email is required' }).email({ message: 'Email is invalid' }),
});

type PinCodeSchema = z.infer<typeof pinCodeSchema>;

const VerifyLoginPage = () => {
  const { email, issue } = usePageProps<VeryifyLoginPageProps>();
  const { post: login, loading } = useAction('/verify_login');

  const { formState: { isValid, errors, isDirty }, handleSubmit, reset, control } = useForm<PinCodeSchema>({
    resolver: zodResolver(pinCodeSchema),
    defaultValues: {
      pin_code: '',
      email: email,
    },
  });

  const submit = (data: PinCodeSchema) => {
    login(data);
    reset({ pin_code: '', email: email });
  };

  return (
    <AuthLayout>
      <div className="flex flex-col justify-center min-h-screen">
        <Container className="flex justify-center py-20 mx-auto">
          <div className="space-y-4">
            <div>
              <Card className="max-w-[650px] min-w-[400px]">
                <CardBody className="!p-16">
                  <div>
                    <img
                      className="h-12 mx-auto"
                      src="assets/logo.svg"
                      alt="AgStacked"
                    />
                    <div className="mt-4">
                      <PageTitle className="!text-xxl text-center">
                        {t('verify_login')}
                      </PageTitle>
                      <PageDescription className="mt-2 text-center whitespace-pre-line">
                        <Trans
                          i18nKey="verify_login_description"
                          values={{
                            email,
                          }}
                        >
                          <strong />
                        </Trans>
                      </PageDescription>
                    </div>
                  </div>
                  {loading ? (
                    <div className='flex justify-center mt-6'><Spinner className='w-10 h-10 mr-0'/> </div>
                  ) : (
                    <form onSubmit={handleSubmit(submit)} className="mt-8">
                      <FormGrid>
                        <FormItem className='flex justify-center'>
                          <div className="space-y-4">
                            <InputLabel className="mb-4" htmlFor="pin_code">
                              {t('pin_code')}
                            </InputLabel>

                            <Controller
                              name="pin_code"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <PinInput
                                  id="pin_code"
                                  length={6}
                                  autoFocus
                                  required
                                  name={field.name}
                                  onBlur={field.onBlur}
                                  value={field.value}
                                  onChange={(value) => {
                                    field.onChange(value);
                                  }}
                                />
                              )}
                            />
                            <Button
                            // @ts-ignore
                              disabled={!isValid}
                              type="submit"
                              variant="primary"
                              className="relative flex justify-center w-full px-3 py-2"
                            >
                              {t('verify_login')}
                            </Button>
                          </div>
                        </FormItem>
                        <div>
                        </div>
                        {(errors.pin_code) && (
                          <div className="flex justify-center mt-4 text-red-500">
                            {errors.pin_code?.message}
                          </div>
                        )}
                        {(issue && !isDirty) && (
                          <div className="flex justify-center mt-4 text-red-500">
                            {issue}
                          </div>
                        )}
                      </FormGrid>
                    </form>
                  )}
                </CardBody>
              </Card>
            </div>
            <div className="flex justify-center">
              <Breadcrumbs backLink={{ type: 'link', name: 'Login', href: '/login' }} />
            </div>
          </div>
        </Container>
      </div>
    </AuthLayout>
  );
};

export default VerifyLoginPage;
