import { MoveRight, TrendingDownIcon, TrendingUpIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { WithGenericFallback } from '@/Fallback';
import { LabelIcon } from '@/Icon';
import { Label } from '@/Label';
import { Tooltipped } from '@/Tooltip';
import { useFormat } from '~/hooks/useFormat';
import { RelativeDifference } from '~/types/types';
import DifferenceType = App.Domain.Analytics.DifferenceType;
import { cva } from 'class-variance-authority';

import { Theme } from '~/utils/colors';

interface RelativeDifferenceProps {
  difference: RelativeDifference;
}

const themeMap: Record<DifferenceType, Theme> = {
  positive: 'emerald',
  negative: 'red',
  neutral: 'gray',
};

const labelStyle = cva('', {
  variants: {
    variant: {
      positive: 'stroke-emerald-500',
      negative: 'stroke-rose-500',
      neutral: 'stroke-gray-500',
    },
  },
  defaultVariants: {
    variant: 'neutral',
  },
});

type Direction = 'up' | 'down' | 'straight';

const getDirection = (difference: RelativeDifference): Direction => {
  if (difference.differenceType === 'neutral') {
    return 'straight';
  }

  return difference.fractionalDifference > 0 ? 'up' : 'down';
};

/**
 * A component that displays a relative difference between a current date and previous date.
 * @param difference
 * @constructor
 */
export const RelativeDifferenceLabel = ({ difference }: RelativeDifferenceProps) => {
  const { t } = useTranslation();
  const value = difference.fractionalDifference;
  const increaseOrDecrease = value > 0 ? t('increased') : t('decreased');

  const { formatFractionPercentage } = useFormat();

  const formattedValue = formatFractionPercentage(Math.abs(value));

  const direction = getDirection(difference);

  const theme = themeMap[difference.differenceType as DifferenceType];
  const period = difference.relativeToDate?.asComparisonType ?? 'previous_period';

  return (
    <WithGenericFallback>
      <Tooltipped label={`${increaseOrDecrease} of ${formattedValue} as opposed to ${t(period)}`}>
        <Label theme={theme}>
          {direction === 'down' && (
            <LabelIcon className={labelStyle({ variant: difference.differenceType })} icon={TrendingDownIcon}/>
          )}

          {direction === 'up' && (
            <LabelIcon className={labelStyle({ variant: difference.differenceType })} icon={TrendingUpIcon}/>
          )}

          {direction === 'straight' &&
            <LabelIcon className={labelStyle({ variant: difference.differenceType })} icon={MoveRight}/>}

          {formattedValue}
        </Label>
      </Tooltipped>
    </WithGenericFallback>
  );
};
