import { Trans, useTranslation } from 'react-i18next';

import { Card, CardBody, CardHeaderContainer, CardPanels } from '@/Card';
import CostsModule = App.Domain.Reports.Modules.Costs.CostsModule;
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from 'recharts';

import { ChartContainer, ChartLegend, ChartLegendContent, ChartTooltip, ChartTooltipContent } from '@/Chart';
import {
  QualityCostsIcon,
  RegionIcon,
  SellerIcon,
  SimpleCardHeaderIcon,
} from '@/Icon';
import { CardDescription, CardTitle, DescriptionText, MutedText, Strong } from '@/Text';
import { GenericBreakdown } from '~/Components/Analytics/Charts/Breakdown/GenericBreakdown';
import { GenericRanking } from '~/Components/Analytics/Charts/Breakdown/GenericRanking';
import { useAction } from '~/hooks/useAction';
import { useFormat } from '~/hooks/useFormat';
import { buildDynamicChartConfig, formatDate, fuseEntityTimeSeries } from '~/utils/charts';
import CostsConfig = App.Domain.Reports.Modules.Costs.CostsConfig;

import { BuildingIcon } from 'lucide-react';

import { RelativeDifferenceLabel } from '~/Components/Analytics/RelativeDifferenceLabel';
import Section from '~/Components/Common/Section';
import { SortButton } from '~/Components/Common/SortButton';
import { SortOrder } from '~/types/types';
import { themeTokens } from '~/utils/colors';

import { ReportsFilter } from '../../types';
import ReportsPageOutput = App.Http.Controllers.Reports.ReportsPageOutput;
import { GenericPicker } from '@/Pickers/GenericPicker';
import { DeepPartial } from '~/utils/utils';

interface GeneralCostsCardProps {
  module: CostsModule;
}

export const GeneralCostsCard = ({ module }: GeneralCostsCardProps) => {
  const { t } = useTranslation();
  const { formatCurrency } = useFormat();

  const { reload, loading } = useAction<DeepPartial<ReportsFilter>, ReportsPageOutput>('', {
    withParams: true,
    only: ['costsModule'],
  });

  const costsOverTime = module.results.costsOverTime ?? [];
  const relativeCostsOverTime = module.results.previousCostsOverTime ?? [];

  const chartData = fuseEntityTimeSeries([...relativeCostsOverTime, ...costsOverTime]);
  const config = buildDynamicChartConfig([...costsOverTime, ...relativeCostsOverTime], formatCurrency);

  const sort = module.config.sortRanking;
  const scope = module.config.entityScopeType;

  const handleScopeChange = (scope: CostsConfig['entityScopeType']) => {
    reload({
      overrideCostsConfig: {
        entityScopeType: scope,
      },
    });
  };

  const handleSortChange = (direction: SortOrder) => {
    reload({
      overrideCostsConfig: {
        sortRanking: {
          ...sort,
          direction,
        },
      },
    });
  };

  return (
    <Section icon={QualityCostsIcon} defaultOpen={false} title={t('reports.costs')}
      description={t('reports.costs_description')} showCollapsed
      enableToggle>
      <Card isLoading={loading}>
        <CardHeaderContainer size="sm">
          <div className="flex items-center justify-between gap-2">
            <div>
              <SimpleCardHeaderIcon icon={QualityCostsIcon}/>
              <MutedText>
                {t('reports.costs')}
              </MutedText>
            </div>
          </div>
        </CardHeaderContainer>
        <CardBody bordered="y">
          <div className="flex justify-between center">
            <div>
              <CardTitle>
                {t('reports.quality_costs_over_time')}
              </CardTitle>
              <CardDescription>
                {t('reports.quality_costs_over_time_description')}
              </CardDescription>
            </div>
          </div>
        </CardBody>
        <div className="border-b px-8 py-2 bg-gray-50 justify-end flex">
          <div className="flex gap-4">
            <div>
              <GenericPicker
                title="Comparison"
                value={scope}
                showTitleOnSelected
                onChange={handleScopeChange}
                showClear={false}
                options={[
                  {
                    value: 'seller',
                    label: t('sellers'),
                    icon: SellerIcon,
                    subLabel: t('compare_sellers') as string,
                  },
                  {
                    value: 'region',
                    label: t('regions'),
                    icon: RegionIcon,
                    subLabel: t('compare_regions') as string,
                  },
                ]}
                emptyMessage=""
                placeholder=""
                icon={BuildingIcon}
              />
            </div>

            {/*<div>*/}
            {/*  <GenericPicker*/}
            {/*    title="Comparison timeframe"*/}
            {/*    value={module.config.comparisonType}*/}
            {/*    onChange={handleScopeChange}*/}
            {/*    options={module.results?.comparisonDateFilters?.map((dateFilter, index) => ({*/}
            {/*      value: dateFilter.asComparisonType,*/}
            {/*      label: t(dateFilter.asComparisonType) as string,*/}
            {/*      subLabel: formatDateRange(parse(dateFilter.fromDate), parse(dateFilter.toDate)),*/}
            {/*    })) ?? []}*/}
            {/*    emptyMessage=""*/}
            {/*    placeholder=""*/}
            {/*    icon={BuildingIcon}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        </div>
        <CardPanels columns="2_1">
          <CardBody>
            <ChartContainer className="min-h-[200px]" config={config}>
              <LineChart data={chartData}>
                <CartesianGrid vertical={false}/>
                <XAxis
                  dataKey="date"
                  tickLine={false}
                  axisLine={false}
                  tickMargin={8}
                  minTickGap={2}
                  tickFormatter={(value) => {
                    const date = new Date(value);
                    return formatDate(date, module.config.dateFilter);
                  }}
                />

                <YAxis
                  className="fill-gray-700 w-24"
                  width={90}
                  stroke="#D1D5DB"
                  tickFormatter={(value) => formatCurrency(value)}
                />

                <ChartTooltip
                  cursor={false}
                  content={
                    <ChartTooltipContent
                      labelFormatter={(value) => {
                        const date = new Date(value);
                        return formatDate(date, module.config.dateFilter);
                      }}
                      indicator="dot"
                    />
                  }
                />

                {Object.keys(config).map((key) => (
                  <Line
                    key={key}
                    dataKey={key}
                    type="monotone"
                    // strokeDasharray={config[key].isComparison ? '5 5' : '0'}
                    // strokeWidth={config[key].isComparison ? 1 : 2}
                    stroke={`var(--color-${key})`}
                  />
                ))}
                <ChartLegend height={100} content={<ChartLegendContent/>}/>
              </LineChart>
            </ChartContainer>
          </CardBody>
          <CardBody className="border-t" bordered="left" variant="muted">
            <div className="space-y-4">
              <div className="flex justify-between items-center gap-4">
                <div>
                  <Strong>
                    {t('reports.total_costs')}
                  </Strong>
                  <div>
                    <MutedText>
                      {formatCurrency(module.results.total)}
                    </MutedText>
                  </div>
                </div>

                <div>
                  {module.results.relativeTotalDifference && (
                    <RelativeDifferenceLabel difference={module.results.relativeTotalDifference}/>
                  )}
                </div>
              </div>

              <hr/>

              <div>
                <div>
                  <Strong>
                    {t('reports.breakdown')}
                  </Strong>
                  <div className="mb-4">
                    <DescriptionText>
                      {t('reports.costs_breakdown_description')}
                    </DescriptionText>
                  </div>
                </div>

                <div>
                  <GenericBreakdown
                    label={t('reports.costs')}
                    color={themeTokens.red}
                    formatValue={formatCurrency}
                    data={module.results.reasonBreakdown}
                  />
                </div>
              </div>

              <hr/>

              <div>
                <div className="flex gap-4 items-center justify-between mb-4">
                  <div>
                    <Strong>
                      {t('reports.ranking')}
                    </Strong>
                    <DescriptionText>
                      <Trans
                        i18nKey="reports.costs_ranking_description"
                        values={{ entity: t(`${scope}s`) }}
                      >
                        <i/>
                      </Trans>
                    </DescriptionText>
                  </div>

                  <div>
                    {sort && (
                      <SortButton sort={sort} onSortChange={handleSortChange}/>
                    )}
                  </div>
                </div>

                {module.results.ranking && (
                  <div>
                    <GenericRanking
                      data={module.results.ranking}
                      label={t('reports.costs')}
                      color={themeTokens.red}
                      formatValue={formatCurrency}
                    />
                  </div>
                )}
              </div>
            </div>
          </CardBody>
        </CardPanels>
      </Card>
    </Section>
  );
};

