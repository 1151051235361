import { parse } from 'qs';
import { useEffect, useRef } from 'react';

import { useAction } from './useAction';
import { useError } from './useError';

interface UseQueryParamsFromStorageProps {
  [key: string]: string | string[];
}

export const useQueryParamsFromStorage = <K extends UseQueryParamsFromStorageProps>(key: string) => {
  const { log } = useError();
  const count = useRef(0);
  const { reload, loading } = useAction('', {
    preserveState: true,
    withParams: true,
  });

  const handleParamsChange = (paramsData: object) => {
    const filteredParams = Object.fromEntries(
      Object.entries(paramsData).filter(([_, value]) => {
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return !!value;
      })
    );

    if (Object.keys(filteredParams).length === 0) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(filteredParams));
    }
  };

  let storageParams = null;
  if (localStorage.getItem(key)) {
    try {
      storageParams = JSON.parse(localStorage.getItem(key) as string);
    } catch (error) {
      log(new Error('OrdersPage: Could not parse local storage data, removing local storage.'));
      reload({});
    }
  }

  const urlParams = parse(window.location.search, { ignoreQueryPrefix: true });
  const needsReload = Object.values(storageParams ?? {}).length > 0 && Object.values(urlParams).length === 0;

  useEffect(() => {
    if (!needsReload) {
      count.current = 0;
    }

    if (count.current >= 5) {
      log(new Error('OrdersPage: Too many reloads indicating potential faulty data, removing local storage.'));
      localStorage.removeItem(key);
      reload({});
    }

    if (needsReload && !loading) {
      count.current = count.current + 1;
      reload(storageParams);
    }
  }, [needsReload, loading, log, key, reload, storageParams]);

  return {
    handleParamsChange,
    isLoading: loading || needsReload,
    storageParams: storageParams as K,
    hasStorageParams: !!storageParams,
    clearStorageParams: () => {
      localStorage.removeItem(key);
    },
  };
};
