import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Input } from '@/Input';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTrigger,
} from '@/Modal';
import { SideLabeledField } from '@/SideLabeledField';
import { BaseAction } from '~/Actions/BaseAction';
import { useCommandAction } from '~/hooks/useCommandAction';
import { Organization } from '~/types/types';

interface InviteUserModalProps {
  organization: Organization;
  children: React.ReactNode;
  action: typeof BaseAction;
}

export const InviteUserModal = ({ children, action }: InviteUserModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const [create, { loading }] = useCommandAction(action, null, () => setIsOpen(false));

  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      email: '',
    },
  });

  return (
    <Modal open={isOpen} onOpenChange={setIsOpen}>
      <ModalTrigger asChild>
        {children}
      </ModalTrigger>

      <ModalContent>
        <form onSubmit={handleSubmit(create.execute)}>
          <ModalHeader>
            <ModalTitle>
              {t('invite_user')}
            </ModalTitle>
            <ModalDescription>
              Invite a new user to the workspace
            </ModalDescription>
          </ModalHeader>
          <ModalBody>
            <div className="space-y-4">
              <SideLabeledField label="Name"
                renderValue={(<Input type="text" {...register('name', { required: true })} />)}/>
              <SideLabeledField label="email"
                renderValue={(<Input type="text" {...register('email', { required: true })} />)}/>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="flex items-center space-x-2">
              <Button type="submit">
                Invite
              </Button>
              <Button type="submit" variant="white" onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
