import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { Container } from '@/Container';
import { WithAwaitingFallback } from '@/Fallback';
import { ProduceIcon } from '@/Icon';
import { LayoutHeader } from '@/Layout';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { Spinner } from '@/Spinner';
import { TableGroupHeader } from '@/Table/TableGroupHeader';
import { PageDescription, PageTitle } from '@/Text';
import { useAction } from '~/hooks/useAction';
import { Issue } from '~/types/types';

import { DashboardLayout } from '../DashboardLayout';

const issuesSchema = z.object({
  search: z.string().optional(),
  stepType: z.string().optional(),
  produceIds: z.array(z.string()).optional(),
  supplier_ids: z.array(z.string()).optional(),
  region: z.string().optional(),
  onlyTemperature: z.boolean().optional(),
  onlyLocation: z.boolean().optional(),
  onlyIssues: z.boolean().optional(),
}).strict();

type OrdersQueryParams = z.infer<typeof issuesSchema>;

interface IssuesPageProps {
  parameters: OrdersQueryParams;
  filterOptions: {}
  issuesByDate: Record<string, Issue[]>;
}

const GroupedRows = ({ issuesByDate, label }: { issuesByDate: Record<string, Issue[]>, label: string }) => {
  const { t } = useTranslation();

  if (!(label in issuesByDate)) {
    return null;
  }

  const issues = issuesByDate[label];

  return (
    <>
      <TableGroupHeader>
        {t(label)}
      </TableGroupHeader>
      <div className="space-y-4">
        
      </div>
    </>
  );
};

const IssuesPage = ({ issuesByDate, parameters }: IssuesPageProps) => {
  const isLoading = false;
  const { reload } = useAction('');

  return (
    <>
      <LayoutHeader>
        <PageTitle>
          Issues
        </PageTitle>
        <PageDescription>Examine recent issues</PageDescription>
      </LayoutHeader>
      <Container className="w-full py-4">
        {isLoading ? (
          <div className="flex h-[400px] items-center justify-center">
            <Spinner className="w-12 h-12 animate-spin"/>
          </div>
        ) : (
          <WithAwaitingFallback>
            <div>
              {/* Filters here*/}
              <div className="flex items-center space-x-2">
                <div>

                </div>
                <GenericPicker
                  icon={ProduceIcon}
                  options={[]}
                  emptyMessage='No produce found'
                  isMulti
                  selected={parameters.produceIds ? parameters.produceIds : []}
                  onChange={(produceIds) => {
                    reload({
                      produceIds: produceIds,
                    }, { type: 'schema', schema: issuesSchema });
                  }}
                  placeholder='Search produce...'
                  title='Produce'
                />
              </div>
              <div>
              </div>
              <div className="space-y-2">
                <GroupedRows issuesByDate={issuesByDate} label="today"/>
                <GroupedRows issuesByDate={issuesByDate} label="yesterday"/>
                <GroupedRows issuesByDate={issuesByDate} label="this_week"/>
                <GroupedRows issuesByDate={issuesByDate} label="this_month"/>
                <GroupedRows issuesByDate={issuesByDate} label="older"/>
              </div>
            </div>
          </WithAwaitingFallback>
        )}
      </Container>
    </>
  );
};

IssuesPage.layout = (page: ReactNode) => (
  <DashboardLayout>{page}</DashboardLayout>
);

export default IssuesPage;
