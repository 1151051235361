import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from '~/utils/cn';

interface Option {
  value: string;
  title_key: string;
  description_key: string;
}

interface SelectOptionsProps {
  titleKey: string;
  options: Option[];
  onChange: (value?: string) => void;
  value?: string;
  icon?: JSX.Element;
}

/**
 * SelectOptions component
 * Selects an option from a list of options
 *
 * @param titleKey
 * @param value
 * @param onChange
 * @param options
 * @constructor
 */
export const SelectOptions = ({ titleKey, value, onChange, options, icon }: SelectOptionsProps) => {
  const selected = options.find((option) => option.value === value) || options[0];
  const { t } = useTranslation();

  const handleChange = (value: Option) => {
    onChange(value.value as string);
  };

  return (
    <Listbox value={selected} onChange={handleChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">
            {t(titleKey)}
          </Listbox.Label>
          <div className="relative">
            <div className="inline-flex divide-x divide-indigo-700 rounded-md shadow-sm">
              <div
                className="inline-flex items-center gap-x-1.5 rounded-l-md bg-indigo-600 px-3 py-2 text-white shadow-sm">
                <div className="-ml-0.5">
                  {icon}
                </div>
                <p className="text-sm font-semibold">{t(selected.title_key)}</p>
              </div>
              <Listbox.Button
                className="inline-flex items-center p-2 bg-indigo-600 rounded-l-none rounded-r-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-gray-50">
                <span className="sr-only">{t(titleKey)}</span>
                <ChevronDownIcon className="w-5 h-5 text-white" aria-hidden="true"/>
              </Listbox.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className="absolute right-0 z-10 mt-2 overflow-hidden origin-top-right bg-white divide-y divide-gray-200 rounded-md shadow-lg sm:left-0 md:left-auto w-72 ring-1 ring-black ring-opacity-5 focus:outline-none">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      cn(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'cursor-default select-none p-4 text-sm'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p className={selected ? 'font-semibold' : 'font-normal'}>{t(option.title_key)}</p>
                          {selected ? (
                            <span className={active ? 'text-white' : 'text-indigo-600'}>
                              <CheckIcon className="w-5 h-5" aria-hidden="true"/>
                            </span>
                          ) : null}
                        </div>
                        <p className={cn(active ? 'text-indigo-200' : 'text-gray-500', 'mt-2')}>
                          {t(option.description_key)}
                        </p>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
