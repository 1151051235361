import { useEffect, useState } from 'react';

// TODO: Somehow grab these from tailwind to make it more stable
const SCREENS = {
  sm: 480,
  md: 768,
  lg: 976,
  xl: 1440,
};

const isSmall = (width: number) => width < SCREENS.sm;
const isMedium = (width: number) => width >= SCREENS.sm && width < SCREENS.md;
const isLarge = (width: number) => width >= SCREENS.md && width < SCREENS.lg;
const isXLarge = (width: number) => width >= SCREENS.lg && width < SCREENS.xl;

const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // Set the initial screen width
    setScreenWidth(window.innerWidth);

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array means this effect runs once after the initial render

  return {
    screenWidth, isSmall, isMedium, isLarge, isXLarge,
  };
};

export default useScreenWidth;
