import { usePostHog } from 'posthog-js/react';
import { useCallback } from 'react';

import { TrackingEvent } from '~/types/events';

import { usePageProps } from './usePageProps';

export const useTracking = () => {
  const posthog = usePostHog();
  const props = usePageProps();
  const track = useCallback(({ event, ...data }: TrackingEvent) => {
    if (!posthog) {
      return console.warn('Posthog is not initialized');
    }

    posthog.capture(event, { ...data, user_id: props.user.id, email: props.user.email });
  }, [posthog]);

  return { track };
};
