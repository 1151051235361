import { usePostHog } from 'posthog-js/react';
import { useEffect, useRef } from 'react';

export const usePageTracking = (location: string) => {
  const posthog = usePostHog();
  const prevPage = useRef('');
  useEffect(() => {
    if (location && posthog && location !== prevPage.current) {
      posthog.capture('$pageview');
      prevPage.current = location;
    }
  }, [location, posthog]);
};
