import { SaveIcon } from '@/Icon';
import { ActionAppType, ActionContext, ActionPost, BaseAction } from '~/Actions/BaseAction';
import { BuyerRoutes } from '~/hooks/useRoutes';
import { Spec } from '~/types/types';
import SpecFieldInput = App.Domain.Specs.SpecFieldInput;

export class SaveSpecField extends BaseAction {
  constructor(public context: ActionContext<Spec>, public post: ActionPost) {
    super(context, post);
  }

  /** Default icon for this action */
  public static icon = SaveIcon;

  /** Name for posthog analytics */
  public static analyticsName = 'save_field';

  /** Execute for `buyer` or `seller` */
  public static type: ActionAppType = 'buyer';

  /** Message to show on `toast` */
  public static toast = {
    titleKey: 'saved_field',
  };

  /** Default translation key */
  public nameKey() {
    return 'save_field';
  }

  /** Callback to check if this should be disabled (using `context.auth` auth, etc) */
  public disabled() {
    // Base this on context?
    return false;
  }

  /**
   * Execute the action
   * @param data
   */
  public execute = async (data: SpecFieldInput) => {
    const routes = this.context.routes as BuyerRoutes;
    const route = routes.saveSpecField;

    await this.post(route(this.context.target.id), data);
  };
}
