"use client";

import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { CheckIcon } from "@heroicons/react/24/outline";
import { router } from "@inertiajs/react";
import { t } from "i18next";
import * as React from "react";

import { cn } from "~/utils/cn";
import { Button } from "@/Button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/Command";
import { ResponsivePicker } from "./ResponsivePicker";
import { useTranslation } from "react-i18next";

interface ProducePickerProps {
  selectedProduce?: string;
  produces?: { value: string; label: string }[];
  disabled?: boolean;
  onChange?: (value: string | null) => void;
}

export const ProducePicker = ({
  selectedProduce = "",
  produces = [],
  disabled = false,
  onChange,
}: ProducePickerProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const setProduce = (produce: string) => {
    const prevSelectedProduce = selectedProduce;
    if (prevSelectedProduce === produce) {
      return;
    }
    if (onChange) {
      onChange(produce);
      return;
    }

    router.reload({
      preserveScroll: true,
      preserveState: true,
      data: {
        produce: produce,
      },
    });
  };

  return (
    <ResponsivePicker
      title={t("produce")}
      open={open}
      onOpenChange={setOpen}
      disabled={disabled}
      Trigger={
        <Button
          aria-disabled={disabled}
          // @ts-ignore
          disabled={disabled}
          role="combobox"
          variant="white"
          aria-expanded={open}
          className="flex items-center justify-between w-full"
        >
          {selectedProduce
            ? produces.find((produce) => produce.value === selectedProduce)
                ?.label
            : "Select produce..."}
          <ChevronUpDownIcon className="w-4 h-4 ml-2 opacity-50 shrink-0" />
        </Button>
      }
    >
      <Command
        filter={(value, search) => {
          const option = produces.find(
            (option) => option.label.toLowerCase() === value
          );
          if (option?.label?.toLowerCase().includes(search.toLowerCase())) {
            return 1;
          }

          return -1;
        }}
      >
        <CommandInput placeholder="Search produce..." />
        <CommandEmpty>No produce found.</CommandEmpty>
        <CommandGroup>
          {produces.map((produce) => (
            <CommandItem
              value={produce.value}
              key={produce.label}
              onSelect={(currentValue) => {
                setProduce(currentValue);
                setOpen(false);
              }}
            >
              <CheckIcon
                className={cn(
                  "mr-2 h-4 w-4",
                  selectedProduce === produce.value
                    ? "opacity-100"
                    : "opacity-0"
                )}
              />
              {produce.label}
            </CommandItem>
          ))}
        </CommandGroup>
      </Command>
    </ResponsivePicker>
  );
};
