interface AccuracyIndicatorProps {
  /** Score should be between 0 and 100 */
  score: number;
}

const calculateClassNames = (score: number): string[] => {
  // Calculate an array of class names based on the score.
  // For example, if the score is 60, we should have 6 'fill-green-500' class names, and 4 'fill-gray-500' class names.

  const basicClassName = 'fill-gray-300';
  const successClassName = 'fill-green-500';
  const errorSuccessClassName = 'fill-red-500';

  // 10 times basicClassName
  const classNames = Array(10).fill(basicClassName);

  // Now we find the indices of the classNames array that should be replaced with successClassName.
  // For example, if the score is 60, we should replace the first 6 elements of the classNames array with successClassName.
  const interestClassNamesCount = Math.floor(Math.max(0, Math.min(100, score)) / 10);
  const interestClassNamesIndices = Array(interestClassNamesCount).fill(0).map((_, i) => i);

  // Now we replace the classNames at the indices we found above with successClassName.
  if (score > 85) {
    interestClassNamesIndices.forEach(i => classNames[i] = successClassName);
  } else {
    interestClassNamesIndices.forEach(i => classNames[i] = errorSuccessClassName);
  }

  return classNames;
};

/**
 * Accuracy indicator
 *
 * @preconditions:
 * - There are 10 paths.
 * - The score ranges between 0 and 100.
 */
export const AccuracyIndicator = ({ score }: AccuracyIndicatorProps) => {
  const classNames = calculateClassNames(score);

  return (
    <svg width="100%" height="100%" viewBox="0 0 59 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.3067 8.30139C18.6588 7.08333 17.7869 6.1559 17.8565 5.30551C17.9983 3.57222 18.2892 0.689541 19.1644 0.426147C20.8475 -0.0803529 24.3613 0.442802 24.669 1.3599C25.6235 4.20448 26.6818 8.17303 25.3985 10.3466C23.3094 13.8849 21.0944 10.801 19.3067 8.30139Z"
        className={classNames[9]}/>
      <path
        d="M12.3213 18.7669C10.2091 17.777 8.11238 17.2221 6.75707 15.9046C5.66367 14.8416 4.57991 12.4222 5.08793 11.3906C5.76217 10.0214 8.83333 8.26243 9.40423 8.68021C12.085 10.642 14.2621 13.2922 16.619 15.6966C15.2983 16.7552 13.9777 17.8138 12.3213 18.7669Z"
        className={classNames[8]}/>
      <path
        d="M7.87405 23.8584C9.80399 25.1087 11.3511 26.2851 12.8982 27.4616C11.2984 28.5698 9.74725 29.7606 8.07481 30.7454C7.40452 31.1401 6.46941 31.0943 5.65128 31.2271C2.57271 31.7266 0.0543466 30.8239 0.00086665 27.4296C-0.0527793 24.0251 2.39063 23.0398 5.51559 23.5516C6.16932 23.6587 6.83243 23.7085 7.87405 23.8584Z"
        className={classNames[7]}/>
      <path
        d="M6.33905 38.9352C8.56237 37.8133 10.4373 36.6038 12.482 36.1359C13.6056 35.8787 15.0149 36.8707 16.2955 37.2994C14.9571 39.7008 14.0444 42.5619 12.1266 44.3374C10.9041 45.4691 8.19221 44.992 6.14815 45.2363C6.11927 43.2099 6.09039 41.1835 6.33905 38.9352Z"
        className={classNames[6]}/>
      <path
        d="M21.0753 55.4613C19.6329 53.8128 17.3498 51.8165 17.7201 50.7013C18.6534 47.8906 20.6942 45.4077 22.5707 43.0124C22.7567 42.7749 25.4193 43.7194 25.9806 44.6746C27.7518 47.6883 24.9162 54.0404 21.0753 55.4613Z"
        className={classNames[5]}/>
      <path
        d="M32.2502 46.0016C34.7521 41.8273 36.2905 41.7151 38.5539 45.2386C39.3538 46.4838 40.0324 47.8225 40.6308 49.1783C41.8024 51.8331 41.5243 54.2279 38.4559 55.1711C35.2324 56.162 33.4599 54.4332 32.9311 51.2935C32.6573 49.6671 32.472 48.0258 32.2502 46.0016Z"
        className={classNames[4]}/>
      <path
        d="M42.0687 39.3284C42.5252 35.4367 44.6374 35.087 47.325 36.5158C50.1794 38.0332 54.7503 38.4354 53.6409 43.2385C52.9355 46.2923 50.241 47.4196 47.5107 45.3711C45.4572 43.8304 43.9131 41.6107 42.0687 39.3284Z"
        className={classNames[3]}/>
      <path
        d="M58.3269 25.5094C59.3242 28.8363 58.0757 31.289 55.1755 31.1796C52.0988 31.0635 49.0648 29.529 46.0844 28.4197C45.988 28.3838 46.4043 25.4063 47.0569 25.1818C50.6815 23.9351 54.4718 21.6688 58.3269 25.5094Z"
        className={classNames[2]}/>
      <path
        d="M44.3373 12.2043C45.408 11.1434 46.1672 9.74289 47.0637 9.64871C49.0353 9.4416 51.0762 9.89457 53.0889 10.0781C52.6541 12.0733 52.5322 14.2226 51.6564 16.0005C51.1989 16.9292 49.4575 17.5406 48.2206 17.6964C46.3539 17.9315 44.4211 17.6428 42.5165 17.5767C43.0404 15.8702 43.5642 14.1638 44.3373 12.2043Z"
        className={classNames[1]}/>
      <path
        d="M39.561 0C41.4558 3.45985 39.7754 10.2149 36.7553 11.3718C35.7082 11.7729 33.0042 10.8442 33.0023 10.5114C32.9843 7.46062 32.9241 4.23949 33.9222 1.44112C34.2977 0.388386 37.417 0.314308 39.561 0Z"
        className={classNames[0]}/>
    </svg>
  );
};
