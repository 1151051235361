import { ActionAppType, ActionContext, ActionPost, BaseAction } from '~/Actions/BaseAction';
import { BuyerRoutes } from '~/hooks/useRoutes';
import { Issue } from '~/types/types';
import SaveUserGroupInput = App.Domain.User.SaveUserGroupInput;
import { UserGroupIcon } from '@/Icon';

export class SaveUserGroup extends BaseAction {
  constructor(public context: ActionContext<Issue>, public post: ActionPost) {
    super(context, post);
  }

  /** Default icon for this action */
  public static icon = UserGroupIcon;

  /** Name for posthog analytics */
  public static analyticsName = 'saved_user_group';

  /** Execute for `buyer` or `seller` */
  public static type: ActionAppType = 'buyer';

  /** Message to show on `toast` */
  public static toast = {
    titleKey: 'saved_user_group',
  };

  /** Default translation key */
  public nameKey = () => 'save_user_group';

  /** Callback to check if this should be disabled (using `context.auth` auth, etc) */
  public disabled = () => false;

  /**
   * Execute the mutation
   * @param data
   */
  public execute = async (data: SaveUserGroupInput) => {
    const routes = this.context.routes as BuyerRoutes;
    const route = routes.saveUserGroup;

    await this.post(route(), data);
  };
}
