import { SparklesIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { Card, CardBody, CardFooter, CardHeader } from '@/Card';
import { WithAwaitingFallback } from '@/Fallback';
import {
  QualityIcon,
} from '@/Icon';
import { MutedStrong, MutedText, Strong, Text } from '@/Text';
import { CompletionMatrix } from '~/Components/Analytics/Charts/CompletionMatrix/CompletionMatrix';
import Section, { SectionLayout } from '~/Components/Common/Section';
import { InspectionIssuesCard } from '~/Components/Issues/Inspections/InspectionIssuesCard';
import { InspectionsCard } from '~/Components/Orders/InspectionsCard';
import { Attachment, OrderFieldSummary, Spec } from '~/types/types';

import { QualityLabel } from '../../QualityLabel';
import { AttachmentsCard } from '../Attachments/AttachmentsCard';
import { useOrder } from '../OrderProvider';

interface OrderInspectionsSectionProps {
  attachments: Attachment[];
  orderFieldSummaries: OrderFieldSummary[];
  activeSpec?: Spec;
  activeOrderFieldSummary?: OrderFieldSummary;
  isSeller?: boolean;
}

export const OrderInspectionsSection = ({
  attachments,
  orderFieldSummaries = [],
  activeSpec,
  activeOrderFieldSummary,
  isSeller,
}: OrderInspectionsSectionProps) => {
  const { t } = useTranslation();
  const { orderProduces, order, inspectionIssues, inspectionIssue } = useOrder();

  if (!orderProduces.length) {
    return null;
  }

  const orderProduce = orderProduces[0];

  return (
    <div className="py-8" id="inspections">
      <Section icon={QualityIcon} nCollapsedItems={2} title={t('inspections')} showCollapsed enableToggle
        renderPreview={(
          <QualityLabel issues={inspectionIssues} label="Inspections"/>
        )}>
        <SectionLayout variant="3_1">
          <WithAwaitingFallback>
            <div className="space-y-8">
              <InspectionsCard
                order={order}
                orderProduce={orderProduce}
                orderFieldSummaries={orderFieldSummaries}
                activeOrderFieldSummary={activeOrderFieldSummary}
                activeSpec={activeSpec}
                isSeller={isSeller}
              />
              <AttachmentsCard attachments={attachments}/>

            </div>
          </WithAwaitingFallback>

          <div className="space-y-4">
            {inspectionIssue && (
              <div>
                <InspectionIssuesCard
                  compoundIssue={inspectionIssue}
                />
              </div>
            )}
            
            {/*<Card className="bg-zinc-50">*/}
            {/*  <CardHeader>*/}
            {/*    <div className="flex items-center justify-between">*/}
            {/*      <div>*/}
            {/*        <MutedStrong>Grapes Red Inspection</MutedStrong>*/}
            {/*      </div>*/}

            {/*      <div>*/}
            {/*        <MutedText>*/}
            {/*          91%*/}
            {/*        </MutedText>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </CardHeader>*/}
            {/*  < hr/>*/}
            {/*  <CardBody>*/}
            {/*    <div>*/}
            {/*    </div>*/}

            {/*    <div>*/}
            {/*      <CompletionMatrix*/}
            {/*        columns={12}*/}
            {/*        size="lg"*/}
            {/*        data={[*/}
            {/*          {*/}
            {/*            label: 'Box weight',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'Secondary stem dehydration',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'Webbing',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'Blemish, scarring, rub marks',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'Heavy soiling',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'Waste',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'Water/dead berries',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'Squashed berries',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'SO2 damaged berries (<1/4)',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'Healed growth cracks',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'Shrivel',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'SO2 damaged berries >1/4',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'Primary/main stem dehydration',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'Hairline splits',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'Spiders and Insects',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'Box Weight (Actual Weight)',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'Average Berry Size',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'Min Average Brix',*/}
            {/*            theme: 'red',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'Sugar/Acid Ratio',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'Shatter',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'Fully developed seeds',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'Packing bruise',*/}
            {/*            theme: 'emerald',*/}
            {/*          },*/}
            {/*          {*/}
            {/*            label: 'Berry color',*/}
            {/*            theme: 'gray',*/}
            {/*          },*/}
            {/*        ]}/>*/}
            {/*    </div>*/}
            {/*  </CardBody>*/}

            {/*  <CardFooter>*/}
            {/*    <Text>*/}
            {/*      21/23 fields passed (1 missing)*/}
            {/*    </Text>*/}
            {/*  </CardFooter>*/}
            {/*</Card>*/}

          </div>
        </SectionLayout>
      </Section>
    </div>
  );
};
