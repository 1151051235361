import { ReactNode } from 'react';

import { Input } from '@/Input';
import { LayoutBody, LayoutHeader } from '@/Layout';
import { ProducePicker } from '@/Pickers/ProducePicker';
import { RegionPicker } from '@/Pickers/RegionPicker';
import StatisticsBanner from '@/Statistics/StatisticsBanner';
import { SortKey } from '@/Table/types';
import {
  CardSubtitle,
  CardTitle,
  PageDescription,
  PageTitle,
  TextLabel,
} from '@/Text';
import { useAction } from '~/hooks/useAction';
import { Organization, PaginatedItems, Produce } from '~/types/types';

import { DashboardLayout } from '../DashboardLayout';
import { SuppliersTable, SuppliersTableFilters } from './SuppliersTable';

interface SuppliersStatistics {
  count: number;
  outstandingCount: number;
  poorCount: number;
  top: Organization[];
  bottom: Organization[];
}

export interface SuppliersPageProps {
  data: {
    suppliers_connection: PaginatedItems<Organization>;
    stats: SuppliersStatistics;
  }
  filters: SuppliersTableFilters;
  parameters: {
    sort: SortKey;
  };
  filterOptions: {
    regions: string[];
    produces: Produce[];
  };
}

const SuppliersPage = ({ data, filters, filterOptions, parameters }: SuppliersPageProps) => {
  const { reload, loading } = useAction('', {
    preserveState: true,
    withParams: true,
    storeLocalStorage: false,
  });

  const availableRegions = filterOptions.regions;
  const selectedRegion = filters.region;

  const availableProduces = filterOptions.produces.map((produce) => ({
    label: produce.name,
    value: produce.id,
  }));

  const selectedProduce = filters.produce?.id;

  return (
    <>
      <LayoutHeader>
        <PageTitle>Suppliers</PageTitle>
        <PageDescription>
          Analyse your suppliers based on their performance score
        </PageDescription>
      </LayoutHeader>

      <LayoutBody>
        <div className="space-y-12">
          <div className="space-y-5">
            <div>
              <CardTitle>Overview</CardTitle>
              <CardSubtitle>Your suppliers at a glance</CardSubtitle>
            </div>

            <StatisticsBanner
              statistics={[
                {
                  name: 'Total suppliers',
                  stat: data.suppliers_connection.total.toString(),
                  previousStat: `${0}`,
                  changeType: 'increase',
                  change: data.suppliers_connection.total.toString(),
                },
                {
                  name: 'Outstanding suppliers',
                  stat: data.stats.outstandingCount.toString(),
                  previousStat: '0',
                  changeType: 'increase',
                  change: '1',
                  description:
                    'Suppliers with a performance score of 80% or higher',
                },
                {
                  name: 'At risk suppliers',
                  stat: data.stats.poorCount.toString(),
                  previousStat: '70',
                  changeType: 'increase',
                  change: '1',
                  description:
                    'Suppliers with decaying performance scores below 40%',
                },
              ]}
            />
          </div>

          <div className="space-y-5">
            <div className="flex flex-col gap-2 md:flex-row md:items-center md:justify-between">
              <div>
                <CardTitle>Suppliers</CardTitle>
                <CardSubtitle>See an overview of your suppliers</CardSubtitle>
              </div>

              <div
                className="flex flex-col w-full space-y-4 md:w-revert md:flex-row md:items-center md:space-y-0 md:space-x-4">
                <div className="w-full md:w-revert">
                  <TextLabel>Search</TextLabel>
                  {/* TODO: Throttle */}
                  <Input
                    className="leading-none"
                    defaultValue={filters.search}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      reload({ search: e.target.value });
                    }}
                    type="text"
                  />
                </div>
                <div className="w-full md:w-revert">
                  <TextLabel>Produce</TextLabel>
                  <div>
                    <ProducePicker
                      produces={availableProduces}
                      selectedProduce={selectedProduce}
                    />
                  </div>
                </div>
                <div className="w-full md:w-revert">
                  <TextLabel>Region</TextLabel>
                  <div>
                    <RegionPicker
                      onChange={(region) => {
                        reload({ region });
                      }}
                      regions={availableRegions}
                      selectedRegion={selectedRegion}
                    />
                  </div>
                </div>
              </div>
            </div>

            <SuppliersTable
              filters={filters}
              sortKey={parameters.sort}
              suppliersConnection={data.suppliers_connection}
              availableRegions={filterOptions.regions}
              availableProduces={filterOptions.produces}
            />
          </div>
        </div>
      </LayoutBody>
    </>
  );
};

SuppliersPage.layout = (page: ReactNode) => (
  <DashboardLayout>{page}</DashboardLayout>
);

export default SuppliersPage;
