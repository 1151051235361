import { PopoverContent, PopoverTrigger } from '@radix-ui/react-popover';
import { DefaultTFuncReturn } from 'i18next';
import { ReactNode } from 'react';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalTitle,
  ModalTrigger,
} from '@/Modal';
import { PopoverRoot } from '@/Popover';
import useScreenWidth from '~/hooks/useScreenWidth';

interface ResponsivePickerProps {
  Trigger: ReactNode;
  children: ReactNode;
  open: boolean;
  title?: string | DefaultTFuncReturn;
  onOpenChange: (open: boolean) => void;
  disabled?: boolean;
}

export const ResponsivePicker = ({
  Trigger,
  children,
  open,
  onOpenChange,
  title,
  disabled,
}: ResponsivePickerProps) => {
  const { isSmall, screenWidth } = useScreenWidth();
  const isSmallScreen = isSmall(screenWidth);

  return (
    <>
      {isSmallScreen ? (
        <Modal
          open={open}
          onOpenChange={(isOpen) => !disabled && onOpenChange(isOpen)}
        >
          <ModalTrigger disabled={disabled} asChild>
            {Trigger}
          </ModalTrigger>
          <ModalContent>
            {title && (
              <ModalHeader id="comment-modal-header">
                <ModalTitle>{title}</ModalTitle>
              </ModalHeader>
            )}

            <ModalBody className="flex flex-col flex-1 pr-2">
              <div>{children}</div>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <PopoverRoot
          open={open}
          onOpenChange={(isOpen) => !disabled && onOpenChange(isOpen)}
        >
          <PopoverTrigger disabled={disabled} asChild>
            {Trigger}
          </PopoverTrigger>
          <PopoverContent className="w-[200px] shadow-md p-0">
            {children}
          </PopoverContent>
        </PopoverRoot>
      )}
    </>
  );
};
