import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DateFormat, useDate } from '~/hooks/useDate';
import { LocationDataPayload } from '~/types/types';
import { formatLocation } from '~/utils/formatLocation';

export interface TransportSummaryTextProps {
  data: LocationDataPayload;
}

/**
 * Constructs the summary text for the transport route.
 *
 * TODO: Add delay issue.
 */
export const TransportSummaryText = ({ data }: TransportSummaryTextProps) => {
  const { t } = useTranslation();
  const { format } = useDate();

  const lineKeys: string[] = useMemo(() => {
    const result = [];

    // First, let's check if we have any events.
    if (data.events.length === 0) {
      return [t('transports_summaries.no_events')];
    }

    // Next, we check if the last event is an arrival.
    const latestEvent = data.events[data.events.length - 1];

    if (latestEvent.type === 'ARRIVAL') {
      result.push(t('transports_summaries.arrived_at', {
        location: formatLocation(latestEvent.location),
        arrivatedAt: format(latestEvent.date, DateFormat.HumanDateTime),
      }));

      return result;
    }

    // First we check if there are any delays.
    const totalDelay = data.summary.totalDelayInSec;

    const hasDestination = data.meta && data.meta.destination && data.meta.destination.label;
    const hasEta = data.meta && data.meta.eta;

    const destination = data.meta.destination ? formatLocation(data.meta.destination) : null;
    const eta = hasEta ? format(data.meta.eta as Date, DateFormat.HumanDateTime) : null;

    if (hasDestination && hasEta) {
      result.push(t('transports_summaries.current_eta_with_location', {
        eta,
        destination,
      }));
    } else if (hasDestination && !hasEta) {
      result.push(t('transports_summaries.no_eta_with_location', {
        destination,
      }));
    } else if (!hasDestination && hasEta) {
      result.push(t('transports_summaries.current_eta', { eta }));
    } else {
      // If there is no destination and no ETA, we just say there is no ETA.
      result.push(t('transports_summaries.no_eta'));
    }

    if (latestEvent && latestEvent.type === 'ARRIVAL') {
      result.push(t('transports_summaries.arrived_at', {
        location: formatLocation(latestEvent.location),
      }));
    } else if (latestEvent && latestEvent.type === 'DELAY') {
      const withLocation = totalDelay > 0;
      const showInHours = totalDelay < 86400;

      if (withLocation && showInHours) {
        result.push(t('transports_summaries.delay_in_hours', {
          delayInHours: Math.ceil(totalDelay / 3600),
        }));
      }
      else if (withLocation && !showInHours) {
        result.push(t('transports_summaries.delay_in_days', {
          delayInDays: Math.ceil(totalDelay / 86400),
        }));
      }
      else if (!withLocation && showInHours) {
        result.push(t('transports_summaries.delay_in_hours_with_location', {
          delayInHours: Math.ceil(totalDelay / 3600),
          location: formatLocation(latestEvent.location),
        }));
      } else if (!withLocation && !showInHours) {
        result.push(t('transports_summaries.delay_in_days_with_location', {
          delayInDays: Math.ceil(totalDelay / 86400),
          location: formatLocation(latestEvent.location),
        }));
      }
    }


    return result;
  }, [data]);

  // Return lineKeys as a string of joined lines.
  return lineKeys.join(' ');
};
