import { Variants } from 'framer-motion';

export const defaultSpring = {
  type: 'spring',
  stiffness: 300,
  damping: 30,
};

export const fadeInOutUpVariants: Variants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
};
