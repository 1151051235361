import { Link } from '@inertiajs/react';
import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';

import Avatar from '@/Avatar';
import { Container } from '@/Container';
import { TopbarIcon } from '@/Icon';
import { Logo } from '@/Layout';
import { getLinkStatus, NavLinkType } from '@/Nav/nav-items';
import { Selectable, UserMenu, UserSelectable } from '@/Nav/UserMenu';
import { usePageProps } from '~/hooks/usePageProps';
import { useRoutes } from '~/hooks/useRoutes';
import { Organization, User } from '~/types/types';

interface NavbarProps {
  user: User;
  renderSubNavLinks?: ReactNode;
  renderNavLinks?: ReactNode;
  organization?: Organization;
}

const mainNavLinkVariants = cva(
  'flex items-center px-3 py-2 rounded-md transition-colors duration-150 ease-in-out text-sm font-medium',
  {
    variants: {
      status: {
        active: 'text-emerald-700 bg-emerald-50 border-gray-100 border hover:bg-blue-100',
        default: 'text-gray-600 hover:bg-gray-100 hover:text-gray-900',
        disabled: 'text-gray-300 cursor-not-allowed',
      },
    },
    defaultVariants: {
      status: 'default',
    },
  }
);

export const MainNavItem = ({ link }: { link: NavLinkType }) => {
  const { url } = usePageProps();

  return (
    <Link
      href={!('disabled' in link) ? link.link : ''}
      disabled={'disabled' in link}
      className={mainNavLinkVariants({ status: getLinkStatus(url, link) })}
    >
      {link.icon && (
        <TopbarIcon icon={link.icon}/>
      )}
      {link.name}
    </Link>
  );
};

const subNavLinkVariants = cva(
  'flex items-center hover:bg-gray-100 px-2 pt-2 pb-3 -mb-2 !font-normal !text-sm',
  {
    variants: {
      status: {
        active: 'text-emerald-700 *:text-inherit border-b-2 border-emerald-700',
        default: 'text-gray-600 border-b-2 border-transparent',
        disabled: 'cursor-not-allowed !text-gray-200 *:text-gray-200',
      },
    },
    defaultVariants: {
      status: 'default',
    },
  }
);

export const SubNavItem = ({ link }: { link: NavLinkType }) => {
  const { url } = usePageProps();

  return (
    <Link
      href={!('disabled' in link) ? link.link : ''}
      disabled={'disabled' in link}
      className={subNavLinkVariants({ status: getLinkStatus(url, link) })}
    >
      {link.name}
    </Link>
  );
};

export const Navbar = ({ user, renderNavLinks, renderSubNavLinks, organization }: NavbarProps) => {
  const routes = useRoutes();

  return (
    <div className="bg-off-white relative z-20 border-b border-gray-200 shadow-sm">
      <Container size="xl">
        <div className="flex items-center flex-wrap justify-between gap-8 w-full">
          <div className="flex md:gap-8 items-center py-2.5">
            <div className="flex-shrink-0">
              <Logo size="sm"/>
            </div>

            {organization && (
              <div className="flex gap-2 items-center">
                <Selectable label={'Select organization'}>
                  <div className="flex gap-3 items-center">
                    <Avatar size="xs" theme="blue" initials={organization.title[0]}/>
                    {organization.title}
                  </div>
                </Selectable>
              </div>
            )}
          </div>

          {!!renderNavLinks && (
            <div className="flex-1 md:flex-initial">
              {renderNavLinks}
            </div>
          )}

          {/* At the end: render the user-nav */}
          <div>
            <UserMenu logoutRoute={routes.logout()}>
              <UserSelectable user={user}/>
            </UserMenu>
          </div>
        </div>

      </Container>
      {!!renderSubNavLinks && (
        <Container size="xl">
          <div className="flex border-t py-2 border-gray-100">
            <div className="flex items-center justify-center space-x-4">
              {renderSubNavLinks}
            </div>
          </div>
        </Container>
      )}
    </div>
  );
};
