import { ReactNode } from 'react';

import { cn } from '~/utils/cn';

type Size = 'xs' | 'sm' | 'md' | 'lg';

const Sizes = {
  xs: 'min-w-4 min-h-4 text-xs',
  sm: 'min-w-5 min-h-5 text-xs',
  md: 'min-w-6 min-h-6 text-sm',
  lg: 'min-w-8 min-h-8 text-base',
};

export const NumericIndicatorTheme = {
  red: 'text-red-700 bg-red-50 ring-red-500/10',
  orange: 'text-orange-600 bg-orange-50 ring-orange-500/10',
  green: 'text-emerald-600 bg-emerald-50 ring-emerald-500/10',
  gray: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  blue: 'text-blue-600 bg-blue-50 ring-blue-500/10',
};

export type Brand = 'red' | 'orange' | 'green' | 'gray' | 'blue';

export const NumericIndicator = ({ children, brand, size = 'sm', withPadding }: {
  children: ReactNode;
  brand: Brand;
  size: Size,
  withPadding?: boolean
}) => {
  return (
    <span
      className={cn(
        'inline-flex items-center justify-center font-medium rounded-full ring-1 ring-inset',
        Sizes[size],
        NumericIndicatorTheme[brand],
        withPadding ? 'px-2' : 'px-0',
      )}>
      {children}
    </span>
  );
};

export const WithNumericIndicator = ({ children, value, brand = 'red', size = 'sm', label }: {
  children: ReactNode;
  value: number;
  brand?: Brand;
  size?: Size;
  label?: string;
}) => {

  if (!value) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <div className="relative" aria-label={label}>
      <div className="absolute -left-2 -top-3">
        <NumericIndicator brand={brand} size={size}>
          {value}
        </NumericIndicator>
      </div>

      {children}
    </div>
  );
};
