export const ReponsiveTableContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="overflow-x-auto">
      <div className="min-w-fit">{children}</div>
    </div>
  );
};
