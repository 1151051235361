import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportIcon } from '@/Icon';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { GenericFilterPane } from '~/Components/Panes/Filters/GenericFilterPane';
import { useAction } from '~/hooks/useAction';
import { useToast } from '~/hooks/useToast';
import { getGroups } from '~/utils/formatPicker';

import { reportOptions } from './helpers';
import { ReportsFilter } from './types';
import AnalyticsFilterOptions = App.Http.Controllers.Reports.AnalyticsFilterOptions;
import { PickerFetcher } from '@/Pickers/PickerFetcher';
import { DateFilterPicker } from '~/Components/Dates/DateFilterPicker';
import { useFormat } from '~/hooks/useFormat';

interface AddProduceModalProps {
  filterOptions?: AnalyticsFilterOptions;
  filter?: FilterParameters;
  children?: ReactNode;
}

type FilterParameters = App.Domain.Reports.ReportsFilter;

export const ReportsFilterPane = ({ filter, filterOptions, children }: AddProduceModalProps) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { formatCurrency } = useFormat();

  const { loading, reload } = useAction<ReportsFilter>('', {
    preserveState: true,
    preserveScroll: true,
    onError: (error) => {
      toast({
        title: t('toasts.error') as string,
        description: t('toasts.error_message'),
        variant: 'destructive',
        error,
      });
    },
  });

  const handleClearFilters = () => {
    // TODO:
    // Either call an onClear prop, or call an action directly
  };

  const handleFilterSubmit = async (values: FilterParameters) => {
    reload(values);
  };

  const { isEmpty, ...filterValues } = filter;

  const defaultValues: FilterParameters = {
    ...filterValues,
  };

  return (
    <GenericFilterPane
      title={t('filters') as string}
      isLoading={loading}
      defaultValues={defaultValues}
      onChange={(values: any) => handleFilterSubmit(values)}
      onClear={handleClearFilters}
      // usesStoreFilter={} // TODO: Enable this if the filter should be stored in the store
      filters={[
        {
          label: t('reports.report_types') as string,
          fieldKey: 'filterTypes',
          component: (
            <GenericPicker
              isMulti={true}
              disableSearch
              icon={ReportIcon}
              title={t('reports.report_types')}
              options={reportOptions}
              emptyMessage={t('reports.no_types')}
              groups={getGroups(reportOptions)}
              placeholder={t('reports.select_types')}
            />
          ),
        },
        {
          label: t('date') as string,
          fieldKey: 'dateFilter',
          component: (
            <DateFilterPicker
              enableGranularity
            />
          ),
        },
        {
          label: t('sellers') as string,
          fieldKey: 'sellerIds',
          group: 'scope',
          component: (
            <PickerFetcher
              reloadKey="filterOptions"
              optionsKey="filterOptions.sellerOptions"
              parseInitialOptionsConfig={{
                key: 'filter.sellerIds',
                labelKey: 'name',
                valueKey: 'id',
              }}
            >
              {(props) => (
                <GenericPicker
                  {...props}
                  isMulti={true}
                  title={t('sellers')}
                  emptyMessage=""
                  placeholder=""
                />
              )}
            </PickerFetcher>
          ),
        },
        {
          label: t('regions') as string,
          fieldKey: 'regionIds',
          group: 'scope',
          component: (
            <PickerFetcher
              reloadKey="filterOptions"
              optionsKey="filterOptions.regionOptions"
              parseInitialOptionsConfig={{
                key: 'filter.regionIds',
                labelKey: 'name',
                valueKey: 'id',
              }}
            >
              {(props) => (
                <GenericPicker
                  {...props}
                  isMulti={true}
                  title={t('regions')}
                  formatCount={(count) => formatCurrency(count)}
                  emptyMessage=""
                  placeholder=""
                />
              )}
            </PickerFetcher>
          ),
        },
      ]}
    >
      {children}
    </GenericFilterPane>
  );
};
