import { LinkIcon } from 'lucide-react';

import { ActionAppType, ActionContext, ActionPost, BaseAction } from '~/Actions/BaseAction';
import { BuyerRoutes } from '~/hooks/useRoutes';
import { Field } from '~/types/types';

export class ConfirmField extends BaseAction {
  constructor(public context: ActionContext<Field>, public post: ActionPost) {
    super(context, post);
  }

  /** Default icon for this action */
  public static icon = LinkIcon;

  /** Name for posthog analytics */
  public static analyticsName = 'confirm_field';

  /** Execute for `buyer` or `seller` */
  public static type: ActionAppType = 'buyer';

  /** Message to show on `toast` */
  public static toast = {
    titleKey: 'confirmed_field',
  };

  /** Default translation key */
  public nameKey() {
    return 'confirmed_field';
  }

  /** Callback to check if this should be disabled (using `context.auth` auth, etc) */
  public disabled() {
    // Base this on context?
    return !this.context.user.superadmin;
  }

  /**
   * Execute the action
   * @param data
   */
  public execute = async (data = undefined) => {
    const routes = this.context.routes as BuyerRoutes;
    const route = routes.confirmField;
    console.log(data, this.context.target);

    await this.post(route(this.context.target.id), {});
  };
}
