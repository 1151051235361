import { w } from 'windstitch';

export const TableGroupHeader = w.div(`
  py-2 border-t border-b border-gray-200 bg-gray-50 border-t-gray-100,
  font-semibold text-sm text-gray-600
`,
{
  variants: {
    size: {
      sm: 'px-4',
      md: 'px-3 sm:px-8',
      lg: 'px-12',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});
