import { Card, CardBody } from '@/Card';
import * as DropdownMenu from '@/DropdownMenu';
import { DropdownCheckboxItem, DropdownGroup, DropdownGroupHeader } from '@/DropdownMenu';
import { DebugJSON } from '@/JSON';
import { Helper } from '@/Text';

import { StackedBarChart } from '../../Charts/BarCharts/StackedBarChart';

interface TimeseriesMetric {
  label: string;
  value: string | number;
  color: string;
}

interface BarchartTimeseriesWidgetProps {
  title: string;
  metrics: TimeseriesMetric[];
  data: any[]; // Use the StackedBarData type from above
  series: any[]; // Use the StackedBarSeries type from above
  className?: string;
  renderDropdownMenu?: (data: any) => JSX.Element;
}

export const BarTimeSeriesWidget = ({
  title,
  metrics,
  data,
  series,
  renderDropdownMenu,
}: BarchartTimeseriesWidgetProps) => {
  return (
    <Card>
      <CardBody>
        <div className="mb-6 flex items-start justify-between">
          <div>
            <Helper className="!text-md">
              {title}
            </Helper>
            <div className="mt-2 flex gap-8">
              {metrics.map((metric, index) => (
                <div key={index} className="space-y-1">
                  <div className="text-4xl font-bold">{metric.value}</div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex gap-6">
            {series.map((s, index) => (
              <div key={index} className="flex items-center gap-2">
                <div
                  className="h-2 w-2 rounded-full"
                  style={{ backgroundColor: s.color }}
                />
                <span className="text-sm font-medium">{s.label}</span>
              </div>
            ))}
          </div>
        </div>
      </CardBody>

      <StackedBarChart
        data={data}
        series={series}
        renderDropdownMenu={renderDropdownMenu}
      />
    </Card>
  );
};
