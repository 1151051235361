import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from '../utils/cn';
import { CardSubtitle, CardTitle } from './Text';

interface FileUploadProps extends ComponentPropsWithoutRef<'input'> {
  onFilesChange?: (files: File[]) => void;
  titleKey?: string;
  descriptionKey?: string;
}

export const FileUpload = forwardRef<
  HTMLInputElement,
  FileUploadProps
>(({
  className,
  onFilesChange,
  titleKey = 'upload_files',
  descriptionKey = 'upload_files_description',
  id,
  ...props
}, ref) => {
  const { t } = useTranslation();
  return (
    <div>
      <input
        id={`${id}2`}
        ref={ref}
        multiple
        type="file"
        {...props}
        onChange={(event) => {
          if (!onFilesChange) {
            props.onChange?.(event);
          } else {
            const fileList = event.target.files;

            // Let's ensure it is not null
            if (fileList) {
              // Conver FileList to File[]
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const files = [...fileList];
              onFilesChange(files);
            }
          }
        }}
        className={cn('hidden', className)}
      />
      <label
        className="flex flex-col items-center w-full p-12 text-center border-2 border-gray-300 border-dashed rounded-lg hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        htmlFor={`${id}2`}>
        <CardTitle>
          {t(titleKey)}
        </CardTitle>
        <CardSubtitle className="max-w-[400px]">
          {t(descriptionKey)}
        </CardSubtitle>
      </label>
    </div>
  );
});

FileUpload.displayName = 'FileUpload';
