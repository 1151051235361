import { useTranslation } from "react-i18next";
import { Produce } from "~/types/types";

export const useProductName = () => {
  const { t } = useTranslation();

  return (product?: Produce | null) => {
    if (!product) return "";
    return t(product.translation_key_ref as string, {
      defaultValue: product.name,
    });
  };
};
