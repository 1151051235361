/**
 * Count the number of times a value appears in an array of objects.
 *
 * Example:
 * [{name: 'John'}, {name: 'Jane'}, {name: 'John'}]
 * returns {'John': 2, Jane: 1}
 *
 * @param values
 * @param property
 */
export const valueCounts = (values: any[], property: string) => {
  return values.reduce((acc, value) => {
    if (value[property] in acc) {
      acc[value[property]]++;
    } else {
      acc[value[property]] = 1;
    }
    return acc;
  }, {});
};
