import { PlusIcon } from '@heroicons/react/20/solid';
import { BoxIcon, CloudIcon } from 'lucide-react';
import * as React from 'react';
import { forwardRef, useMemo, useState } from 'react';

import { CompoundButton, CompoundButtonItem, CompoundButtonSibling } from '@/Button';
import { Command, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator } from '@/Command';
import { PlaceholderBox } from '@/Fallback';
import { ButtonIcon, CheckIcon, ChevronDownIcon, CloseIcon, Icon, PlaceholderIcon } from '@/Icon';
import { Label, MiniLabel } from '@/Label';
import { PopoverContent, PopoverRoot, PopoverTrigger } from '@/Popover';
import { Separator } from '@/Separator';
import { Spinner } from '@/Spinner';
import { MutedText, Strong } from '@/Text';
import { Tooltipped } from '@/Tooltip';
import { cn } from '~/utils/cn';
import { searchPickerItems } from '~/utils/formatPicker';

import { PickerIcon } from './PickerIcon';

/**
 * Represents the base structure for an option in the picker.
 */
export type OptionBaseType = {
  label: string;
  value: string;
  subLabel?: string;
  icon?: React.ComponentType;
  group?: string;
  count?: number;
  disabled?: boolean;
  metaLabel?: string;
  metaLabelBrand?: string;
};

/**
 * Represents a group in the picker.
 */
export type GroupType = {
  label: string;
  icon?: React.ComponentType;
};

export type Groups = Record<string, GroupType>;

/**
 * Shared props for both single and multi-select pickers.
 */
interface SharedPickerProps<T extends OptionBaseType> {
  title: string;
  options: T[];
  groups?: Record<string, GroupType>;
  emptyMessage: string;
  placeholder: string;
  autoFocus?: boolean;
  loading?: boolean;
  showClear?: boolean;
  disabled?: boolean;
  icon?: React.ComponentType;
  showTitleOnSelected?: boolean;
  clearMessage?: string;
  onCreate?: (value: string) => void;
  formatCount?: (count: number) => string;
  nullOption?: T;
  onOpen?: () => void;
  disableSearch?: boolean;
  initialCountSelected?: number;
}

/**
 * Props specific to single-select picker.
 */
interface SinglePickerProps<T extends OptionBaseType, V = string> extends SharedPickerProps<T> {
  value?: string | null;
  selected?: string | null;
  onChange?: (value: V | null) => void;
  isMulti?: false;
}

/**
 * Props specific to multi-select picker.
 */
interface MultiPickerProps<T extends OptionBaseType, V = string> extends SharedPickerProps<T> {
  value?: string[];
  selected?: string[];
  onChange?: (value: V[] | null) => void;
  isMulti?: true;
}

/**
 * Union type for all possible picker props.
 */
type GenericPickerProps<T extends OptionBaseType, V = string> =
  | (Omit<SinglePickerProps<T, V>, 'onChange'> & {
  value?: V | null;
  onChange?: (value: V | null) => void;
  isMulti?: false;
})
  | (Omit<MultiPickerProps<T, V>, 'onChange'> & {
  value?: V[];
  onChange?: (value: V[] | null) => void;
  isMulti: true;
});

/**
 * Renders the body of a single-select picker.
 */
const SinglePickerBody: React.FC<{
  selectedOption: OptionBaseType | null;
  icon?: React.ComponentType;
  title: string;
}> = ({ selectedOption, icon: Icon, title }) => (
  <>
    {selectedOption ? (
      <>
        {selectedOption.icon && (<PickerIcon as={selectedOption.icon}/>)}
        {!selectedOption && Icon && <PickerIcon as={Icon}/>}
        {selectedOption.label}
      </>
    ) : (
      <span className="inline-flex items-center text-token-strong">
        {Icon && <PickerIcon as={Icon}/>}
        {title}
      </span>
    )}
  </>
);

/**
 * Renders the body of a multi-select picker.
 */
const MultiPickerBody: React.FC<{
  options: OptionBaseType[];
  selectedOptions: OptionBaseType[];
  icon?: React.ComponentType;
  title: string;
  initialCountSelected?: number;
}> = ({ selectedOptions, options, icon: Icon, title, initialCountSelected }) => (
  <>
    <span className="inline-flex items-center text-token-strong">
      {Icon && <PickerIcon as={Icon}/>}
      {title}
    </span>
    {(selectedOptions.length > 0 || initialCountSelected !== undefined && initialCountSelected > 0 && options.length === 0) && (
      <>
        <Separator orientation="vertical" className="mx-2"/>
        <Label className="px-1 font-normal rounded-sm lg:hidden">
          {selectedOptions.length || initialCountSelected}
        </Label>
        <div className="hidden space-x-1 lg:flex">
          {selectedOptions.length > 0 ? (
            selectedOptions.length > 2 ? (
              <Label className="px-1 font-normal rounded-sm">
                {selectedOptions.length} selected
              </Label>
            ) : (
              selectedOptions.map((option) => (
                <Label
                  key={option.value}
                  className="px-1 font-normal rounded-sm"
                >
                  {option.label}
                </Label>
              ))
            )
          ) : initialCountSelected !== undefined && (
            <Label className="px-1 font-normal rounded-sm">
              {initialCountSelected} selected
            </Label>
          )}
        </div>
      </>
    )}
  </>
);

/**
 * GenericPicker
 *
 * A versatile picker component that supports both single and multi-select functionality.
 * It provides a customizable dropdown interface for selecting options, with support for
 * grouping, searching, and asynchronous loading.
 *
 * ---
 * @param value
 * @param {Object} props
 * @param {string} props.title - The title of the picker.
 * @param {T[]} props.options - Array of options to display in the picker.
 * @param {Record<string, GroupType>} [props.groups] - Grouping configuration for options.
 * @param {string} props.emptyMessage - Message to display when no options are available.
 * @param {string} props.placeholder - Placeholder text for the search input.
 * @param {boolean} [props.autoFocus] - Whether to autofocus the picker on mount.
 * @param {boolean} [props.loading] - Indicates if options are being loaded.
 * @param {boolean} [props.showClear=true] - Whether to show the clear selection button.
 * @param {boolean} [props.disabled] - Disables the picker when true.
 * @param {React.ComponentType} [props.icon] - Icon to display alongside the picker title.
 * @param {boolean} [props.showTitleOnSelected] - Whether to show the title when an option is selected.
 * @param {string} [props.clearMessage] - Custom message for clearing selection.
 * @param {(value: string) => void} [props.onCreate] - Callback for creating a new option.
 * @param {(count: number) => string} [props.formatCount] - Function to format the count display.
 * @param {T} [props.nullOption] - Option to represent a null selection.
 * @param {() => void} [props.onOpen] - Callback fired when the picker is opened.
 * @param {boolean} [props.disableSearch] - Disables the search functionality when true.
 * @param {number} [props.initialCountSelected] - Initial count of selected items for async loading scenarios.
 * @param {boolean} [props.isMulti] - Set to true for multi-select functionality.
 * ---
 *
 * @example
 * <GenericPicker
 *   title="Select Users"
 *   options={users}
 *   value={selectedUsers}
 *   onChange={handleUserSelection}
 *   isMulti={true}
 *   placeholder="Search users..."
 *   emptyMessage="No users found"
 * />
 *
 * @note
 * This component can be used in combination with PickerFetcher for asynchronous options loading:
 *
 * @example
 * <PickerFetcher
 *   reloadKey="userOptions"
 *   optionsKey="pageProps.userOptions"
 * >
 *   {({ options, loading, initialCountSelected, onOpen }) => (
 *     <GenericPicker
 *       options={options}
 *       loading={loading}
 *       initialCountSelected={initialCountSelected}
 *       onOpen={onOpen}
 *       // ... other props
 *     />
 *   )}
 * </PickerFetcher>
 *
 * @note
 * For direct URL manipulation, you can wrap GenericPicker with ParamsSetter:
 *@example
 * <ParamsSetter
 *   reloadKey="userSelection"
 *   dataPrefix="pageProps"
 *   dataKey="selectedUsers"
 * >
 *   {({ data, loading, onChange }) => (
 *     <GenericPicker
 *       value={data}
 *       onChange={onChange}
 *       loading={loading}
 *       // ... other props
 *     />
 *   )}
 * </ParamsSetter>
 *
 * This setup allows the GenericPicker to directly update URL parameters
 * when selections change, enabling easy state management and deep linking.
 */
export const GenericPicker = forwardRef(<T extends OptionBaseType, V = string>(
  {
    value,
    title,
    options = [],
    placeholder,
    groups = {},
    autoFocus,
    loading,
    showClear = true,
    disabled,
    icon,
    onCreate,
    clearMessage,
    formatCount,
    showTitleOnSelected,
    disableSearch = false, // Add this line
    initialCountSelected,
    ...props
  }: GenericPickerProps<T, V>,
  ref: React.Ref<HTMLDivElement>
) => {
  const [open, setOpen] = useState(false);

  const handleOpen = (open: boolean) => {
    setOpen(open);

    if (open) {
      props.onOpen?.();
    }
  };

  const relevantValue = value || props.selected;
  const [inputValue, setInputValue] = useState('');

  /**
   * Memoized array of selected options.
   */
  const selectedOptions = useMemo(() => {
    if (props.isMulti) {
      const selectedValues = Array.isArray(value) ? value : relevantValue || [];
      return options.filter((option) => selectedValues.includes(option.value));
    }
    return [];
  }, [props.isMulti, options, value, relevantValue]);

  const handleCreate = () => {
    if (onCreate && inputValue) {
      onCreate(inputValue);
      setInputValue('');
      setOpen(false);
    }
  };

  /**
   * The currently selected option for single-select mode.
   */
  const selectedOption = props.isMulti
    ? null
    : options.find((option) => option.value === (value || relevantValue));

  /**
   * Clears the current selection.
   */
  const clearPicker = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();
    props.onChange?.(props.isMulti ? null : null);
  };

  /**
   * Handles the selection of an option.
   */
  const onSelect = (optionId: string) => {
    const option = options.find(opt => opt.value === optionId);
    if (option?.disabled) return;

    if (props.isMulti) {
      const currentSelection = selectedOptions.map(option => option.value);
      const newSelection = currentSelection.includes(optionId)
        ? currentSelection.filter(id => id !== optionId)
        : [...currentSelection, optionId];
      props.onChange?.(newSelection as V[]);
    } else {
      props.onChange?.(optionId as V);
      setOpen(false);
    }
  };

  /**
   * Renders the command items for the picker.
   */
  const renderCommandItems = (optionsToRender: T[]) => (
    <>
      {optionsToRender.map((option) => (
        <CommandItem
          key={option.value}
          value={option.value}
          onSelect={() => onSelect(option.value)}
          disabled={option.disabled}
          className={cn(option.disabled && 'opacity-50 cursor-not-allowed', 'cursor-pointer')}
        >
          {props.isMulti ? (
            <MultiSelectItem formatCount={formatCount} option={option} selectedOptions={selectedOptions}/>
          ) : (
            <SingleSelectItem formatCount={formatCount} option={option} selectedOption={selectedOption}/>
          )}
        </CommandItem>
      ))}
    </>
  );

  return (
    <PopoverRoot open={open} onOpenChange={handleOpen}>
      {/*  This is the trigger and body */}
      <PopoverTrigger asChild>
        <CompoundButton aria-disabled={loading || disabled} variant="white">
          {showTitleOnSelected && (
            <CompoundButtonSibling variant="muted" interactivity="static">
              {title}
            </CompoundButtonSibling>
          )}
          <CompoundButtonItem
            role="combobox"
            type="button"
            autoFocus={autoFocus}
            aria-expanded={open}
            aria-label={title}
          >
            {props.isMulti ? (
              <MultiPickerBody
                options={options}
                initialCountSelected={initialCountSelected}
                selectedOptions={selectedOptions}
                title={title}
                icon={icon}
              />
            ) : (
              <SinglePickerBody selectedOption={selectedOption as OptionBaseType} icon={icon} title={title}/>
            )}

            {loading ? (
              <div className="ml-2">
                <Spinner/>
              </div>
            ) : (
              <ButtonIcon className="ml-2 !stroke-gray-500 !w-8" icon={ChevronDownIcon}/>
            )}
          </CompoundButtonItem>
          {((props.isMulti && selectedOptions.length > 0) || selectedOption) && showClear && (
            <CompoundButtonSibling onClick={clearPicker} variant="muted" aria-label="clear">
              <Tooltipped label="Clear">
                <Icon
                  aria-label='clear-button'

                  className="items-center justify-center mr-0 text-gray-400 hover:text-gray-600"
                >
                  <CloseIcon className="w-4 h-4"/>
                </Icon>
              </Tooltipped>
            </CompoundButtonSibling>
          )}
        </CompoundButton>
      </PopoverTrigger>

      {/* This is the menu itself */}
      <PopoverContent aria-label={`popover content for ${title}`} ref={ref} className="w-[300px] p-0">
        <Command
          filter={(value, searchVal) => searchPickerItems(value, options, searchVal, ['value', 'subLabel', 'label', 'group'])}
        >
          {!disableSearch && ( // Add this condition
            <CommandInput
              className="my-2 h-7"
              placeholder={placeholder}
              value={inputValue}
              onValueChange={setInputValue}
            />
          )}

          <CommandList>
            {/* In case we are not loading items and have no options */}
            {options.length === 0 && !loading && (
              <PlaceholderBox
                title="No options"
                description="No options are available"
                icon={<PlaceholderIcon icon={BoxIcon}/>}
              />
            )}

            {/* In case we have no items and are loading */}
            {options.length === 0 && loading && (
              <PlaceholderBox
                title="Fetching data"
                description="Fetching data"
                icon={<PlaceholderIcon icon={CloudIcon}/>}
              />
            )}

            {/* In case we have some items */}
            {Object.keys(groups).length > 0 ? (
              Object.keys(groups).map((groupKey) => (
                <CommandGroup key={groupKey} heading={groups[groupKey].label}>
                  {renderCommandItems(options.filter(option => option.group === groupKey))}
                </CommandGroup>
              ))
            ) : (
              <CommandGroup>
                {renderCommandItems(options)}
              </CommandGroup>
            )}

            {onCreate && (
              <CommandGroup forceMount>
                <hr/>

                <CommandItem onSelect={handleCreate} forceMount>
                  <PickerIcon as={PlusIcon}/>
                  Create {inputValue}
                </CommandItem>
              </CommandGroup>
            )}

            {props.isMulti && selectedOptions.length > 0 && (
              <>
                <CommandSeparator/>
                <CommandGroup>
                  <CommandItem
                    onSelect={() => props.onChange?.([])
                    }
                    className="justify-center text-center"
                  >
                    {clearMessage || 'Clear selection'}
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </PopoverRoot>
  );
});
GenericPicker.displayName = 'GenericPicker';

/**
 * Renders a multi-select item in the picker.
 */
const MultiSelectItem: React.FC<{
  option: OptionBaseType,
  selectedOptions: OptionBaseType[],
  formatCount?: (val: any) => any
}> = ({
  option,
  selectedOptions,
  formatCount,
}) => (
  <div className="flex items-center justify-between w-full">
    <div>
      {option.icon ? <PickerIcon as={option.icon}/> : null}
      {option.label}
      {option.count !== undefined && (
        <span className="ml-2 text-xs text-gray-500">
          {formatCount ? (
            <>
              {formatCount(option.count)}
            </>
          ) : (
            <>
              ({option.count})
            </>
          )}
        </span>
      )}
    </div>

    <div
      className={cn(
        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
        selectedOptions.some((selectedOption) => selectedOption.value === option.value)
          ? 'bg-primary text-primary-foreground'
          : 'opacity-50 [&_svg]:invisible'
      )}
    >
      <CheckIcon className={cn('h-4 w-4')}/>
    </div>
  </div>
);

/**
 * Renders a single-select item in the picker.
 */
const SingleSelectItem: React.FC<{
  option: OptionBaseType,
  selectedOption: OptionBaseType | null | undefined,
  formatCount?: (val: any) => any
}> = ({ option, selectedOption, formatCount }) => (
  <div className="flex items-center justify-between w-full gap-2">
    <div className="flex items-center">
      {option.subLabel ? (
        <div>
          <div className="flex">
            {option.icon && (
              <div className="mt-1">
                <PickerIcon as={option.icon}/>
              </div>
            )}
            <div>
              <Strong>
                {option.label}
              </Strong>
              <div>
                <MutedText>
                  {option.subLabel}
                </MutedText>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex font-medium">
          {option.icon && (
            <div className="mt-0.5">
              <PickerIcon as={option.icon}/>
            </div>
          )}
          {option.label}
        </div>
      )}

      {option.count !== undefined && (
        <span className="ml-2 text-xs text-gray-500">
          {formatCount ? formatCount(option.count) : `(${option.count})`}
        </span>
      )}
    </div>

    <div className="items-center">
      <CheckIcon
        className={cn(
          'mr-2 h-4 w-4',
          selectedOption?.value === option.value ? 'opacity-100' : 'opacity-0'
        )}
      />
      {option.metaLabel && (
        <MiniLabel>
          {option.metaLabel}
        </MiniLabel>
      )}
    </div>
  </div>
);

/**
 * Renders a check icon for selected items.
 */
export const PickerCheck: React.FC<{ isSelected: boolean }> = ({ isSelected }) => (
  <>
    {isSelected ? (
      <span>
        <CheckIcon className={cn('h-4 w-4 stroke-gray-500')}/>
      </span>
    ) : (
      <span className="w-4 h-4"/>
    )}
  </>
);
