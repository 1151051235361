import { ReactNode } from 'react';

import { GeneralCostsCard } from './Modules/Costs/GeneralCostsCard';
import { ReportType } from './types';
import BaseReportModule = App.Domain.Reports.BaseReportModule;
import CostsModule = App.Domain.Reports.Modules.Costs.CostsModule;
import { QualityIssuesCard } from './Modules/QualityIssues/QualityIssuesCard';
import QualityIssuesModule = App.Domain.Reports.Modules.QualityIssues.QualityIssuesModule;
import { ReportingAccuracyModuleSection } from './Modules/ReportingAccuracy/ReportingAccuracyModuleSection';
import ReportingAccuracyModule = App.Domain.Reports.Modules.ReportingAccuracy.ReportingAccuracyModule;

interface ModulesMapProps {
  baseModule: BaseReportModule;
}

export const ModulesMap = ({ baseModule }: ModulesMapProps) => {
  const componentMap: Record<ReportType, ReactNode> = {
    ['costs']: <GeneralCostsCard module={baseModule as unknown as CostsModule}/>,
    ['quality_issues']: <QualityIssuesCard module={baseModule as unknown as QualityIssuesModule}/>,
    ['reporting_accuracy']: <ReportingAccuracyModuleSection module={baseModule as unknown as ReportingAccuracyModule}/>,
  };

  const Component = componentMap[baseModule.type] as ReactNode;

  if (!Component) {
    return null;
  }

  return (
    <>
      {Component}
    </>
  );
};
