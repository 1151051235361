import { SunIcon } from '@radix-ui/react-icons';
import { useTranslation } from 'react-i18next';

import { Card, CardHeader } from '@/Card';
import { MultiLabel } from '@/Label';
import { CountryName } from '~/Components/Locations/CountryName';
import { WeatherChart } from '~/Components/Weather/WeatherChart';
import { OrderDataStream, WeatherDataPayload } from '~/types/types';

import { useOrder } from '../OrderProvider';

interface WeatherSectionProps {
  dataStream: OrderDataStream<WeatherDataPayload>;
}

export const HarvestWeatherCard = ({ dataStream }: WeatherSectionProps) => {
  const { t } = useTranslation();
  const { order } = useOrder();

  return (
    <div id={dataStream.type}>
      <Card>
        <CardHeader
          title={t('origin_weather')}
          description={t('origin_weather_description')}
          renderIcon={<SunIcon className="w-6 h-6" />}
        >
          <div className="mt-1">
            <MultiLabel label="Origin" theme="gray">
              <CountryName countryCode={order.seller.country} />
            </MultiLabel>
          </div>
        </CardHeader>

        <div className="!bg-gray-100">
          <WeatherChart data={dataStream.data} />
        </div>
      </Card>
    </div>
  );
};
