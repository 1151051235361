import { useTranslation } from 'react-i18next';

import { CalendarIcon } from '@/Icon';
import { MutedText } from '@/Text';
import { DateFormat, useDate } from '~/hooks/useDate';

export interface DateLabelProps {
  from?: Date | string | null;
  to?: Date | string | null;
  format?: DateFormat;
}

/**
 * DateLabel: Format dates in a consistent way.
 * @deprecated Use `FormatDateFilter` instead, and style it with a surrounding Label.
 */
export const DateLabel = ({ from, to, format = DateFormat.DayFormat }: DateLabelProps) => {
  const { parseOrFormat } = useDate();
  const { t } = useTranslation();

  return (
    <div className="inline-flex items-center px-2 py-1 border border-gray-100 rounded">
      <CalendarIcon className="inline-block w-4 h-4 mr-1 text-gray-500"/>
      <MutedText>
        {!from && !to && t('not_specified')}

        {!from && to && parseOrFormat(to, format)}

        {from && !to && parseOrFormat(from, format)}

        {from && to && (
          <>
            {parseOrFormat(from, format)} - {parseOrFormat(to, format)}
          </>
        )}
      </MutedText>
    </div>
  );
};
