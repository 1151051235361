import { useTranslation } from 'react-i18next';

import { OrderIssue } from '~/Components/Orders/OrderIssue';
import { DateFormat, useDate } from '~/hooks/useDate';
import { LogisticsRouteIssue } from '~/types/types';

export interface LogisticsRouteIssueProps {
  issues: LogisticsRouteIssue[];
  refs: any;
  floatingStyles: any;
}

/**
 * Logistics route issue interprets issues from the logistics route and displays them in a card.
 *
 * TODO: Support multiple issues
 */
export const LogisticsRouteIssues = ({ issues, refs, floatingStyles }: LogisticsRouteIssueProps) => {
  const { t } = useTranslation();
  const { parseOrFormat } = useDate();
  const issue = issues[0];

  const delayInSeconds = issue.issueable?.data.delayInSeconds;
  const startDate = issue.issueable?.data.startDate;

  if (!delayInSeconds || !startDate) {
    return null;
  }

  return (
    <OrderIssue
      refs={refs}
      floatingStyles={floatingStyles}
      description={t('transports_summaries.delay_issue_with_time_delay', {
        delayInDays: Math.floor(delayInSeconds / 86400).toFixed(2),
        delayDate: parseOrFormat(startDate, DateFormat.HumanDateTime),
      })}
      issues={issues}
      title={t('issue_encountered')}
    />
  );
};
