/**
 * Scrolls to an element on the page
 * @requires root element to have smooth scroll behavior enabled
 * @requires to have an element with the id of 'sticky-header' to account for the sticky header
 * @returns {Function} scrollToElement
 */
export const useScrollToElement = () => {
  return (elementId: string) => {
    const section = document.getElementById(elementId);
    const stickyHeader = document.getElementById('sticky-header');

    if (!section || !stickyHeader) {
      return console.error('section or sticky header not found cannot scroll to selected element');
    }

    window.scrollTo({
      top: section.offsetTop - stickyHeader.offsetHeight - 20, // 20px offset to give the sticky header some breathing room
      behavior: 'smooth',
    });
    history.pushState(null, '', `#${elementId}`);
  };
};
