import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '@/Container';
import { Breadcrumbs, LayoutBody, LayoutHeader } from '@/Layout';
import { PageDescription, PageTitle } from '@/Text';
import { PageProps, Spec, VerifySpecFileOutput } from '~/types/types';

import { DashboardLayout } from '../DashboardLayout';
import { OrderPayload } from './types';

export interface AddQualityPage extends PageProps {
  data: OrderPayload;
}

const AddQualityPage = ({ data, active }: AddQualityPage) => {
  const { t } = useTranslation();

  return (
    <div>
      <LayoutHeader isSticky>
        <div>
          <Breadcrumbs backLink={{ type: 'link', href: '/dashboard/orders', name: t('Order') }}/>
          <PageTitle>{t('Add Quality')}</PageTitle>
          <PageDescription>
            {t('Add Quality description')}
          </PageDescription>
        </div>
      </LayoutHeader>
      <LayoutBody>
        <div>
          test
        </div>
      </LayoutBody>
    </div>
  );
};

AddQualityPage.layout = (page: ReactNode) => (
  <DashboardLayout>{page}</DashboardLayout>
);

export default AddQualityPage;
