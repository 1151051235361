'use client';

import { format } from 'date-fns';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CompoundButton, CompoundButtonItem, CompoundButtonSibling } from '@/Button';
import { CalendarIcon, CheckIcon, Icon } from '@/Icon';
import { Popover, PopoverContent, PopoverTrigger } from '@/Popover';
import { Helper } from '@/Text';
import { isDate, isString, useDate } from '~/hooks/useDate';
import { DatePeriod, DateRange } from '~/types/global-types';
import { cn } from '~/utils/cn';

import { Calendar } from './Calendar';
import Period = App.Domain.Dates.Period;

type RangeDatePickerProps = {
  value?: DateRange;
  defaultPeriod?: DatePeriod;
  onChange?: (range: DateRange) => void;
  enablePeriod?: boolean;
  disabled?: boolean;
  onOpen?: () => void;
  dateNumbers?: [Date, number][];
  dateNumbersLabel?: string;
  loading?: boolean;
  requireSubmit?: boolean; // New prop
};

export const RangeDatePicker = ({
  value,
  defaultPeriod,
  onChange,
  enablePeriod,
  disabled,
  onOpen,
  loading,
  dateNumbers,
  dateNumbersLabel,
  requireSubmit,
}: RangeDatePickerProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { parse } = useDate();

  /**
   * We set the initial default date as follows:
   * 1. If `value` is given, it should reflect whatever is inside value.
   * 2. If not, we check if `defaultPeriod` is given instead. We unpack that, and then use those.
   * 3. If neither, we return just a simple `null`.
   */
  const [internalDate, setInternalDate] = React.useState<DateRange>(() => {
    if (!value && !defaultPeriod) {
      return {
        from: undefined,
        to: undefined,
        period: undefined,
      };
    }

    if (!value && defaultPeriod) {
      return {
        from: undefined,
        to: undefined,
        period: defaultPeriod,
      };
    }

    let period;
    let from;
    let to;

    if (isDate(value?.from)) {
      from = value.from;
    }

    if (isString(value?.from)) {
      from = parse(value.from);
    }

    if (isDate(value?.to)) {
      to = value.to;
    }

    if (isString(value?.to)) {
      to = parse(value.to);
    }

    if (value?.period) {
      period = value.period;
    }

    return {
      from,
      to,
      period,
    };
  });

  useEffect(() => {
    console.log('opened');
    if (isOpen) {
      onOpen?.();
    }
  }, [isOpen, onOpen]);

  /**
   * In the case a calendar range is selected.
   */

  const handleCalendarRangeChange = (
    data: { from?: Date | undefined; to?: Date | undefined } | undefined
  ) => {
    const newDate = {
      from: data?.from ?? undefined,
      to: data?.to ?? undefined,
      period: undefined,
    };

    setInternalDate(newDate);
    if (!requireSubmit) {
      onChange?.(newDate);
    }
  };

  const handleClear = () => {
    const newDate = {
      from: undefined,
      to: undefined,
      period: undefined,
    };
    setInternalDate(newDate);
    if (!requireSubmit) {
      onChange?.(newDate);
    }
    setIsOpen(false);
  };

  const handleSave = () => {
    onChange?.(internalDate);
    setIsOpen(false);
  };

  const { t } = useTranslation();

  const supportedPeriods: Period[] = [
    'daily',
    'weekly',
    'monthly',
    'quarterly',
    'yearly',
  ];

  return (
    <Popover defaultOpen={false} open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <CompoundButton
          disabled={loading || (disabled as any)}
          className={cn(
            'justify-start text-left font-normal'
          )}
        >
          <CompoundButtonSibling>
            <CalendarIcon className="w-4 h-4 stroke-gray-400"/>
          </CompoundButtonSibling>
          <CompoundButtonItem>
            {internalDate?.from ? (
              internalDate.to ? (
                <>
                  {format(internalDate.from, 'LLL dd, y')} -{' '}
                  {format(internalDate.to, 'LLL dd, y')}
                </>
              ) : (
                format(internalDate.from, 'LLL dd, y')
              )
            ) : internalDate.period ? (
              t(`period_date_picker.${internalDate.period}`)
            ) : (
              'Pick a date'
            )}
          </CompoundButtonItem>
        </CompoundButton>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" aria-label='date picker'>
        <div className="flex">
          {enablePeriod && (
            <div className="px-6 py-4 border-r rounded-l bg-gray-50">
              <div className="flex flex-col justify-between h-full">
                <div>
                  <Helper className="ml-5">Period</Helper>
                  <div className="mt-2 space-y-1">
                    {supportedPeriods.map((period) => (
                      <div className="w-full" key={period}>
                        {internalDate?.period === period ? (
                          <div className="flex items-center w-full text-blue-500">
                            <Icon className="flex items-center !text-inherit">
                              <CheckIcon className="w-4 h-4 !stroke-gray-400"/>
                            </Icon>
                            <button className="text-sm">
                              {t(`period_date_picker.${period}`)}
                            </button>
                          </div>
                        ) : (
                          <div className="flex w-full">
                            <div className="w-4 h-4 mr-1"></div>
                            <button
                              className="text-sm"
                              key={period}
                              onClick={() => {
                                const newDate = {
                                  period,
                                  from: null,
                                  to: null,
                                };

                                if (requireSubmit) {
                                  setInternalDate(newDate);
                                } else {
                                  setInternalDate(newDate);
                                  onChange?.(newDate); // Call onChange when a period is selected
                                }
                              }}
                            >
                              {t(`period_date_picker.${period}`)}
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex space-x-2">
                  <Button
                    type="button"
                    className="w-1/2 !text-center justify-center"
                    size="sm"
                    variant="white"
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                  <Button
                    type="button"
                    className="w-1/2 !text-center justify-center"
                    size="sm"
                    variant="white"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          )}
          <Calendar
            initialFocus
            loading={loading}
            mode="range"
            dateNumbers={dateNumbers}
            dateNumberLabel={dateNumbersLabel}
            defaultMonth={internalDate?.from || undefined}
            selected={{
              from: internalDate?.from ?? undefined,
              to: internalDate?.to ?? undefined,
            }}
            onSelect={handleCalendarRangeChange}
            numberOfMonths={2}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};
