// noinspection JSUnusedGlobalSymbols

/**
 * Dropdown menu.
 *
 * Used for displaying a list of actions or links in a dropdown.
 * @example
 * <DropdownRoot>
 *       <DropdownTrigger asChild>
 *         <Button>
 *           <DropdownIcon icon={UserIcon} />
 *           User Menu
 *         </Button>
 *       </DropdownTrigger>
 *       <DropdownContent className="w-56">
 *         <DropdownBlock breakout className="bg-gray-50">
 *         </DropdownBlock>
 *
 *         <DropdownSeparator />
 *
 *         <DropdownGroup>
 *           <DropdownItem onSelect={() => console.log('Profile clicked')}>
 *             <DropdownIcon icon={UserIcon} />
 *             Profile
 *           </DropdownItem>
 *           <DropdownItem onSelect={() => console.log('Settings clicked')}>
 *             <DropdownIcon icon={SettingsIcon} />
 *             Settings
 *           </DropdownItem>
 *         </DropdownGroup>
 *
 *         <DropdownSeparator />
 *
 *         <DropdownGroup>
 *           <DropdownGroupHeader>Preferences</DropdownGroupHeader>
 *           <DropdownCheckboxItem
 *             checked={isNotificationsEnabled}
 *             onCheckedChange={setIsNotificationsEnabled}
 *           >
 *             <DropdownIcon icon={BellIcon} />
 *             Enable Notifications
 *           </DropdownCheckboxItem>
 *
 *           <DropdownSubMenu>
 *             <DropdownSubMenuTrigger>
 *               <DropdownIcon icon={LanguageIcon} />
 *               Language
 *               <ChevronRightIcon className="ml-auto" />
 *             </DropdownSubMenuTrigger>
 *             <DropdownSubMenuContent>
 *               <DropdownCheckboxItem
 *                 checked={selectedLanguage === 'en'}
 *                 onCheckedChange={() => setSelectedLanguage('en')}
 *               >
 *                 English
 *               </DropdownCheckboxItem>
 *               <DropdownCheckboxItem
 *                 checked={selectedLanguage === 'es'}
 *                 onCheckedChange={() => setSelectedLanguage('es')}
 *               >
 *                 Español
 *               </DropdownCheckboxItem>
 *               <DropdownCheckboxItem
 *                 checked={selectedLanguage === 'fr'}
 *                 onCheckedChange={() => setSelectedLanguage('fr')}
 *               >
 *                 Français
 *               </DropdownCheckboxItem>
 *             </DropdownSubMenuContent>
 *           </DropdownSubMenu>
 *         </DropdownGroup>
 *
 *         <DropdownSeparator />
 *
 *         <DropdownItem
 *           onSelect={() => console.log('Logout clicked')}
 *           className="text-red-500"
 *         >
 *           <DropdownIcon icon={LogoutIcon} />
 *           Logout
 *         </DropdownItem>
 *       </DropdownContent>
 *     </DropdownRoot>
 */

/* eslint-disable react/prop-types */
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import {
  CheckIcon,
  ChevronRightIcon,
  DotFilledIcon,
} from '@radix-ui/react-icons';
import * as React from 'react';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import { cn } from '~/utils/cn';

/**
 * ====
 * Core components
 * ====
 */

export const DropdownRoot = DropdownMenuPrimitive.Root;
export const DropdownTrigger = DropdownMenuPrimitive.Trigger;
export const DropdownPortal = DropdownMenuPrimitive.Portal;

/**
 * =====
 * General items: Group, GroupHeader, Item, Label
 * =====
 */

export const DropdownGroup = DropdownMenuPrimitive.Group;

export const DropdownContent = forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        'z-50 min-w-[14rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md',
        'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        className
      )}
      {...props}
    />
  </DropdownMenuPrimitive.Portal>
));
DropdownContent.displayName = DropdownMenuPrimitive.Content.displayName;

export const DropdownItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
  inset?: boolean
}
>(({ className, inset, ...props }, ref) => (
  <DropdownMenuPrimitive.Item
    ref={ref}
    className={cn(
      'font-light *:w-full text-gray-900 relative cursor-pointer flex select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      inset && 'pl-8',
      className
    )}
    {...props}
  />
));
DropdownItem.displayName = DropdownMenuPrimitive.Item.displayName;

export const DropdownLabel = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Label>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
  inset?: boolean
}
>(({ className, inset, ...props }, ref) => (
  <DropdownMenuPrimitive.Label
    ref={ref}
    className={cn(
      'px-2 py-1.5 text-sm font-medium text-gray-600',
      inset && 'pl-8',
      className
    )}
    {...props}
  />
));
DropdownLabel.displayName = DropdownMenuPrimitive.Label.displayName;

/**
 * The DropdownBlock renders inside a Dropdown, used for non-keyboard navigational content (such as meta-data).
 *
 * Examples:
 * - Information about logged-in user at the top of a UserMenu.
 */
export const DropdownBlock = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Label>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
  breakout?: boolean;
}
>(({ className, breakout, ...props }, ref) => (
  <DropdownMenuPrimitive.Label
    ref={ref}
    className={cn(
      'px-2 py-1.5',
      breakout && '-mx-1 -my-1 px-3 py-2.5',
      className
    )}
    {...props}
  />
));
DropdownBlock.displayName = 'DropdownBlock';

export const DropdownGroupHeader = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Label>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
  inset?: boolean
}
>(({ className, inset, ...props }, ref) => (
  <DropdownMenuPrimitive.Label
    ref={ref}
    className={cn(
      'px-2 py-1',
      'font-normal text-gray-400 text-xs uppercase tracking-wider inline-block',
      inset && 'pl-8',
      className
    )}
    {...props}
  />
));
DropdownGroupHeader.displayName = 'DropdownGroupHeader';

/**
 * =====
 * Sub menu items: Menu, content
 * =====
 */

export const DropdownSubMenu = DropdownMenuPrimitive.Sub;
export const DropdownSubMenuTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger> & {
  inset?: boolean
}
>(({ className, inset, children, ...props }, ref) => (
  <DropdownMenuPrimitive.SubTrigger
    ref={ref}
    className={cn(
      'font-light text-gray-900 flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent data-[state=open]:bg-accent',
      inset && 'pl-8',
      className
    )}
    {...props}
  >
    {children}
    <ChevronRightIcon className="w-4 h-4 ml-auto"/>
  </DropdownMenuPrimitive.SubTrigger>
));
DropdownSubMenuTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName;

export const DropdownSubMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubContent>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.SubContent
    ref={ref}
    className={cn(
      'z-50 min-w-[12rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className
    )}
    {...props}
  />
));
DropdownSubMenuContent.displayName =
  DropdownMenuPrimitive.SubContent.displayName;

/**
 * ====
 * Special item types: Checkbox, radio group and item
 * ====
 */
export const DropdownCheckboxItem = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem>
>(({ className, children, checked, ...props }, ref) => (
  <DropdownMenuPrimitive.CheckboxItem
    ref={ref}
    className={cn(
      'relative flex-wrap flex cursor-pointer select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className
    )}
    checked={checked}
    {...props}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <DropdownMenuPrimitive.ItemIndicator>
        <CheckIcon className="w-4 h-4"/>
      </DropdownMenuPrimitive.ItemIndicator>
    </span>
    {children}
  </DropdownMenuPrimitive.CheckboxItem>
));
DropdownCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName;

export const DropdownRadioGroup = DropdownMenuPrimitive.RadioGroup;

export const DropdownRadioItem = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.RadioItem>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.RadioItem>
>(({ className, children, ...props }, ref) => (
  <DropdownMenuPrimitive.RadioItem
    ref={ref}
    className={cn(
      'relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className
    )}
    {...props}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <DropdownMenuPrimitive.ItemIndicator>
        <DotFilledIcon className="w-4 h-4 fill-current"/>
      </DropdownMenuPrimitive.ItemIndicator>
    </span>
    {children}
  </DropdownMenuPrimitive.RadioItem>
));
DropdownRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName;

/**
 * =====
 * Misc items: shortcut, separator, icon
 * =====
 */

export const DropdownShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      className={cn('ml-auto text-xs tracking-widest opacity-60', className)}
      {...props}
    />
  );
};
DropdownShortcut.displayName = 'DropdownShortcut';

export const DropdownSeparator = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Separator>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.Separator
    ref={ref}
    className={cn('-mx-1 my-1 h-px bg-gray-100', className)}
    {...props}
  />
));
DropdownSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;
