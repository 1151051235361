import { DocumentIcon } from '@heroicons/react/24/outline';
import { ReactNode } from 'react';

import { DateFormat, useDate } from '../../hooks/useDate';
import { useFormat } from '../../hooks/useFormat';
import { Integration, Order } from '../../types/types';
import { Button } from '../../UI/Button';
import { FileUpload } from '../../UI/FileUpload';
import { Icon } from '../../UI/Icon';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTrigger,
} from '../../UI/Modal';
import { MutedText, Text } from '../../UI/Text';

interface AddCSVDataModalProps {
  order: Order;
  integration: Integration;
  children: ReactNode;
}

export const AddCSVDataModal = ({
  order,
  integration,
  children,
}: AddCSVDataModalProps) => {
  const { format } = useDate();
  return (
    <Modal>
      <ModalTrigger asChild>{children}</ModalTrigger>
      <ModalContent className="flex flex-col md:max-w-lg">
        <ModalHeader>
          <ModalTitle>Sync</ModalTitle>
          <ModalDescription>Sync order with integration</ModalDescription>
        </ModalHeader>
        <ModalBody>
          <div className="space-y-2">
            <Text>Upload a new CSV file to update the order.</Text>
            <Icon className="!w-12 !h-12 block mx-auto text-gray-400">
              <DocumentIcon />
            </Icon>
            <span className="block mt-2 font-semibold text-gray-900 text-md">
              Order file
            </span>
            <span className="block mt-2 text-xs font-semibold text-gray-600">
              Supported formats: *.csv
            </span>
            <FileUpload></FileUpload>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="flex items-center md:space-x-4 w-full md:w-auto">
            <MutedText className="text-xs">
              {/* Last synced: {format(new Date(integration.last_synced_at as string), DateFormat.HumanMonthDateTime)} */}
            </MutedText>
            <Button
              disabled
              variant="primary"
              className="w-full md:w-auto flex justify-center md:justify-start"
            >
              Sync
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
