import { RadioBooleanSelection } from '@/Form/RadioGroup';
import { MutedText, Strong } from '@/Text';
import { cn } from '~/utils/cn';

interface SideBooleanRadio {
  title?: string | null;
  description?: string | null;
  labels?: [string, string];
  value?: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
}

/**
 * SideRadioLayout
 *
 * Renders a spaced text+description <--> radio group layout.
 *
 * @example
 * ```tsx
 * <SideRadioLayout
 *  title="Is this a test?"
 *  description="This is a test description"
 *  labels={['Yes', 'No']}
 *  {...controlledField} // or onChange={} value={}
 * />
 * ```
 *
 * @param title
 * @param description
 * @param labels
 * @param value
 * @param onChange
 * @param disabled
 * @constructor
 */
export const SideRadioLayout = ({ title, description, labels, value, onChange, disabled }: SideBooleanRadio) => {
  return (
    <div
      aria-label={title}
      className={cn('flex justify-between items-center gap-4 lg:gap-8 flex-wrap', { 'opacity-50': disabled })}
    >
      <div>
        {title && <Strong>{title}</Strong>}
        {description && (
          <div className="max-w-md">
            <MutedText>{description}</MutedText>
          </div>
        )}
      </div>

      {value !== undefined && onChange !== undefined && (
        <div>
          <RadioBooleanSelection
            disabled={disabled}
            labels={labels}
            value={value}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};
