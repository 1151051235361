import { MarkAsReadIcon } from '@/Icon';
import { ActionAppType, ActionContext, ActionPost, BaseAction } from '~/Actions/BaseAction';
import ResolveIssueInput = App.Domain.Issues.Inputs.ResolveIssueInput;
import { BuyerRoutes } from '~/hooks/useRoutes';
import { Issue } from '~/types/types';

export class MarkIssueAsRead extends BaseAction {
  public static icon = MarkAsReadIcon;
  public static analyticsName = 'Mark as read';
  public static type: ActionAppType = 'buyer';
  public static toast = {
    titleKey: 'general_mark_as_read',
  };

  constructor(public context: ActionContext<Issue>, public post: ActionPost) {
    super(context, post);
  }

  public nameKey() {
    return 'mark_as_read';
  }

  public disabled() {
    return (this.context.target as Issue).status === 'read';
  }

  public async execute(data: ResolveIssueInput) {
    const routes = this.context.routes as BuyerRoutes;
    await this.post(routes.resolveIssue(this.context.target.id), data);
  }
}
