import * as Sentry from '@sentry/react';

export const logError = (error: unknown, customMessage?: string) => {
  let message = '🔴 Error detected';

  if (customMessage) {
    message += `\n${customMessage}\n`;
  }

  console.error(message, error);
  Sentry.captureException(error);
};

export const logInfo = (message: string) => {
  console.log(`ℹ️ ${message}`);
};

export const logWarning = (message: string) => {
  console.warn(`🟡 ${message}`);
  Sentry.captureMessage(message);
};

export const logDebug = (title: string, message: any) => {
  if (import.meta.env.VITE_ENVIRONMENT === 'local') {
    console.debug(`🟣 ${title} \n`, message);
  }
};
