import { Area, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Icon, ThermometerIcon, UmbrellaIcon } from '@/Icon';
import { Helper } from '@/Text';
import { DateFormat, useDate } from '~/hooks/useDate';
import { WeatherDataPayload } from '~/types/types';

import { RainTooltip } from './RainChart';
import { WeatherTemperatureTooltip } from './WeatherTemperatureChart';

export interface WeatherChartProps {
  data: WeatherDataPayload;
}

export const WeatherChart = ({ data }: WeatherChartProps) => {
  const { format, parse } = useDate();

  return (
    <div className="flex flex-col gap-4">
      <div className="px-6 py-4 border-t border-b border-gray-200 bg-gray-50">
        <div className="flex items-center justify-between">
          <div className="flex items-end space-x-2">
            <Icon>
              <ThermometerIcon/>
            </Icon>
            <Helper>
              Temperature
            </Helper>
          </div>
        </div>
      </div>
      <div className="h-[350px]">
        <div
          style={{ position: 'relative', width: '100%', paddingBottom: '350px' }}
        >
          <div
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0,
              top: 0,
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                className="text-sm"
                width={500}
                height={300}
                syncId="test-weather-chart"
                data={data.events}
                margin={{
                  top: 20,
                  right: 50,
                  left: 20,
                  bottom: 0,
                }}
              >
                <XAxis
                  tickFormatter={val => format(
                    parse(val, DateFormat.ISO),
                    DateFormat.HumanMonthDay
                  )}
                  dataKey="date"
                  className="fill-gray-700"
                  label={{ fill: 'blue' }}
                />
                <YAxis
                  tickFormatter={val => `${val.toFixed(2)}°C`}
                  className="fill-gray-700"
                  domain={[0, 25]}
                />
                <Tooltip content={(props) => <WeatherTemperatureTooltip {...props as any} />}/>
                <Area type="monotone" dataKey="minCelcius" fill="#8884d8" stroke="#8884d8"/>
                {/*<ReferenceLine*/}
                {/*  type="monotone"*/}
                {/*  y={14}*/}
                {/*  label="Min"*/}
                {/*  className="stroke-rose-400"*/}
                {/*  strokeWidth={2}*/}
                {/*  strokeDasharray="3 3"*/}
                {/*/>*/}

                {/*<ReferenceLine*/}
                {/*  type="monotone"*/}
                {/*  y={21}*/}
                {/*  label="Max"*/}
                {/*  className="stroke-rose-400"*/}
                {/*  strokeWidth={2}*/}
                {/*  strokeDasharray="3 3"*/}
                {/*/>*/}

                <Line type="monotone" dataKey="avgCelcius" className="stroke-2 stroke-blue-500" strokeWidth={2}/>
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div>
        <div className="px-6 py-4 border-t border-b border-gray-200 bg-gray-50">
          <div className="flex items-center justify-between">
            <div className="flex items-end space-x-2">
              <Icon>
                <UmbrellaIcon/>
              </Icon>
              <Helper>
                Rainfall
              </Helper>
            </div>
          </div>
        </div>
        <div className="h-[200px]">
          <div
            style={{ position: 'relative', width: '100%', paddingBottom: '200px' }}
          >
            <div
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
              }}
            >
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  className="text-sm"
                  width={500}
                  height={300}
                  syncId="test-weather-chart"
                  data={data.events}
                  margin={{
                    top: 20,
                    right: 50,
                    left: 20,
                    bottom: 24,
                  }}
                >
                  <XAxis
                    tickFormatter={val => format(
                      parse(val, DateFormat.ISO),
                      DateFormat.HumanMonthDay
                    )}
                    dataKey="date"
                    className="fill-gray-700"
                  />
                  <YAxis
                    tickFormatter={val => `${val.toFixed(2)}mm`}
                    className="fill-gray-700"
                    // domain={[0, 9]}
                  />
                  {/*<ReferenceLine*/}
                  {/*  y={1.5}*/}
                  {/*  label="Min"*/}
                  {/*  className="stroke-rose-400"*/}
                  {/*  strokeWidth={2}*/}
                  {/*  strokeDasharray="3 3"*/}
                  {/*/>*/}
                  <defs>
                    <linearGradient id="redGradient" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="50%" stopColor="rgba(255, 99, 71, 0.8)"/>
                      <stop offset="100%" stopColor="rgba(255, 99, 71, 0)"/>
                    </linearGradient>
                    <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor="rgba(130, 202, 157, 0.8)"/>
                      <stop offset="100%" stopColor="rgba(130, 202, 157, 0)"/>
                    </linearGradient>
                  </defs>
                  <Tooltip content={(props) => <RainTooltip {...props as any} />}/>
                  <Area
                    type="monotone"
                    dataKey="rainfall"
                    stroke={(data) => (data.rainfall <= 1.5 ? '#ff6347' : '#82ca9d')}
                    strokeWidth={2}
                    fillOpacity={1}
                    fill="url(#redGradient)"
                  />
                  {/*<Area*/}
                  {/*  type="monotone"*/}
                  {/*  dataKey="rainfall"*/}
                  {/*  stroke="none"*/}
                  {/*  fillOpacity={1}*/}
                  {/*  fill="url(#greenGradient)"*/}
                  {/*  baseValue={1.5}*/}
                  {/*  isAnimationActive={false}*/}
                  {/*/>*/}
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
