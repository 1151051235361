// @flow
import { ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Modal, ModalBody, ModalContent, ModalHeader, ModalTitle, ModalTrigger } from '@/Modal';
import { useAction } from '~/hooks/useAction';
import { BuyerRoutes, useRoutes } from '~/hooks/useRoutes';
import { useToast } from '~/hooks/useToast';
import {
  Form,
  FormGrid,
  FormItem,
  Input,
  InputLabel,
} from '~/UI/Form';
import ProduceInput = App.Domain.Produce.ProduceInput;

interface AddProduceModalProps {
  children?: ReactNode;
}

export const AddProduceModal = ({ children }: AddProduceModalProps) => {
  const routes = useRoutes<BuyerRoutes>();
  const { t } = useTranslation();
  const { toast } = useToast();
  const [isOpen, setIsOpen] = useState(false);

  const { loading, post: saveProduce } = useAction<ProduceInput>(routes.saveProduce(), {
    onSuccess: () => {
      toast({
        title: 'Created new produce',
        variant: 'default',
      });

      setIsOpen(false);
    },
    onError: (error) => {
      toast({
        title: 'Failed to save produce',
        // Follow the pattern of the following: description, reason, nextAction,
        description: 'We received an error while saving the produce. Please try again, or reach the admin.',
        error: error,
        variant: 'destructive',
      });
    },
  });

  const form = useForm({
    defaultValues: {
      name: '',
    },
  });

  const handleSubmit = async (data: typeof form.formState['defaultValues']) => {
    await saveProduce({
      name: data?.name,
      produceVarietyName: 'General',
      initSpec: true,
    });
  };

  return (
    <Modal onOpenChange={setIsOpen} open={isOpen}>
      <ModalTrigger asChild>
        {children}
      </ModalTrigger>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>
            {t('add_produce')}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
              <FormGrid>
                <FormItem>
                  <InputLabel htmlFor="name">{t('produce')}</InputLabel>
                  <Input
                    placeholder={t('apple')}
                    {...form.register('name')}
                  />
                </FormItem>

                <Button
                  type="submit"
                  disabled={!form.formState.isValid || loading as any}
                >
                  {t('submit')}
                </Button>
              </FormGrid>
            </form>
          </Form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
