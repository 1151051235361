import { formatISO } from 'date-fns';

export const deepMerge = (target: any, source: any) => {
  // If target is not an object, initialize it as an empty object
  if (typeof target !== 'object' || target === null) {
    target = {};
  }

  // Return source if it's not an object (including null)
  if (typeof source !== 'object' || source === null) {
    return target;
  }

  const result: any = {};

  // Get all keys from both objects
  const allKeys = Object.keys(target)
    .concat(Object.keys(source))
    .filter((key, index, self) => self.indexOf(key) === index);

  for (const key of allKeys) {
    // Special handling for Date objects in source
    if (source[key] instanceof Date) {
      result[key] = source[key];
      continue;
    }

    // If source has an explicit null/undefined, use it
    if (key in source && (source[key] === null || source[key] === undefined)) {
      result[key] = source[key];
      continue;
    }

    // Handle objects (but not arrays)
    if (source[key] instanceof Object && !Array.isArray(source[key])) {
      // If source has an empty object but target has a value, use target's value
      if (Object.keys(source[key]).length === 0 && target[key] && !(target[key] instanceof Object)) {
        result[key] = target[key];
      } else {
        result[key] = deepMerge(target[key], source[key]);
      }
      continue;
    }

    // For all other cases, prefer source over target
    result[key] = key in source ? source[key] : target[key];
  }

  return result;
};

export const formatData = (data: any): any => {
  if (data === null || typeof data !== 'object') {
    return data;
  }

  if (data instanceof File) {
    return data;
  }

  if (data instanceof Date) {
    return formatISO(data, { format: 'extended' });
  }

  if (Array.isArray(data)) {
    return data.map(formatData);
  }

  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [key, formatData(value)])
  );
};
