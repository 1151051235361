import { Bar, BarChart, XAxis, YAxis } from 'recharts';

import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/Chart';
import Breakdown = App.Domain.Analytics.Breakdown.Breakdown;
import React from 'react';

interface GenericBreakdownProps {
  data: Breakdown;
  label: string;
  color?: string;
  formatValue?: (value: number) => string;
}

/**
 * The GenericBreakdown component breaks down a component into a chart.
 *
 * @abstraction: L2 Component (using underlying L1 Chart components). Give it a `breakdown` object and it will render a simple.
 *
 * @param data - A Breakdown object
 *
 * @param formatValue - A function to format the value of each item in the breakdown
 * @param label - How to label each item in the breakdown
 * @param color - The color of the chart
 * @postconditions - A chart is rendered with the data from the Breakdown object
 */
export const GenericBreakdown = ({ data, formatValue, label, color }: GenericBreakdownProps) => {
  const chartConfig = {
    value: {
      label,
      color,
      formatValue: formatValue,
    },
  } satisfies ChartConfig;

  const chartData = data.items.map((item) => ({
    label: item.label,
    value: item.value,
  }));

  return (
    <div className="min-h-[250px]">
      <ChartContainer config={chartConfig}>
        <BarChart
          accessibilityLayer
          data={chartData}
          layout="vertical"
          margin={{
            left: -20,
          }}
        >
          <XAxis
            type="number"
            dataKey="value"
            tickFormatter={(value) => formatValue ? formatValue(value) : value}
          />
          <YAxis
            dataKey="label"
            type="category"
            minTickGap={0}
            tickLine={false}
            interval={0}
            axisLine={false}
            tickMargin={0}
            tickFormatter={(value) => value.slice(0, 4)}
          />
          <ChartTooltip
            content={
              <ChartTooltipContent
                labelFormatter={(value) => value}
                nameKey="name"
              />
            }
          />
          <Bar dataKey="value" fill="var(--color-value)" radius={5}/>
        </BarChart>
      </ChartContainer>
    </div>
  );
};
