/* eslint-disable react/prop-types */
// noinspection JSUnusedGlobalSymbols

'use client';

import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import * as React from 'react';
import { DayPicker } from 'react-day-picker';

import { buttonVariants } from '@/Button';
import { CardFooter } from '@/Card';
import { Spinner } from '@/Spinner';
import { MutedText } from '@/Text';
import { DateFormat, useDate } from '~/hooks/useDate';
import { cn } from '~/utils/cn';

/**
 * The Calendar is used as a nested component inside a DatePicker.
 */
const Calendar = ({
  className,
  classNames,
  showOutsideDays = true,
  dateNumbers = [],
  loading,
  ...props
}: CalendarProps) => {
  const { format } = useDate();
  // Create a map for quick lookup of numbers by date
  const dateNumberMap = React.useMemo(() => {
    const map = new Map<string, number>();
    dateNumbers.forEach(([date, number]) => {
      map.set(date.toDateString(), number);
    });
    return map;
  }, [dateNumbers]);

  return (
    <div aria-label="calendar">
      <div>
        <DayPicker
          showOutsideDays={showOutsideDays}
          className={cn('p-3', className)}
          classNames={{
            months:
              'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
            month: 'space-y-4',
            caption: 'flex justify-center pt-1 relative items-center',
            caption_label: 'text-sm font-medium',
            nav: 'space-x-1 flex items-center',
            nav_button: cn(
              buttonVariants({ variant: 'outline' }),
              'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100'
            ),
            nav_button_previous: 'absolute left-1',
            nav_button_next: 'absolute right-1',
            table: 'w-full border-collapse space-y-1',
            head_row: 'flex',
            head_cell:
              'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
            row: 'flex w-full mt-2',
            cell: 'h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
            day: cn(
              buttonVariants({ variant: 'ghost' }),
              'h-9 w-9 p-0 font-normal aria-selected:opacity-100'
            ),
            day_range_end: 'day-range-end',
            day_selected:
              'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
            day_today: 'bg-accent text-accent-foreground',
            day_outside:
              'day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
            day_disabled: 'text-muted-foreground opacity-50',
            day_range_middle:
              'aria-selected:bg-accent aria-selected:text-accent-foreground',
            day_hidden: 'invisible',
            ...classNames,
          }}
          components={{
            DayContent: ({ date }) => {
              const number = dateNumberMap.get(date.toDateString());
              let dotColor = '';

              if (number !== undefined) {
                if (number > 0 && number <= 5) {
                  dotColor = 'bg-gray-300';
                } else if (number > 5 && number <= 15) {
                  dotColor = 'bg-blue-500';
                } else if (number > 15) {
                  dotColor = 'bg-red-500';
                }
              }

              const dateFormatted = format(date, DateFormat.DayFormat);

              return (
                <div className="relative flex items-center justify-center w-8 h-8" aria-label={dateFormatted}>
                  {date.getDate()}
                  {dotColor && (
                    <span
                      className={`absolute bottom-0.5  left-1/2 transform -translate-x-1/2 w-1 h-1 rounded-full ${dotColor}`}
                    />
                  )}
                </div>
              );
            },
            IconLeft: () => <ChevronLeftIcon className="w-4 h-4"/>,
            IconRight: () => <ChevronRightIcon className="w-4 h-4"/>,
          }}
          {...props}
        />
      </div>

      <CardFooter className="h-12 flex items-center">
        {loading && (
          <div className="flex">
            <Spinner/>
            <MutedText>Loading</MutedText>
          </div>
        )}
      </CardFooter>
    </div>
  );
};

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  dateNumbers?: [Date, number][];
  dateNumberLabel?: string;
  loading?: boolean;
};

Calendar.displayName = 'Calendar';

export { Calendar };
