// StackedBarChart.tsx
import { MousePointerClickIcon } from 'lucide-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { ChartContainer, ChartTooltip, ChartTooltipContent } from '@/Chart';
import * as DropdownMenu from '@/DropdownMenu';
import { TooltipFooterIcon } from '@/Icon';
import { cn } from '~/utils/cn';

interface StackedBarData {
  date: string;

  [key: string]: number | string; // Allow for dynamic data keys
}

interface StackedBarSeries {
  key: string;
  name: string;
  color?: string;
  theme?: string;
}

interface StackedBarChartProps {
  data: StackedBarData[];
  series: StackedBarSeries[];
  height?: number;
  className?: string;
  renderDropdownMenu?: (data: StackedBarData) => JSX.Element;
}

export const StackedBarChart = ({
  data,
  series,
  height = 300,
  className,
  renderDropdownMenu,
}: StackedBarChartProps) => {
  const [selectedBar, setSelectedBar] = useState<{
    data: StackedBarData;
    element: HTMLElement;
  } | null>(null);

  const { t } = useTranslation();

  const shouldShowTick = (index: number) => {
    if (index === 0) return true; // First tick
    if (index === data.length - 1) return true; // Last tick
    if (index === Math.floor(data.length / 2)) return true; // Middle tick
    return false;
  };

  const handleBarClick = (data: StackedBarData, event: any) => {
    if (!renderDropdownMenu) return;
    // Get click coordinates for positioning dropdown
    const barElement = event.target as HTMLElement;

    const dataPayload = data.payload;

    setSelectedBar({
      data: dataPayload,
      element: barElement,
    });
  };

  return (
    <div>
      <ChartContainer className={cn(className, 'h-48 w-full')} config={series}>
        <BarChart data={data}>
          <XAxis
            dataKey="date"
            tickLine={false}
            axisLine={false}
            interval={0} // Show all ticks (we'll filter them)
            tick={({ x, y, payload, index }) => {
              if (!shouldShowTick(index)) return null;

              const date = new Date(payload.value);
              const formattedDate = date.toLocaleString('en-US', {
                day: '2-digit',
                month: 'short',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              }) + ' UTC';

              return (
                <g transform={`translate(${x},${y})`}>
                  <text
                    x={0}
                    y={30}
                    dy={-10} // Move text up inside the chart
                    textAnchor={index === 0 ? 'start' : index === data.length - 1 ? 'end' : 'middle'}
                    fill="#666"
                    fontSize={12}
                  >
                    {formattedDate}
                  </text>
                </g>
              );
            }}

          />
          <YAxis
            tick={{ fill: '#666' }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(value) => {
              if (value >= 1000) {
                return `${(value / 1000).toFixed(1)}K`;
              }
              return value;
            }}
          />
          <ChartTooltip
            content={<ChartTooltipContent nameKey="name" renderFooter={renderDropdownMenu ? (
              <>
                <TooltipFooterIcon icon={MousePointerClickIcon}/>
                {t('analytics.click_to_zoom_in')}
              </>
            ) : null}/>}
          />
         
          {series.map((s) => {
            // Get the color from theme or use provided color
            return (
              <Bar
                key={s.key}
                className={s.theme}
                dataKey={s.key}
                fill={s.color}
                onClick={(e, d, a) => handleBarClick(e, a)}
                stackId="stack"
                radius={[0, 0, 0, 0]}
              />
            );
          })}
        </BarChart>

        {selectedBar && renderDropdownMenu && (
          <DropdownMenu.DropdownRoot open={!!selectedBar} onOpenChange={() => setSelectedBar(null)}>
            <DropdownMenu.DropdownTrigger asChild>
              {/* Create an invisible trigger that covers the bar */}
              <div
                style={{
                  position: 'fixed',
                  left: selectedBar.element.getBoundingClientRect().left,
                  top: selectedBar.element.getBoundingClientRect().top,
                  width: selectedBar.element.getBoundingClientRect().width,
                  height: selectedBar.element.getBoundingClientRect().height,
                }}
              />
            </DropdownMenu.DropdownTrigger>
            <DropdownMenu.DropdownContent
              side="right" // Position to the right of the bar
              align="start" // Align with the top of the bar
              sideOffset={5} // Add a small gap
            >
              {renderDropdownMenu?.(selectedBar.data)}
            </DropdownMenu.DropdownContent>
          </DropdownMenu.DropdownRoot>
        )}
      </ChartContainer>
    </div>
  );
};
