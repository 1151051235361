import { FieldConstraint } from '~/types/types';

import { SpecField } from './schemas';

/**
 * Extract a list of "all" fields from the form fields and supported fields.
 *
 * - Supported fields are priority 1
 * - Form fields are set (and could be fields with the same name as supported fields).
 * - We only care about a unique union.
 * @param formFields
 * @param supportedFields
 */
export const extractAllFields = (
  formFields: SpecField[],
  supportedFields: SpecField[],
): SpecField[] => {
  const fieldMap = new Map<string, SpecField>();

  // Add supported fields first (priority 1)
  supportedFields.forEach(field => {
    fieldMap.set(field.name, field);
  });

  // Add or overwrite with form fields
  formFields.forEach(field => {
    fieldMap.set(field.name, field);
  });

  // Convert the map values back to an array
  return Array.from(fieldMap.values());
};

export const copyRanges = (fieldConstraint: FieldConstraint) => {
  return JSON.parse(JSON.stringify(fieldConstraint.ranges));
};
