import { useTranslation } from 'react-i18next';

import { ActionContext, BaseAction } from '~/Actions/BaseAction';
import { useAction } from '~/hooks/useAction';
import { usePageProps } from '~/hooks/usePageProps';
import { useRoutes } from '~/hooks/useRoutes';
import { useToast } from '~/hooks/useToast';

/**
 * UseCommandAction is a hook that takes in an Action abstraction, and can be used to execute the action.
 *
 * Why this hook: Actions follow the `Command` pattern, but require an injection of `context` to work. The
 * context contains information about the current user, organization, and routes. This hook provides each action
 * with the relevant context, and outputs the means to interact with the action.
 *
 * Mental model: (context) + (inserted Action) => (ReadyToUseAction) + (StateRelatedToAction)
 *
 * What is a target: The target is the object that the action is being performed on. For example, if the action is to delete a user,
 * the target would be the user object.
 * - For multi-targets, we would be using a `useMultiCommandAction` hook (TBD).
 *
 * @param action
 * @param target
 */
export const useCommandAction = (action: typeof BaseAction, target: any, onSuccess?: any = undefined): ReturnTuple => {
  const routes = useRoutes(action.type ?? 'buyer' as any);

  const { toast } = useToast();
  const { t } = useTranslation();
  const { organization, user } = usePageProps();

  const { loading, manualPost } = useAction('', {
    preserveState: false,
    onSuccess: () => {
      onSuccess?.();
      // Dependent on the actionProps variable...
      if (action.toast) {
        toast({
          title: t(action.toast.titleKey) as string,
          description: action.toast.descriptionKey ? t(action.toast.descriptionKey as any) as any : undefined,
        });
      }
    },
  });

  const context: ActionContext = {
    routes, organization, user, target,
  };

  const actionInstance = new action(context, manualPost);

  return [actionInstance, {
    loading,
  }];
};

type ReturnTuple = [
  BaseAction,
  {
    loading: boolean;
  }
];
