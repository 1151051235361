import { useMemo } from "react";
import { useOrder } from "../../pages/Dashboard/Order/OrderProvider";
import { Comment } from "~/types/types";

type Nullable<T> = T | null;

type UseCommentsResult<K> = {
  comments: K extends undefined ? never : Comment[];
  getComments: (commentableId: Nullable<string>) => Comment[];
};

export const useComments = <K extends string | undefined = undefined>(
  commentableId?: K
): UseCommentsResult<K> => {
  const { comments } = useOrder();

  const getComments = useMemo(
    () => (commentableId: string) => {
      if (!comments) console.warn("No inspections found in order");
      if (comments.length === 0) return [];
      if (!commentableId) return [];

      return (
        comments.filter(
          (comment) => comment.commentable_id === commentableId
        ) || []
      );
    },
    [comments]
  );

  return {
    comments: useMemo(
      () => (commentableId ? getComments(commentableId) : null),
      [commentableId]
    ),
    getComments,
  } as UseCommentsResult<K>;
};
