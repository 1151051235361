import { useTranslation } from 'react-i18next';

import { Card, CardBody } from '@/Card';
import { WithGenericFallback } from '@/Fallback';
import {
  TimelineIconIcon,
} from '@/Icon';
import { getStatusFromMap } from '@/Labels/Status';
import {
  Timeline,
  TimelineAvatar,
  TimelineContent,
  TimelineDescription,
  TimelineHeader,
  WithTimelineDateGroup,
} from '@/Timeline';
import { Event } from '~/types/types';

import { orderChangeStatusMap } from './OrderChangeStatusMap';

interface OrderChangeCardProps {
  events: Event[];
  onViewVersion?: (versionId: number) => void;
}

export const OrderChangeItem = ({ event }: { event: Event }) => {
  const { t } = useTranslation();

  const changeVariant = getStatusFromMap(event.type, orderChangeStatusMap);
  if (!changeVariant) {
    return null;
  }

  return (
    <>
      <TimelineAvatar variant={changeVariant.theme}>
        <TimelineIconIcon icon={changeVariant.icon}/>
      </TimelineAvatar>
      <TimelineContent>
        <TimelineHeader date={event.created_at as unknown as Date}>
          {t(changeVariant.textKey as string)}
        </TimelineHeader>
        <TimelineDescription>
          {t(changeVariant.descriptionKey as string, {})}
        </TimelineDescription>
      </TimelineContent>
    </>
  );
};

export const OrderChangeCard = ({ events }: OrderChangeCardProps) => {
  return (
    <WithGenericFallback>
      <Card>
        <CardBody>
          <Timeline>
            <WithTimelineDateGroup items={events} dateKey="created_at" groupBy="day">
              {(event) => (
                <OrderChangeItem event={event}/>
              )}
            </WithTimelineDateGroup>
          </Timeline>
        </CardBody>
      </Card>
    </WithGenericFallback>

  );
};
