import { CheckIcon } from '@heroicons/react/20/solid';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
// export const Checkbox = w.input(
//   `
//     w-4 h-4
//     text-indigo-600 border-gray-300 rounded focus:ring-indigo-600
//   `, {
//     defaultProps: {
//       type: 'checkbox',
//     },
//   }
// );
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { w } from 'windstitch';

import { FormControl, FormField, FormItem } from '@/Form';
import { Text } from '@/Text';
import { cn } from '~/utils/cn';

export const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      '' +
      'peer h-4 w-4 shrink-0 rounded-sm border border-gray-500 text-white focus:ring-indigo-600 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-indigo-500',
      className
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn('flex items-center justify-center text-current')}
    >
      <CheckIcon className="h-4 w-4"/>
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export const Checklist = ({ items, name }: any) => {
  const { control } = useFormContext();
  return (
    <FormItem>
      {items.map((item) => (
        <div className="flex items-center gap-x-2" key={item.value}>
          <FormField
            key={item.value}
            control={control}
            name={name}
            render={({ field }) => (
              <FormControl key={item.value}>
                <Checkbox
                  checked={field.value?.includes(item.value)}
                  onCheckedChange={(checked) => {
                    return checked
                      ? field.onChange([...field.value, item.value])
                      : field.onChange(
                        field.value?.filter(
                          (value) => value !== item.value
                        )
                      );
                  }}
                />
              </FormControl>
            )}
          />
          <Text>
            <label
              htmlFor={`${item.value}`}
            >
              {item.label}
            </label>
          </Text>
        </div>
      ))}
    </FormItem>
  );
};
