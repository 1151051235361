import classnames from 'classnames';
import React from 'react';

interface RowProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  columns: string;
  children: React.ReactNode;
}

export const EmptyTableContainer = ({ placeholder, height = 200 }: { placeholder: string, height?: number }) => {
  return (
    <div style={{ height: `${height}px` }} className="flex items-center justify-center py-4 px-3 sm:px-8 text-token-muted text-sm">
      {placeholder}
    </div>
  );
};

export const Row = ({ isDisabled, isLoading, children, columns }: RowProps) => {
  return (
    <div
      style={{ gridTemplateColumns: columns, gap: '8px' }}
      className={classnames(
        'grid min-w-fit bg-white items-center py-[6px] mb-[12px] rounded-[12px] transition-all shadow-sm cursor-pointer',
        `${(isDisabled || isLoading) && 'opacity-40 pointer-events-none cursor-auto'}`
      )
      }>
      {children}
    </div >
  );
};
