'use client';

import { XCircleIcon } from '@heroicons/react/20/solid';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/Command';
import { Icon, RegionIcon } from '@/Icon';
import { Spinner } from '@/Spinner';
import { Text } from '@/Text';
import { CountryFlag } from '~/Components/Locations/CountryFlag';
import { CountryName } from '~/Components/Locations/CountryName';

import { PickerCheck } from './GenericPicker';
import { PickerChevron, PickerIcon } from './PickerIcon';
import { ResponsivePicker } from './ResponsivePicker';

interface RegionPickerProps {
  onChange: (region: string | null) => void;
  loading?: boolean;
  selectedRegion?: string | null;
  value?: string | null;
  regions?: string[];
  titleKey?: string;
}

/**
 * The RegionPicker is used to select a region (currently one region).
 *
 * - It works by reloading the page with a `region` key in the query string `?region=PE`.
 * - Pass in the currently selected region as `selectedRegion` (should be uppercased country-code, EG 'PE').
 * - Pass in the available regions as `regions` (should be uppercased country-codes, EG 'PE').
 */
export const RegionPicker = ({
  onChange,
  value = null,
  selectedRegion = null,
  regions = [],
  titleKey = 'select_region',
  loading,
}: RegionPickerProps) => {
  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation();
  const options = regions;

  const selected = selectedRegion || value;
  const selectedStep = options.find((option) => {
    if (!selected) return null;

    if (option.toLowerCase() === selected.toLowerCase()) {
      return option;
    }

    return null;
  });

  const handleUpdate = (region: string | null) => {
    onChange(region);
  };

  const clearPicker = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.stopPropagation();
    onChange(null);
  };

  return (
    <ResponsivePicker
      title={t('region')}
      open={open}
      onOpenChange={setOpen}
      Trigger={
        <Button
          role="combobox"
          variant="white"
          aria-expanded={open}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          disabled={loading}
          className="flex items-center w-full h-full"
        >
          {selectedStep ? (
            <>
              <Step
                option={{
                  icon: <CountryFlag countryCode={selectedStep}/>,
                  labelKey: selectedStep,
                }}
              />
            </>
          ) : (
            <>
              <PickerIcon as={RegionIcon}/>
              {t(titleKey)}
            </>
          )}

          <div className="ml-auto flex items-center">
            {loading ? (
              <div className="ml-2">
                <Spinner/>
              </div>
            ) : (
              <PickerChevron/>
            )}
            {selectedStep && (
              <Icon
                aria-label="clear-button"
                className="ml-2"
                onClick={clearPicker}
              >
                <XCircleIcon className="w-4 h-4 text-gray-300"/>
              </Icon>
            )}
          </div>
        </Button>
      }
    >
      <Command
        filter={(filterValue, search) => {
          // Value is country code, search is the search string
          let country: string | null | undefined;
          try {
            country = new Intl.DisplayNames(['en'], { type: 'region' }).of(
              filterValue.toUpperCase()
            );
          } catch {
            country = null;
          }

          if (
            filterValue.toLowerCase().includes(search.toLowerCase()) ||
            country?.toLowerCase().includes(search.toLowerCase())
          ) {
            return 1;
          }

          return -1;
        }}
      >
        <CommandInput placeholder="Search region..."/>
        <CommandEmpty>No region found.</CommandEmpty>
        <CommandList>
          <CommandGroup>
            {regions.map((region) => (
              <CommandItem
                key={region}
                value={region}
                onSelect={(currentValue) => {
                  handleUpdate(currentValue.toUpperCase());
                  setOpen(false);
                }}
              >
                <PickerCheck
                  isSelected={
                    selectedRegion?.toLowerCase() === region.toLowerCase()
                  }
                />
                <Step
                  option={{
                    icon: <CountryFlag countryCode={region}/>,
                    labelKey: region,
                  }}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </CommandList>
      </Command>
    </ResponsivePicker>
  );
};

const Step = ({
  option,
}: {
  option: { icon: React.ReactNode; labelKey: string };
}) => {
  return (
    <span className="flex items-center">
      <span className="mx-2">{option.icon}</span>
      <Text>
        <CountryName countryCode={option.labelKey.toUpperCase()}/>
      </Text>
    </span>
  );
};
