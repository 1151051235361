import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

import { Button } from '@/Button';
import { Card, CardBody, CardFooter, CardHeader, SimpleCardHeader } from '@/Card';
import { Form, FormField, FormGrid, FormItem, FormMessage, Input } from '@/Form';
import { SimpleIcon, UserGroupIcon } from '@/Icon';
import { LabeledField } from '@/LabeledField';
import { SideLabeledField } from '@/SideLabeledField';
import { CardDescription, CardTitle, MutedText, SimpleCardTitle, Strong } from '@/Text';
import { SaveUserGroup } from '~/Actions/Users/Settings/SaveUserGroup';
import { Swappable } from '~/Components/General/Swappable';
import { ProduceImage } from '~/Components/Produce/ProduceImage';
import { UserGroup } from '~/types/types';

import { saveUserGroupSchema, UserGroupFormatter, UserGroupFormSchema } from './factories';
import PickerOption = App.Domain.Common.PickerOption;
import { SideRadioLayout } from '@/Form/FormLayouts';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { FormatProduceList } from '~/Components/Produce/FormatProduce';
import { formatPicker } from '~/utils/formatPicker';

interface UserGroupCardProps {
  userGroup: UserGroup;
  action: SaveUserGroup;
  loading: boolean;
  isActive: boolean;
  onCancel?: () => void;
  onEdit?: () => void;
  produceOptions?: PickerOption[];
}

export const EditUserGroupCard = ({ userGroup, action, loading, onCancel, produceOptions }: UserGroupCardProps) => {
  const form = useForm({
    defaultValues: UserGroupFormatter.modelToForm(userGroup),
    resolver: zodResolver(saveUserGroupSchema),
  });

  const handleSubmit = async (data: UserGroupFormSchema) => {
    await action.execute(UserGroupFormatter.formToApi(data));
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Card>
          <SimpleCardHeader>
            <SimpleIcon icon={UserGroupIcon}/>
            <SimpleCardTitle>
              User groups
            </SimpleCardTitle>
          </SimpleCardHeader>
          <CardHeader>
            <CardTitle>
              Save user group
            </CardTitle>
            <CardDescription>
              Save user group
            </CardDescription>
          </CardHeader>

          <CardBody>
            <div className="space-y-12">
              <SideLabeledField
                label="General"
                subLabel="General settings"
                renderValue={(
                  <div className="space-y-4">
                    <LabeledField label="Name" renderValue={(
                      <FormField
                        name="name"
                        render={({ field: controlledField }) => (
                          <FormItem>
                            <Input
                              {...controlledField}
                              placeholder={'name'}
                              type="text"
                              className=""
                              id={`name for ${controlledField.name}`}
                            />

                            <FormMessage/>
                          </FormItem>
                        )}
                      />
                    )}/>

                    <LabeledField label="Produces" renderValue={(
                      <FormField
                        name="produceIds"
                        render={({ field: controlledField }) => (
                          <FormItem>
                            <GenericPicker
                              {...controlledField}
                              isMulti
                              placeholder={'Select produces'}
                              title={'Produces'}
                              options={formatPicker(produceOptions)}
                            />

                            <FormMessage/>
                          </FormItem>
                        )}
                      />
                    )}/>
                  </div>

                )}
              />

              <hr/>

              <SideLabeledField
                label="Notifications"
                subLabel="Control settings for notifications"
                renderValue={(
                  <FormGrid>
                    <FormField
                      name="notificationSettings.enableMorningDigestUpdates"
                      render={({ field: controlledField }) => (
                        <FormItem>
                          <SideRadioLayout
                            title="Send morning digest updates"
                            description="Receive a morning digest of updates related to quality, issue and PO data."
                            {...controlledField}
                          />
                        </FormItem>
                      )}
                    />

                    <FormField
                      name="notificationSettings.enableNewOrderNotifications"
                      render={({ field: controlledField }) => (
                        <FormItem>
                          <SideRadioLayout
                            title="Send new order notifications"
                            description="Receive an hourly feed of new orders coming in."
                            {...controlledField}
                          />
                        </FormItem>
                      )}
                    />
                  </FormGrid>
                )}
              />

              <hr/>

              <SideLabeledField
                label="Filtering"
                subLabel="Control settings for filtering"
                renderValue={(
                  <FormGrid>
                    <FormField
                      name="filterSettings.filterOrdersByProduce"
                      render={({ field: controlledField }) => (
                        <FormItem>
                          <SideRadioLayout
                            title="Enable filtering by produce"
                            description="Filter your dashboard and overviews by the produces assigned to this user-group."
                            {...controlledField}
                          />
                        </FormItem>
                      )}
                    />
                  </FormGrid>
                )}
              />

              <hr/>

            </div>
          </CardBody>

          <CardFooter>
            <div className="flex gap-4">
              <Button type="submit" variant="primary" disabled={loading}>
                Save
              </Button>
              <Button type="button" variant="white" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
};

export const PreviewUserGroupCard = ({ onEdit, userGroup }: UserGroupCardProps) => {
  return (
    
    <Card>
      <CardBody>
        <div className="grid grid-cols-3 gap-4">
          {/* Column 1 */}
          <div aria-label="column user group name">
            <div>
              <Strong>
                {userGroup.name}
              </Strong>
            </div>
            <div>
              <MutedText>
                {userGroup.users?.length} users
              </MutedText>
            </div>
          </div>

          {/* Column 2 */}
          <div aria-label="column user group produces">
            {userGroup.produces?.length > 0 && (
              <div className="flex gap-2 items-center">
                <div>
                  {userGroup.produces?.length > 0 && (
                    <ProduceImage produce={userGroup?.produces[0]}/>
                  )}
                </div>
                <div>
                  <Strong>
                    <FormatProduceList produces={userGroup.produces ?? []}/>
                  </Strong>
                </div>
              </div>
            )}

            {userGroup.produces?.length === 0 && (
              <div>
                <MutedText>
                  No produces
                </MutedText>
              </div>
            )}
          </div>

          {/* Column 3 */}
          <div className="flex justify-end" aria-label="column user group actions">
            <div>
              <Button variant="white" onClick={() => onEdit?.()}>
                Edit
              </Button>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export const UserGroupCard = ({
  produceOptions,
  onEdit,
  userGroup,
  action,
  isActive,
  loading,
  onCancel,
}: UserGroupCardProps) => {
  return (
    <Swappable
      isEdit={isActive}
      displayComponent={(
        <PreviewUserGroupCard
          onCancel={onCancel}
          userGroup={userGroup}
          action={action}
          loading={loading}
          onEdit={onEdit}
          isActive={isActive}
        />
      )}
      editComponent={(
        <EditUserGroupCard
          userGroup={userGroup}
          action={action}
          loading={loading}
          onCancel={onCancel}
          isActive={isActive}
          produceOptions={produceOptions}
        />
      )}
    />
  );
};
