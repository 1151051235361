// useOrder which is a custom hook to work with order data
// relies on context and context provider

import { createContext, useContext, useMemo } from 'react';

import { Comment, CompoundIssue, Produce, Spec } from '~/types/types';
import { makeMap, mapItemsToColor } from '~/utils/collections';
import { themes as defaultThemes } from '~/utils/colors';

import { OrderPayload } from './types';
import ProduceVariety = App.Models.ProduceVariety;

interface OrderOutput extends OrderPayload {
  specMap: Record<string, Spec>;
  produceVarietyMap: Record<string, ProduceVariety>;
  produceMap: Record<string, Produce>;
  specColorMap: Record<string, string>;
  findSpec: (specId: string | null) => Spec | null;
  findProduce: (produceId: string | null) => Produce | null;
  findProduceVariety: (produceVarietyId: string | null) => ProduceVariety | null;
  comments: Comment[];
  inspectionIssue?: CompoundIssue;
  transportIssue?: CompoundIssue;
  logisticIssue?: CompoundIssue;
}

export const OrderContext = createContext<OrderOutput>({} as OrderOutput);

/**
 * Provider for order data (to be used in the Order page)
 */
export const OrderProvider = ({
  children,
  orderData,
}: {
  children: React.ReactNode;
  orderData: OrderPayload;
}) => {
  const allSpecs = orderData.orderProduces?.reduce<Spec[]>(
    (acc, orderProduce) => {
      const spec = orderProduce.active_spec;

      if (spec) {
        return [...acc, spec];
      }

      return acc;
    },
    []
  );

  const comments = orderData.allComments;

  const produceMap = useMemo(() => makeMap(orderData.orderProduces.map(orderProduce => orderProduce.produce)), [orderData.orderProduces]);
  const produceVarietyMap = useMemo(() => makeMap(orderData.orderProduces.map(orderProduce => orderProduce.produce_variety)), [orderData.orderProduces]);

  const specMap = useMemo(() => makeMap<Spec>(allSpecs), [allSpecs]);
  const specColorMap = useMemo(
    () => mapItemsToColor(allSpecs, defaultThemes),
    [allSpecs]
  );

  const findSpec = (specId: string | null): Spec | null => {
    if (specId && specId in specMap) {
      return specMap[specId];
    }

    return null;
  };

  const findProduce = (produceId: string | null): Produce | null => {
    if (produceId && produceId in produceMap) {
      return produceMap[produceId];
    }

    return null;
  };

  const findProduceVariety = (produceVarietyId: string | null): ProduceVariety | null => {
    if (produceVarietyId && produceVarietyId in produceVarietyMap) {
      return produceVarietyMap[produceVarietyId];
    }

    return null;
  };

  const output: OrderOutput = {
    ...orderData,
    comments,
    findSpec,
    specMap,
    produceMap,
    produceVarietyMap,
    findProduce,
    findProduceVariety,
    specColorMap,
  };

  return (
    <OrderContext.Provider value={output}>{children}</OrderContext.Provider>
  );
};

export const useOrder = () => useContext(OrderContext);
