import { AnimatePresence, motion, MotionConfig } from 'framer-motion';
import { ReactNode } from 'react';

import { cn } from '~/utils/cn';

interface SwappableProps {
  isEdit: boolean;
  displayComponent: ReactNode;
  editComponent: ReactNode;
  className?: string;
}

const transition = {
  type: 'spring',
  bounce: 0.1,
  duration: 0.2,
};

export const Swappable = ({
  isEdit,
  displayComponent,
  editComponent,
  className,
}: SwappableProps) => {
  return (
    <MotionConfig transition={transition}>
      <div className={cn('relative w-full', className)}>
        <AnimatePresence mode="wait" initial={false}>
          {isEdit ? (
            <motion.div
              key="edit"
              layout
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="w-full"
            >
              {editComponent}
            </motion.div>
          ) : (
            <motion.div
              key="display"
              layout
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="w-full"
            >
              {displayComponent}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </MotionConfig>
  );
};

