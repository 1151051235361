import { DesktopOnly } from './Responsive';

interface FloaterProps {
  refs: any;
  floatingStyles: any;
  children: any;
}

/**
 * Floater component which we can place on the side of a reference element.
 * It will automatically position itself to be visible on the screen, and hide on non-desktop devices.
 */
export const Floater = ({ refs, floatingStyles, children }: FloaterProps) => {
  return (
    <DesktopOnly>
      <div ref={refs.setFloating} style={floatingStyles}>
        {children}
      </div>
    </DesktopOnly>
  );
};
