import { JsonEditor } from 'json-edit-react';
import { Diff } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardBody,
  CardFooter,
  CardGutter,
  CardHeaderContainer,
  CardPanels,
  CardSection,
  SimpleCardHeader,
} from '@/Card';
import { Delimit } from '@/Delimit';
import {
  formatEntityIcon,
  MeasurementFieldIcon,
  MiniLabelIcon,
  OrganizationIcon,
  ReportingAccuracyIcon,
  SellerIcon,
  SimpleCardHeaderIcon, SimpleIcon,
} from '@/Icon';
import { Label, MiniLabel } from '@/Label';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { CardDescription, CardTitle, Helper, MutedText, SimpleCardTitle, Strong } from '@/Text';
import { RecommendationPrompt } from '~/Components/AI/Recommendations/RecommendationPrompt';
import { FormatDistribution } from '~/Components/Analytics/FormatDistribution';
import { SummarizeDistribution } from '~/Components/Analytics/SummarizeDistribution';
import Section from '~/Components/Common/Section';

import { sampleData } from './sample-data';

interface ReportingAccuracyModuleProps {
  module?: any;
}

export const ReportingAccuracyModuleSection = ({ module }: ReportingAccuracyModuleProps) => {
  const { t } = useTranslation();

  return (
    <Section
      icon={ReportingAccuracyIcon}
      defaultOpen={false}
      showCollapsed title={t('reporting_accuracy')} description={t('reporting_accuracy_description')}
      enableToggle>
      <div aria-label="card for reporting accuracy">
        <Card>
          <SimpleCardHeader>
            <SimpleCardHeaderIcon icon={ReportingAccuracyIcon}/>
            <SimpleCardTitle>
              {t('reporting_accuracy')}
            </SimpleCardTitle>
          </SimpleCardHeader>

          <CardHeaderContainer>
            <CardTitle>
              {t('buyer_seller_accuracy_breakdown')}
            </CardTitle>
            <CardDescription>
              {t('buyer_seller_accuracy_breakdown_description')}
            </CardDescription>
          </CardHeaderContainer>

          <CardGutter align="end">
            <div className="space-x-4 flex"
            >
              <div>
                <GenericPicker icon={MeasurementFieldIcon} title="Select breakdown"
                  options={[{ label: 'Fields with issues', value: 'field' }]} emptyMessage=""
                  placeholder="" value="field"/>
              </div>

              <div>
                <GenericPicker icon={SellerIcon} title="Select seller"
                  options={[{ label: 'Sunripe', value: 'hass' }]} emptyMessage="" placeholder=""
                  value="hass"/>
              </div>
            </div>
          </CardGutter>

          <CardGutter align="start" variant="darker">
            <div className="flex w-full justify-between">
              <div className="w-full grid grid-cols-[1fr_2fr]">
                <div>
                  <Helper>
                    Field
                  </Helper>
                </div>

                <div className="w-full flex justify-between items-center">
                  <Helper>
                    Organization
                  </Helper>

                  <Helper>
                    Breakdown
                  </Helper>
                </div>
              </div>

            </div>
          </CardGutter>

          <div>
            <div>
              {sampleData.map((item, index) => (
                <CardPanels columns="1_2" key={index}>
                  <CardBody bordered="all" variant="muted">
                    <div>
                      <Strong>
                        {item.field.name}
                      </Strong>

                      {item.field.criticalLevel && (
                        <Label theme="red" className="ml-2">
                          {t(item.field.criticalLevel)}
                        </Label>
                      )}
                    </div>

                    {item.recommendation && (
                      <div className="mt-3">
                        <RecommendationPrompt recommendation={item.recommendation}/>
                      </div>
                    )}
                  </CardBody>
                  <div className="px-4 py-2 divide-y divide-gray-100 border-b">
                    {item.entries.map(entry => (
                      <div className="py-4 flex justify-between items-center" key={entry.entity.id}>
                        <div aria-label="entity">
                          <div>
                            <Strong className="!leading-none">
                              {entry.entity.name}
                            </Strong>
                          </div>
                          <div className="flex items-center gap-2">
                            <MiniLabel>
                              <MiniLabelIcon className="!stroke-gray-400"
                                icon={formatEntityIcon({ entity: entry.entity.role })}
                              />
                              {t(entry.entity.role)}
                            </MiniLabel>
                            <MutedText className="!leading-none !text-gray-500 text-xxs">
                              <span className="flex items-center gap-1">
                                <SimpleIcon className="!stroke-gray-300" icon={Diff}/>
                                {entry.entity.postHarvest} days post-harvest
                              </span>
                            </MutedText>
                          </div>
                        </div>

                        <div className="min-w-[240px]" aria-label="measurements">
                          <div>
                            <FormatDistribution distribution={entry.values} isCompact/>
                          </div>
                          <div className="mt-1">
                            <SummarizeDistribution distribution={entry.values}/>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </CardPanels>
              ))}
            </div>
          </div>

          <CardFooter></CardFooter>
        </Card>
      </div>
    </Section>
  );
};
