import { FieldConstraint, FieldType, Range } from '~/types/types';

export const extractActiveConstraint = (constraint: FieldConstraint) => {
  let activeConstraint = constraint;
  let hasActiveNestedConstraint = false;

  if (constraint.nested_constraints?.length) {
    const activeNestedConstraint = constraint.nested_constraints.find(nestedConstraint => nestedConstraint.active);

    if (activeNestedConstraint) {
      activeConstraint = activeNestedConstraint;
      hasActiveNestedConstraint = true;
    }
  }

  return {
    activeConstraint,
    hasActiveNestedConstraint,
  };
};

export const preprocessRange = (range: Range) => {
  return {
    ...range,
    min: range.min ? range.min * 100 : range.min,
    max: range.max ? range.max * 100 : range.max,
  };
};

/**
 * Preps and preprocesses constraint.
 * @param constraint
 * @param fieldType
 */
export const preprocessConstraint = (constraint: FieldConstraint, fieldType: FieldType): FieldConstraint => {
  if (fieldType === 'measurement' || fieldType === 'boolean' || fieldType === 'ratio') {
    return constraint;
  }

  return {
    ...constraint,
    ranges: (constraint.ranges || []).map(preprocessRange),
    nested_constraints: constraint.nested_constraints?.map(nestedConstraint => ({
      ...nestedConstraint,
      ranges: (nestedConstraint.ranges || []).map(preprocessRange),
    })),
  };
};
