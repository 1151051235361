import { ClipboardIcon, HandThumbUpIcon } from '@heroicons/react/20/solid';
import { PauseCircleIcon } from '@heroicons/react/24/outline';
import { ErrorBoundary } from '@sentry/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardBody,
  CardFooter,
  CardHeaderContainer,
} from '@/Card';
import { WithGenericFallback } from '@/Fallback';
import { Floater } from '@/Floater';
import { IconBox, OpenIssueIcon } from '@/Icon';
import { Text, TextLabel } from '@/Text';
import { Issue, IssueStatus } from '~/types/types';
import { cn } from '~/utils/cn';

export interface OrderIssueProps {
  title: string;
  description?: string;
  renderBody?: ReactNode;
  refs: any;
  floatingStyles: any;
  renderActions?: ReactNode;
  issues?: Issue[];
}

const makeTheme = (status: IssueStatus) => {
  switch (status) {
  case IssueStatus.Ignored:
    return {
      bgColor: '!bg-gray-200',
      borderColor: 'border-gray-100',
      ringColor: 'ring-gray-100',
      textColor: 'text-gray-600',
      icon: PauseCircleIcon,
      iconColor: 'fill-gray-500 stroke-white',
    };
  case IssueStatus.Resolved:
    return {
      bgColor: '!bg-blue-100',
      borderColor: 'border-blue-100',
      ringColor: 'ring-blue-100',
      textColor: 'text-blue-600',
      icon: HandThumbUpIcon,
      iconColor: 'fill-blue-500 stroke-white',
    };
  case 'mixed':
    return {
      bgColor: '!bg-white',
      borderColor: 'border-gray-100',
      ringColor: 'ring-gray-100',
      textColor: 'text-gray-600',
      icon: ClipboardIcon,
      iconColor: 'fill-blue-500 stroke-white',
    };
  default:
    return {
      bgColor: '!bg-red-50',
      borderColor: 'border-red-100',
      ringColor: 'ring-red-100',
      textColor: 'text-red-600',
      icon: OpenIssueIcon,
      iconColor: 'fill-yellow-500 stroke-white',
    };
  }
};

export const OrderIssueType = (
  {
    title = 'Issue encountered',
    description,
    renderActions,
    renderBody,
    issues,
  }: any) => {
  const ignoredIssues = issues?.filter((issue: Issue) => issue.status === IssueStatus.Ignored);
  const resolvedIssues = issues?.filter((issue: Issue) => issue.status === IssueStatus.Resolved);
  const openIssues = issues?.filter((issue: Issue) => issue.status === IssueStatus.Open);
  const { t } = useTranslation();

  if (ignoredIssues?.length === issues?.length) {
    return (
      <WithGenericFallback>
        <Card className="!bg-gray-200 !border-gray-100 !ring-gray-100 text-gray-600 max-w-[300px] min-w-[200px]">
          <CardBody size="sm">
            <div className="flex space-x-2">
              <div className="flex items-center">
                <IconBox className="w-6 h-6 mr-2">
                  <PauseCircleIcon className="fill-gray-500 stroke-white"/>
                </IconBox>
              </div>
              <TextLabel className="!text-gray-600 flex items-center !text-xs space-x-2">
                {t('n_issues_ignored', { count: ignoredIssues?.length })}
              </TextLabel>
            </div>
          </CardBody>
        </Card>
      </WithGenericFallback>
    );
  }

  if (resolvedIssues?.length === issues?.length) {
    return (
      <WithGenericFallback>
        <Card className="!bg-blue-100 !border-blue-100 !ring-blue-100 text-blue-600 max-w-[300px] min-w-[200px]">
          <CardBody size="sm">
            <div className="flex space-x-2">
              <div className="flex items-center">
                <IconBox className="w-6 h-6 mr-2">
                  <HandThumbUpIcon className="fill-blue-500 stroke-white"/>
                </IconBox>
              </div>
              <TextLabel className="!text-blue-600 flex items-center !text-xs space-x-2">
                {t('n_issues_resolved', { count: resolvedIssues?.length })}
              </TextLabel>
            </div>
          </CardBody>
        </Card>
      </WithGenericFallback>
    );
  }

  const hasHandledAllIssues = openIssues?.length === 0;

  const theme = makeTheme(hasHandledAllIssues ? IssueStatus.Mixed : IssueStatus.Open);

  return (
    <Card className={
      cn(
        'max-w-[300px] min-w-[200px] w-full',
        theme.bgColor
      )
    }>
      <CardHeaderContainer className={cn('!py-2', theme.borderColor)} size="sm">
        <TextLabel className="text-inherit flex items-center !text-xs">
          <div className="flex items-center">
            <IconBox className="mr-2">
              <theme.icon className={cn(theme.iconColor, 'max-w-4 max-h-4')}/>
            </IconBox>
          </div>
          {title}
        </TextLabel>
      </CardHeaderContainer>
      {description && (
        <CardBody className="!py-2" size="sm">
          <Text className="text-red-600 !text-xs">{description}</Text>
        </CardBody>
      )}
      {!!renderBody && (
        <CardBody className="!py-2" size="sm">
          {renderBody}
        </CardBody>
      )}

      {!!renderActions && (
        <CardFooter className="!bg-red-100" size="sm">
          {renderActions}
        </CardFooter>
      )}
    </Card>
  );
};

/**
 * Order issue component which we can place on the side of a reference element, or inline in case of mobile.
 */
export const OrderIssue = ({
  title = 'Issue encountered',
  description,
  renderActions,
  renderBody,
  issues,
}: OrderIssueProps) => {

  return (
    <ErrorBoundary fallback={<div/>}>
      <div>
        <OrderIssueType issues={issues} title={title} description={description} renderActions={renderActions}
          renderBody={renderBody}/>
      </div>
    </ErrorBoundary>
  );
};
