/* eslint-disable @typescript-eslint/ban-ts-comment */
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';
import { ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Form, FormControl, FormField, FormGrid, FormItem, Input, InputDescription, InputLabel } from '@/Form';
import { Modal, ModalBody, ModalContent, ModalDescription, ModalHeader, ModalIcon, ModalTitle, ModalTrigger } from '@/Modal';
import { RadioButtonCard, RadioGroup } from '@/RadioButtonCard';
import { useAction } from '~/hooks/useAction';
import { useError } from '~/hooks/useError';
import { BuyerRoutes, useRoutes } from '~/hooks/useRoutes';
import { useToast } from '~/hooks/useToast';

interface CreateOrganizationFormProps {
  onSubmit: () => void;
}

export const CreateOrganizationForm = ({ onSubmit }: CreateOrganizationFormProps) => {
  const routes = useRoutes<BuyerRoutes>();

  const { toast } = useToast();
  const { log } = useError();

  const { post: create, loading } = useAction(routes.createOrganization(), {
    onSuccess: () => {
      toast({
        title: 'Organization created',
        description: 'Your organization has been created.',
      });

      // Set a timeout, then onSubmit
      setTimeout(() => {
        onSubmit();
      }, 1000);
    },
    onError: (e) => {
      log(e);

      toast({
        title: 'Organization creation failed',
        description: 'Something went wrong while creating your organization.',
        variant: 'destructive',
      });
    },
  });

  const handleSubmit = (data: any) => {
    create({
      ...data,
      inviteSelf: data.inviteSelf === 'true',
    });
  };

  const form = useForm({
    defaultValues: {
      name: '',
      countryCode: '',
      inviteSelf: false,
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <FormGrid>
          <FormItem>
            <InputLabel>
              Name
            </InputLabel>
            <InputDescription>
              What is the name of your workspace?
            </InputDescription>
            <Input {...form.register('name', { required: true })} />
          </FormItem>

          <FormItem>
            <InputLabel>
              Country Code
            </InputLabel>
            <InputDescription>
              What is the country code for your workspace? E.g. NL, US, etc.
            </InputDescription>
            <Input {...form.register('countryCode')} />
          </FormItem>

          <FormField
            name="inviteSelf"
            render={({ field }) => (
              <FormItem>
                <InputLabel>
                  Invite Self
                </InputLabel>
                <InputDescription>
                  Should we invite you to your workspace?
                </InputDescription>
                <FormControl>
                  <RadioGroup className="space-y-1" defaultValue='false' onValueChange={field.onChange}>
                    <RadioButtonCard
                      id="TRUE"
                      value='true'
                      label='Yes'
                    />
                    <RadioButtonCard
                      id="FALSE"
                      value='false'
                      label='No'
                    />
                  </RadioGroup>
                </FormControl>
              </FormItem>
            )}
          />
          <div>
            {/* @ts-ignore */}
            <Button type="submit" disabled={!form.formState.isValid || loading}>
              Create
            </Button>
          </div>
        </FormGrid>

      </form>
    </Form>
  );
};

interface CreateOrganizationModalProps {
  children: ReactNode;
}

export const CreateOrganizationModal = ({ children }: CreateOrganizationModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <Modal open={isOpen} defaultOpen={isOpen} onOpenChange={setIsOpen}>
      <ModalTrigger asChild>
        {children}
      </ModalTrigger>
      <ModalContent className="max-w-[800px]">
        <ModalHeader>
          <div className="flex items-center space-x-3">
            <ModalIcon icon={<BuildingOffice2Icon className="w-7 h-7" />} />
            <div>
              <ModalTitle>
                {t('create_workspace')}
              </ModalTitle>
              <ModalDescription>
                {t('create_workspace_description')}
              </ModalDescription>
            </div>
          </div>
        </ModalHeader>

        <ModalBody>
          <CreateOrganizationForm onSubmit={() => setIsOpen(false)} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
