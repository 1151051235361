import { ReactNode } from "react";

export const LocalOnly = ({ children }: { children: ReactNode }) => {
  // If we're not in a local environment, don't render anything
  if (!import.meta.env.VITE_ENVIRONMENT) {
    return null;
  }

  if (import.meta.env.VITE_ENVIRONMENT !== 'local') {
    return null;
  }

  // If we're not in debug mode, don't render anything
  if (import.meta.env.VITE_ENABLE_DEBUG === "false") {
    return null;
  }

  if (import.meta.env.VITE_ENVIRONMENT === 'local') {
    return children;
  }

  return null;
}
