import { Button } from '../../Shared/UI/Button';
import { Container } from '../../Shared/UI/Container';
import { Input } from '../../Shared/UI/Input';
import { AuthLayout } from './AuthLayout';

const RegisterPage = () => {
  return (
    <AuthLayout>
      <Container className="flex justify-center py-20 mx-auto">
        <div className="mx-auto">
          <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
            <div className="w-full max-w-md space-y-8">
              <div>
                <img
                  className="w-auto h-12 mx-auto"
                  src="assets/logo.svg"
                  alt="Your Company"
                />
                <h2 className="mt-6 text-3xl font-bold tracking-tight text-center text-gray-700">
                  Register for your account
                </h2>
              </div>
              <form className="mt-8 space-y-6" action="#" method="POST">
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="-space-y-px rounded-md shadow-sm">
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                  Email address
                    </label>
                    <Input
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="rounded-t-md"
                      placeholder="Email address"
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="sr-only">
                  Password
                    </label>
                    <Input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="rounded-b-md"
                      placeholder="Password"
                    />
                  </div>
                </div>

                <div>
                  <Button
                    type="submit"
                    variant="primary"
                    className="relative flex justify-center w-full px-3 py-2"
                  >
                    Register
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </AuthLayout>
  );
};

export default RegisterPage;
