import { OrderDataStream } from "~/types/types";
import { Label } from "@/Label";
import { Icon, TransportLocationIcon } from "@/Icon";
import { MutedText } from "@/Text";
import { Tooltipped } from "@/Tooltip";
import React from "react";

export const RelevantTransportLabel = ({
  dataStream,
}: {
  dataStream: OrderDataStream<any>;
}) => {
  // Get latest event (last event from .data.events)

  const events = dataStream.data?.events ?? [];
  const latestEvent = events[events.length - 1] ?? { label: "Unknown" };

  if (!latestEvent.label) {
    return null;
  }

  return (
    <Tooltipped label={latestEvent.label}>
      <Label>
        <Icon className="hidden sm:inline-flex">
          <TransportLocationIcon className="w-3 h-3" />
        </Icon>
        <div className="overflow-ellipsis overflow-hidden max-w-[80px] sm:max-w-[150px] whitespace-nowrap">
          <MutedText>{latestEvent.label}</MutedText>
        </div>
      </Label>
    </Tooltipped>
  );
};
