import { ReactNode } from 'react';
import { w } from 'windstitch';

import { Button } from './Button';
import { SectionTitle, Text } from './Text';

/**
 * Show content only on desktop (XL sizes and above.)
 */
export const DesktopOnly = w.div(`
  hidden lg:revert
`);

/**
 * Show content only on mobile and tablet (Below XL)
 */
export const MobileOnly = w.div(`
  block lg:hidden
`);

export const BlockMobile = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <DesktopOnly>
        {children}
      </DesktopOnly>
      <MobileOnly className="h-screen">
        <div className="flex flex-col items-center justify-center h-screen">
          <div className="mb-4 text-center">
            <SectionTitle>Not Mobile Ready</SectionTitle>
            <Text>Please use a desktop or laptop to view this page.</Text>
          </div>
          <Button>
            Back
          </Button>
        </div>
      </MobileOnly>
    </>
  );
};
