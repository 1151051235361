import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { ButtonIcon, EscalateIcon } from '@/Icon';
import { MarkIssueAsAccepted } from '~/Actions/Issues/MarkIssueAsAccepted';
import { MarkIssueAsEscalated } from '~/Actions/Issues/MarkIssueAsEscalated';
import { MarkIssueAsRead } from '~/Actions/Issues/MarkIssueAsRead';
import { ResolveIssueModal } from '~/Components/Issues/ResolveIssueModal';
import { useCommandAction } from '~/hooks/useCommandAction';
import { usePageProps } from '~/hooks/usePageProps';
import { Issue } from '~/types/types';

interface IssueActionsProps {
  issue: Issue;
}

export const IssueActions = ({
  issue,
}: IssueActionsProps) => {
  const { t } = useTranslation();
  const { organization, user } = usePageProps();

  const [markIssueAsRead, { loading: markIssueAsReadLoading }] = useCommandAction(MarkIssueAsRead, issue);
  const [acceptIssue, { loading: acceptedLoading }] = useCommandAction(MarkIssueAsAccepted, issue);
  const [escalateIssue, { loading: escalatedLoading }] = useCommandAction(MarkIssueAsEscalated, issue);

  const loading = false;

  return (
    <div className="flex items-center gap-2 flex-wrap">
      <Button
        disabled={loading || markIssueAsRead.disabled() || markIssueAsReadLoading as any}
        variant="white" size="xs"
        onClick={() => markIssueAsRead.execute({
          type: 'mark_as_read',
          issueId: issue.id,
          buyerId: organization.id,
          userId: user.id,
          comment: '',
          informSeller: false,
        })}
        className="whitespace-nowrap"
      >
        <ButtonIcon icon={markIssueAsRead.getIcon()} className="stroke-emerald-500"/>
        Mark as read
      </Button>
      <ResolveIssueModal
        issue={issue}
        initialIssueState="accepted"
        onChange={(data) => acceptIssue.execute({
          type: 'mark_as_accepted',
          issueId: issue.id,
          buyerId: organization.id,
          userId: user.id,
          comment: data.comment,
          informSeller: data.informSeller,
        })}
      >
        <Button
          disabled={acceptedLoading || (acceptIssue.disabled()) as any}
          variant="white" size="xs"
        >
          <ButtonIcon icon={acceptIssue.getIcon()} className="stroke-orange-500"/>
          {t(acceptIssue.nameKey())}
        </Button>
      </ResolveIssueModal>

      <ResolveIssueModal
        issue={issue}
        onChange={(data) => escalateIssue.execute({
          type: 'mark_as_escalated',
          issueId: issue.id,
          buyerId: organization.id,
          userId: user.id,
          comment: data.comment,
          informSeller: data.informSeller,
        })}
        initialIssueState="escalated"
      >
        <Button
          disabled={loading || (escalateIssue.disabled() || escalatedLoading) as any}
          variant="white" size="xs"
        >
          <ButtonIcon icon={EscalateIcon} className="stroke-red-500"/>
          {t(escalateIssue.nameKey())}
        </Button>
      </ResolveIssueModal>
    </div>
  );
};
