import { PencilIcon, XMarkIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import {
  Card,
  CardBody,
  CardContent,
  CardHeaderContainer,
} from '@/Card';
import { Label } from '@/Label';
import { EmptyTableContainer } from '@/Table';
import { ReponsiveTableContainer } from '@/Table/TableContainer';
import { CardTitle, MutedText, PageDescription, PageTitle, Text } from '@/Text';
import { useAction } from '~/hooks/useAction';
import { toast } from '~/hooks/useToast';

import { usePageProps } from '../../../../Shared/hooks/usePageProps';
import {
  PageProps,
  PaginatedItems,
  Produce,
  User,
  UserGroup,
} from '../../../../Shared/types/types';
import { SettingsHeader } from '../SettingsHeader';
import SettingsLayout from '../SettingsLayout';
import { CreateUserGroupModal } from './Components/CreateUserGroupModal';
import { InviteUserModal } from './Components/InviteUserModal';
import { UsersTable } from './Components/UsersTable';

interface UsersOrganizationSettingsProps extends PageProps {
  groups: UserGroup[];
  users: PaginatedItems<User>;
  produces: Produce[];
}

const UserGroupTable = ({ groups }: { groups: UserGroup[] }) => {
  const { t } = useTranslation();
  const { organization, produces } = usePageProps<UsersOrganizationSettingsProps>();

  const { post: deleteUserGroup } = useAction(`/b/${organization.id}/user_groups/delete`, {
    onSuccess: () => {
      toast({
        title: 'User group has been succesfully removed',
        description: 'All users in this group have been removed from the group.',
      });
    },
  });

  const getGroupById = (id: string) => {
    return groups.find((group) => group.id === id);
  };

  return (
    <Card>
      <ReponsiveTableContainer>
        <CardHeaderContainer>
          <div className="grid grid-cols-[1fr_0.75fr_1fr_1fr_75px]  gap-8">
            <div>
              <MutedText>Groups</MutedText>
            </div>
            <div>
              <MutedText>Group Type</MutedText>
            </div>
            <div>
              <MutedText>Member Count</MutedText>
            </div>
            <div>
              <MutedText>Produces</MutedText>
            </div>
            <div></div>
          </div>
        </CardHeaderContainer>
        <hr />

        <div>
          <div>
            <div className="divide-y divide-gray-200">
              <CardContent>
                {groups.length > 0 ? groups.map((group) => (
                  <div
                    key={group.id}
                    className="grid grid-cols-[1fr_0.75fr_1fr_1fr_75px] gap-8 items-center justify-between py-4"
                  >
                    <div>
                      <Text>{group.name}</Text>
                    </div>
                    <div>
                      <Label>{group.admin ? 'Admin' : 'User'}</Label>
                    </div>
                    <div>
                      <Text>{group.users?.length} members</Text>
                    </div>
                    <div className='flex flex-col gap-1'>
                      {!!group.produces && group.produces?.length > 0 ? (
                        <div className="space-x-2">
                          {group.produces?.map((produce) => (
                            <Label key={produce.id}>
                              {produce?.name}
                            </Label>
                          ))}
                        </div>
                      ) : (
                        <Label className='w-fit'>No produces</Label>
                      )}
                    </div>
                    <div className='p-2 top-3 right-3 hidden md:flex gap-2'>
                      <CreateUserGroupModal
                        group={getGroupById(group.id)}
                        produces={produces}
                        organization={organization}
                        isEditing
                      >
                        <button>
                          <PencilIcon className="w-4 h-4 text-gray-500" />
                        </button>
                      </CreateUserGroupModal>

                      <button onClick={() => deleteUserGroup({ id: group.id })
                      }>
                        <XMarkIcon className='w-6 h-6 text-gray-500'/>
                      </button>
                    </div>
                  </div>
                )) : (
                  <EmptyTableContainer placeholder={t('no_user_groups_found')} />
                )}
              </CardContent>
            </div>
          </div>
        </div>
      </ReponsiveTableContainer>

    </Card>
  );
};

const UsersOrganizationSettingsPage = () => {
  const { organization, groups, users, produces } =
    usePageProps<UsersOrganizationSettingsProps>();

  return (
    <div>
      <div className="space-y-6">
        <SettingsHeader>
          <PageTitle>Users & User Groups</PageTitle>
          <PageDescription>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Manage your organization's users and user groups.
          </PageDescription>
        </SettingsHeader>

        <hr />

        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <CardTitle>User groups</CardTitle>

            {groups.length > 0 && (
              <div>
                <CreateUserGroupModal produces={produces} organization={organization}>
                  <Button>Create user group</Button>
                </CreateUserGroupModal>
              </div>
            )}
          </div>

          {groups.length > 0 ? (
            <UserGroupTable groups={groups} />
          ) : (
            <Card>
              <CardBody>
                <div className="mb-4">
                  <Text>
                    No user groups found yet. Create your first user group.
                  </Text>
                </div>

                <CreateUserGroupModal produces={produces} organization={organization}>
                  <Button>Create user group</Button>
                </CreateUserGroupModal>
              </CardBody>
            </Card>
          )}
        </div>

        <hr />

        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <CardTitle>Users</CardTitle>

            {users.data.length > 0 && (
              <div>
                <InviteUserModal organization={organization}>
                  <Button>Invite user</Button>
                </InviteUserModal>
              </div>
            )}
          </div>

          {users.data.length > 0 ? (
            <UsersTable
              organization={organization}
              availableGroups={groups}
              usersConnection={users}
            />
          ) : (
            <Card>
              <CardBody>
                <div className="mb-4">
                  <Text>No users added yet. Invite your first user</Text>
                </div>

                <InviteUserModal organization={organization}>
                  <Button>Invite user</Button>
                </InviteUserModal>
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

UsersOrganizationSettingsPage.layout = (page: React.ReactNode) => (
  <SettingsLayout>{page}</SettingsLayout>
);

export default UsersOrganizationSettingsPage;
