import { ClockIcon } from '@radix-ui/react-icons';
import { ErrorBoundary } from '@sentry/react';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Spinner } from '@/Spinner';
import { cn } from '~/utils/cn';

import { Card, CardBody } from './Card';
import { IconBox } from './Icon';
import { CardSubtitle, CardTitle, MutedText, Strong } from './Text';

interface WithAwaitingFallbackProps {
  children: React.ReactNode;
  title?: string;
  description?: string;
}

/**
 * The WithAwaitingFallback component is a wrapper that displays a fallback when the children have an error.
 */
export const WithAwaitingFallback = ({ children, title, description }: WithAwaitingFallbackProps) => {
  const { t } = useTranslation();

  const textText = title || t('awaiting_data');
  const descriptionText = description || t('awaiting_data_description');
  return (
    <ErrorBoundary fallback={(
      <Card>
        <CardBody className="!py-12">
          <div className="space-y-2">
            <div className="flex justify-center">
              <div className="text-center">
                <div className="inline-block">
                  <IconBox>
                    <ClockIcon className="w-6 h-6"/>
                  </IconBox>
                </div>
                <CardTitle>
                  {textText}
                </CardTitle>
                <CardSubtitle>
                  {descriptionText}
                </CardSubtitle>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    )}>
      {children}
    </ErrorBoundary>
  );
};

interface PlaceholderBoxProps {
  title: string;
  description: string;
  icon?: ReactNode;
  isFlat?: boolean;
  loading?: boolean;
  children?: ReactNode;
  isDisabled?: boolean;
}

export const PlaceholderBox = ({
  title,
  description,
  icon,
  isFlat,
  loading,
  children,
  isDisabled,
}: PlaceholderBoxProps) => {
  const { t } = useTranslation();
  const textText = title || t('placeholders.no_data');
  const descriptionText = description || t('placeholders.no_data_description');

  return (
    <Card
      className={cn(isFlat && '!rounded-none !shadow-none !ring-0', loading && 'opacity-60', isDisabled && 'opacity-40')}>
      <CardBody className={cn('!py-12', isFlat && '!rounded-none !shadow-none !ring-0')}>
        <div className="space-y-2">
          <div className="flex justify-center">
            <div className="text-center">
              {icon && (
                <div className="text-center flex items-center justify-center mb-1">
                  {icon}
                </div>
              )}
              <div>
                <Strong>
                  {loading && (
                    <Spinner className="w-6 h-6 mr-2"/>
                  )}
                  {textText}
                </Strong>
              </div>

              {!!description && (
                <MutedText className="max-w-[400px]">
                  {descriptionText}
                </MutedText>
              )}
              {!!children && (
                <div className="mt-2">
                  {children}
                </div>
              )}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export const WithGenericFallback = ({ children }: { children: ReactNode }) => {
  return (
    <ErrorBoundary fallback={<div/>}>
      {children}
    </ErrorBoundary>
  );
};
