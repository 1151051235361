import {
  createElement,
  ElementRef,
  forwardRef,
  ForwardRefExoticComponent,
} from 'react';

import { cn } from './cn';

export function withDefaultProps<T extends { className?: string }>(
  Component: ForwardRefExoticComponent<T>,
  defaultProps: T
) {
  return forwardRef<ElementRef<typeof Component>, T>(function ExtendComponent(
    props,
    ref
  ) {
    return (
      <Component
        ref={ref}
        {...defaultProps}
        {...props}
        className={cn(defaultProps.className, props.className)}
      />
    );
  });
}

export function create<T extends keyof HTMLElementTagNameMap>(tag: T) {
  // eslint-disable-next-line
  // @ts-ignore
  return forwardRef<HTMLElementTagNameMap[T], JSX.IntrinsicElements[T]>(
    function CreateComponent(props, ref) {
      return createElement(tag, { ...props, ref });
    }
  );
}
