import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { usePageProps } from '~/hooks/usePageProps';

interface ServerErrorOutput {
  clearAllErrors: () => void;
  errors: Record<string, string>;
  clearError: (key: string) => void;
}

export const ServerErrorContext = createContext<ServerErrorOutput>({} as ServerErrorOutput);

/**
 * ServerErrorProvider is a context provider that allows you to read a "copy" of the server errors
 *
 * Why provider:
 * - On validation errors, the server puts an `errors` prop as a global page prop. However, this is a read-only prop.
 * - In our application, there are times when we want to "dismiss" these errors, without re-requesting the page (e.g. when a user closes a modal)
 * - This provider allows you to read and clear a copy of these errors.
 *
 * @preconditions - This provider should be placed at a shared Layout component (but within a Page), because it relies on the `usePageProps` hook.
 * @postconditions - You can use the `useServerErrors` hook to read `errors`, and clear them.
 */
export const ServerErrorProvider = ({
  children,
}: { children: ReactNode }) => {
  const { errors: initialErrors } = usePageProps();

  const [errors, setErrors] = useState(() => initialErrors ?? {});

  useEffect(() => {
    if (initialErrors) {
      setErrors(initialErrors);
    } else {
      setErrors({});
    }
  }, [initialErrors]);

  const clearAllErrors = () => {
    setErrors({});
  };

  const clearError = (key: string) => {
    setErrors((errors) => {
      const newErrors = { ...errors };
      delete newErrors[key];
      return newErrors;
    });
  };

  const output: ServerErrorOutput = {
    clearAllErrors,
    errors,
    clearError,
  };

  return (
    <ServerErrorContext.Provider value={output}>{children}</ServerErrorContext.Provider>
  );
};

export const useServerErrors = () => useContext(ServerErrorContext);
