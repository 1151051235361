/* eslint-disable react-hooks/exhaustive-deps */
import { FocusEvent, useEffect, useState } from 'react';

import { Card, CardBody, CardFooter } from '@/Card';
import { RangeDatePicker } from '@/DatePicker/RangeDatePicker';
import { ErrorDisplay, RangeRow, useRangeValidation } from '@/Labels/EditRangeLabel';
import { MutedText } from '@/Text';
import { DateRange } from '~/types/global-types';
import { Field, FieldConstraint, Range } from '~/types/types';

export interface EditConstraintsCardProps {
  constraint: FieldConstraint;
  field: Field;
  onUpdate: (updatedConstraint: FieldConstraint) => void;
  onAddTempConstraint?: () => void;
  onUpdateRange?: (range: Range) => void;
  useDateRange?: boolean;
}

/**
 * EditConstraintsCard
 *
 * A card used for editing constraints on a field.
 *
 * @precondition The ranges of the constraint have been pre-processed (e.g. fractions have been converted to percentages).
 *
 * @abstraction L3: Used to fully control the editing of a Label.
 * @param constraint
 * @param field
 * @param onUpdate
 * @param onAddTempConstraint
 * @constructor
 */
export const EditConstraintsCard = ({
  constraint,
  field,
  onUpdate,
  useDateRange,
}: EditConstraintsCardProps) => {
  const [currentlyEditing, setCurrentlyEditing] = useState<string | null>(null);
  const { errors, problematicRanges, validateRanges } = useRangeValidation();

  const [localRanges, setLocalRanges] = useState<Range[]>(() => {
    return constraint.ranges ?? [];
  });

  /**
   * When a range value changes, update the local ranges (which are the "formatted values").
   *
   * @param rangeId
   * @param field
   * @param value
   */
  const handleRangeChange = (rangeId: string, field: 'min' | 'max', value: string) => {
    const parsedValue = value === '' ? null : parseFloat(value);
    const updatedRanges = localRanges.map(range =>
      range.id === rangeId ? { ...range, [field]: parsedValue } : range
    ) ?? [];

    setLocalRanges(updatedRanges);

    onUpdate({
      ...constraint,
      ranges: updatedRanges,
    });
  };

  const handleFocus = (rangeId: string, field: 'min' | 'max') => {
    const newEditingId = `${rangeId}-${field}`;
    if (currentlyEditing && currentlyEditing !== newEditingId) {
      validateRanges(localRanges);
    }

    setCurrentlyEditing(newEditingId);
  };

  const handleBlur = (event: FocusEvent) => {
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      setCurrentlyEditing(null);
      validateRanges(localRanges);
    }
  };

  const handleRemove = (rangeIndex: number) => {
    const updatedRanges = localRanges.filter(((_, index) => index !== rangeIndex));
    setLocalRanges(updatedRanges);
    onUpdate({
      ...constraint,
      ranges: updatedRanges,
    });

    validateRanges(updatedRanges);
  };

  /**
   * Handle updating the constraint.
   */
  const handleDateUpdate = (dates: DateRange) => {
    const letFromDate = dates.from;
    const letToDate = dates.to;

    // We run the o
    onUpdate({
      ...constraint,
      tempStartDate: letFromDate,
      tempEndDate: letToDate,
    });
  };

  useEffect(() => {
    validateRanges(localRanges);
  }, []);

  const defaultConstraintTypes = constraint.type === 'rag' ? 'rag' : field.type === 'ratio' ? 'ratio' : 'range';
  console.log(defaultConstraintTypes);

  return (
    <Card data-use-date-range={useDateRange ? 'true' : 'false'} aria-label="edit-constraints-card">
      <CardBody size="sm" className="flex-1">
        <fieldset onBlur={handleBlur}>
          <div>
            {localRanges.map((range, index) => (
              <RangeRow
                key={range.id}
                range={range}
                isRAG={constraint.type === 'rag'}
                isPercentage={field.type === 'percentage'}
                unit={field.unit ?? undefined}
                onChangeMin={(value) => handleRangeChange(range.id, 'min', value)}
                onChangeMax={(value) => handleRangeChange(range.id, 'max', value)}
                onFocusMin={() => handleFocus(range.id, 'min')}
                onFocusMax={() => handleFocus(range.id, 'max')}
                isProblematic={problematicRanges.has(range.id)}
                type={defaultConstraintTypes}
                onRemove={() => handleRemove(index)}
              />
            ))}
          </div>
          <div className="mt-4">
            <ErrorDisplay errors={errors}/>
          </div>
        </fieldset>
      </CardBody>
      <CardFooter>
        {useDateRange ? (
          <RangeDatePicker
            value={{ from: constraint.tempStartDate, to: constraint.tempEndDate }}
            onChange={handleDateUpdate}
          />
        ) : (
          <MutedText>
            Default range
          </MutedText>
        )}
      </CardFooter>
    </Card>
  );
};
