import { EditIcon } from '@/Icon';
import { ActionAppType, ActionContext, ActionPost, BaseAction } from '~/Actions/BaseAction';
import { BuyerRoutes } from '~/hooks/useRoutes';
import { User } from '~/types/types';
import UserInput = App.Domain.User.UserInput;

export class EditUser extends BaseAction {
  public static icon = EditIcon;
  public static analyticsName = 'Edited user';
  public static type: ActionAppType = 'buyer';
  public static toast = {
    titleKey: 'user_edited',
  };

  constructor(public context: ActionContext<User>, public post: ActionPost) {
    super(context, post);
  }

  public nameKey() {
    return 'edit_user';
  }

  public disabled() {
    return !this.context.user.superadmin && this.context.target.id !== this.context.user.id;
  }

  public async execute(data: UserInput) {
    const routes = this.context.routes as BuyerRoutes;
    const route = routes.editUser;

    await this.post(route(this.context.target.id), data);
  }
}
