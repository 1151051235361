import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CompoundButton, CompoundButtonItem, CompoundButtonSibling } from '@/Button';
import { BaseAction } from '~/Actions/BaseAction';
import { useCommandAction } from '~/hooks/useCommandAction';
import { UserGroup } from '~/types/types';

import { UserGroupFactory } from './factories';
import { UserGroupCard } from './UserGroupCard';
import PickerOption = App.Domain.Common.PickerOption;
import { PlusIcon } from '@heroicons/react/20/solid';

import { Icon } from '@/Icon';

interface UserGroupOverviewProps {
  produceOptions: PickerOption[];
  defaultUserGroups: UserGroup[];
  saveAction: typeof BaseAction;
}

export const UserGroupOverview = ({ produceOptions, defaultUserGroups, saveAction }: UserGroupOverviewProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const [userGroups, setUserGroups] = useState<UserGroup[]>(defaultUserGroups);

  const { t } = useTranslation();

  const [saveUserGroup, { loading }] = useCommandAction(saveAction, undefined, () => setActiveIndex(null));

  const addNewUserGroup = () => {
    setUserGroups([UserGroupFactory.makeDefault(), ...userGroups]);
    setActiveIndex(0);
  };

  const handleCancel = () => {
    setActiveIndex(null);
    setUserGroups(defaultUserGroups);
  };

  return (
    <div>
      <div className="mb-4">
        <CompoundButton>
          <CompoundButtonSibling variant="muted">
            <Icon as={PlusIcon}/>
          </CompoundButtonSibling>

          <CompoundButtonItem disabled={activeIndex !== null} onClick={addNewUserGroup}>
            {t('Add new user group')}
          </CompoundButtonItem>
        </CompoundButton>
      </div>

      <div>
        <div className="space-y-4">
          {userGroups.map((userGroup, index) => (
            <UserGroupCard
              key={userGroup.id}
              userGroup={userGroup}
              onEdit={() => setActiveIndex(index)}
              produceOptions={produceOptions}
              action={saveUserGroup}
              loading={loading}
              isActive={activeIndex === index}
              onCancel={handleCancel}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
