import { CheckCircleIcon, ClipboardIcon, CogIcon, TruckIcon } from '@heroicons/react/24/outline';

import { OrderStep } from '../../types/types';
import { Icon } from '../Icon';
import { Label } from '../Label';

export const OrderStepLabel = ({ step }: { step: OrderStep }) => {
  if (!step) return null;
  if (!step.type) return null;

  if (step.type === 'qc') {
    return (
      <Label theme="gray">
        <Icon className="mr-1">
          <CogIcon className="fill-gray-500 stroke-white"/>
        </Icon>
          QC
      </Label>
    );
  } else if (step.type === 'qc_review') {
    return (
      <Label theme="gray">
        <Icon className="mr-1">
          <ClipboardIcon className="fill-gray-500 stroke-white"/>
        </Icon>
        QC Review
      </Label>
    );
  } else if (step.type === 'in_transit') {
    return (
      <Label theme="gray">
        <Icon className="mr-1">
          <TruckIcon className="stroke-gray-500"/>
        </Icon>
        In transit
      </Label>
    );
  } else if (step.type === 'qa_intake') {
    return (
      <Label theme="gray">
        <Icon className="mr-1">
          <ClipboardIcon className="stroke-gray-500"/>
        </Icon>
        QA intake
      </Label>
    );
  } else if (step.type === 'finalized') {
    return (
      <Label theme="emerald">
        <Icon className="mr-1">
          <CheckCircleIcon className="fill-emerald-500 stroke-white"/>
        </Icon>
          Finalized
      </Label>
    );
  } else {
    return (
      <Label theme="gray">
        N/As
      </Label>
    );
  }
};
