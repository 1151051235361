import { OptionBaseType } from '@/Pickers/GenericPicker';

export const reportOptions: OptionBaseType[] = [
  {
    label: 'Quality Costs',
    subLabel: 'Quality Costs',
    group: 'costs',
    value: 'costs',
    disabled: false,
  },
  {
    label: 'Quality issues',
    subLabel: 'Quality issues',
    group: 'issues',
    value: 'quality_issues',
    disabled: false,
    metaLabel: 'Quality issues',
  },
  {
    label: 'Reporting accuracy',
    subLabel: 'The measure of how accurate the reporting is',
    group: 'trust',
    value: 'reporting_accuracy',
    disabled: true,
  },
];
