import { type VariantProps, cva } from 'class-variance-authority';
import React from 'react';

import { cn } from '~/utils/cn';

const avatarVariants = cva(
  'inline-flex items-center justify-center rounded border',
  {
    variants: {
      size: {
        xs: 'w-6 h-6',
        sm: 'w-7 h-7',
        md: 'w-8 h-8',
        lg: 'w-10 h-10',
        xl: 'w-12 h-12',
      },
      theme: {
        white: 'bg-white border-gray-200',
        gray: 'bg-gray-50 text-gray-800',
        blue: 'bg-blue-50 text-blue-700',
        green: 'bg-emerald-50 text-emerald-700 border-gray-200',
      },
    },
    defaultVariants: {
      size: 'sm',
      theme: 'white',
    },
  }
);

interface AvatarProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof avatarVariants> {
  imageSrc?: string;
  initials?: string;
  alt?: string;
}

export const Avatar = React.forwardRef<HTMLDivElement, AvatarProps>(
  ({ imageSrc, initials, size, alt, className, theme, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(avatarVariants({ size, className, theme }))}
        {...props}
      >
        {imageSrc ? (
          <img
            src={imageSrc}
            alt={alt || 'Avatar'}
            className="w-full h-full object-cover rounded"
          />
        ) : (
          <span className={cn(
            'text-inherit font-medium leading-none',
            size === 'sm' && 'text-xs',
            size === 'md' && 'text-sm',
            size === 'lg' && 'text-base',
            size === 'xl' && 'text-lg',
          )}>
            {initials}
          </span>
        )}
      </div>
    );
  }
);

Avatar.displayName = 'Avatar';

export default Avatar;
