import { useTranslation } from 'react-i18next';

interface Field {
  base_type?: string | null;
  name?: string | null;
}

export const FormatSpecCategory = ({ categoryName }: { categoryName: string }) => {
  const { t } = useTranslation();
  const translationKey = 'quality_input_fields.' + categoryName;
  const translationExists = t(translationKey, { defaultValue: '' }) !== '';

  return (
    <>
      {translationExists ? t(translationKey) : categoryName}
    </>
  );
};

/**
 * Formats the spec field by a standard:
 * - Standardized: If the field has an official base-type, we format the field by this base-type's name.
 * - Custom name: If the field does not have an official base-type, we format the field by its name.
 * @param field
 * @constructor
 */
export const FormatSpecField = ({ field }: { field: Field }) => {
  const { t } = useTranslation();

  if (field.base_type && field.base_type === 'unknown') {
    return (
      <>
        {field.name}
      </>
    );
  }
  
  const translationKey = 'quality_input_fields.' + field.base_type;
  const translationExists = t(translationKey, { defaultValue: '' }) !== '';

  return (
    <>
      {translationExists ? t(translationKey) : field.name}
    </>
  );
};

