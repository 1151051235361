import {
  Field,
  ForecastAccuracySummary,
  OrderIssueSummary,
  Organization,
  PageProps,
  Produce,
  SavedReport,
} from '~/types/types';
import OrderFieldAccuracy = App.Models.OrderFieldAccuracy;
import { formatChangeType, Statistic } from '@/Statistics/StatisticsBanner';
import ForecastSellerFieldSummary = App.Domain.Quality.ForecastSellerFieldSummary;
import { useFormat } from '~/hooks/useFormat';

import { BaseReportProps } from '../ReportsPage/types';

type HistorySummary = {
  [key: string]: number & { date: string };
};

export interface ForecastAccuracyParameters {
  sellers?: string[];
  produces?: string[];
  bySeller?: {
    id: string;
  }
  activeSupplierId?: string;
}

export interface ForecastAccuracyData {
  report: SavedReport;
  summary: ForecastAccuracySummary;
  sellers: Organization[];
  timeSeries: HistorySummary[];
  bySeller: {
    forecastFieldSummaries: ForecastSellerFieldSummary[];
  }
  deepDive: DeepDiveItem[];
  fieldAccuracyData: {
    accuracy: number;
    supplier: Organization;
    field_explanations: FieldExplanation[];
  }
}

export interface ForecastAccuracyOptions {
  sellers: Organization[];
  produces: Produce[];
  reportSellers: Organization[];
}

export interface ForecastAccuracyPageProps extends BaseReportProps<ForecastAccuracyParameters, ForecastAccuracyData, ForecastAccuracyOptions> {
  filter: {
    fromDate: string;
    toDate: string;
    suppliers: string[];
  }
}

export interface FieldExplanation {
  field: Field;
  valueByStep: Record<string, number>;
  accuracy: number;
}

interface OrderAccuracies {
  [key: string]: {
    average_accuracy: number;
    order_id: string;
  };
}

interface DeepDiveItem {
  id: string;
  title: string;
  accuracy: number;
  orderAccuracies: OrderAccuracies[];
  problematic: OrderFieldAccuracy[];
}

export interface ForecastAccuracyPageProps extends PageProps {
  parameters: {}
  filter: {
    fromDate: string;
    toDate: string;
    suppliers: string[];
  }
}

/**
 * Format the general summary.
 */
export const formatSummary = (summary: ForecastAccuracySummary): Statistic[] => {
  const { formatCurrency } = useFormat();
  /**
   * Start with the orders.
   */
  const orderStatistic: Statistic = {
    name: 'Received orders',
    description: 'The total number of orders in the selected period.',
    stat: `${summary.orderCount}`,
    changeType: formatChangeType(summary.orderIncreasePercentage, false),
    change: `${summary.orderIncreasePercentage}%`,
  };

  /**
   * Add the fields.
   */
  const fieldStatistic: Statistic = {
    name: 'Quality field inputs',
    description: 'The total number of fields in the selected period.',
    stat: `${summary.inputCount}`,
    changeType: formatChangeType(summary.inputIncreasePercentage, true),
    change: `${summary.inputIncreasePercentage}%`,
  };

  /**
   * Add the costs associated.
   */
  const costStatistic: Statistic = {
    name: 'Costs associated',
    description: 'The total costs associated with the inaccuracies.',
    stat: `${formatCurrency(summary.associatedCosts)}`,
    changeType: formatChangeType(summary.associatedCostsIncreasePercentage, true),
    change: `${summary.associatedCostsIncreasePercentage}%`,
  };

  return [orderStatistic, fieldStatistic, costStatistic];
};
