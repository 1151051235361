import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { ReactNode } from 'react';

import { Icon } from '@/Icon';

interface InspectTextButtonProps {
  onClick?: () => void;
  children: ReactNode;
}

/**
 * A text that looks a link (with a magnifying glass), signifying it should be interactable.
 * @param onClick
 * @param children
 * @constructor
 */
export const InspectTextButton = ({ onClick, children }: InspectTextButtonProps) => {

  return (
    <span className="text-xs flex items-center text-blue-400 hover:underline cursor-pointer" onClick={onClick}>
      <Icon className="stroke-blue-400">
        <MagnifyingGlassIcon className="w-3 h-3"/>
      </Icon>
      {children}
    </span>
  );
};
