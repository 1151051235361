import { ErrorBoundary } from '@sentry/react';
import { usePostHog } from 'posthog-js/react';
import { ReactNode, useEffect } from 'react';

import { makeSellerSettingsSubLinks, makeSellerTopLinks } from '@/Nav/nav-items';
import { MainNavItem, Navbar, SubNavItem } from '@/Nav/Navbar';
import { DebugProvider, LocalDebugModal } from '~/Components/Common/LocalDebugModal';
import { FAB } from '~/Components/FAB';
import { ServerErrorProvider } from '~/hooks/ServerErrorProvider';
import { usePageProps } from '~/hooks/usePageProps';
import { Toaster } from '~/hooks/useToast';
import { OrganizationStatus } from '~/types/types';

interface SellerSettingsLayoutProps {
  children: ReactNode;
}

export const SellerSettingsLayout = ({ children }: SellerSettingsLayoutProps) => {
  const posthog = usePostHog();
  const { organization, user, ...props } = usePageProps();

  const topLinks = makeSellerTopLinks(organization.id);
  const subLinks = makeSellerSettingsSubLinks(organization.id);

  useEffect(() => {
    if (!posthog) {
      return console.warn('Posthog is not initialized');
    }
    posthog.identify(user.id);
  }, [posthog]);

  return (
    <div className="min-h-screen flex flex-col bg-gray-50 [&>*]:w-full">
      <DebugProvider pageProps={props}>
        <ServerErrorProvider>
          <LocalDebugModal/>
          <Navbar
            user={user}
            organization={organization}
            renderNavLinks={(
              <div className="flex gap-3">
                {topLinks.map((link) => (
                  <MainNavItem key={link.name} link={link}/>
                ))}
              </div>
            )}
            renderSubNavLinks={(
              <div className="flex items-center justify-center space-x-4">
                {subLinks.map((link) => (
                  <SubNavItem key={link.name} link={link}/>
                ))}
              </div>
            )}
          />
          <Toaster/>
          <FAB/>

          {organization.status === OrganizationStatus.GENERATING ? (
            <ErrorBoundary onError={() => {
              console.log('ignore');
            }} fallback={(
              <div>
                Generating new data
              </div>
            )}>
              {children}
            </ErrorBoundary>
          ) : (
            <>
              {children}
            </>
          )}
        </ServerErrorProvider>
      </DebugProvider>

    </div>
  );
};
