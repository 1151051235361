import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { PlaceholderBox } from '@/Fallback';
import {
  AddIcon,
  ButtonIcon,
  GenericFieldIcon, PlaceholderIcon,
} from '@/Icon';
import { SaveSpecField } from '~/Actions/Specs/SaveSpecField';
import { useCommandAction } from '~/hooks/useCommandAction';
import { Field, Spec } from '~/types/types';

import { makeDefaultField } from './helpers/factories';
import { SpecFieldCard } from './SpecFieldCard/SpecFieldCard';

interface SpecFieldsCardProps {
  spec: Spec;
  fields: Field[];
  supportedFields: Field[];
}

/**
 * SpecFieldsCard
 *
 * @preconditions
 * - All fields in `initialFields` must be known by the `knownFields` array.
 * @param spec
 * @param initialFields
 * @param fields
 * @constructor
 */
export const SpecFieldsList = ({
  spec,
  fields,
  supportedFields,
}: SpecFieldsCardProps) => {
  const { t } = useTranslation();
  const [availableFields, setAvailableFields] = useState<Field[]>(fields ?? []);

  const [editingFieldIndex, setEditingFieldIndex] = useState<number | null>(null);

  /**
   * Define how to add a new field.
   */
  const handleAddNewField = () => {
    setEditingFieldIndex(availableFields.length);
    setAvailableFields([
      ...availableFields,
      makeDefaultField(),
    ]);
  };

  /**
   * Discard the editing and all changes.
   */
  const discardSingleEdit = () => {
    setEditingFieldIndex(null);
    setAvailableFields(fields);
  };

  const [saveField, { loading }] = useCommandAction(SaveSpecField, spec, () => {
    setEditingFieldIndex(null);
  });

  return (
    <div>
      <div className="space-y-4">
        {availableFields.map((field, index) => (
          <div id={`index-${index}`} key={field.id}>
            <SpecFieldCard
              spec={spec}
              isEdit={editingFieldIndex === index}
              saveAction={saveField}
              onCancel={discardSingleEdit}
              knownFields={supportedFields}
              disabled={editingFieldIndex !== null && editingFieldIndex !== index || loading}
              defaultField={field}
              fields={fields}
              onEnableEdit={() => {
                if (editingFieldIndex === index) {
                  discardSingleEdit();
                } else {
                  setEditingFieldIndex(index);
                }
              }}
            />
          </div>
        ))}

        <div>
          <Button
            disabled={(editingFieldIndex !== null || loading) as any}
            onClick={handleAddNewField}
            size="lg"
            variant="addGhost"
            className="w-full text-center flex justify-center"
          >
            <ButtonIcon icon={AddIcon}/>
            {t('add_field')}
          </Button>
        </div>
      </div>

      {availableFields.length === 0 && (
        <PlaceholderBox
          title={t('no_fields')}
          description={t('no_fields_description')}
          icon={<PlaceholderIcon icon={GenericFieldIcon}/>}
        >
          <Button
            size="xs"
            variant="white"
            onClick={handleAddNewField}
            disabled={(editingFieldIndex !== null || loading) as any}
          >
            <ButtonIcon icon={AddIcon}/>
            {t('add_first_field')}
          </Button>
        </PlaceholderBox>
      )}
    </div>
  );
};
