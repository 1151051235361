import { ReactNode } from 'react';

import { usePageProps } from '~/hooks/usePageProps';

/**
 * Render a component only if the user is a super-admin.
 *
 * @precondition - UsePageProps is accessible.
 * @param children
 * @constructor
 */
export const AdminOnly = ({ children }: { children: ReactNode }) => {
  const { user } = usePageProps();

  if (user.superadmin) {
    return children;
  }

  return null;
};
