import { ArrowPathIcon, BellAlertIcon } from '@heroicons/react/20/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/Button';
import { Checkbox } from '@/Checkbox';
import { Icon, ProduceIcon } from '@/Icon';
import { Input } from '@/Input';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTrigger,
} from '@/Modal';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { SideLabeledField } from '@/SideLabeledField';
import Switch from '@/Switch';

import { useAction } from '../../../../../Shared/hooks/useAction';
import {
  Organization,
  Produce,
  UserGroup,
} from '../../../../../Shared/types/types';

interface CreateUserGroupModalProps {
  organization: Organization;
  produces: Produce[];
  children: React.ReactNode;
  isEditing?: boolean;
  group?: UserGroup;
}

const schema = z.object({
  id: z.string().optional(),
  name: z.string().min(1),
  admin: z.boolean(),
  produces: z.array(z.string()),
  notification_settings: z.object({
    enable_weekly_report: z.boolean(),
  }),
});

type FormValues = z.infer<typeof schema>;

export const CreateUserGroupModal = ({
  organization,
  produces,
  children,
  isEditing,
  group,
}: CreateUserGroupModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { post: create, loading: createLoading } = useAction(
    `/b/${organization.id}/user_groups/create`,
    {
      onSuccess: () => {
        setIsOpen(false);
      },
    }
  );
  const { post: edit, loading: editLoading } = useAction(
    `/b/${organization.id}/user_groups/edit`,
    {
      preserveState: false,
      onSuccess: () => {
        setIsOpen(false);
      },
    }
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    shouldUnregister: true,
    defaultValues: {
      id: group?.id,
      name: group?.name,
      admin: group?.admin ?? false,
      produces: group?.produces?.map((produce) => produce.id) ?? [],
      notification_settings:
        Object.keys(JSON.parse(group?.notification_settings ?? '{}')).length > 0
          ? JSON.parse(group.notification_settings)
          : {
            enable_weekly_report: false,
          },
    },
  });

  const producesList =
    produces?.map((produce) => ({
      label: produce.name,
      value: produce.id,
    })) || [];

  const onSubmit = (data: FormValues) => {
    const { notification_settings, ...rest } = data;
    const json = JSON.stringify(notification_settings);
    const newData = { ...rest, notification_settings: json };
    if (!isEditing) {
      create(newData);
    } else {
      edit(newData);
    }
  };

  return (
    <Modal open={isOpen} onOpenChange={setIsOpen}>
      <ModalTrigger asChild>{children}</ModalTrigger>

      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>
            <ModalTitle>Create user group</ModalTitle>
            <ModalDescription>Create a new user group.</ModalDescription>
          </ModalHeader>
          <ModalBody>
            <div className="flex flex-col space-y-4">
              <input type="hidden" {...register('id', { required: false })} />
              <SideLabeledField
                label="Name"
                renderValue={
                  <Input
                    type="text"
                    {...register('name', { required: true })}
                  />
                }
              />
              <SideLabeledField
                label="Is admin"
                renderValue={
                  <Checkbox
                    disabled={isEditing}
                    {...register('admin', { required: false })}
                  />
                }
              />
              <SideLabeledField
                label="Produces"
                renderValue={
                  <Controller
                    name="produces"
                    control={control}
                    render={({ field }) => (
                      <div className="w-fit">
                        <GenericPicker
                          icon={ProduceIcon}
                          title="Produces"
                          emptyMessage="No produces found."
                          placeholder="Search produces..."
                          isMulti
                          onChange={field.onChange}
                          options={producesList}
                          selected={field.value}
                        />
                      </div>
                    )}
                  />
                }
              />
              <div>
                <div className="flex items-center mt-2 space-x-2">
                  <Icon className="text-gray-500">
                    <BellAlertIcon />
                  </Icon>
                  <div>
                    <p className="text-sm text-gray-500">Notifications</p>
                  </div>
                </div>
              </div>
              <SideLabeledField
                label="Weekly Report"
                renderValue={
                  <Controller
                    name="notification_settings.enable_weekly_report"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    )}
                  />
                }
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="flex items-center space-x-2">
              <Button disabled={!isValid} type="submit">
                {(createLoading || editLoading) && (
                  <Icon className="mr-1 text-white">
                    <ArrowPathIcon
                      className={`${(createLoading || editLoading) && 'animate-spin'}`}
                    />
                  </Icon>
                )}
                {!isEditing ? 'Create' : 'Update'}
              </Button>
              <Button
                type="submit"
                variant="white"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </Button>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
