export const qualityCostsData = [
  {
    name: 'Jan',
    'PE': 167,
    'CL': 145,
    'ZA': 135,
    'ES': 115,
    'KE': 150,
  },
  {
    name: 'Feb',
    'PE': 125,
    'CL': 110,
    'ZA': 155,
    'ES': 85,
    'KE': 180,
  },
  {
    name: 'Mar',
    'PE': 125,
    'CL': 110,
    'ZA': 155,
    'ES': 85,
    'KE': 180,
  },
  {
    name: 'Apr',
    'PE': 167,
    'CL': 145,
    'ZA': 135,
    'ES': 115,
    'KE': 150,
  },
  {
    name: 'May',
    'PE': 167,
    'CL': 145,
    'ZA': 135,
    'ES': 115,
    'KE': 150,
  },
  {
    name: 'Jun',
    'PE': 125,
    'CL': 110,
    'ZA': 155,
    'ES': 85,
    'KE': 180,
  },
  {
    name: 'Jul',
    'PE': 125,
    'CL': 110,
    'ZA': 155,
    'ES': 85,
    'KE': 180,
  },
  {
    name: 'Aug',
    'PE': 167,
    'CL': 145,
    'ZA': 135,
    'ES': 115,
    'KE': 150,
  },
  {
    name: 'Sep',
    'PE': 167,
    'CL': 145,
    'ZA': 135,
    'ES': 115,
    'KE': 150,
  },
  {
    name: 'Okt',
    'PE': 125,
    'CL': 110,
    'ZA': 155,
    'ES': 85,
    'KE': 180,
  },
  {
    name: 'Nov',
    'PE': 125,
    'CL': 110,
    'ZA': 155,
    'ES': 85,
    'KE': 180,
  },
  {
    name: 'Dec',
    'PE': 167,
    'CL': 145,
    'ZA': 135,
    'ES': 115,
    'KE': 150,
  },
];
