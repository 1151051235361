import { autoUpdate, offset, useFloating } from '@floating-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardBody, CardHeaderContainer } from '@/Card';
import { Tabby, Tabs } from '@/Tabbar';
import { CardSubtitle, CardTitle, MutedText } from '@/Text';
import {
  OrderDataStream,
  OrderDataStreamType, WeatherDataPayload,
} from '~/types/types';

import {WeatherChart} from "~/Components/Weather/WeatherChart";
import {SunIcon} from "@radix-ui/react-icons";

interface WeatherSummaryCardProps {
  dataStreams: OrderDataStream<WeatherDataPayload>[];
}

export const WeatherSummarySection = ({
  dataStreams,
}: WeatherSummaryCardProps) => {
  const weatherDataStreams = dataStreams.filter(dataStream => dataStream.type === OrderDataStreamType.WEATHER);

  return (
    <div className="space-y-4">
      <WeatherSummaryCard dataStreams={weatherDataStreams}/>
    </div>
  );
};

export const WeatherSummaryCard = ({
  dataStreams,
}: WeatherSummaryCardProps) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);

  const dataStream = dataStreams[activeIndex];

  const { refs } = useFloating({
    placement: 'right-start',
    middleware: [offset(10)],
    whileElementsMounted: autoUpdate,
  });

  // Concatenate all issues from all data streams
  const issues = dataStreams.reduce((acc, dataStream) => {
    return acc.concat(dataStream.issues || []);
  }, [] as any[]);

  return (
    <div ref={refs.setReference}>
      <Card>
        <div className="px-6 py-3 border-b">
          <div className="flex items-center gap-2">
            <div>
              <SunIcon className="w-4 h-4 text-gray-400" />
            </div>
            <MutedText className="!text-sm">
             Weather
            </MutedText>
          </div>
        </div>
        <CardHeaderContainer className="py-6 pb-4">
          <div className="flex space-x-3">
            <div>
              <CardTitle className="flex items-center mb-1 leading-none">
                {t('origin_weather')}
              </CardTitle>
              <div>
                <CardSubtitle>
                  {t('origin_weather_description')}
                </CardSubtitle>
              </div>
            </div>
          </div>

          {dataStreams.length > 1 && (
            <Tabs inCardHeader>
              {dataStreams.map((dataStream, index) => (
                <Tabby
                  key={dataStream.id}
                  as="button"
                  tab={{
                    name: dataStream.data.ggn,
                    href: '#',
                    current: index === activeIndex,
                    count: dataStream?.issues?.filter(issue => issue.status !== 'read').length,
                  }}
                  onClick={() => setActiveIndex(index)}
                />
              ))}
            </Tabs>
          )}
        </CardHeaderContainer>

        <CardBody>
          <div>
            <div className="space-y-2">
              <div className="!bg-gray-100">
                <WeatherChart data={dataStream.data}/>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
