import * as z from 'zod';

export const flushID = (id: string | null | undefined): string | null => {
  if (!id) return null;

  return id.startsWith('CREATE_') ? null : id || null;
};

export function formatZodError(issue: z.ZodIssue): string {
  const path = issue.path.join(' => ');
  return `In ${path}: ${issue.message}`;
}
