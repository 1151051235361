/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import {
  BadgeIcon,
  CornerDownRightIcon,
  EyeIcon,
  EyeOffIcon,
  TriangleIcon,
  UploadIcon,
} from 'lucide-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, IconButton } from '@/Button';
import { Card, CardBody, CardGutter } from '@/Card';
import { ListDetail } from '@/Details';
import { PlaceholderBox } from '@/Fallback';
import { Gutter } from '@/Gutter';
import {
  ButtonIcon, CheckIcon,
  ConstraintIcon, EditIcon, IconBox, IconBoxIcon, IconButtonIcon, PlaceholderIcon, QualityIcon, TrashIcon, WarningIcon,
} from '@/Icon';
import { ConstraintsLabel } from '@/Labels/ConstraintsLabel';
import { Helper, MutedText, Strong } from '@/Text';
import { ConfirmField } from '~/Actions/Specs/ConfirmField';
import { MapFieldToField } from '~/Actions/Specs/MapFieldToField';
import { CommandAttributePicker } from '~/Components/Common/Command/CommandAttributePicker';
import { FieldIcon } from '~/Components/Fields/FieldIcon';
import { ConstraintScopeLabel } from '~/Components/Spec/ConstraintScopeLabel';
import { FormatSpecField } from '~/Components/Spec/FormatSpec';
import { useCommandAction } from '~/hooks/useCommandAction';
import { Field, FieldConstraint } from '~/types/types';
import { cn } from '~/utils/cn';
import { strongBorderColorByIndex, strongColorByIndex } from '~/utils/colors';
import { preprocessConstraint } from '~/utils/constraints';
import { formatPicker } from '~/utils/formatPicker';

interface SpecFieldCardProps {
  field: Field;
  onEnableEdit: () => void;
  disabled?: boolean;
  onRemove: () => void;
  fields?: Field[];
}

export const PreviewSpecFieldCard = ({
  field,
  onEnableEdit,
  disabled,
  onRemove,
  fields,
}: SpecFieldCardProps) => {
  const { t } = useTranslation();
  const constraints = field.constraints as FieldConstraint[] ?? [];

  const otherFields = fields?.filter(f => f.id !== field.id) ?? [];

  const [confirm, { loading }] = useCommandAction(ConfirmField, field);

  const fieldStatus = useMemo(() => {
    if (field.is_supported) {
      return 'supported';
    }

    if (field.is_custom) {
      return 'custom';
    }

    if (field.is_unknown) {
      return 'undocumented';
    }

    return 'supported';
  }, [field]);

  const fieldRequired = useMemo(() => {
    if (field.hidden) {
      return 'hidden';
    }

    if (field.required) {
      return 'required';
    }

    return 'optional';
  }, [field]);

  return (
    <div
      aria-label={`preview field-card for ${field.name}`}
      className={cn('grid shadow-sm grid-cols-[1fr_3fr] rounded', disabled && 'opacity-30 pointer-events-none')}
    >
      <div className="bg-gray-100 border border-r-0 border-gray-200 rounded-l">
        <div className="h-full gap-2">
          <div className="flex flex-col justify-between w-full h-full" aria-label="field name">
            <div className="border-b px-4 py-2">
              <div className="flex gap-2 items-center">
                <div className="flex items-center gap-1">
                  <FieldIcon field={field}/>
                  <Strong id={`fieldId::${field.id}`}>
                    <FormatSpecField
                      field={field}
                    />
                  </Strong>
                </div>
              </div>
            </div>
            <div className="px-4 py-2 flex-grow space-y-2 pb-24" aria-label="field details">
              <ListDetail
                layout="space-between"
                dtype="status"
                label="Required"
                description="Whether this field is required, optional, or completely hidden."
                value={fieldRequired}
                statusMap={{
                  'required': {
                    icon: ExclamationCircleIcon,
                    theme: 'gray',
                    label: t('required'),
                  },
                  'optional': {
                    theme: 'gray',
                    label: t('optional'),
                  },
                  'hidden': {
                    icon: EyeOffIcon,
                    theme: 'gray',
                    label: t('hidden'),
                  },
                }}
                icon={EyeIcon}
              />

              <ListDetail
                layout="space-between"
                dtype="count"
                label="Submissions"
                description="Number of quality entries associated with this field."
                value={field.quality_entries_count}
                icon={UploadIcon}
              />
              <ListDetail
                layout="space-between"
                label="Defect Type"
                value={field.defect_type ? field.defect_type : null}
                dtype="status"
                icon={TriangleIcon}
                statusMap={{
                  'minor': {
                    icon: WarningIcon,
                    theme: 'yellow',
                    label: t('minor'),
                  },
                  'major': {
                    icon: ExclamationCircleIcon,
                    theme: 'red',
                    label: t('major'),
                  },
                }}
              />

              <ListDetail
                layout="space-between"
                label="Status"
                description="The status of this field. Supported fields are fields that are part of the standard specification. Custom fields are fields that are not part of the standard specification. Undocumented fields are fields that have not been documented."
                dtype="status"
                value={fieldStatus}
                icon={CheckIcon}
                statusMap={{
                  'supported': {
                    icon: QualityIcon,
                    theme: 'green',
                    label: t('supported'),
                  },
                  'custom': {
                    icon: BadgeIcon,
                    theme: 'blue',
                    label: t('custom'),
                  },
                  'undocumented': {
                    icon: WarningIcon,
                    theme: 'red',
                    label: t('undocumented'),
                  },
                }}
              />

              {!!field.aliases_count && field.aliases_count > 0 && (
                <ListDetail
                  layout="space-between"
                  label="Aliases"
                  description="Aliases refer to fields referring to this fields."
                  dtype="count"
                  value={field.aliases_count}
                  icon={CornerDownRightIcon}
                />
              )}
            </div>
            <div>
              <div className="px-4 py-1">
                {field.is_unknown && (
                  <div className="bg-rose-50 border-2 border-rose-100 px-2 py-2 my-2 rounded">
                    <div className="mb-1">
                      <Helper className="text-xs flex !text-rose-800">
                        {t('undocumented_field')}
                      </Helper>

                      <div>
                        <MutedText>
                          {t('undocumented_field_description', { fieldName: field.name })}
                        </MutedText>
                      </div>
                    </div>

                    <hr/>

                    <div className="py-2">
                      <CommandAttributePicker
                        action={MapFieldToField}
                        target={field}
                        attribute="mapToFieldId"
                        options={formatPicker(otherFields, 'id', 'name') as any}
                        title="Link to existing field"
                        confirmation={{
                          title: 'Map field',
                          description: `You will be mapping ${field?.quality_entries_count} quality entries to this new field.`,
                          onConfirm: () => !!field.quality_entries_count && field.quality_entries_count > 0,
                        }}
                      />
                    </div>

                    <div className="py-2 flex items-center">
                      <MutedText className="mr-2 flex items-center">
                        or
                      </MutedText>

                      <Button
                        onClick={() => confirm.execute({})}
                        disabled={loading as any}
                        size="sm"
                        variant="white"
                      >
                        <ButtonIcon icon={CheckIcon}/>
                        Create as new field
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              {!field.is_unknown && (
                <div className="bg-gray-100 border-t px-4 py-1 rounded-bl">
                  <Gutter theme="transparent">
                    <Button
                      onClick={onEnableEdit}
                      variant="gray"
                      className="w-full"
                    >
                      <ButtonIcon icon={EditIcon}/>
                      Edit field
                    </Button>

                    <div className="flex items-center gap-2">
                      <CommandAttributePicker
                        action={MapFieldToField}
                        target={field}
                        attribute="mapToFieldId"
                        options={formatPicker(otherFields, 'id', 'name') as any}
                        title="Link to field"
                        confirmation={{
                          title: 'Map field',
                          description: `You will be mapping ${field?.quality_entries_count} quality entries to this new field.`,
                          onConfirm: () => !!field.quality_entries_count && field.quality_entries_count > 0,
                        }}
                      />

                      <IconButton onClick={onRemove} label="Remove field" variant="white">
                        <IconButtonIcon icon={TrashIcon}/>
                      </IconButton>
                    </div>
                  </Gutter>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 bg-white border border-gray-200 rounded-r">
        <div>
          <div className="-mx-4">
            <CardGutter className="rounded-tr">
              <Helper>
                Constraints
              </Helper>
            </CardGutter>
          </div>
        </div>

        {constraints.length > 0 && (
          <div className="py-4 space-y-4">
            {constraints.map((constraint, index) => (
              <div key={constraint.id}>
                <Card aria-label="preview constraint container"
                  className={cn('border-l-4', strongBorderColorByIndex(index))}>
                  <CardBody size="xs">
                    <div className="flex items-center gap-3">
                      <IconBox
                        className={cn(
                          '!min-w-8 !h-8 flex items-center justify-center !border-0',
                          strongColorByIndex(index),
                        )}
                      >
                        <IconBoxIcon icon={ConstraintIcon}/>
                      </IconBox>

                      <div className="flex-grow">
                        <Strong className="leading-none">
                          Field Constraint {index + 1}
                        </Strong>

                        <div className="flex justify-between">
                          <div>
                            <ConstraintsLabel constraint={preprocessConstraint(constraint, field.type)} field={field}/>
                          </div>

                          <div>
                            <ConstraintScopeLabel constraint={constraint}/>
                          </div>
                        </div>
                      </div>
                    </div>

                  </CardBody>
                </Card>
              </div>
            ))}
          </div>
        )}

        {constraints.length === 0 && (
          <div className="py-4">
            <PlaceholderBox
              title="Unconstrained field"
              description="This field has no specifications for any produce set. Add constraints to define acceptable ranges or values for this field."
              icon={<PlaceholderIcon icon={ConstraintIcon}/>}
            />
          </div>
        )}
      </div>
    </div>
  );
};
