import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/Tooltip';
import { cn } from '~/utils/cn';

const StatisticCard = ({ statistic }: { statistic: Statistic }) => (
  <div className="px-4 py-8 sm:px-6 xl:px-8">
    <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-4 bg-white">
      <dt className="text-sm font-normal leading-6 text-gray-500 flex items-center">
        {statistic.name}
        {statistic.description && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <QuestionMarkCircleIcon className="w-4 h-4 ml-2 text-gray-200" aria-hidden="true"/>
              </TooltipTrigger>
              <TooltipContent sideOffset={5} alignOffset={5}>
                {statistic.description}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </dt>
      <dd
        className={cn(
          statistic.changeType === 'increase' && 'text-green-600',
          statistic.changeType === 'decrease' && 'text-rose-600',
          statistic.changeType === 'neutral' && 'text-gray-700',
          'text-xs font-medium'
        )}
      >
        {statistic.change}
      </dd>
    </div>
    <dd className="text-gray-900 text-3xl font-medium leading-10 tracking-light">
      {statistic.stat}
    </dd>
  </div>
);

export const formatChangeType = (value: number, redIfIncrease = false) => {
  if (!value) {
    return 'neutral';
  }

  // If the value is large, we consider it increasing.
  if (value > 0.01) {
    return redIfIncrease ? 'decrease' : 'increase';
  }

  // If the value is small, we consider it decreasing.
  if (value < -0.01) {
    return redIfIncrease ? 'increase' : 'decrease';
  }

  // If the value is neutral, we consider it neutral.
  return 'neutral';
};

export interface Statistic {
  name: string;
  stat: string;
  changeType: 'increase' | 'decrease' | 'neutral';
  change: string;
  previousStat?: string;
  description?: string;
}

interface StatisticsBannerProps {
  statistics: Statistic[];
}

const StatisticsBanner = ({ statistics }: StatisticsBannerProps) => {
  return (
    <div
      className="grid grid-cols-1 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow md:grid-cols-3 md:divide-x md:divide-y-0">
      {statistics.map((item) => (
        <StatisticCard key={item.name} statistic={item}/>
      ))}
    </div>
  );
};
export default StatisticsBanner;
