import React from 'react';

import { Input } from '@/Input';
import { cn } from '~/utils/cn';

interface NumberInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  value: number | null;
  onChange: (value: number | null) => void;
  allowNull?: boolean;
  className?: string;
  min?: number;
  max?: number;
  step?: number;
  placeholder?: string;
}

export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      value,
      onChange,
      allowNull = true,
      className,
      min,
      max,
      step = 1,
      placeholder = '0',
      ...props
    },
    ref
  ) => {
    // Handle changes and parse the string to a number
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;

      // Handle empty input
      if (inputValue === '') {
        onChange(allowNull ? null : 0);
        return;
      }

      // Remove any non-numeric characters except decimal points and minus signs
      const sanitizedValue = inputValue.replace(/[^\d.-]/g, '');
      const parsedValue = parseFloat(sanitizedValue);

      // Check if the parsed value is within bounds
      if (!isNaN(parsedValue)) {
        let finalValue = parsedValue;

        if (min !== undefined) {
          finalValue = Math.max(min, finalValue);
        }
        if (max !== undefined) {
          finalValue = Math.min(max, finalValue);
        }

        onChange(finalValue);
      }
    };

    // Format the display value
    const displayValue = value === null ? '' : value.toString();

    return (
      <Input
        ref={ref}
        type="text"
        inputMode="decimal"
        value={displayValue}
        onChange={handleChange}
        className={cn('font-mono', className)}
        min={min}
        max={max}
        step={step}
        placeholder={placeholder}
        {...props}
      />
    );
  }
);

NumberInput.displayName = 'NumberInput';
