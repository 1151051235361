/* eslint-disable react-hooks/exhaustive-deps */
import { CornerDownRight } from 'lucide-react';

import { Card, CardBody } from '@/Card';
import { AddIcon, MutedIcon, PlaceholderIcon } from '@/Icon';
import { EditConstraintsCard } from '@/Labels/EditConstraintsCard';
import { MutedStrong, MutedText, Strong } from '@/Text';
import { FieldConstraint } from '~/types/types';

import { SpecField } from '../helpers/schemas';

export interface EditConstraintsCardProps {
  constraint: FieldConstraint;
  field: SpecField;
  onUpdate: (updatedConstraint: FieldConstraint) => void;
  onAddTempConstraint?: () => void;
}

/**
 * SpecConstraintsEditorCard
 *
 * A card used for editing constraints on a field.
 *
 * @precondition The ranges of the constraint have been pre-processed (e.g. fractions have been converted to percentages).
 *
 * @abstraction L3: Used to fully control the editing of a Label.
 * @param constraint
 * @param field
 * @param onUpdate
 * @param onAddTempConstraint
 * @constructor
 */
export const SpecConstraintsEditorCard = ({
  constraint,
  field,
  onUpdate,
  onAddTempConstraint,
}: EditConstraintsCardProps) => {
  const promptTempSpec = !!onAddTempConstraint;

  const hasTempSpec = constraint.nested_constraints && constraint.nested_constraints?.length > 0;

  const nestedConstraint = constraint.nested_constraints?.[0];

  const updateNestedConstraint = (updatedNestedConstraint: FieldConstraint) => {
    onUpdate({
      ...constraint,
      nested_constraints: [updatedNestedConstraint],
    });
  };

  return (
    <Card>
      <CardBody className="rounded" variant="muted">
        <div>
          <EditConstraintsCard constraint={constraint} field={field} onUpdate={onUpdate}/>
        </div>
        {promptTempSpec && (
          <div
            aria-label="temp constraint container"
            className="w-full py-4 mt-4 border-t-2 border-gray-300 border-dashed"
          >
            <div className="flex gap-4 mb-2 w-ful">
              <MutedIcon icon={CornerDownRight}/>
              <div className="flex flex-col flex-grow">
                <div>
                  <MutedStrong>
                    (Optional) Set temporary specification
                  </MutedStrong>
                </div>
                <div className="mb-4">
                  <MutedText className="!leading-none">
                    Create a time-limited adjustment to this field. Useful for seasonal changes without altering the
                    main
                    specification
                  </MutedText>
                </div>

                {!constraint.nested_constraints || constraint.nested_constraints?.length === 0 && (
                  <div
                    onClick={onAddTempConstraint}
                    className="flex items-center justify-center flex-1 px-4 py-6 text-center bg-gray-100 border border-gray-200 border-dashed rounded cursor-pointer hover:bg-gray-200">
                    <div>
                      <div>
                        <div>
                          <PlaceholderIcon icon={AddIcon}/>
                        </div>
                        <div>
                          <Strong>
                            Temporary Specification
                          </Strong>
                        </div>
                        <div>
                          <MutedText>
                            Add a temporary adjustment to this field
                          </MutedText>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {hasTempSpec && nestedConstraint && (
                  <EditConstraintsCard
                    constraint={nestedConstraint as FieldConstraint}
                    field={field}
                    onUpdate={updateNestedConstraint}
                    useDateRange
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};
