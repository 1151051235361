import { GeoJsonLoader, Map } from 'pigeon-maps';
import { maptiler } from 'pigeon-maps/providers';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardBody, CardHeader, CardHeaderContainer, PromptCard } from '@/Card';
import { Container } from '@/Container';
import { LayoutHeader } from '@/Layout';
import { Helper, MutedText, PageDescription, PageTitle, SectionDescription, SectionTitle } from '@/Text';

import { DashboardLayout } from '../../DashboardLayout';
import { qualityCostsData } from './data';
import { LineCard } from './LineCard';
import { YearlyRankCard } from './YearlyRankCard';

export interface ActionPlanReportPageProps {
  children: ReactNode;
}

const maptilerProvider = maptiler('ig12uhr5NqMmNdmTjBpB', 'dataviz');
const geoJsonLink = 'https://debugmainbucket.s3.eu-central-1.amazonaws.com/Geo%20Countries.geojson?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEAEaDGV1LWNlbnRyYWwtMSJIMEYCIQCOuUYd91CQrJIwkmLOW%2FqKvinDXomQW8%2BRPOTxfbntWAIhAPNeWvsAPCPKxV7fe7UQkOjpYP10PxbrSH0PHLO4MEXkKtADCLv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMOTUyOTYxNjUzMzI1IgxMuQsY6Fssot3J31UqpAN9YS%2FQ1VE1SAHlZnp6ka3X7KNyLRjM%2FnsmNahWcGtRtttY%2BD6OTtiktYZSfBTmTU%2FRH4%2B6npzQs4MhNNROoBII3G2PX10P3649k%2FDXWxWN8GchR%2FtEUvI1w5LUb7ylu67igDs4oNTXm8wYcpRoLbttPQvqGot7gzk9KqhC3aWVOmLqAjYK1Q6kAYuL5WSuzPhWx7b%2FllK81N1iFkEnQoJzSpqPxdnW8OE4%2B4YrlY7lg5kQpoqQNyqmFaGLKt2ZlutQ1by8KjROgta1Db2AbhIWCdv3rLa%2FrDYtWeph5qtf%2Bl8KSHp68PI2zmGBZTLjBE3AZXFsNMNicp3GK1k6aft7yI0o%2BEHm7RjuJeHofo8RflFZsTRgzX6oUPPV022h0uI%2Fi1fzt7S6oV8tu4TaXlHwwkLrQ%2Fse401JKFFdNaMpDkaesj6MNVye1H84chVCOfaFyzmhIt0%2BURb3pZA9%2FU5%2BG0rnHBkkcq7kIPL%2FHrEmoPuiHiCidlOzTkcwEbZ%2F3tfkj3nU1Jggoip1Q%2FbaTWFdScHQg4eNHT7KYve1ZgXD8%2BZAzeMwx5fTrQY6kwJoSQQmxqxOC5zQafJiH%2F6fsGG0LjfX4AOfu%2FsVFYIiXGtOTCSImbqRILKszKUL8n1UPbWF9O5V%2B9Kryuwk48F2Exr%2Flx53bRiNrfgKiqy5bNY67CxhfOgiGKiTe1NIq2QKH0mBDI4n%2FIKc5IIjjWh%2F2%2F7XAuZ8pNCkJcDNy9lofnFbCkGhid2AGo1U1zfbiOvLsUq%2BSDoFXZqPqM7LbIAMMyY2E2rye%2BLmfbDqoRF%2FKSdEDx9QmIr%2FST1Haxem4KRzJEQHIfjD6RycZ154IZc%2BH2v2TbLQAm0y1dGmn%2FJv1tK6nIs%2Fwv9FoFqI0JYLuIlxTXGypxqLt%2F97BU5%2Bh2vEtnRgJdArRtOzqnpAb5SjlaBPkQ%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240127T092624Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIA53YHQ3JG5VRBOC3T%2F20240127%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Signature=06e143f906eb9fcb110f5a1f19fb0fbafaaf1a3c7699c595deb7b4fd409d8fbf';

const RecommendationCard = ({ variant }: { variant: string }) => {
  const { t } = useTranslation();
  const [showStats, setShowStats] = useState(false);

  return (
    <PromptCard variant={variant as any}>
      <div className="mb-4">
        <SectionTitle>
          Quarter 1
        </SectionTitle>
        <Helper>
          Jan - Mar 2021
        </Helper>

        <hr/>
      </div>

      <div className="grid grid-cols-[2fr_2fr] gap-4">

        {/* Column 1 */}
        <div>
          <MutedText>
            Based on your data, we recommend the following actions:
          </MutedText>
        </div>

        {/* Column 2 */}

        <div className="h-[300px] rounded">
          <Map boxClassname="rounded-lg shadow" center={[0, 100]} zoom={2} attribution={false} zoomSnap={true}
            provider={maptilerProvider}>
            <GeoJsonLoader
              link={geoJsonLink}
              onMouseOver={(event, feature) => {
                console.log('onMouseOver', feature, event);
              }}
              onMouseOut={(event, feature) => {
                console.log('onMouseOut', feature, event);
              }}
              styleCallback={(feature, hover) =>
                hover
                  ? { fill: '#93c0d099', strokeWidth: '2' }
                  : { fill: '#DC262660', strokeWidth: '1' }
              }
            />
          </Map>
        </div>
      </div>
    </PromptCard>
  );
};

const TimelineSection = () => {
  const items = [
    {
      children: (
        <RecommendationCard variant="yellow"/>
      ),
    },
    {
      children: (
        <RecommendationCard variant="blue"/>
      ),
    },
    {
      children: (
        <RecommendationCard variant="emerald"/>
      ),
    },
  ];

  return (
    <div>
      <div className="space-y-8">
        <div>
          <SectionTitle>
            By month
          </SectionTitle>
          <SectionDescription>
            Based on your data, we recommend the following actions:
          </SectionDescription>
        </div>
        {/*<Timeline items={items} activeItem={1}/>*/}
      </div>
    </div>
  );
};

const SummarySection = () => {
  return (
    <div>
      <div className="space-y-8">
        <div>
          <SectionTitle>
            General overview
          </SectionTitle>
          <SectionDescription>
            Based on your data, we recommend the following actions:
          </SectionDescription>
        </div>
        <div>
          <YearlyRankCard/>
        </div>
      </div>
    </div>
  );
};

const BreakdownSection = () => {
  return (
    <div>
      <div className="space-y-8">
        <div>
          <SectionTitle>
            Breakdown
          </SectionTitle>
          <SectionDescription>
            Based on your data, we recommend the following actions:
          </SectionDescription>
        </div>

        <div>
          <div className="grid grid-cols-2 gap-4">
            <LineCard unit="$" data={qualityCostsData} bestKey="KE" title="Quality costs by region"
              description="Quick glance of the best regions in terms of quality costs"/>
            <LineCard data={qualityCostsData} bestKey="CL" title="Quality performance to Spec"
              description="Which region generally contains best adherence to spec"/>
            <LineCard data={qualityCostsData} bestKey="CL" title="Weather performance indicator"
              description="Trended weather by region"/>
            <LineCard data={qualityCostsData} bestKey="ZA" title="Logistics performance indicator"
              description="Trended logistics delay by region"/>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Component for the action plan report page.
 */
const ActionPlanReportPage = ({ children }: ActionPlanReportPageProps) => {

  return (
    <div>
      <LayoutHeader isSticky>
        <PageTitle>
          Sourcing Action Plan
        </PageTitle>
        <PageDescription>
          Use past findings and future predictions to plan the next 4-12 months.
        </PageDescription>
      </LayoutHeader>

      <Container>
        <div className="space-y-6 mt-4">
          <SummarySection/>

          <hr/>
          <BreakdownSection/>
        </div>
      </Container>
    </div>
  );
};

ActionPlanReportPage.layout = (page: ReactNode) => <DashboardLayout>{page}</DashboardLayout>;

export default ActionPlanReportPage;
