const formatNumberWithDots = (num: number): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

type Currency = 'EUR' | 'GBP' | 'USD';

const currencySymbolMap: Record<any, any> = {
  EUR: '€',
  GBP: '£',
  USD: '$',
};

export const useFormat = () => {

  return {
    formatCurrency: (value: number, currency: Currency = 'GBP') => {
      try {
        const symbol = currency in currencySymbolMap ? currencySymbolMap[currency] : '';

        const integerPart = Math.floor(Math.abs(value));
        const decimalPart = Math.abs(value % 1).toFixed(2).slice(2);
        const formattedInteger = formatNumberWithDots(integerPart);

        return `${value < 0 ? '-' : ''}${symbol} ${formattedInteger},${decimalPart}`;
      } catch (e) {
        return currency;
      }
    },
    formatFractionPercentage: (fraction: number, decimals = 2) => {
      return `${(fraction * 100).toFixed(decimals)}%`;
    },
    formatPercentage: (value: number, decimals = 2) => {
      return `${value.toFixed(decimals)}%`;
    },
    formatMb: (kb: number) => {
      return `${(kb / 1024).toFixed(2)} MB`;
    },
    formatProduceName: (produceName?: string) => {
      if (produceName) {
        return produceName.charAt(0).toUpperCase() + produceName.toLowerCase().slice(1);
      }

      return produceName;
    },
  };
};
