import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Card, CardBody } from '@/Card';
import { ColorDot } from '@/ColorDot';
import { MutedText } from '@/Text';
import { BooleanBreakdown } from '~/Components/Analytics/BooleanBreakdown';
import { BoxPlot } from '~/Components/Analytics/BoxPlot';
import { useFormat } from '~/hooks/useFormat';

interface SummarizeDistributionProps {
  distribution?: any;
}

export const SummarizeDistribution = ({ distribution }: SummarizeDistributionProps) => {
  const { formatFractionPercentage } = useFormat();
  if (distribution && 'median' in distribution) {
    return (
      <div className="flex justify-between w-full text-xxxs">
        <MutedText className="!text-xxxs">Min: {distribution.minValue}</MutedText>
        <MutedText className="!text-xxxs">Mean: {distribution.median}</MutedText>
        <MutedText className="!text-xxxs">Max: {distribution.maxValue}</MutedText>
      </div>
    );
  }

  if (distribution && 'countTrue' in distribution) {
    return (
      <div className="flex justify-between">
        <div className="flex gap-1">
          <div className="mt-2.5">
            <ColorDot brand="red" size="sm"/>
          </div>
          <div>
            <MutedText className="!leading-none !text-xxs">Defects</MutedText>
            <div className="leading-none">
              <MutedText className="!leading-none !text-xxs">
                {formatFractionPercentage(distribution.fractionTrue)}
              </MutedText>
            </div>
          </div>
        </div>

        <div className="flex gap-1">
          <div className="mt-2.5">
            <ColorDot brand="emerald" size="sm"/>
          </div>
          <div>
            <MutedText className="!leading-none !text-xxs">No defects</MutedText>
            <div className="!leading-none">
              <MutedText className="!leading-none !text-xxs">
                {formatFractionPercentage(distribution.fractionFalse)}
              </MutedText>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>

    </div>
  );
};
