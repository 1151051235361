import * as z from 'zod';

import { User, UserGroup } from '~/types/types';
import SaveUserGroupInput = App.Domain.User.SaveUserGroupInput;
import UserInput = App.Domain.User.UserInput;

export const saveUserGroupSchema = z.object({
  id: z.string().nullish(),
  name: z.string().min(1, 'User group name is required'),
  // userIds: z.array(z.string()),
  produceIds: z.array(z.string()),
  notificationSettings: z.object({
    enableMorningDigestUpdates: z.boolean(),
    enableNewOrderNotifications: z.boolean(),
  }),
  filterSettings: z.object({
    filterOrdersByProduce: z.boolean(),
  }),
});
export type UserGroupFormSchema = z.infer<typeof saveUserGroupSchema>;
export const saveUserSchema = z.object({
  id: z.string().nullish(),
  name: z.string().min(1, 'User name is required'),
  email: z.string().email('Invalid email address'),
  active_mail_recipient: z.boolean(),
  active_daily_digest_mail_recipient: z.boolean(),
  active_issues_mail_recipient: z.boolean(),
  active_hourly_pos_recipient: z.boolean(),
  settings: z.object({
    locale: z.string(),
  }).nullish(),
});
export type UserFormSchema = z.infer<typeof saveUserSchema>;

export const UserGroupFactory = {
  makeDefault: () => ({
    id: undefined,
    name: '',
    userIds: [],
    produceIds: [],
    notification_settings: {
      enableMorningDigestUpdates: true,
      enableNewOrderNotifications: false,
    },
    filter_settings: {
      filterOrdersByProduce: false,
    },
  }),
};

export const parseBooleanRadioValue = (value: string) => value === 'true';

export const UserFormatter = {
  modelToForm: (user: User): UserFormSchema => ({
    id: user.id,
    name: user.name,
    email: user.email,
    active_mail_recipient: user.active_mail_recipient,
    active_daily_digest_mail_recipient: user.active_daily_digest_mail_recipient,
    active_issues_mail_recipient: user.active_issues_mail_recipient,
    active_hourly_pos_recipient: user.active_hourly_pos_recipient,
    settings: user.settings,
  }),
  formToApi: (data: UserFormSchema): UserInput => ({
    id: data.id ?? null,
    name: data.name,
    email: data.email,
    active_mail_recipient: data.active_mail_recipient,
    active_daily_digest_mail_recipient: data.active_daily_digest_mail_recipient,
    active_issues_mail_recipient: data.active_issues_mail_recipient,
    active_hourly_pos_recipient: data.active_hourly_pos_recipient,
    settings: data.settings,
  }),
};

export const UserGroupFormatter = {
  modelToForm: (userGroup: UserGroup) => ({
    id: userGroup.id,
    name: userGroup.name,
    userIds: userGroup.users?.map(user => user.id),
    produceIds: userGroup.produces?.map(produce => produce.id),
    notificationSettings: {
      enableMorningDigestUpdates: userGroup.notification_settings?.enableMorningDigestUpdates,
      enableNewOrderNotifications: userGroup.notification_settings?.enableNewOrderNotifications,
    },
    filterSettings: {
      filterOrdersByProduce: userGroup.filter_settings?.filterOrdersByProduce,
    },
  }),
  formToApi: (data: UserGroupFormSchema): SaveUserGroupInput => ({
    id: data.id ?? null,
    name: data.name,
    userIds: data.userIds,
    produceIds: data.produceIds,
    notificationSettings: {
      enableMorningDigestUpdates: data?.notificationSettings?.enableMorningDigestUpdates,
      enableNewOrderNotifications: data.notificationSettings?.enableNewOrderNotifications,
    },
    filterSettings: {
      filterOrdersByProduce: data.filterSettings.filterOrdersByProduce,
    },
  }),
};
