'use client';

import * as PanePrimitive from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

import { PageTitle, SectionTitle } from '@/Text';
import { cn } from '~/utils/cn';

const Pane = PanePrimitive.Root;

const PaneTrigger = PanePrimitive.Trigger;

const PaneClose = PanePrimitive.Close;

const PanePortal = PanePrimitive.Portal;

const PaneOverlay = React.forwardRef<
  React.ElementRef<typeof PanePrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof PanePrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <PanePrimitive.Overlay
    className={cn(
      'fixed inset-0 z-50 bg-black/10  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className
    )}
    {...props}
    ref={ref}
  />
));
PaneOverlay.displayName = PanePrimitive.Overlay.displayName;

const sheetVariants = cva(
  'fixed z-50 gap-4 bg-background shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500',
  {
    variants: {
      side: {
        top: 'inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top',
        bottom:
          'inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom',
        left: 'inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm',
        right:
          'inset-y-0 right-0 h-full w-full border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-xl',
      },
    },
    defaultVariants: {
      side: 'right',
    },
  }
);

interface PaneContentProps
  extends React.ComponentPropsWithoutRef<typeof PanePrimitive.Content>,
    VariantProps<typeof sheetVariants> {
}

const PaneContent = React.forwardRef<
  React.ElementRef<typeof PanePrimitive.Content>,
  PaneContentProps
>(({ side = 'right', className, children, ...props }, ref) => (
  <div>
    <PaneOverlay/>
    <PanePrimitive.Content
      ref={ref}
      className={cn(sheetVariants({ side }), className)}
      {...props}
    >
      {children}
      <PanePrimitive.Close
        autoFocus
        className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary"
      >
        <Cross2Icon className="h-4 w-4"/>
        <span className="sr-only">Close</span>
      </PanePrimitive.Close>
    </PanePrimitive.Content>
  </div>
));
PaneContent.displayName = PanePrimitive.Content.displayName;

const PaneHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col space-y-2 text-center sm:text-left border-b',
      className
    )}
    {...props}
  />
);
PaneHeader.displayName = 'PaneHeader';

const PaneFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex sm:flex-row sm:space-x-2 mt-auto border-t -mx-4 px-4 pt-4',
      className
    )}
    {...props}
  />
);
PaneFooter.displayName = 'PaneFooter';

const PaneTitle = SectionTitle;

const PaneDescription = React.forwardRef<
  React.ElementRef<typeof PanePrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof PanePrimitive.Description>
>(({ className, ...props }, ref) => (
  <PanePrimitive.Description
    ref={ref}
    className={cn('text-sm text-muted-foreground', className)}
    {...props}
  />
));
PaneDescription.displayName = PanePrimitive.Description.displayName;

export {
  Pane,
  PaneClose,
  PaneContent,
  PaneDescription,
  PaneFooter,
  PaneHeader,
  PaneOverlay,
  PanePortal,
  PaneTitle,
  PaneTrigger,
};
