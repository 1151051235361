import { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'windstitch';

import { SearchIcon } from '@/Icon';

import useDebouncedEffect from '../hooks/useDebouncedEffect';
import { Input } from './Input';

interface SearchBarProps {
  value?: string | null;
  onChange?: (newSearch: string) => void;
  className?: string;
  placeholder?: string;
  loading?: boolean;
}

const SearchBarInput = styled(Input, { className: 'pl-9' });

// TODO: Refactor such that searchbar directly interacts with the URL and search param
export const SearchBar = ({
  value: activeSearchTerm,
  onChange: onSearchTermChange,
  className,
  placeholder = 'Search',
}: SearchBarProps) => {
  const [searchTerm, setSearchTerm] = useState<string>(activeSearchTerm ?? '');
  const startedRef = useRef<boolean>();

  useDebouncedEffect(() => {
    if (typeof startedRef.current !== 'undefined' && searchTerm !== undefined && searchTerm !== null) {
      onSearchTermChange?.(searchTerm);
      startedRef.current = false;
    }
  }, 500, [searchTerm]);

  useEffect(() => {
    if (!startedRef.current) {
      setSearchTerm(activeSearchTerm || '');
    }
  }, [activeSearchTerm, setSearchTerm]);

  return (
    <div className={`relative ${className} isolate`}>
      <div className="absolute -top-2 bottom-0 w-4 h-4 my-auto text-gray-400 left-3 z-50">
        <SearchIcon className="w-full"/>
      </div>
      <SearchBarInput
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          startedRef.current = true;
          setSearchTerm(e.target.value);
        }}
      />
    </div>
  );
};
