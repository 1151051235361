import { Link } from '@inertiajs/react';
import { AnimatePresence, motion } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';

import { Brand, NumericIndicator } from '@/NumericIndicator';
import { cn } from '~/utils/cn';

export interface Tab {
  name: string;
  href: string;
  current: boolean;
  count?: number;
}

export const Tabs = ({ children, className, inCardHeader, ...props }: any) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const checkScroll = () => {
    const el = scrollRef.current;
    if (!el) return;

    setShowLeftArrow(el.scrollLeft > 0);
    setShowRightArrow(
      Math.floor(el.scrollLeft) < el.scrollWidth - el.clientWidth - 1
    );
  };

  const scroll = (direction: 'left' | 'right') => {
    const el = scrollRef.current;
    if (!el) return;

    const scrollAmount = direction === 'left' ? -200 : 200;
    el.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  useEffect(() => {
    const el = scrollRef.current;
    if (!el) return;

    checkScroll();
    el.addEventListener('scroll', checkScroll);
    window.addEventListener('resize', checkScroll);

    return () => {
      el.removeEventListener('scroll', checkScroll);
      window.removeEventListener('resize', checkScroll);
    };
  }, []);

  return (
    <div
      className={cn(
        inCardHeader ? 'hidden pt-4 -mb-4 sm:block' : 'hidden sm:block',
        'relative', // Add relative positioning
        className
      )}
      {...props}
    >
      <div
        className={cn(
          !inCardHeader && 'border-gray-200 border-b',
          'relative max-w-full overflow-hidden'
        )}
      >
        <AnimatePresence>
          {showLeftArrow && (
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => scroll('left')}
              className="absolute left-0 top-1/2 -translate-y-1/2 z-10 p-1 rounded-full bg-white shadow-md hover:bg-gray-50 border border-gray-200"
              aria-label="Scroll left"
            >
              <ChevronLeft className="h-4 w-4 text-gray-600"/>
            </motion.button>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showRightArrow && (
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => scroll('right')}
              className="absolute right-0 top-1/2 -translate-y-1/2 z-10 p-1 rounded-full bg-white shadow-md hover:bg-gray-50 border border-gray-200"
              aria-label="Scroll right"
            >
              <ChevronRight className="h-4 w-4 text-gray-600"/>
            </motion.button>
          )}
        </AnimatePresence>

        <nav
          ref={scrollRef}
          className={cn(
            'flex -mb-px space-x-8 overflow-auto whitespace-nowrap scrollbar-none relative no-scrollbar',
            // Add padding for the arrows
            showLeftArrow && 'pl-8',
            showRightArrow && 'pr-8'
          )}
          style={{
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            WebkitOverflowScrolling: 'touch',
          }}
          aria-label="Tabs"
        >
          {children}
        </nav>
      </div>
    </div>
  );
};

interface TabbyProps {
  tab: {
    name: string;
    href: string;
    current: boolean;
    count?: number;
  };
  onClick?: () => void;
  theme?: 'red' | 'blue' | 'green' | 'yellow';
  className?: string;
  as?: 'a' | 'link' | 'button';
}

export const Tabby = ({ tab, theme = 'red', className, as = 'link', ...props }: TabbyProps) => {
  const commonProps = {
    className: cn(
      tab.current
        ? 'border-indigo-500 text-indigo-600'
        : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
      'flex items-center shrink-0 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
      className
    ),
    'ariaCurrent': tab.current ? 'page' : undefined,
    ...props,
  };

  const content = (
    <>
      {tab.name}
      {tab.count ? (
        <div className="ml-2">
          <NumericIndicator brand={theme as Brand} size="sm">
            {tab.count}
          </NumericIndicator>
        </div>
      ) : null}
    </>
  );

  if (as === 'a') {
    return (
      <a href={tab.href} {...commonProps}>
        {content}
      </a>
    );
  }

  if (as === 'button') {
    return (
      <button {...commonProps}>
        {content}
      </button>
    );
  }

  // Default to <Link />
  return (
    <Link href={tab.href} {...commonProps}>
      {content}
    </Link>
  );
};

Tabby.displayName = 'Tabby';
