import { FormatRatioEntry } from '~/Components/Quality/QualityEntries/Ratio/FormatRatioEntry';
import { RatioFieldSummary } from '~/types/types';

interface FormatRatioFieldSummaryProps {
  fieldSummary: RatioFieldSummary;
}

/**
 * Formats the ratio field summary.
 *
 * @precondition - fieldSummary.average_number_a and fieldSummary.average_number_b are not null.
 */
export const FormatRatioFieldSummary = ({ fieldSummary }: FormatRatioFieldSummaryProps) => {
  if (fieldSummary.average_number_a === null || fieldSummary.average_number_b === null) {
    return null;
  }

  return (
    <FormatRatioEntry numberA={fieldSummary.average_number_a} numberB={fieldSummary.average_number_b}/>
  );
};
