import { InviteIcon } from '@/Icon';
import { ActionAppType, ActionContext, ActionPost, BaseAction } from '~/Actions/BaseAction';
import { SellerRoutes } from '~/hooks/useRoutes';
import { Issue } from '~/types/types';
import UserInput = App.Domain.User.UserInput;

export class InviteSellerUser extends BaseAction {
  constructor(public context: ActionContext<Issue>, public post: ActionPost) {
    super(context, post);
  }

  /** Default icon for this action */
  public static icon = InviteIcon;

  /** Name for posthog analytics */
  public static analyticsName = 'invite_seller';

  /** Execute for `buyer` or `seller` */
  public static type: ActionAppType = 'seller';

  /** Message to show on `toast` */
  public static toast = {
    titleKey: 'invited_user',
  };

  /** Default translation key */
  public nameKey() {
    return 'invite_user';
  }

  /** Callback to check if this should be disabled (using `context.auth` auth, etc) */
  public disabled() {
    // Base this on context?
    return false;
  }

  /**
   * Execute the action
   * @param data
   */
  public execute = async (data: UserInput) => {
    const routes = this.context.routes as SellerRoutes;
    const route = routes.inviteUser;

    await this.post(route(), data);
  };
}
