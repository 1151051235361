import {
  CancelledIcon,
  DetectionIcon,
  EditIcon,
  ErrorIcon,
  QualityIcon,
  SpecFileIcon,
} from '@/Icon';
import { StatusMap } from '@/Labels/Status';
import OrderUpdateType = App.Domain.Order.OrderUpdateType;

export const orderChangeStatusMap: StatusMap<OrderUpdateType> = {
  'order_created': {
    icon: SpecFileIcon,
    textKey: 'order_created',
    descriptionKey: 'order_created_description',
    theme: 'green',
  },
  quality_added: {
    icon: QualityIcon,
    textKey: 'quality_added',
    descriptionKey: 'quality_added_description',
    theme: 'green',
  },
  load_plan_added: {
    icon: DetectionIcon,
    textKey: 'load_plan_added',
    descriptionKey: 'load_plan_added_description',
    theme: 'green',
  },
  order_arrived: {
    icon: DetectionIcon,
    textKey: 'order_arrived',
    descriptionKey: 'order_arrived_description',
    theme: 'green',
  },
  missing_order_imported: {
    icon: ErrorIcon,
    textKey: 'missing_order_imported',
    descriptionKey: 'missing_order_imported_description',
    theme: 'red',
  },
  issue_resolved: {
    icon: EditIcon,
    textKey: 'issue_resolved',
    descriptionKey: 'issue_resolved_description',
    theme: 'green',
  },
  archived: {
    icon: ErrorIcon,
    textKey: 'archived',
    descriptionKey: 'archived_description',
    theme: 'red',
  },
  order_cancelled: {
    icon: CancelledIcon,
    textKey: 'order_cancelled',
    descriptionKey: 'order_cancelled_description',
    theme: 'yellow',
  },
  order_status_changed: {
    theme: 'red',
  },
  order_at: {
    theme: 'blue',
  },
  updated_existing_field: {
    theme: 'green',
  },
  updated_fields: {
    theme: 'green',
  },
  updated_varieties: {
    theme: 'green',
  },
};
