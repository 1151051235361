import { ReactNode } from 'react';

import { cn } from '~/utils/cn';

interface ColorDotProps {
  brand?: 'emerald' | 'blue' | 'indigo' | 'red' | 'gray' | 'orange' | 'green';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  className?: string;
  children?: ReactNode;
}

const colorVariants = {
  green: 'bg-green-100 border-green-300 border',
  gray: 'bg-gray-100 border-gray-300 border',
  emerald: 'bg-green-100 border-green-300 border',
  blue: 'bg-blue-100 border-blue-300 border',
  indigo: 'bg-indigo-100 border-indigo-300 border',
  red: 'bg-rose-100 border-rose-300 border',
  orange: 'bg-orange-100 border-orange-300 border',
};

const sizeVariants = {
  xs: 'w-1 h-1',
  sm: 'w-2.5 h-2.5',
  md: 'w-3 h-3',
  lg: 'w-4 h-4',
};

export const ColorDot = ({ brand, size, className, children }: ColorDotProps) => {
  return (
    <div
      className={cn(
        className,
        `rounded-full ${sizeVariants[size || 'md']} ${
          colorVariants[brand || 'emerald']
        }`
      )}
    >
      {children}
    </div>
  );
};
