import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';

import { MutedText, Strong, Text } from './Text';

interface GenericFieldProps {
  label: string;
  subLabel?: string;
  value?: string | number | null;
  renderValue?: ReactNode;
  subValue?: string;
  useMutedLabel?: boolean;
  uniqueLabel?: string;
  id?: string;
  testId?: string;
  columns?: 'default' | 'wide';
}

const fieldVariants = cva(
  'flex justify-center items-start flex-col gap-0.5 sm:grid sm:gap-4',
  {
    variants: {
      columns: {
        default: 'sm:grid-cols-[1fr,3fr]',
        wide: 'sm:grid-cols-[2fr,1fr]',
      },
    },
    defaultVariants: {
      columns: 'default',
    },
  }
);

/**
 * Used to display fields next ot a label.
 *
 * - Use `value` and `subValue` to display a simple value with a simple "sub-value".
 * - Either use `renderValue` to render custom JSX.
 */
export const SideLabeledField = ({
  label,
  subLabel,
  value,
  renderValue,
  subValue,
  useMutedLabel = false,
  id,
  testId,
  columns = 'default',
  uniqueLabel = undefined,
}: GenericFieldProps) => {
  if (!value && !renderValue) return <></>;

  return (
    <div className={fieldVariants({ columns })}
      id={id}
      data-testid={testId}>
      <div>
        <div>
          {uniqueLabel ? (
            <label htmlFor={uniqueLabel}>
              <Strong>
                {label}
              </Strong>
            </label>
          ) : (
            <>
              {useMutedLabel ? (
                <Text>
                  {label}
                </Text>
              ) : (
                <Strong>
                  {label}
                </Strong>
              )}
            </>
          )}
        </div>
        <div>
          {subLabel && (
            <Text className="!text-xs !text-gray-500">
              {subLabel}
            </Text>
          )}
        </div>
      </div>
      <div>
        {value && (
          <>
            <Text className="text-sm">
              {value}
            </Text>
            <div>
              {subValue && (
                <MutedText className="block">
                  {subValue}
                </MutedText>
              )}
            </div>
          </>
        )}
        {renderValue && (
          <>
            {renderValue}
          </>
        )}
      </div>
    </div>
  );
};
