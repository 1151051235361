import { ChartConfig } from '@/Chart';
import { EntityBreakdowns } from '~/types/types';

/**
 * Build for each entity a breakdown.
 *
 * The output needed:
 * - `config`: A configuration where we have {id}: { label: <categoryName>, color: <categoryLabel> }
 *
 * How it works:
 * - For each entity-breakdown, we extract all available categories (from the entity's Breakdowns->name) and their relevant colors (from the entity's Breakdowns->color).
 *
 * - Used to format in stacked bar charts.
 * @param breakdowns
 */
export const buildEntityBreakdownConfig = (breakdowns: EntityBreakdowns): ChartConfig => {
  const config: ChartConfig = {};

  const categories = extractCategories(breakdowns);

  // Add categories to the config
  categories.forEach((category) => {
    config[category.identifier] = {
      label: category.label,
      color: category.color ?? '#334155',
    };
  });

  return config;
};

/**
 * Build the data for each entity breakdown.
 *
 * The output needed:
 * - `data`: A flat list, where each key-value (that is not the `xLabel`) is part of the breakdown.
 * @param breakdowns
 * @param xLabel
 */
export const buildEntityBreakdownData = (breakdowns: EntityBreakdowns, xLabel = 'identifier') => {
  const data: any = [];

  breakdowns.items.forEach((item) => {
    const entity = item.entity;
    const breakdownPerEntity = item.breakdown.items ?? [];

    if (entity && breakdownPerEntity.length > 0) {
      // Now break the breakdown-per entity into a "record" for each entity.
      const values = breakdownPerEntity.reduce((acc, breakdownItem) => {
        acc[breakdownItem.identifier] = breakdownItem.value;
        return acc;
      }, {} as any);

      data.push({
        ...values,
        [xLabel]: entity.label ?? entity.title ?? entity.name ?? entity.id,
        entity: entity,
      });
    }
  });

  return data;
};

/**
 * Extract all categories from the entity breakdowns, and return them as a list with unique items
 *
 * How it works:
 * - Each entityBreakdown has a `breakdown.items`, where each item is a category with an identifier, label, color, value.
 * - We extract all the (names, color) from each breakdown item, and return them as a list.
 * - We make sure that the list are unique by name.
 * @param entityBreakdowns
 */
export const extractCategories = (entityBreakdowns: EntityBreakdowns): {
  label: string;
  identifier: string;
  color: string
}[] => {
  const categories = entityBreakdowns?.items?.reduce<{
    identifier: string;
    label: string;
    color: string
  }[]>((acc, item) => {
    const breakdown = item.breakdown.items ?? [];
    const itemCategories = breakdown.map((breakdownItem) => ({
      identifier: breakdownItem.identifier,
      label: breakdownItem.label,
      color: breakdownItem.color ?? '#334155',
    }));
    return [...acc, ...itemCategories];
  }, []) ?? [];

  // Use a Map to ensure uniqueness by name while keeping the last occurrence of each category
  const uniqueCategories = new Map(categories.map(cat => [cat.identifier, cat]));

  return Array.from(uniqueCategories.values());
};

