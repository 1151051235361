import { useTranslation } from 'react-i18next';

import { StandoutBorder } from '@/Borders';
import { WithGenericFallback } from '@/Fallback';
import { InspectTextButton } from '@/Link';
import { Helper, MutedText, TextLabel } from '@/Text';
import { GenericDetailsPane } from '~/Components/Panes/Details/GenericDetailsPane';
import { useAction } from '~/hooks/useAction';
import { useError } from '~/hooks/useError';
import { useProductName } from '~/hooks/useProductName';
import { OrderProduce } from '~/types/types';

import { useOrder } from '../../../pages/Dashboard/Order/OrderProvider';
import { SpecLabel } from '../Spec/SpecLabel';

export interface OrderProduceCardProps {
  orderProduce: OrderProduce;
  renderSpec?: boolean;
}

/**
 * OrderProduceCard renders some details about the order produce.
 *
 * Preconditions:
 * - orderProduce has a produce loaded.
 */
export const OrderProduceCard = ({
  orderProduce,
  renderSpec = false,
}: OrderProduceCardProps) => {
  const { t } = useTranslation();
  const { log } = useError();
  const { specColorMap } = useOrder();
  const { reload } = useAction('', {
    withParams: true,
    preserveState: true,
    only: ['active'],
  });

  const getProduceName = useProductName();

  if (!orderProduce.produce) {
    log(new Error('OrderProduceCard: orderProduce must have produce loaded.'));
    return null;
  }

  const openSpecModal = () => {
    reload({ activeSpecId: orderProduce?.active_spec?.id });
  };
  const productName = getProduceName(orderProduce.produce);

  const units = orderProduce.units ?? [];

  return (
    <div className="flex items-start px-4 border border-gray-100 rounded shadow-sm">
      {orderProduce.produce.image_url && (
        <div className="inline mt-4 mr-4">
          <StandoutBorder>
            <img
              className="object-cover rounded-full w-7 h-7"
              src={orderProduce.produce.image_url}
              alt={productName}
            />
          </StandoutBorder>
        </div>
      )}
      <div className="flex flex-col divide-y">
        <div className="py-2">
          <TextLabel
            className="leading-none"
            id={`produce::${orderProduce.produce_id}`}
            data-testid="orderProduce"
          >
            {productName}
          </TextLabel>
          {orderProduce.produce_variety && (
            <div>
              <MutedText
                className="leading-none"
                id={`produceVariety::${orderProduce.produce_variety_id}`}
                data-testid="orderProduceVariety"
              >
                {orderProduce.produce_variety.name}
              </MutedText>
            </div>
          )}
        </div>

        {renderSpec && orderProduce.active_spec && (
          <div>
            <div className="py-2">
              <Helper>{t('spec')}</Helper>
              <div className="flex items-center space-x-1">
                <SpecLabel
                  spec={orderProduce.active_spec}
                  theme={specColorMap[orderProduce.active_spec.id]}
                  onOpen={() => openSpecModal()}
                />
              </div>
            </div>
          </div>
        )}

        <hr/>
        <WithGenericFallback>
          {units.length > 0 && (
            <div className="py-2">
              <Helper>{t('units')}</Helper>
              {units.map((unit, index) => (
                <GenericDetailsPane key={index}
                  object={unit.custom_properties}
                  details={[
                    {
                      key: 'bestBeforeDate',
                      title: t('bestBeforeDate'),
                      description: t('bestBeforeDate_description') as string,
                      group: 'Residency',
                      dtype: 'date',
                    },
                    {
                      key: 'packhouseName',
                      title: t('packhouseName'),
                      description: t('packhouseName_description') as string,
                      group: 'Traceability',
                    },
                    {
                      key: 'packhouseCode',
                      title: t('packhouseCode'),
                      description: t('packhouseCode_description') as string,
                      group: 'Traceability',
                      dtype: 'code',
                    },
                    {
                      key: 'traceabilityCode',
                      title: t('traceabilityCode'),
                      description: t('traceabilityCode_description') as string,
                      group: 'Traceability',
                      dtype: 'code',
                    },
                    {
                      key: 'harvestDate',
                      title: t('harvestDate'),
                      description: t('harvestDate_description') as string,
                      group: 'Residency',
                      dtype: 'date',
                    },
                    {
                      key: 'GGN',
                      title: t('GGN'),
                      description: t('GGN_description') as string,
                    },
                  ]}>
                  <div key={unit.id} className="flex items-center space-x-2"
                    aria-label={`Unit ${index + 1} for produce ${productName}, variant ${orderProduce.produce_variety?.name}`}>
                    <pre>

                      <InspectTextButton>
                        {unit.ext_ref}
                      </InspectTextButton>
                    </pre>
                  </div>
                </GenericDetailsPane>
              ))}
            </div>
          )}
        </WithGenericFallback>
      </div>
    </div>
  );
};
