import { router } from '@inertiajs/react';
import React, { ReactNode } from 'react';

import { DropdownCheckboxItem, DropdownGroup, DropdownGroupHeader } from '@/DropdownMenu';
import { OrderIcon, TransportTemperatureIcon } from '@/Icon';
import { LayoutBody, LayoutHeader } from '@/Layout';
import { PageTitle } from '@/Text';
import { BarTimeSeriesWidget } from '~/Components/Analytics/Widgets/TimeSeries/BarTimeSeriesWidget';
import Section, { Sections } from '~/Components/Common/Section';
import { DateFilterPicker } from '~/Components/Dates/DateFilterPicker';
import { FilterColumn } from '~/Components/Filters/FilterColumn';
import { FilterConfig } from '~/Components/Panes/Filters/GenericFilterPane';
import { useAction } from '~/hooks/useAction';
import { BuyerRoutes, useRoutes } from '~/hooks/useRoutes';
import { PageProps } from '~/types/types';

import { DashboardLayout } from '../DashboardLayout';

export type DashboardPageProps = PageProps

const DashboardPage = ({ orderTimeSeries, fusedTimeline, filter }: DashboardPageProps) => {
  const { reload, loading } = useAction('');
  const routes = useRoutes<BuyerRoutes>();

  const handleFilterChange = (values: Record<string, any>) => {
    reload(values);
  };

  const filters: FilterConfig[] = [
    {
      fieldKey: 'dateRange',
      label: 'Date Range',
      component: <DateFilterPicker
        loading={loading}
        requiresSubmit={true}
      />,
    },
  ];

  const goToOrders = (filter: any) => {
    const mainRoute = routes.orders();

    console.log(filter);

    router.get(`${mainRoute}`, { ...filter });
  };

  return (
    <div>
      <LayoutHeader isSticky>
        <div>
          <PageTitle>Dashboard</PageTitle>
        </div>
      </LayoutHeader>
      <LayoutBody>
        <div className="grid grid-cols-[1fr_3fr] gap-8">
          <div>
            <FilterColumn
              defaultValues={{
                dateRange: filter?.dateRange,
              }}
              filters={filters}
              onChange={handleFilterChange}
              title="Filters"
            />
          </div>
          <div>
            <Sections>
              <Section title="Temperature Issues" icon={TransportTemperatureIcon}>
                <BarTimeSeriesWidget
                  title="Test"
                  metrics={[]}
                  data={fusedTimeline.events}
                  series={fusedTimeline.seriesConfigs}
                />
              </Section>

              <Section title="Orders by arrival date with temperature data streams" icon={OrderIcon}>
                <BarTimeSeriesWidget
                  title="Test"
                  metrics={[]}
                  data={orderTimeSeries.events}
                  series={orderTimeSeries.seriesConfigs}
                  renderDropdownMenu={(data) => (
                    <>
                      <DropdownGroup>
                        <DropdownGroupHeader>
                          Inspect orders on {data.date}
                        </DropdownGroupHeader>

                        <DropdownCheckboxItem
                          onClick={() => goToOrders({
                            arrival_from: data.date,
                            arrival_to: data.date,
                            temperatureStatusType: 'has_temp_data_streams',
                          })}>
                          Orders with datastreams ({data.with_temperature})
                        </DropdownCheckboxItem>
                        <DropdownCheckboxItem
                          onClick={() => goToOrders({
                            arrival_from: data.date,
                            arrival_to: data.date,
                            temperatureStatusType: 'has_no_temp_data_streams',
                          })}>
                          Orders without datastreams ({data.no_datastream})
                        </DropdownCheckboxItem>
                      </DropdownGroup>
                    </>
                  )}
                />
              </Section>
            </Sections>
          </div>
        </div>
      </LayoutBody>
    </div>
  );
};

DashboardPage.layout = (page: ReactNode) => (
  <DashboardLayout>{page}</DashboardLayout>
);

export default DashboardPage;
