
/**
 * Make a map of a collection of items by a specified key
 */
export const makeMap = <T extends { [key: string]: any }>(collection: T[], by = 'id'): Record<string, T> => {
  const unique = collection.filter((item, index, self) => {
    return index === self.findIndex((s) => (
      s[by] === item[by]
    ));
  });

  return unique.reduce((acc, item) => {
    return {
      ...acc,
      [item[by]]: item,
    };
  }, {} as Record<string, T>);
};

export const mapItemsToColor = (array: any[], colors: string[]) => {
  return array.reduce((acc, item, index) => {
    if (index > colors.length) {
      return {
        ...acc,
        [item.id]: 'gray',
      };
    }

    const color = colors[index];

    return {
      ...acc,
      [item.id]: color,
    };
  }, {} as Record<string, string>);
};
