import {
  CheckIcon,
  Cross1Icon,
} from '@radix-ui/react-icons';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/Icon';
import { Tooltipped } from '@/Tooltip';

export interface InspectionStatusIconProps {
  hasIssue: boolean;
  comment?: string;
}

/**
 * InspectionStatusIcon
 */
export const InspectionStatusIcon = ({
  hasIssue,
}: InspectionStatusIconProps) => {
  const { t } = useTranslation();

  if (hasIssue) {
    return (
      <Tooltipped label={t('outside_allowed_range')}>
        <Icon
          aria-label={t('outside_allowed_range')}
          className="justify-center !w-3 !h-3 px-0.5 border border-red-600 rounded-sm mr-2 stroke-red-600 bg-red-50 hover:bg-red-200"
          style={{ borderRadius: 4.5 }}
        >
          <Cross1Icon/>
        </Icon>
      </Tooltipped>
    );
  }

  return (
    <Tooltipped label={t('within_allowed_range')}>
      <Icon
        className="justify-center !w-3 !h-3 px-0 border border-green-600 rounded-sm mr-2 stroke-green-600 bg-green-50"
        style={{ borderRadius: 4.5 }}
      >
        <CheckIcon/>
      </Icon>
    </Tooltipped>
  );
};
