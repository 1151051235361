import { autoUpdate, offset, useFloating } from '@floating-ui/react';
import { ThermometerIcon } from 'lucide-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardBody, CardHeaderContainer } from '@/Card';
import { Tabby, Tabs } from '@/Tabbar';
import { CardSubtitle, CardTitle, MutedText } from '@/Text';
import {
  OrderDataStream,
  OrderDataStreamType,
} from '~/types/types';

import { useOrder } from '../OrderProvider';
import { TemperatureIssues } from './TemperatureIssues';
import { TransportChart } from './TransportChart';

interface TemperatureSummaryCardProps {
  dataStreams: OrderDataStream<any>[];
}

export const TemperatureSummarySection = ({
  dataStreams,
}: TemperatureSummaryCardProps) => {
  const tempDataStreams = dataStreams.filter(dataStream => dataStream.type === OrderDataStreamType.TRANSPORT_TEMPERATURE);

  return (
    <div className="space-y-4">
      <TemperatureSummaryCard dataStreams={tempDataStreams}/>
    </div>
  );
};

export const TemperatureSummaryCard = ({
  dataStreams,
}: TemperatureSummaryCardProps) => {
  const { t } = useTranslation();
  const { tempThresholds, orderProduces } = useOrder();
  const [activeIndex, setActiveIndex] = useState(0);

  const dataStream = dataStreams[activeIndex];
  const tempCategory = dataStream.data?.eventGroups?.find((eventGroup: any) => eventGroup.type === 'temperature');
  const tempEvents = tempCategory?.events || [];

  const { refs } = useFloating({
    placement: 'right-start',
    middleware: [offset(10)],
    whileElementsMounted: autoUpdate,
  });

  // Concatenate all issues from all data streams
  const issues = dataStreams.reduce((acc, dataStream) => {
    return acc.concat(dataStream.issues || []);
  }, [] as any[]);

  const tempConfig = orderProduces?.find(orderProduce => orderProduce.produce.temp_config?.low !== undefined)?.produce?.temp_config;

  return (
    <div id={OrderDataStreamType.TRANSPORT_TEMPERATURE} ref={refs.setReference}>
      <Card>
        <div className="px-6 py-3 border-b">
          <div className="flex items-center gap-2">
            <div>
              <ThermometerIcon className="w-4 h-4 text-gray-400"/>
            </div>
            <MutedText className="!text-sm">
              Logistics
            </MutedText>
          </div>
        </div>
        <CardHeaderContainer className="py-6 pb-4">
          <div className="flex space-x-3">
            <div>
              <CardTitle className="flex items-center mb-1 leading-none">
                Transport Temperature
              </CardTitle>
              <div>
                <CardSubtitle>
                  {t('temperature_summaries.transport_temperature_description')}
                </CardSubtitle>
              </div>
            </div>
          </div>

          {dataStreams.length > 1 && (
            <Tabs inCardHeader>
              {dataStreams.map((dataStream, index) => (
                <Tabby
                  key={dataStream.id}
                  as="button"
                  tab={{
                    name: dataStream.id,
                    href: '#',
                    current: index === activeIndex,
                    count: dataStream?.issues?.filter(issue => issue.status !== 'read').length,
                  }}
                  onClick={() => setActiveIndex(index)}
                />
              ))}
            </Tabs>
          )}
        </CardHeaderContainer>

        <CardBody>
          <div>
            <div className="space-y-2">
              <TransportChart
                dataStream={dataStream}
                lowThresholdY={tempConfig?.low}
                upperThresholdY={tempConfig?.high}
                type="temperature"
                data={tempEvents}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
