import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { LayoutBody, LayoutHeader } from '@/Layout';
import { PageDescription, PageTitle } from '@/Text';
import { InviteSellerUser } from '~/Actions/Sellers/Settings/InviteSellerUser';
import { EditUser } from '~/Actions/Users/EditUser';
import { PageProps } from '~/types/types';

import { InviteUserModal } from '../../Dashboard/Settings/Organization/Components/InviteUserModal';
import { UsersTable } from '../../Dashboard/Settings/Organization/Components/UsersTable';
import { SellerSettingsLayout } from './SellerSettingsLayout';
import SellerUsersSettingsPageOutput = App.Http.Controllers.Seller.Settings.SellerUsersSettingsPageOutput;

export type SellerUsersSettingsPageProps = PageProps & SellerUsersSettingsPageOutput;

const SellerUsersSettingsPage = ({ organization, ...props }: SellerUsersSettingsPageProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <LayoutHeader isSticky>
        <div className="flex justify-between items-center gap-4">
          <div>
            <PageTitle>
              {t('users')}
            </PageTitle>
            <PageDescription>
              {t('manage_your_users')}
            </PageDescription>
          </div>

          <div>
            <InviteUserModal organization={organization} action={InviteSellerUser}>
              <Button>
                {t('invite_user')}
              </Button>
            </InviteUserModal>
          </div>
        </div>
      </LayoutHeader>
      <LayoutBody>
        <div>
          <UsersTable
            actions={{ editUser: EditUser, inviteUser: InviteSellerUser }}
            organization={organization}
            usersConnection={props.users}
            availableGroups={props.userGroups}
          />
        </div>
      </LayoutBody>
    </div>
  );
};

SellerUsersSettingsPage.layout = (page: ReactNode) => (
  <SellerSettingsLayout>{page}</SellerSettingsLayout>
);

export default SellerUsersSettingsPage;
