import React from 'react';
import { Customized } from 'recharts';

export interface Anomaly {
  date: string;
  label: string;
}

export const AnomalyMarkers = ({
  anomalies,
}: {
  anomalies: Anomaly[];
}) => {
  console.log('hm');
  return (
    <>
      {/* @ts-ignore */}
      <Customized component={({ xAxisMap }) => {
        console.log('xAxisMap', xAxisMap);
        if (!xAxisMap || !xAxisMap[0] || typeof xAxisMap[0].scale !== 'function') {
          return null;
        }
        const xScale = xAxisMap[0].scale;
        return <InnerAnomalyMarkers anomalies={anomalies} xScale={xScale}/>;
      }}
      />
    </>
  );
};

/**
 * Anomaly markers render anomalies as circles with an exclamation mark in the top of a Recharts chart.
 *
 * @preconditions - This is inserted in the top of a `XChart` Recharts component
 *                - Inserted inside a Customized component, along with the render props' xAxisMap.scale.
 *
 * @example (child of AreaChart)
 * ```tsx
 * <AreaChart>
 *   <XAxis dataKey="date" scale="time" type="number" domain={['auto', 'auto']} />
 *
 * <Customized
 *   component={({ xAxisMap }) => {
 *               if (!xAxisMap || !xAxisMap[0] || typeof xAxisMap[0].scale !== 'function') {
 *                 return null;
 *               }
 *               const xScale = xAxisMap[0].scale;
 *               return <AnomalyMarkers anomalies={anomalyDots} xScale={xScale}/>;
 *             }}
 *           />
 * ```
 */
export const InnerAnomalyMarkers = ({
  anomalies,
  xScale,
}: {
  anomalies: Anomaly[];
  xScale: any;
}) => {
  const yPosOffset = -10; // Adjust this value to position anomalies visually below the top

  return (
    <>
      {anomalies.map((anomaly, index) => {
        const xPosition = xScale(anomaly.date);
        if (xPosition === undefined) return null;

        return (
          <g key={index} transform={`translate(${xPosition}, ${yPosOffset})`}>
            <circle
              cx={0}
              cy={20}
              r={10}
              className="text-rose-500 fill-current drop-shadow-md"
            />
            <text
              x={0}
              y={25}
              textAnchor="middle"
              className="fill-rose-100 text-white font-bold text-sm"
            >
              !
            </text>
          </g>
        );
      })}
    </>
  );
};
