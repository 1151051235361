import { TFunction } from 'i18next';
import { ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input } from '@/Input';
import { Pane, PaneContent, PaneHeader, PanePortal, PaneTitle, PaneTrigger } from '@/Pane';
import TabFilter from '@/Tabs/TabFilter';
import { useAction } from '~/hooks/useAction';
import { usePageProps } from '~/hooks/usePageProps';
import { Comment, CommentableType } from '~/types/types';

import { AttachmentCommentEvent } from './comments/AttachmentCommentEvent';
import { InspectionCommentEvent } from './comments/InspectionCommentEvent';
import { OrderDataStreamCommentEvent } from './comments/OrderDataStreamCommentEvent';
import { ReplyComment } from './ReplyComment';

interface CommentsPaneProps {
  Trigger: ReactNode;
  groupedComments?: Record<string, Comment[]>;
  commentable: { id: string; type: string };
  path: string;
}

const getCommentModes = (
  t: TFunction
): { key: CommentableType; value: string }[] => [
  {
    key: 'Order',
    value: t('general', { defaultValue: 'General' }),
  },
  {
    key: 'QualityEntry',
    value: t('inspections', { defaultValue: 'Inspections' }),
  },
  {
    key: 'Attachment',
    value: t('attachments', { defaultValue: 'Attachments' }),
  },
  {
    key: 'OrderDataStream',
    value: t('logistics', { defaultValue: 'Logistics' }),
  },
];

export const GroupedCommentsPane = ({
  Trigger,
  groupedComments,
  commentable,
  path,
}: CommentsPaneProps) => {
  const { t } = useTranslation();
  const { organization, order } = usePageProps();
  const [open, setOpen] = useState(false);

  const { post: createComment } = useAction(path, {
    preserveState: true,
    preserveScroll: true,
  });

  const [activeTab, setActiveTab] = useState(0);

  const { handleSubmit, register, reset, formState } = useForm({
    defaultValues: {
      text: '',
    },
  });

  const onSubmit = (input: typeof formState.defaultValues) => {
    const data = {
      commentable_id: commentable?.id,
      commentable_type: commentable?.type,
      text: input?.text ?? '',
      organizationId: organization.id,
      orderId: order?.id ?? null,
    };

    createComment(data).then(r => {
      return r;
    });
    reset();
  };

  const activeCommentType = getCommentModes(t)[activeTab].key;
  const comments = groupedComments?.[activeCommentType] ?? [];

  return (
    <>
      <Pane open={open} onOpenChange={(isOpen) => setOpen(isOpen)}>
        <PaneTrigger>{Trigger}</PaneTrigger>
        <PanePortal>
          <PaneContent className="flex flex-col gap-0">
            <PaneHeader className="space-y-0">
              <PaneTitle className="px-3 py-4">{t('comments')}</PaneTitle>
              <hr/>
            </PaneHeader>
            <div className="relative px-3 my-4">
              <TabFilter
                initialIndex={activeTab}
                onChange={(index) => {
                  setActiveTab(index);
                }}
                items={Object.values(getCommentModes(t)).map(
                  (mode) => mode.value
                )}
              />
            </div>
            <div className="flex flex-col flex-1 space-y-2 overflow-y-auto">
              {activeCommentType === 'Order' &&
                comments.map((comment) => {
                  return (
                    <ReplyComment
                      key={comment.id}
                      isRootReply={false}
                      comment={comment}
                      onClose={() => setOpen(false)}
                    />
                  );
                })}

              {activeCommentType === 'Attachment' &&
                comments.map((comment) => {
                  return (
                    <AttachmentCommentEvent
                      key={comment.id}
                      isRootReply={false}
                      comment={comment}
                      onClose={() => setOpen(false)}
                    />
                  );
                })}

              {activeCommentType === 'QualityEntry' &&
                comments.map((comment) => {
                  return (
                    <InspectionCommentEvent
                      key={comment.id}
                      isRootReply={false}
                      comment={comment}
                      onClose={() => setOpen(false)}
                    />
                  );
                })}

              {activeCommentType === 'OrderDataStream' &&
                comments.map((comment) => {
                  return (
                    <OrderDataStreamCommentEvent
                      key={comment.id}
                      isRootReply={false}
                      comment={comment}
                      onClose={() => setOpen(false)}
                    />
                  );
                })}

              {comments.length === 0 && (
                <div className="grid h-full text-sm place-items-center text-token-muted">
                  {t('empty_states.no_comments')}
                </div>
              )}
            </div>
            {activeCommentType === 'Order' && (
              <div className="px-3 py-6">
                <form
                  id="commentable-footer"
                  onSubmit={handleSubmit(onSubmit)}
                  className="mt-auto"
                >
                  <Input
                    autoFocus
                    className=""
                    placeholder={t('placeholders.write_a_reply')}
                    type="text"
                    {...register('text')}
                  />
                </form>
              </div>
            )}
          </PaneContent>
        </PanePortal>
      </Pane>
    </>
  );
};
