/**
 * We define two types of formatters
 * - Model => Form schemas
 * - Form => API Inputs
 */
import { Field, FieldConstraint, ProduceVariety, Range, Spec } from '~/types/types';

import { flushID } from '../helpers';
import { SpecField, SpecFieldConstraint, SpecFieldRange, SpecFieldsFormValues, SpecVarietiesFormInputs } from './schemas';
import DefectType = App.Domain.Quality.DefectType;
import BaseSpecInput = App.Domain.Specs.BaseSpecInput;
import ProduceSpecInput = App.Domain.Specs.ProduceSpecInput;
import SpecInput = App.Domain.Specs.SpecInput;
import SpecFieldInput = App.Domain.Specs.SpecFieldInput;
import SpecFieldConstraintInput = App.Domain.Specs.SpecFieldConstraintInput;
import FieldCategory = App.Domain.Fields.SupportedFields.FieldCategory;
import ConstraintType = App.Domain.Specs.ConstraintType;
import RAGScoreType = App.Domain.Specs.RAGScoreType;
import RegularRangeInput = App.Domain.Specs.RegularRangeInput;
import RAGRangeInput = App.Domain.Specs.RAGRangeInput;

import { parse } from '~/hooks/useDate';

export const makeDefaultFieldFormValues = (fields: Field[]): SpecFieldsFormValues => {
  return {
    fields: fields.map((field) => FieldFormatters.modelToForm(field)),
  };
};

const formatRangeValue = (value: number | null, isPercentage: boolean): number | null => {
  if (value === null) {
    return null;
  }
  return isPercentage ? value / 100 : value;
};

/**
 * =================================
 * FORMATTER: Model => Form
 * =================================
 */

export const formatVarietiesModelToForm = (spec: Spec): SpecVarietiesFormInputs => {
  return {
    varieties: spec.linked_varieties?.map(variety => ({
      id: variety.id,
      public_name: variety.name,
    })) ?? [],
  };
};

/**
 * Maps the {SpecField} form schemas to the SpecInput API inputs.
 * Formatter: Form => API
 * - Utilizes the formatter for varieties to ensure that the varieties are not touched.
 *
 * Scope: fields.
 * Used to save the form for spec-fields to the API (and convert the form values to the correct format).
 * @param spec
 * @param input
 */
export const formatFieldsInputToApi = (spec: Spec, input: SpecFieldsFormValues): SpecInput => {
  const baseInput: BaseSpecInput = {
    name: spec.name,
    produceName: spec.produce?.name ?? null,
    produceType: spec.produce_type as any ?? null,
    produceId: spec.produce?.id ?? null,
  };

  const varietyInputs: ProduceSpecInput[] = spec.linked_varieties?.map(variety => ({
    id: variety.id ?? null,
    produceVarietyName: variety.name,
    crudPolicy: 'save',
  })) ?? [];

  const fieldInputs = input.fields.map(FieldFormatters.formToApi);

  return {
    baseInput,
    fieldsInput: {
      specFieldInputs: fieldInputs,
    },
    varietyInputs,
  };
};

/**
 * Maps the {Variety} form schemas to the SpecInput API inputs.
 *
 * Formatter: Form => API
 * - Utilizes the formatter for varieties to ensure that the varieties are not touched.
 *
 * Scope: varieties.
 * Used to save the form for spec-varieties to the API (and convert the form values to the correct format).
 * @param spec
 * @param input
 */
export const formatVarietyInputsToApi = (spec: Spec, input: SpecVarietiesFormInputs): SpecInput => {
  const baseInput: BaseSpecInput = {
    name: spec.name,
    produceName: spec.produce?.name ?? null,
    produceType: spec.produce_type as any ?? null,
    produceId: spec.produce?.id ?? null,
  };

  const varietyAPInputs: ProduceSpecInput[] = input.varieties.map(variety => ({
    id: variety.id ?? null,
    produceVarietyName: variety.public_name,
    crudPolicy: 'save',
  }));

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const fieldInputs = spec.linked_fields?.map(FieldFormatters.modelToApi) ?? [];

  // We map the spec fields to form-inputs => to api-inputs to ensure that the api-inputs are in the correct format
  return {
    baseInput,
    varietyInputs: varietyAPInputs,
    fieldsInput: {
      specFieldInputs: fieldInputs,
    },
  };
};

export const RangeFormatters = {
  modelToForm: (range: Range, fieldType: string): SpecFieldRange => ({
    id: range.id,
    min: fieldType === 'percentage'
      ? range.min ? range.min * 100 : 0
      : range.min,
    max: fieldType === 'percentage'
      ? range.max ? range.max * 100 : 100
      : range.max,
    score: range.score as RAGScoreType,
  }),

  formToApi: (input: SpecFieldRange, fieldType: string): RegularRangeInput | RAGRangeInput => ({
    id: flushID(input?.id) ?? null,
    min: formatRangeValue(input.min, fieldType === 'percentage'),
    max: formatRangeValue(input.max, fieldType === 'percentage'),
    scoreType: input.score as any,
    includeMin: true,
    includeMax: true,
    tempFromDate: null,
    tempToDate: null,
    tempToDat: null,
  }),

  formToModel: (input: SpecFieldRange): Range => ({
    id: input.id as any,
    min: input.min,
    max: input.max,
    score: input.score as RAGScoreType,
    rangeable_id: '',
    rangeable_type: '',
    field_constraint_id: '',
    inclusive_min: true,
    inclusive_max: true,
  }),
};

export const ConstraintFormatters = {
  formToApi: (input: SpecFieldConstraint, fieldType: string): SpecFieldConstraintInput => ({
    id: flushID(input.id),
    type: input.type,
    produceVarieties: input?.produce_varieties?.map(variety => ({
      id: null,
      produceVarietyName: variety as string,
      crudPolicy: 'save',
    })) ?? [],
    tempStartDate: input.tempStartDate,
    tempEndDate: input.tempEndDate,
    nested_constraints: input?.nested_constraints?.map(constraintInput => ConstraintFormatters.formToApi(constraintInput as any, fieldType)) ?? [],
    rangeInputs: (input.ranges.map(rangeFormInput => RangeFormatters.formToApi(rangeFormInput, fieldType)) ?? []) as any,
  }),

  modelToForm: (constraint: FieldConstraint, fieldType: string): SpecFieldConstraint => ({
    id: constraint.id,
    type: constraint.type,
    active: constraint.active,
    is_future: constraint.is_future,
    produce_varieties: constraint.produce_varieties?.map((variety: ProduceVariety) => variety.public_name) ?? [],
    tempStartDate: constraint.temp_start_date ? parse(constraint.temp_start_date) : undefined,
    tempEndDate: constraint.temp_end_date ? parse(constraint.temp_end_date) : undefined,
    ranges: constraint?.ranges?.map(range => RangeFormatters.modelToForm(range, fieldType)) ?? [],
    nested_constraints: constraint?.nested_constraints?.map((nestedConstraint: any) => ConstraintFormatters.modelToForm(nestedConstraint, fieldType)) ?? [],
  }),

  formToModel: (input: SpecFieldConstraint, includeNested = false): FieldConstraint => ({
    id: input.id ?? '',
    type: input.type,
    active: undefined,
    is_future: undefined,
    produce_varieties: (input.produce_varieties || []).map(variety => ({
      id: null,
      name: variety,
    })) as any || [],
    temp_start_date: input.tempStartDate,
    temp_end_date: input.tempEndDate,
    field_id: '',
    spec_id: '',
    parent_constraint_id: '',
    constraint_relation_type: '',
    ranges: input.ranges.map(RangeFormatters.formToModel),
    nested_constraints: includeNested ? input.nested_constraints.map((constraint: any) => ConstraintFormatters.formToModel(constraint, false)) : [],
  }),
};

export const FieldFormatters = {
  /**
   * Format the model to form.
   */
  modelToForm: (field: Field): SpecField => {
    return {
      id: field.id,
      name: field.name,
      description: field.description,
      defect_type: field.defect_type as DefectType,
      required: field.required,
      hidden: field.hidden,
      type: field.type as any,
      unit: field.unit,
      base_type: field.base_type ?? undefined,
      constraints: field.constraints?.map(constraint => ConstraintFormatters.modelToForm(constraint as FieldConstraint, field.type)) ?? [],
    };
  },

  /**
   * Format the Spec Field database model to an API input (SpecFieldInput).
   * @param field
   */
  modelToApi: (field: Field): SpecFieldInput => {
    return {
      id: flushID(field.id),
      name: field.name,
      description: field.description,
      defectType: field.defect_type as DefectType,
      required: field.required ?? false,
      hidden: field.hidden ?? false,
      type: field.type as any,
      baseType: field.base_type as any,
      category: field.category as FieldCategory,
      unit: field.unit ?? null,
      crudPolicy: 'save',
      constraintInputs: field?.constraints?.map(constraint => {
        const constraintInput: SpecFieldConstraintInput = {
          id: constraint.id,
          type: constraint.type as ConstraintType,
          produceVarieties: constraint?.produce_varieties?.map(variety => ({
            id: flushID(variety.id),
            produceVarietyName: variety.name,
            crudPolicy: 'save',
          })) ?? [],
          tempStartDate: constraint.temp_start_date,
          tempEndDate: constraint.temp_end_date,
          nested_constraints: constraint?.nested_constraints?.map(nestedConstraint => ({
            id: flushID(nestedConstraint.id),
            type: nestedConstraint.type as ConstraintType,
            tempStartDate: nestedConstraint.temp_start_date,
            tempEndDate: nestedConstraint.temp_end_date,
            produceVarieties: [],
            nested_constraints: [],
            rangeInputs: nestedConstraint.ranges?.map(range => ({
              id: flushID(range.id),
              min: field.type === 'percentage'
                ? range.min ? range.min / 100 : 0
                : range.min ?? 0,
              max: field.type === 'percentage'
                ? range.max ? range.max / 100 : 100
                : range.max ?? 100,
              scoreType: range.score as any,
              includeMin: true,
              tempFromDate: null,
              tempToDat: null,
              includeMax: true,
            })) ?? [],
          })) ?? [],
          rangeInputs: constraint?.ranges?.map(range => ({
            id: flushID(range.id),
            min: field.type === 'percentage'
              ? range.min ? range.min / 100 : 0
              : range.min ?? 0,
            max: field.type === 'percentage'
              ? range.max ? range.max / 100 : 100
              : range.max ?? 100,
            scoreType: range.score as any,
            includeMin: true,
            includeMax: true,
            tempFromDate: null,
            tempToDat: null,
          })) ?? [],
        };

        return constraintInput;
      }) ?? [],
    };

  },

  /**
   * Format the form schema to an API input (SpecFieldInput).
   * @param input
   */
  formToApi: (input: SpecField): SpecFieldInput => {
    return {
      id: flushID(input.id),
      name: input.name,
      description: input.description ?? null,
      defectType: input.defect_type as DefectType,
      required: input.required ?? false,
      hidden: input.hidden ?? false,
      type: input.type as any,
      baseType: input.base_type as any,
      category: 'quality',
      unit: input.unit ?? null,
      crudPolicy: 'save',
      constraintInputs: input.constraints.map(constraint => ConstraintFormatters.formToApi(constraint, input.type)),
    };
  },

  /**
   * Format the form schema to the model.
   * @param input
   */
  formToModel: (input: SpecField): Field => {
    return {
      id: input.id,
      name: input.name,
      description: input.description,
      defect_type: input.defect_type,
      required: input.required,
      hidden: input.hidden,
      type: input.type,
      unit: input.unit,
      base_type: input.base_type,
      constraints: input.constraints.map((constraint) => ConstraintFormatters.formToModel(constraint, true)),
    } as any;
  },
};
