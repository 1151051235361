import { ReactNode } from 'react';

import {
  OrderFieldSummary as OrderFieldSummaryType,
  Spec,
  Summarizable,
} from '~/types/types';

import { GenericFieldSummary } from './GenericFieldSummary';

interface OrderFieldSummaryProps {
  orderFieldSummary: OrderFieldSummaryType;
  isOpen: boolean;
  qcDisabled?: boolean;
  readOnly?: boolean;
  activeSpec: Spec | null;
}

export const OrderFieldSummary = ({
  orderFieldSummary,
  isOpen,
  activeSpec,
}: OrderFieldSummaryProps) => {
  const componentMap: Record<Summarizable, ReactNode> = {
    // [Summarizable.BooleanFieldSummary]: () => <BooleanFieldSummary fieldSummary={fieldSummary} />,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    [Summarizable.MeasurementFieldSummary]: () => (
      <GenericFieldSummary
        isOpen={isOpen}
        orderFieldSummary={orderFieldSummary}
        activeSpec={activeSpec}
      />
    ),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    [Summarizable.PercentageFieldSummary]: () => (
      <GenericFieldSummary
        isOpen={isOpen}
        orderFieldSummary={orderFieldSummary}
        activeSpec={activeSpec}
      />
    ),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    [Summarizable.RatioFieldSummary]: () => (
      <GenericFieldSummary
        isOpen={isOpen}
        orderFieldSummary={orderFieldSummary}
        activeSpec={activeSpec}
      />
    ),
  };

  const fieldSummaries = orderFieldSummary.fieldSummaries;
  const type = fieldSummaries[0].summarizable_type as Summarizable;
  const Component = componentMap[type];

  if (!Component) {
    return null;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Component/>;
};
