import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Card, CardBody } from '@/Card';
import { Container } from '@/Container';
import { FormGrid, FormItem, InputLabel } from '@/Form';
import { Input } from '@/Input';
import { PageDescription, PageTitle } from '@/Text';
import { useAction } from '~/hooks/useAction';

import { AuthLayout } from './AuthLayout';

const LoginPage = () => {
  const { post: login } = useAction('/login');
  const { t } = useTranslation();

  const {
    formState: { isValid },
    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: null,
    },
  });

  const submit = async (data: { email: string | null }) => {
    await login(data);
  };

  return (
    <AuthLayout>
      <div className="flex flex-col justify-center min-h-screen">
        <Container className="flex justify-center py-20 mx-auto">
          <Card>
            <CardBody className="!p-16">
              <div>
                <img
                  className="h-12 mx-auto"
                  src="assets/logo.svg"
                  alt="AgStacked"
                />
                <div className="mt-4">
                  <PageTitle className="!text-xxl text-center">
                    {t('welcome_to_agstacked')}
                  </PageTitle>
                  <PageDescription className="mt-2 text-center">
                    {t('login_description')}
                  </PageDescription>
                </div>
              </div>

              <form onSubmit={handleSubmit(submit)} className="mt-8">
                <FormGrid>
                  <FormItem>
                    <InputLabel htmlFor="email">
                      {t('email_address')}
                    </InputLabel>
                    <Input
                      id="email-address"
                      type="email"
                      autoFocus
                      autoComplete="email"
                      required
                      className="rounded-lg"
                      placeholder={t('placeholders.enter_email')}
                      {...register('email', { required: true })}
                    />
                  </FormItem>
                  <div>
                    <Button
                      disabled={!isValid as any}
                      type="submit"
                      variant="primary"
                      className="relative flex justify-center w-full px-3 py-2"
                    >
                      {t('login')}
                    </Button>
                  </div>
                </FormGrid>
              </form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </AuthLayout>
  );
};

export default LoginPage;
