import { AcademicCapIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Modal, ModalBody, ModalContent, ModalDescription, ModalHeader, ModalIcon, ModalTitle } from '@/Modal';
import { Text } from '@/Text';
import { useAction } from '~/hooks/useAction';
import { Organization, User } from '~/types/types';

interface OnboardingModalProps {
  user: User;
  organization: Organization;
  show: boolean;
}

export const OnboardingModal = ({ user, organization, show }: OnboardingModalProps) => {
  const { t } = useTranslation();

  const { post } = useAction(`/b/${organization.id}/users/${user.id}/onboard`, {
    preserveState: false,
  });

  return (
    <Modal open={show} onOpenChange={() => post({ redirect_to_settings: false })}>
      <ModalContent className=" max-w-[600px]">
        <ModalHeader>
          <div className="flex items-center space-x-3">
            <ModalIcon icon={<AcademicCapIcon className="h-7 w-7" />} />
            <div>
              <ModalTitle>
                {t('welcome_to_agstacked_title')}
              </ModalTitle>
              <ModalDescription>
                {t('welcome_to_agstacked_description')}
              </ModalDescription>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <Text className="text-sm whitespace-pre-wrap text-slate-600">
            {t('welcome_to_agstacked_body')}
          </Text>

          <div className="space-x-2">
            <Button variant="primary" onClick={() => post({ redirect_to_settings: true })} className="mt-4">
              {t('go_to_settings')}
            </Button>
            <Button variant="outline" onClick={() => post({ redirect_to_settings: false })}>
              {t('do_later')}
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
