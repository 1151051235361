/* eslint-disable react/prop-types */
import { ErrorBoundary } from '@sentry/react';
import { usePostHog } from 'posthog-js/react';
import { Fragment, ReactNode, useEffect } from 'react';

import { makeTopLinks } from '@/Nav/nav-items';
import { MainNavItem, Navbar } from '@/Nav/Navbar';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarInset,
  SidebarProvider,
  SidebarRail,
} from '@/Nav/Sidebar';
import { UserMenu, UserSelectable } from '@/Nav/UserMenu';
import { DebugProvider, LocalDebugModal } from '~/Components/Common/LocalDebugModal';
import BreakpointIndicator from '~/Components/Common/Tools/BreakpointIndicator';
import { FAB } from '~/Components/FAB';
import { ServerErrorProvider } from '~/hooks/ServerErrorProvider';
import { useAppTitle } from '~/hooks/useAppTitle.js';
import { usePageProps } from '~/hooks/usePageProps';
import { usePageTracking } from '~/hooks/usePageTracking.js';
import { useRoutes } from '~/hooks/useRoutes';
import { OrganizationStatus } from '~/types/types';

import { GeneratingModal } from './GeneratingModal.tsx';
import { OnboardingModal } from './OnboardingModal';

interface DashboardLayoutProps {
  children: ReactNode;
  sideBar?: ReactNode;
}

export const DashboardLayout = ({ children, sideBar }: DashboardLayoutProps) => {
  const posthog = usePostHog();
  const routes = useRoutes();
  const props = usePageProps();
  const {
    user,
    organization,
    show_onboard_modal,
    features,
    app: { path },
  } = props;

  const topLinks = makeTopLinks(organization.id);

  useAppTitle();
  usePageTracking(path);
  useEffect(() => {
    if (!posthog) {
      return console.warn('Posthog is not initialized');
    }
    posthog.identify(user.id);
  }, [posthog, user.id]);

  return (
    <div className="min-h-screen flex flex-col bg-gray-50 [&>*]:w-full pb-12">
      <SidebarProvider defaultOpen={false} style={{
        '--sidebar-width': '26rem',
        '--sidebar-width-mobile': '26rem',
      }}>
        <DebugProvider pageProps={props}>
          <ServerErrorProvider>
            <SidebarInset>
              <LocalDebugModal/>
              <Navbar
                user={user}
                organization={organization}
                renderNavLinks={(
                  <div className="flex gap-3">
                    {topLinks.map((link) => (
                      <Fragment key={link.name}>
                        {link.requiresFeature && features && link.requiresFeature in features && features[link.requiresFeature][0] && (
                          <MainNavItem key={link.name} link={link}/>
                        )}

                        {!link.requiresFeature && (
                          <MainNavItem key={link.name} link={link}/>
                        )}
                      </Fragment>
                    ))}
                  </div>
                )}
              />
              <FAB/>
              <BreakpointIndicator/>
              <OnboardingModal
                user={user}
                organization={organization}
                show={show_onboard_modal ?? false}
              />

              {organization.status === OrganizationStatus.GENERATING && (
                <GeneratingModal/>
              )}
              {organization.status === OrganizationStatus.GENERATING ? (
                <ErrorBoundary
                  onError={() => {
                    console.log('ignore');
                  }}
                  fallback={<div>Generating new data</div>}
                >
                  {children}
                </ErrorBoundary>
              ) : (
                <>{children}</>
              )}
            </SidebarInset>

            {!!sideBar && (
              <Sidebar
                collapsible="offcanvas"
                side="right"
                variant="sidebar"
                className="bg-stone-50"
              >
                {!!sideBar && (
                  <>
                    {sideBar}
                  </>
                )}
              </Sidebar>
            )}
          </ServerErrorProvider>
        </DebugProvider>
      </SidebarProvider>
    </div>
  );
};
