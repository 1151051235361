import { ExoticComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericPicker } from '@/Pickers/GenericPicker';
import { BaseAction } from '~/Actions/BaseAction';
import { useConfirmationModal } from '~/Components/Common/Modals/useConfirmationModal';
import { useCommandAction } from '~/hooks/useCommandAction';
import { PickerItem } from '~/types/types';
import { formatPicker } from '~/utils/formatPicker';

interface CommandAttributePickerProps {
  action: typeof BaseAction;
  target?: any;
  attribute: string;
  options: PickerItem[];
  title: string;
  selected?: any;
  disabled?: boolean;
  isMulti?: boolean;
  emptyMessage?: string;
  placeholder?: string;
  defaultEmptyValue?: any;
  confirmation?: {
    onConfirm?: (value: any) => boolean;
    title?: string;
    description?: string;
    confirmText?: string;
    cancelText?: string;
    renderConfirmation?: (value: any) => ReactNode;
  };
}

/**
 * An attribute picker abstraction that uses a command to update the target.
 *
 * How to use it: use it to set a property on a target and instantly update it using the `action`.
 *
 * @param action
 * @param target
 * @param options
 * @param attribute
 * @param selected
 * @param title
 * @param disabled
 * @param isMulti
 * @param emptyMessage
 * @param placeholder
 * @param defaultEmptyValue
 * @param confirmation
 * @constructor
 */
export const CommandAttributePicker = ({
  action,
  target,
  options,
  attribute,
  selected,
  title,
  disabled,
  isMulti,
  emptyMessage,
  placeholder,
  defaultEmptyValue = null,
  confirmation,
}: CommandAttributePickerProps) => {
  const { t } = useTranslation();
  const [commandInstance, { loading }] = useCommandAction(action, target);

  const formattedOptions = formatPicker(options);

  const getSelected = () => {
    if (selected) {
      return selected;
    }

    if (target && target[attribute]) {
      return target[attribute];
    }

    return null;
  };

  const handleChange = async (selectedValue: any) => {
    const value = selectedValue ?? defaultEmptyValue;

    if (confirmation?.onConfirm && confirmation.onConfirm(value)) {
      confirm(async () => {
        await commandInstance.execute({ [attribute]: value });
      }, value);
    } else {
      await commandInstance.execute({ [attribute]: value });
    }
  };

  const { confirm, ConfirmationModal } = useConfirmationModal({
    title: confirmation?.title || 'Are you sure?',
    description: confirmation?.description || 'This action cannot be undone.',
    confirmText: confirmation?.confirmText || 'Confirm',
    cancelText: confirmation?.cancelText || 'Cancel',
    renderConfirmation: confirmation?.renderConfirmation,
  });

  return (
    <div>
      <ConfirmationModal/>
      <GenericPicker
        disabled={disabled || commandInstance.disabled()}
        icon={commandInstance.getIcon() as ExoticComponent}
        autoFocus
        title={title}
        emptyMessage={emptyMessage ?? t('no_options')}
        placeholder={placeholder ?? t('select_option')}
        options={formattedOptions}
        loading={loading}
        isMulti={isMulti}
        selected={getSelected()}
        onChange={handleChange}
        showClear={true}
      />
    </div>
  );
};
