import * as z from 'zod';

import { DatePeriod } from '~/types/global-types';

export const dateRangeTypes: DatePeriod[] = [
  'CUSTOM',
  'DAILY',
  'WEEKLY',
  'MONTHLY',
  'QUARTERLY',
  'YEARLY',
];

/**
 * Validates a date-range object. Used for RangeDatePicker.
 */
export const dateValidation = z.object({
  // Type is one of the following: CUSTOM, WEEKLY, MONTHLY, QUARTERLY, YEARLY
  type: z.string().refine((val) => dateRangeTypes.includes(val as DatePeriod)),
  // From and to are only required if type is CUSTOM; furthermore, `from` must be before `to`
  from: z.string().nullable().optional(),
  to: z.string().nullable().optional(),
});
