import { ExoticComponent } from 'react';

import { BuyerRoutes, SellerRoutes } from '~/hooks/useRoutes';
import { Organization, User } from '~/types/types';

export type ActionPost = (url: string, data: any) => Promise<any>;

export type ActionAppType = 'buyer' | 'seller' | 'shared';

export interface ActionContext<Target = any> {
  routes: BuyerRoutes | SellerRoutes;
  organization: Organization;
  user: User;
  target: Target;
}

export interface ActionToast {
  titleKey: string;
  descriptionKey?: string;
}

export class BaseAction {
  public static icon: ExoticComponent | null = null;
  public static analyticsName = '';
  public static type: ActionAppType = 'buyer';
  public static toast: ActionToast | null = null;

  constructor(public context: ActionContext, public post: ActionPost) {
  }

  public getAnalyticsName(): string {
    return this.constructor.analyticsName;
  }

  public getToast(): any {
    return this.constructor.toast;
  }

  public getType(): ActionAppType {
    // @ts-ignore
    return this.constructor.type;
  }

  public getIcon(): ExoticComponent | null {
    // @ts-ignore
    return this.constructor.icon;
  }

  public nameKey(): string {
    return '';
  }

  public descriptionKey(): string {
    return '';
  }

  /**
   * Sometimes, an action should be disabled based on where it is called (rather than using the target).
   * @param params
   */
  public disabled(params: any | undefined | null = undefined): boolean {
    return false;
  }

  public async execute(userInput: any): Promise<void> {
    return;
  }
}
