import React from 'react';

import { MutedText, Text } from '@/Text';

interface LegendProps {
  items: {
    id: string;
    color: string;
    title: string;
  }[];
}

export const Legend = ({ items }: LegendProps) => {
  return (
    <div className="flex gap-3 items-center flex-wrap">
      {items.map((item) => (
        <div key={item.id} className="flex items-center">
          <div
            className="w-2 h-2 mr-2 rounded"
            style={{ backgroundColor: item.color }}
          />
          <MutedText>
            {item.title}
          </MutedText>
        </div>
      ))}
    </div>
  );
};
