import { BooleanFieldIcon, MeasurementFieldIcon, PercentageIcon, RatioIcon } from '@/Icon';
import { PickerIcon } from '@/Pickers/PickerIcon';
import { Field, FieldType } from '~/types/types';

export interface FieldIconProps {
  field: Field;
}

/**
 * FieldIcon
 */
export const FieldIcon = ({ field }: FieldIconProps) => {
  switch (field.type as FieldType) {
  case 'measurement': {
    return (
      <PickerIcon as={MeasurementFieldIcon}/>
    );
  }
  case 'percentage': {
    return (
      <PickerIcon as={PercentageIcon}/>
    );
  }
  case 'ratio': {
    return (
      <PickerIcon as={RatioIcon}/>
    );
  }
  default: {
    return (
      <PickerIcon as={BooleanFieldIcon}/>
    );
  }
  }
};
