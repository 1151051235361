import { motion } from 'framer-motion';
import { useState } from 'react';

import { Brand, NumericIndicator } from '@/NumericIndicator';

import styles from './TabFilter.module.css';

interface TabItem {
  label: string;
  number?: number;
  brand?: Brand;
}

interface TabsProps {
  initialIndex?: number;
  onChange: (index: number) => void;
  items: Array<string> | TabItem[];
}

const getLabel = (item: string | TabItem) => {
  return typeof item === 'string' ? item : item.label;
};

const getBrand = (item: string | TabItem) => {
  return typeof item === 'string' ? 'gray' : item.brand ?? 'gray';
};

const getNumber = (item: string | TabItem) => {
  return typeof item === 'string' ? undefined : item.number;
};

/**
 * A visual switcher for "shifting" between elements.
 *
 * @example
 * <TabFilter
 *  initialIndex={0}
 *  onChange={(ind  xex) => console.log(index)}
 *  items={['All', 'Active', 'Inactive']}
 * />
 */
const TabFilter = ({ items, onChange, initialIndex }: TabsProps) => {
  const [activeIndex, setActiveIndex] = useState(initialIndex ?? 0);

  const handleChange = (index: number) => {
    setActiveIndex(index);
    onChange(index);
  };

  return (
    <div className="relative overflow-x-auto">
      <ol className={styles.list}>
        {items.map((item, i) => {
          const isActive = i === activeIndex;
          return (
            <motion.li
              className={
                isActive || i === activeIndex - 1
                  ? styles.itemNoDivider
                  : styles.item
              }
              whileTap={isActive ? { scale: 0.95 } : { opacity: 0.6 }}
              key={getLabel(item)}
            >
              <button
                onClick={() => handleChange(i)}
                type="button"
                className={styles.button}
              >
                {isActive && (
                  <motion.div
                    layoutId="SegmentedControlActive"
                    className={styles.active}
                  />
                )}
                <span className={styles.label}>
                  {getLabel(item)}
                  {!!getNumber(item) && (
                    <span
                      className="ml-2"
                      aria-label={`number of items for ${isActive ? 'active' : 'inactive'} ${getLabel(items[i])}`}
                    >
                      <NumericIndicator brand={getBrand(item)} size="sm" withPadding>
                        {getNumber(item)}
                      </NumericIndicator>
                    </span>
                  )}
                </span>
              </button>
            </motion.li>
          );
        })}
      </ol>
    </div>
  );
};

export default TabFilter;
