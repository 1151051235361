import React, { Fragment, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Card, CardBody, CardFooter, CardHeaderContainer } from '@/Card';
import { CardSubtitle, CardTitle, MutedText, SectionDescription, SectionTitle, Strong, Text, TextLabel } from '@/Text';
import { colorMap } from '~/Components/Analytics/colorMap';
import { CountryName } from '~/Components/Locations/CountryName';
import { useFormat } from '~/hooks/useFormat';
import { TooltipProps } from '~/types/global-types';
import { axisClass, calcCeiling, getChartNames, neutralAxisColor } from '~/utils/charts';

export interface LineCardProps {
  children: ReactNode;
  data: any[];
  title?: string;
  description?: string;
  bestKey?: string;
  bestIndex?: number;
  unit?: string;
}

type Payload = any;

export type ForecastAccuracyTooltipProps = TooltipProps<Payload>;

export const ForecastAccuracyTooltip = ({
  payload,
  label,
  sellerTitleMap,
}: ForecastAccuracyTooltipProps) => {
  const { formatPercentage } = useFormat();
  const { t } = useTranslation();

  return (
    <Card className="min-w-[250px]">
      <CardHeaderContainer className="!py-2 bg-gray-50" size="sm">
        <Strong className={'!text-gray-600'}>
          {label}
        </Strong>
      </CardHeaderContainer>
      <CardBody size="sm">
        <div className="divide-y">
          {payload?.map((entry, index) => (
            <div
              key={`item-${index}`}
              className="flex items-center justify-between py-1 space-x-4"
            >
              <div className="flex gap-2 items-center">
                {entry.name in sellerTitleMap ? (
                  <div
                    className="w-2 h-2 rounded"
                    style={{ backgroundColor: sellerTitleMap[entry.name].color }}
                  />
                ) : (
                  <div/>
                )}
                <MutedText>{entry.name}</MutedText>
              </div>
              <div className="flex items-center">
                <MutedText>{formatPercentage(entry.value)}</MutedText>
              </div>
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

/**
 * Component for the line card.
 */
export const LineCard = ({ data, bestKey, title, description, unit }: LineCardProps) => {
  const { t } = useTranslation();

  const chartKeys = getChartNames(data, ['name']);
  const maxY = calcCeiling(data);

  const chosenIndex = chartKeys.indexOf(bestKey);

  return (
    <Card>
      <CardBody>
        <CardTitle>
          {title}
        </CardTitle>
        <CardSubtitle>
          {description}
        </CardSubtitle>
      </CardBody>
      <CardBody size="sm" className="h-[350px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 10,
              right: 5,
              left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false}/>
            <XAxis
              dataKey="name"
              stroke={neutralAxisColor}
              tick={(props) => {
                const { index } = props;

                if (index % 2 === 0) {
                  return (
                    <g transform={`translate(${props.x},${props.y})`}>
                      <text x={0} y={0} dy={16} className={axisClass} textAnchor="middle" transform="rotate(0)"
                        fontSize={14}>
                        {props.payload.value}
                      </text>
                    </g>
                  );
                }

                return null;
              }}
            />
            <YAxis
              domain={[0, maxY]}
              stroke={neutralAxisColor}
              tick={(props) => {
                return (
                  <text {...props} dx={-12} className={axisClass} fontSize={14}>
                    {unit ? ` ${unit}` : ''}
                    {props.payload.value}
                  </text>
                );
              }}
            />

            {/*<Tooltip content={ForecastAccuracyTooltip as any}/>*/}

            {chartKeys.map((key, index) => (
              <Fragment key={index}>
                {index === chosenIndex ? (
                  <Line
                    type="monotone"
                    opacity={1}
                    dataKey={key}
                    stroke={colorMap[index]}
                    fill={colorMap[index]}
                    dot={{
                      strokeWidth: 2,
                      fill: 'white',
                      r: 4,
                      opacity: 1,
                      filter: `drop-shadow(0px 0px 1px ${colorMap[index]}`,
                    }}
                  />
                ) : (
                  <Line
                    type="monotone"
                    opacity={0.1}
                    dataKey={key}
                    stroke={colorMap[index]}
                    fill={colorMap[index]}
                  />
                )}
              </Fragment>
            ))}
          </LineChart>
        </ResponsiveContainer>
      </CardBody>
      <CardFooter>
        <div className="flex space-x-4">
          {chartKeys.map((key, index) => (
            <div key={key} className="flex items-center">
              <div
                className="w-3 h-3 mr-2"
                style={{ backgroundColor: colorMap[index] }}
              />
              <TextLabel>
                <CountryName countryCode={key.toUpperCase()}/>
              </TextLabel>
            </div>
          ))}
        </div>
      </CardFooter>
    </Card>
  );
};
