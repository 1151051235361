import { format } from 'date-fns';
import { useMemo } from 'react';

import { RangeDatePicker } from '@/DatePicker/RangeDatePicker';
import { GranularityPicker } from '~/Components/Dates/GranularityPicker';
import { DateRange } from '~/types/global-types';
import { DateFilter } from '~/types/types';
import PeriodGranularity = App.Domain.Dates.PeriodGranularity;
import { useTranslation } from 'react-i18next';

interface DateFilterPickerProps {
  value?: DateFilter;
  onChange?: (value: Partial<DateFilter>) => void;
  enableGranularity?: boolean;
  requiresSubmit?: boolean;
  loading?: boolean;
}

/**
 * DateFilterPicker is a component that takes in a DateFilter, and spews out a DateFilter.
 *
 * How to use it:
 * - It receives a DateFilter value DTO, transforms it into a DateRange (for the RangePicker) and a PeriodGranularity (for the GranularityPicker if enabled).
 * - If the RangePicker value changes, it updates the DateFilter value with `inputFromDate`, `inputToDate` and `period`. Note: the `fromDate` and `toDate` are backend readonly values (derived based on period and inputFromDate/inputToDate).
 * - If the GranularityPicker value changes, it updates the DateFilter value with `granularity`.
 */
export const DateFilterPicker = ({
  value,
  onChange,
  enableGranularity,
  requiresSubmit,
  loading,
}: DateFilterPickerProps) => {
  const { t } = useTranslation();
  const formatDateWithTimezone = (date: Date | undefined): string | undefined => {
    if (!date) return undefined;

    // Get the local timezone offset
    const tzOffset = -date.getTimezoneOffset();
    const absOffset = Math.abs(tzOffset);
    const tzString =
      (tzOffset > 0 ? '+' : '-') +
      String(Math.floor(absOffset / 60)).padStart(2, '0') + ':' +
      String(absOffset % 60).padStart(2, '0');

    // Format the date with the timezone offset
    return format(date, 'yyyy-MM-dd\'T\'HH:mm:ss') + tzString;
  };

  const handleRangePickerChange = (newValue: DateRange) => {
    // If new value is set to null, clear the filter
    if (!newValue) {
      onChange?.({
        ...value,
        inputFromDate: undefined,
        inputToDate: undefined,
        period: null,
      });

      return;
    }

    // If from and to dates are set, we rely on them (instead of the period)
    if (newValue.from && newValue.to) {
      onChange?.({
        ...value,
        inputFromDate: formatDateWithTimezone(newValue.from),
        inputToDate: formatDateWithTimezone(newValue.to),
        period: null,
      });
    }

    // If period is set, we rely on it (instead of the from and to dates)
    if (newValue.period) {
      onChange?.({
        ...value,
        inputFromDate: undefined,
        inputToDate: undefined,
        period: newValue.period,
      });
    }
  };

  // We set the value of the RangeDatePicker as follows:
  // we check if there is an inputFromDate (which is the "setter date"; if not, check if there is a readonly date (fromDate)).
  // do the same for the period.
  const controlledRangeValue: DateRange = useMemo<DateRange>(() => {
    if (value?.period) {
      return {
        from: undefined,
        to: undefined,
        period: value.period,
      };
    }

    if (value?.inputFromDate && value?.inputToDate) {
      return {
        from: new Date(value.inputFromDate),
        to: new Date(value.inputToDate),
        period: undefined,
      };
    }

    if (value?.fromDate && value?.toDate) {
      return {
        from: new Date(value.fromDate),
        to: new Date(value.toDate),
        period: undefined,
      };
    }

    return {
      from: undefined,
      to: undefined,
      period: undefined,
    };
  }, [value]);

  const controlledGranularityValue = useMemo(() => {
    return value?.granularity ?? undefined;
  }, [value]);

  const handleGranularityChange = (newValue: PeriodGranularity) => {
    onChange?.({
      ...value,
      granularity: newValue,
    });
  };

  return (
    <div className="flex gap-4 items-center">
      <div>
        <RangeDatePicker
          enablePeriod
          value={controlledRangeValue}
          requireSubmit={requiresSubmit}
          loading={loading}
          onChange={handleRangePickerChange}
        />
      </div>

      <div>
        {enableGranularity && (
          <GranularityPicker
            loading={loading}
            title={t('time_grouping') as string}
            value={controlledGranularityValue}
            onChange={handleGranularityChange}
          />
        )}
      </div>
    </div>
  );
};
