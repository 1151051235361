import { useEffect } from "react";
import { usePageProps } from "./usePageProps";

export const useAppTitle = () => {
  const { user } = usePageProps();
  useEffect(() => {
    const oldTitleWithoutNotifications = document.title
      .split(") ")
      .pop() as string;
    if (!user.unread_notifications || user.unread_notifications?.length === 0) {
      document.title = oldTitleWithoutNotifications;
      return;
    }
    document.title = `(${user.unread_notifications.length}) ${oldTitleWithoutNotifications}`;
  }, [user.unread_notifications]);
};
