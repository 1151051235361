import * as SliderPrimitive from '@radix-ui/react-slider';
import { forwardRef, useEffect, useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import { cn } from '~/utils/cn';

import { Text } from './Text';

type ColorVariant = 'emerald' | 'blue' | 'red' | 'orange' | 'slate';
type Direction = 'ltr' | 'rtl';

interface SliderProps extends Partial<ControllerRenderProps> {
  min?: number;
  max?: number;
  step?: number;
  ariaLabel?: string;
  type?: 'single' | 'range';
  width?: number;
  showThumbValue?: boolean;
  defaultValue?: number[];
  onChange?: (value: number[]) => void;
  value?: number | number[];
  unit?: string;
  color?: ColorVariant;
  direction?: Direction;
  upperThreshold?: number; // Optional upper threshold
  onThresholdExceeded?: () => void; // Callback when threshold is exceeded
}

const colorVariants = {
  emerald: {
    thumb: 'bg-emerald-600 focus:outline-emerald-400',
    range: 'bg-emerald-500',
  },
  blue: {
    thumb: 'bg-blue-600 focus:outline-blue-400',
    range: 'bg-blue-500',
  },
  red: {
    thumb: 'bg-red-600 focus:outline-red-400',
    range: 'bg-red-500',
  },
  orange: {
    thumb: 'bg-orange-600 focus:outline-orange-400',
    range: 'bg-orange-500',
  },
  slate: {
    thumb: 'bg-slate-600 focus:outline-slate-400',
    range: 'bg-slate-500',
  },
};

const Thumb = ({ value, showThumbValue, unit = '%', color = 'blue' }: ThumbProps) => {
  return (
    <SliderPrimitive.Thumb
      className={cn(
        'relative flex h-5 w-5 cursor-pointer flex-col items-center justify-center rounded-full focus:outline-none focus:outline-2',
        colorVariants[color].thumb
      )}
    >
      {showThumbValue ? (
        <Text className="absolute -bottom-6">
          {value}
          {unit}
        </Text>
      ) : null}
    </SliderPrimitive.Thumb>
  );
};

export const Slider = forwardRef<HTMLSpanElement, SliderProps>(
  (
    {
      type = 'single',
      min = 0,
      max = 100,
      step = 1,
      defaultValue = [50],
      ariaLabel = 'Slider',
      width,
      showThumbValue = false,
      onChange,
      value: controlledValue,
      unit,
      color = 'blue',
      direction = 'ltr',
      upperThreshold,
      onThresholdExceeded,
      ...props
    },
    ref
  ) => {
    const normalizeValue = (val: number | number[] | undefined): number[] => {
      if (Array.isArray(val)) return val;
      if (typeof val === 'number') return [val];
      return defaultValue;
    };

    // Mirror the value for RTL display
    const mirrorValue = (val: number): number => {
      if (direction === 'rtl') {
        return max - (val - min);
      }
      return val;
    };

    // Unmirror the value for actual state
    const unmirrorValue = (val: number): number => {
      if (direction === 'rtl') {
        return max - (val - min);
      }
      return val;
    };

    const [internalValue, setInternalValue] = useState(
      normalizeValue(defaultValue).map(mirrorValue)
    );

    const value = normalizeValue(controlledValue ?? internalValue).map(mirrorValue);

    const handleValueChange = (newValue: number[]) => {
      const unmirroredValues = newValue.map(unmirrorValue);

      // Check upper threshold if defined
      if (upperThreshold !== undefined) {
        const exceedsThreshold = unmirroredValues.some(val => val > upperThreshold);
        if (exceedsThreshold) {
          onThresholdExceeded?.();
          // Clamp values to threshold
          unmirroredValues.forEach((val, index) => {
            if (val > upperThreshold) {
              unmirroredValues[index] = upperThreshold;
            }
          });
        }
      }

      setInternalValue(unmirroredValues.map(mirrorValue));

      const outputValue = type === 'single' ? unmirroredValues[0] : unmirroredValues;
      onChange?.(unmirroredValues);
      props.onChange?.(outputValue);
    };

    useEffect(() => {
      if (controlledValue !== undefined) {
        setInternalValue(normalizeValue(controlledValue).map(mirrorValue));
      }
    }, [controlledValue]);

    return (
      <SliderPrimitive.Root
        ref={ref}
        dir={direction}
        className={cn(
          'relative flex h-5 touch-none select-none items-center',
          width ? `w-[${width}px]` : 'w-full'
        )}
        value={value}
        min={min}
        max={max}
        step={step}
        minStepsBetweenThumbs={1}
        aria-label={ariaLabel}
        onValueChange={handleValueChange}
      >
        <SliderPrimitive.Track
          className="relative h-[5px] grow rounded-full bg-gray-200"
        >
          <SliderPrimitive.Range
            className={cn(
              'absolute h-full rounded-full',
              colorVariants[color].range
            )}
          />
        </SliderPrimitive.Track>

        <Thumb
          unit={unit}
          showThumbValue={showThumbValue}
          value={unmirrorValue(value[0])}
          color={color}
        />

        {type === 'range' ? (
          <Thumb
            unit={unit}
            showThumbValue={showThumbValue}
            value={unmirrorValue(value[1])}
            color={color}
          />
        ) : null}

        {upperThreshold && (
          <div
            className={cn(
              'absolute w-0.5 h-7 -top-1',
              colorVariants[color].range,
              direction === 'rtl'
                ? 'border-l border-l-white'
                : 'border-r border-r-white'
            )}
            style={{
              [direction === 'rtl' ? 'right' : 'left']: `${(upperThreshold / max) * 100}%`,
            }}
          />
        )}
      </SliderPrimitive.Root>
    );
  }
);

Slider.displayName = 'Slider';
