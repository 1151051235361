/**
 * TextEditor component. This is a wrapper around a textarea, which has a small icon-button for approval and cancellation.
 *
 * Inspired by Notion's visuals.
 */
import { CheckboxIcon, Cross2Icon } from "@radix-ui/react-icons";
import { useForm } from "react-hook-form";

import { IconButton } from "@/Button";

interface TextEditorProps {
  value: string;
  onSubmit: ({ value }: { value: string }) => void;
  onCancel?: () => void;
  controlled?: boolean;
  placeholder?: string;
}

export const TextEditor = ({
  value,
  onSubmit,
  onCancel,
  controlled,
  placeholder,
}: TextEditorProps) => {
  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      value: value,
    },
  });

  const watchValue = watch("value");

  const handleControlledValueChange = () => {
    onSubmit({ value: watchValue });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="border bor  der-1 border-gray-500 focus-within:ring-1 focus-within:ring-blue-500 focus-within:border-blue-500 rounded-md">
        <textarea
          placeholder={placeholder}
          autoFocus
          {...register("value")}
          className="min-h-[50px] w-full p-2 focus:outline-none border-none resize-none text-xs"
        />
        <div className="flex justify-end p-2">
          <IconButton label="Cancel" onClick={onCancel}>
            <Cross2Icon />
          </IconButton>
          <IconButton label="Submit" type={controlled ? "button" : "submit"}>
            <CheckboxIcon
              onClick={
                controlled ? () => handleControlledValueChange() : undefined
              }
            />
          </IconButton>
        </div>
      </div>
    </form>
  );
};
