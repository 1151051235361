import { Alert, AlertDescription, AlertFooter, AlertTitle } from '@/Alert';
import { Button } from '@/Button';

type Level = 'info' | 'warning' | 'error';

export interface Recommendation {
  title: string;
  description: string;
  confidence: number;
  level: Level;
}

interface RecommendationPromptProps {
  recommendation: Recommendation;
}

export const RecommendationPrompt = ({ recommendation }: RecommendationPromptProps) => {
  return (
    <Alert variant="ai">
      <AlertTitle className="text-sm">
        {recommendation.title}
      </AlertTitle>

      <AlertDescription className="text-xs">
        {recommendation.description}
      </AlertDescription>

      <AlertFooter>
        <div className="space-x-1">
          <Button size="xs" variant="white">
            View details
          </Button>
        </div>
      </AlertFooter>
    </Alert>
  );
};
