export const FormatCount = ({ count, unitType }: { count?: number, unitType?: string }) => {
  if (!count || !unitType) {
    return null;
  }

  return (
    <div>
      {count} {unitType}
    </div>
  );
};
