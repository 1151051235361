import {
  ChatBubbleLeftEllipsisIcon,
  CheckBadgeIcon,
} from '@heroicons/react/20/solid';
import {
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from '@radix-ui/react-popover';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Icon } from '@/Icon';
import { PopoverRoot } from '@/Popover';
import { TextEditor } from '@/TextEditor';

interface AddFieldNoteProps {
  value: string;
  onChange: (value: string) => void;
  ariaLabel?: string;
}

export const AddFieldNote = ({
  value,
  onChange,
  ariaLabel,
}: AddFieldNoteProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <PopoverRoot
      open={isOpen}
      onOpenChange={(open: boolean) => setIsOpen(open)}
    >
      <PopoverTrigger asChild>
        <Button
          type="button"
          className="relative items-center justify-center"
          onClick={() => setIsOpen(!isOpen)}
          variant="white"
          size="xs"
          aria-label={ariaLabel}
        >
          Comment
          {value && (
            <div className="absolute w-3 h-3 text-blue-600 bottom-1 right-1">
              <CheckBadgeIcon/>
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent
          side="bottom"
          collisionPadding={10}
          sideOffset={5}
          className="flex flex-col sm:data-[side=top]:max-h-[var(--radix-popover-content-available-height)] overflow-y-auto p-4 sm:rounded shadow-xl bg-white min-w-[300px] w-full sm:h-auto sm:max-w-sm"
          align="start"
        >
          <CommentsPopover
            value={value || ''}
            onChange={(newValue) => onChange(newValue)}
            onClose={() => setIsOpen(false)}
          />
        </PopoverContent>
      </PopoverPortal>
    </PopoverRoot>
  );
};

interface CommentsPopoverProps {
  value: string;
  onClose: () => void;
  onChange: (value: string) => void;
}

const CommentsPopover = ({
  value,
  onClose,
  onChange,
}: CommentsPopoverProps) => {
  const { t } = useTranslation();
  const onSubmit = (newValue: string) => {
    onChange(newValue);
    onClose();
  };

  return (
    <div className="flex flex-1 flex-col gap-2 overflow-auto">
      <div className="flex flex-col gap-2">
        <TextEditor
          placeholder={t('add_a_note') as string}
          onCancel={() => onClose()}
          onSubmit={({ value }) => onSubmit(value)}
          value={value}
          controlled
        />
      </div>
    </div>
  );
};
