/**
 * Color map with readable and beautiful tailwind-based colors
 */
export const colorMap = [
  '#5A68F1',
  '#FF8B00',
  '#21B252',
  '#EF7B61',
  '#98DFD7',
  '#379395',
  '#F97316',
  '#6366F1',
  '#4ADE80',
  '#FCD34D',
  '#F87171',
  '#60A5FA',
  '#A78BFA',
  '#F472B6',
  '#FBBF24',
];
