import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DateGranularityIcon } from '@/Icon';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { formatPicker } from '~/utils/formatPicker';
import PeriodGranularity = App.Domain.Dates.PeriodGranularity;

interface GranularityPickerProps {
  value?: PeriodGranularity;
  onChange?: (value: PeriodGranularity) => void;
  title?: string;
  loading?: boolean;
}

/**
 * The GranularityPicker component.
 *
 * How it works:
 * - Receive a PeriodGranularity value and calls the onChange function when a new value is selected.
 *
 * How a picker works:
 * - Receives a { value } which is compared with the list of `value` in `options`.
 * - When one of the options is selected, the `onChange` function is called with that selected value.
 *
 * @param title
 * @param value
 * @param onChange
 * @param loading
 * @constructor
 */
export const GranularityPicker = ({ title, value, onChange, loading }: GranularityPickerProps) => {
  const { t } = useTranslation();

  const defaultOptions = useMemo(() => formatPicker([
    {
      label: t('granularity.daily') as string,
      subLabel: t('granularity.daily_comparison') as string,
      value: 'day',
      count: undefined,
      group: undefined,
      imageUrl: undefined,
      additionalLabel: undefined,
    },
    {
      label: t('granularity.weekly') as string,
      subLabel: t('granularity.weekly_comparison') as string,
      value: 'week',
      count: undefined,
      group: undefined,
      imageUrl: undefined,
      additionalLabel: undefined,
    },
    {
      label: t('granularity.monthly') as string,
      subLabel: t('granularity.monthly_comparison') as string,
      value: 'month',
      count: undefined,
      group: undefined,
      imageUrl: undefined,
      additionalLabel: undefined,
    },
    {
      label: t('granularity.quarterly') as string,
      subLabel: t('granularity.quarterly_comparison') as string,
      value: 'quarter',
      count: undefined,
      group: undefined,
      imageUrl: undefined,
      additionalLabel: undefined,
    },
    {
      label: t('granularity.yearly') as string,
      subLabel: t('granularity.yearly_comparison') as string,
      value: 'year',
      count: undefined,
      group: undefined,
      imageUrl: undefined,
      additionalLabel: undefined,
    },
  ]), [t]);

  return (
    <GenericPicker
      nullOption={{
        label: 'Automatic',
        subLabel: 'Automatically decide the granularity of report based on date range',
        value: 'null',
      }}
      value={value}
      onChange={onChange as any}
      isMulti={false}
      title={title as string}
      disableSearch
      icon={DateGranularityIcon}
      loading={loading}
      options={defaultOptions}
      emptyMessage={t('granularity.empty')}
      placeholder={t('granularity.select')}
    />
  );
};
