import { ProgressBar } from "@tremor/react";

import { MutedText } from "@/Text";
import { SellerSummary } from "~/types/types";

interface PerformanceScoreBarProps {
  sellerSummary: SellerSummary;
}

export const PerformanceScoreBar = ({
  sellerSummary,
}: PerformanceScoreBarProps) => {
  const performanceScore = sellerSummary.performance_score ?? 0;

  // If performance score is greater than 80, then green; if it is below 40, then red; otherwise, orange

  const color =
    performanceScore > 80 ? "green" : performanceScore < 40 ? "red" : "orange";

  return (
    <div className="flex flex-col align-end">
      <div className="flex justify-end">
        <MutedText>
          {performanceScore.toFixed(2)} {"%"}
        </MutedText>
      </div>
      <div className="hidden sm:revert self-end">
        <ProgressBar
          value={performanceScore}
          className="w-[100px]"
          color={color}
        />
      </div>
    </div>
  );
};
