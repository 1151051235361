import { ChevronRightIcon, ClockIcon } from '@radix-ui/react-icons';
import { cva } from 'class-variance-authority';

import { CalendarIcon, CheckIcon, LabelIcon, PendingIcon } from '@/Icon';
import { Label, MultiLabel } from '@/Label';
import { useDate } from '~/hooks/useDate';
import { useFormat } from '~/hooks/useFormat';
import { Field, FieldConstraint } from '~/types/types';
import { cn } from '~/utils/cn';
import { extractActiveConstraint } from '~/utils/constraints';
import ConstraintType = App.Domain.Specs.ConstraintType;
import { ButtonGutter } from '@/Button';
import { logWarning } from '~/utils/logger';

interface ConstraintsLabelProps {
  constraint: FieldConstraint;
  field: Field;
  fractionToPercentage?: boolean;
  compact?: boolean;
  renderOnlyActive?: boolean;
}

const ragVariants = cva(
  'h-2 w-2 rounded-full',
  {
    variants: {
      score: {
        red: 'bg-red-400',
        green: 'bg-green-400',
        amber: 'bg-orange-400',
      },
      defaultVariants: {
        variant: 'green',
      },
    },
  }
);

const RAGLabel = ({ constraint, compact }: ConstraintsLabelProps) => {
  const { formatPercentage } = useFormat();

  const decimals = compact ? 0 : 2;

  const formatValue = (value: number | null) => {
    if (value === null) return '∞';
    return formatPercentage(value, decimals);
  };

  const sortedRanges = constraint.ranges?.sort((a, b) => (a.min ?? 0) - (b.min ?? 0)) ?? [];

  return (
    <div className="flex flex-wrap gap-1" aria-label={'rag range'}>
      {sortedRanges.map((range) => (
        <div key={range.id} aria-label={`rag range for ${range.score}`}>
          <Label key={range.id} className="whitespace-nowrap" size="xs">
            <div className="flex items-center gap-1 mr-1 text-xxs">
              <div className={ragVariants({ score: range.score as any })}/>
              {formatValue(range.min)} - {formatValue(range.max)}
            </div>
          </Label>
        </div>
      ))}
    </div>
  );
};

const RatioLabel = ({ constraint }: ConstraintsLabelProps) => {
  const range = constraint.ranges?.[0];
  if (!range) return null;

  return (
    <div className="space-y-1" aria-label='general range'>

      {(range.min !== null && range.max !== null) && (
        <div className="flex items-center gap-2">
          {/* Include +/- after ratio */}
          <MultiLabel theme="gray" label="Ratio ± 0.2">
            {(range.min).toFixed(1)}:{(range.max).toFixed(1)}
          </MultiLabel>
        </div>
      )}
    </div>
  );
};

const RangeLabel = ({ constraint, field }: ConstraintsLabelProps) => {
  const formatValue = (value: number | null) => {
    if (value === null) return '∞';

    return `${value} ${field.unit}`;
  };

  const range = constraint.ranges?.[0];
  if (!range) return null;

  const min = formatValue(range.min);
  const max = formatValue(range.max);

  if (range.min === null && range.max === null) {
    return <span>Any value</span>;
  } else if (range.min === null) {
    return <span>≤ {max}</span>;
  } else if (range.max === null) {
    return <span>≥ {min}</span>;
  } else {
    return <span>{min} - {max}</span>;
  }
};

/**
 * ConstraintsLabel
 *
 * A label used for displaying constraints on a field.
 *
 * @abstraction L3: Used to render all variants of a Label
 *
 * @precondition - The constraint has been pre-processed (e.g. fractions have been converted to percentages).
 * @param constraint
 * @param field
 * @param fractionToPercentage
 * @param compact
 * @param renderOnlyActive
 * @constructor
 */
export const ConstraintsLabel = ({
  constraint,
  field,
  fractionToPercentage = false,
  compact = false,
  renderOnlyActive,
}: ConstraintsLabelProps) => {
  const { activeConstraint, hasActiveNestedConstraint } = extractActiveConstraint(constraint);
  if (!activeConstraint) {
    logWarning('No active constraint found');
    return null;
  }

  const constraintType: ConstraintType = activeConstraint.type;

  const renderOverride = hasActiveNestedConstraint && !renderOnlyActive;

  // Render `RAG` label by itself
  if (constraintType === 'rag' && !renderOverride) {
    return (
      <RAGLabel
        constraint={activeConstraint}
        field={field}
        fractionToPercentage={fractionToPercentage}
        compact={compact}
      />
    );
  }

  // Render `RAG` label along with the original
  if (constraintType === 'rag' && renderOverride) {
    return (
      <div>
        <div className="opacity-30">
          <RAGLabel
            constraint={constraint}
            field={field}
            compact={compact}
          />
        </div>
        <div>
          <RAGLabel
            constraint={activeConstraint}
            field={field}
            compact={compact}
          />
        </div>
      </div>
    );
  }

  // Render `RAG` label along with a new t

  if (constraintType === 'range' && !renderOverride) {
    return (
      <Label aria-label={'general range'}>
        <RangeLabel
          constraint={constraint}
          field={field}
        />
      </Label>
    );
  }

  if (constraintType === 'range' && renderOverride) {
    return (
      <div>
        <Label aria-label={'general range'}>
          <RangeLabel
            constraint={constraint}
            field={field}
          />
        </Label>
        <Label aria-label={'active range'}>
          <RangeLabel
            constraint={activeConstraint}
            field={field}
          />
        </Label>
      </div>
    );
  }

  // Add ratio handling
  if (constraintType === 'ratio') {
    if (!renderOverride) {
      return (
        <RatioLabel
          constraint={activeConstraint}
          field={field}
          compact={compact}
        />
      );
    }

    return (
      <div className="space-y-2">
        <div className="opacity-30">
          <RatioLabel
            constraint={constraint}
            field={field}
            compact={compact}
          />
        </div>
        <RatioLabel
          constraint={activeConstraint}
          field={field}
          compact={compact}
        />
      </div>
    );
  }

  return null;
};
