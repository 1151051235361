import { DatePeriod } from '~/types/global-types';
import { SavedReport } from '~/types/types';

import { DeprectaedReportType, OrganizationFilterType } from '../types';

export const reportTypes: DeprectaedReportType[] = [
  'FORECAST_ACCURACY',
  'QUALITY',
  'QUALITY_COSTS',
];

export const organizationTypes: OrganizationFilterType[] = [
  'ORGANIZATIONS',
  'REGIONS',
];

export const dateRangeTypes: DatePeriod[] = [
  'CUSTOM',
  'DAILY',
  'WEEKLY',
  'MONTHLY',
  'QUARTERLY',
  'YEARLY',
];

export interface CreateReportFormProps {
  onSubmit: () => void;
  data?: SavedReport;
  isEditing?: boolean;
}

export type ModeKey = 'organizations' | 'regions';
