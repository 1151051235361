import { Link } from '@inertiajs/react';
import React from 'react';

import { StandoutBorder } from '@/Borders';
import { LabeledField } from '@/LabeledField';
import { MutedText, Strong } from '@/Text';
import { useFormat } from '~/hooks/useFormat';
import { useRoutes } from '~/hooks/useRoutes';
import { Order } from '~/types/types';
import { cn } from '~/utils/cn';

import { FormatStringList } from '../../../pages/Dashboard/Orders/FormatStringList';

interface OrderPreviewCardProps {
  order: Order;
  compact?: boolean;
}

interface OrderImageProps {
  order: Order;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

/**
 * Represents the order image appropriately
 *
 * @preconditions - Ensure that the order has preloaded order_produces.produce
 *                - Ensure that the parent is a flex container
 *
 */
export const OrderImage = ({ order, size = 'xs' }: OrderImageProps) => {
  const produce = order.order_produces?.[0].produce;

  return (
    <>
      {produce?.image_url && (
        <StandoutBorder className="mr-2">
          <div className={cn(
            size === 'xs' && 'w-4 h-4',
            size === 'sm' && 'w-6 h-6',
            size === 'md' && 'w-8 h-8',
            size === 'lg' && 'w-12 h-12'
          )}>
            <img
              src={produce.image_url}
              alt={produce.name}
              className={cn('object-cover rounded-full w-full h-full',
              )}
            />
          </div>
        </StandoutBorder>
      )}
    </>
  );
};

/**
 * Represents the order title
 *
 * @preconditions - Ensure that the order has preloaded order_produces.produce
 *
 */
export const OrderTitle = ({ order }: { order: Order }) => {
  const uniqueProduces = order.order_produces
    ?.map((orderProduce) => orderProduce.produce?.name.toLocaleLowerCase())
    .filter((v, i, a) => a.indexOf(v) === i) as string[];
  const uniqueProduceVarieties = order.order_produces
    ?.map((orderProduce) => orderProduce.produce_variety?.name)
    .filter((v, i, a) => a.indexOf(v) === i) as string[];

  return (
    <div className="flex flex-col justify-start">
      <div className="flex">
        <Strong className="mb-2 leading-none capitalize ">
          <FormatStringList strings={uniqueProduces}/>
        </Strong>
      </div>
      <div className="flex">
        <MutedText className="!text-xxs leading-none !text-gray-500">
          <FormatStringList strings={uniqueProduceVarieties}/>
        </MutedText>
      </div>
    </div>
  );
};

export const OrderPreviewCard = ({ order, compact }: OrderPreviewCardProps) => {
  const routes = useRoutes();
  const { formatCurrency } = useFormat();

  const produce = order.order_produces?.[0].produce;

  return (
    <div
      className="relative border border-gray-100 shadow-sm border-1 rounded-md px-4 py-1 hover:border-gray-300 hover:cursor-pointer">
      <Link href={routes.order(order.id)} className="absolute inset-0"/>
      <div className="flex space-x-2 items-center">
        <div className="w-full">
          <div className="flex items-center space-x-2">
            <OrderImage order={order}/>

            {compact ? (
              <div className="flex gap-2 grow">
                <MutedText>
                  {order.public_ref}
                </MutedText>
                {order.total && (
                  <MutedText className="grow text-right">
                    {formatCurrency(order.total)}
                  </MutedText>
                )}
              </div>
            ) : (
              <div className="flex gap-2">
                <LabeledField label={'PO'} value={order.public_ref}/>
                <LabeledField label={'Supplier'} value={order.seller_id ?? ''}/>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
