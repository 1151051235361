import { HistoryIcon } from 'lucide-react';

import Section, { SectionLayout } from '~/Components/Common/Section';
import { usePageProps } from '~/hooks/usePageProps';
import { Event, Order, Organization } from '~/types/types';

import { OrderChangeCard } from './Changes/OrderChangeCard';

interface OrderEventsLogProps {
  order: Order;
  buyer?: Organization;
  seller?: Organization;
  events: Event[];
}

export const OrderEventsLog = ({ events }: OrderEventsLogProps) => {
  const { user } = usePageProps();

  if (!user.superadmin) {
    return null;
  }

  let filteredEvents = events;
  const supportedEvents = ['load_plan_added', 'quality_added', 'order_arrived', 'order_cancelled'];
  filteredEvents = filteredEvents.filter((event) => supportedEvents.includes(event.type));

  // Ensure that if we have multiple received events, we only show the first "arrived event"
  const seenEvents = new Set();
  filteredEvents = filteredEvents.filter((event) => {
    if (event.type === 'order_arrived') {
      if (seenEvents.has('order_arrived')) {
        return false;
      }

      seenEvents.add('order_arrived');
    }

    return true;
  });

  return (
    <div className="py-8">
      <Section title="History" icon={HistoryIcon}>
        <SectionLayout variant="1">
          <OrderChangeCard events={filteredEvents}/>
        </SectionLayout>
      </Section>
    </div>
  );
};
