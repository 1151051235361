import uniquBy from 'lodash/fp/uniqBy';
import { useMemo } from 'react';

import { useProductName } from '~/hooks/useProductName';
import { Field, QualityEntry } from '~/types/types';

import { useOrder } from '../../../pages/Dashboard/Order/OrderProvider';

export interface ProduceVarietyItem {
  label: string;
  value: string;
  produceId: string;
  specId?: string | null;
}

// TODO: Probably move a bunch of these into a useProduceVariety hook at a later stage
export const isProduceVariety = (
  orderProduce: ProduceVarietyItem,
  produceVarietyId: string
) => orderProduce.value === produceVarietyId;

export const getProduceVariety = (
  produceVarietyId: string,
  orderProduces: ProduceVarietyItem[]
) => {
  return orderProduces.find((orderProduce) =>
    isProduceVariety(orderProduce, produceVarietyId)
  );
};

export const getSpecId = (
  produceVarietyId: string,
  orderProduces: ProduceVarietyItem[]
) => getProduceVariety(produceVarietyId, orderProduces)?.specId;

export const getProduceId = (
  produceVarietyId: string,
  orderProduces: ProduceVarietyItem[]
) => getProduceVariety(produceVarietyId, orderProduces)?.produceId;

export const getProduceVarieties = (
  produceId: string,
  orderProduces: ProduceVarietyItem[]
) =>
  orderProduces.filter((orderProduce) => orderProduce.produceId === produceId);

export const qualityEntryToFormField = (qualityEntry: QualityEntry) => ({
  id: qualityEntry.id,
  value:
    qualityEntry?.number || qualityEntry?.boolean || qualityEntry?.text || '',
  number_a: qualityEntry.number_a ?? null,
  number_b: qualityEntry.number_b ?? null,
  fieldId: qualityEntry.field_id ?? '',
  samples: qualityEntry.samples_qty ?? '0',
});

export const specFieldsToFormFields = (fields: Field[]) => {
  const relevantFields = fields.filter(field => !field.hidden);

  /**
   * Sort fields by which are required (those go first), and those not go second
   */
  relevantFields.sort((a, b) => {
    if (a.required && !b.required) return -1;
    if (!a.required && b.required) return 1;
    return 0;
  });

  return relevantFields.map((field) => ({
    id: '',
    value: '',
    number_a: null,
    number_b: null,
    by_percentage: false,
    fieldId: field.id,
    samples: '',
    note: '',
  }));
};

// TODO: Probably move this into a hook at a later stage
export const useProduceItems = () => {
  const { orderProduces } = useOrder();
  const getProductName = useProductName();

  return useMemo(() => {
    return uniquBy(
      'value',
      orderProduces.map((orderProduce) => ({
        label: getProductName(orderProduce.produce) ?? '',
        value: orderProduce.produce_id ?? '',
      })) || []
    );
  }, [orderProduces]);
};

// TODO: Probably move this into a hook at a later stage
export const useProduceVarietyItems = (): ProduceVarietyItem[] => {
  const { orderProduces } = useOrder();

  return useMemo(
    () =>
      orderProduces.map((orderProduce) => ({
        label: orderProduce.produce_variety?.name ?? '',
        value: orderProduce?.produce_variety_id ?? '',
        produceId: orderProduce.produce_id,
        specId: orderProduce.active_spec?.id,
      })) || [],
    [orderProduces]
  );
};
