import React from 'react';
import { Bar, BarChart, XAxis, YAxis } from 'recharts';

import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/Chart';
import { Ranking } from '~/types/types';

interface GenericRankingProps {
  data: Ranking;
  label: string;
  formatValue?: (value: number) => string;
  color?: string;
}

/**
 * The GenericRanking component breaks down a ranking into a chart.
 *
 * @abstraction: L2 Component (using underlying L1 Chart components). Give it a `ranking` object and it will render a simple ranking.
 *
 * @param data - A Ranking object
 *
 * @param formatValue - A function to format the value of each item in the ranking
 * @param label - How to label each item in the ranking
 * @param color - The color of the chart
 * @postconditions - A chart is rendered with the data from the Breakdown object
 */
export const GenericRanking = ({ data, formatValue, label, color }: GenericRankingProps) => {
  const chartConfig = {
    value: {
      label,
      color,
      formatValue,
    },
  } satisfies ChartConfig;

  const chartData = data.items.map((item) => ({
    name: item.name,
    value: item.value,
  }));

  return (
    <div className="min-h-[250px]">
      <ChartContainer config={chartConfig}>
        <BarChart
          accessibilityLayer
          data={chartData}
          layout="vertical"
          margin={{
            left: -10,
          }}
        >
          <XAxis
            type="number"
            dataKey="value"
            tickFormatter={(value) => formatValue ? formatValue(value) : value}
          />

          <YAxis
            dataKey="name"
            type="category"
            tickLine={false}
            tickMargin={10}
            interval={0}
            axisLine={false}
            tickFormatter={(value) => value.slice(0, 5)}
          />
          <ChartTooltip
            content={<ChartTooltipContent nameKey="name"/>}
          />
          <Bar dataKey="value" fill="var(--color-value)" radius={5}/>
        </BarChart>
      </ChartContainer>
    </div>
  );
};
