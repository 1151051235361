import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CartesianGrid, Customized, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { RangeDatePicker } from '@/DatePicker/RangeDatePicker';
import { Strong } from '@/Text';
import { InnerAnomalyMarkers } from '~/Components/Analytics/Charts/AnomalyMarkers';
import { Legend } from '~/Components/Analytics/Legend';
import { Organization, SavedReport } from '~/types/types';

import { ForecastAccuracyTooltip } from '../ActionPlanReport/LineCard';

export interface ForecastAccuracyChartProps {
  report: SavedReport;
  timeSeries: any[];
  onClick: (startDate: Date, endDate: Date) => void;
  sellers: Organization[];
}

export const ForecastAccuracyChart = ({ report, timeSeries, onClick, sellers }: ForecastAccuracyChartProps) => {
  const { t } = useTranslation();
  const sellerTitleMap = useMemo(() => {
    return sellers.reduce((acc, item) => {
      acc[item.title] = item;

      return acc;
    }, {} as any);
  }, [sellers]);

  const anomalyDots = timeSeries.filter((item) => item.anomaly).map((item) => ({
    date: item.date,
    label: item.anomaly,
  }));

  const handleDeepDiveClick = (payload: any) => {
    if (payload.start_date && payload.end_date) {
      onClick(payload.start_date, payload.end_date);
    }
  };

  return (
    <div>
      <div className="mb-4 flex justify-between gap-2 flex-wrap">
        <div>
          <Strong>
            {t('overview')}
          </Strong>
          <Legend items={sellers.map(seller => ({
            id: seller.id,
            color: seller?.color ?? 'red',
            title: seller.title,
          }))}/>
        </div>

        <div>
          <RangeDatePicker defaultPeriod={report.date_range_type} disabled/>
        </div>
      </div>

      <div className="-mx-8">
        <hr/>
      </div>

      <div className="h-[400px] mt-4 text-gray-400 cursor-pointer">
        <ResponsiveContainer className="cursor-pointer" width="100%" height="100%">
          <LineChart
            data={timeSeries}
            margin={{
              top: 10,
              right: 30,
              left: 30,
              bottom: 5,
            }}
            className="cursor-pointer"
            onClick={(data) => {
              const payload = data.activePayload?.[0].payload;
              handleDeepDiveClick(payload);
            }}
          >
            {/* grays.300 */}
            <CartesianGrid
              className="cursor-pointer"

              strokeDasharray="4 4" stroke="#D1D5DB"/>
            <XAxis
              stroke="#D1D5DB"
              dataKey="date"
              tick={{ fontFamily: 'Inter', fontSize: 13, fill: 'currentColor' }}
              tickMargin={10}
            />
            {/* grays.300 */}
            <YAxis
              stroke="#D1D5DB"
              domain={[0, 100]}
              tick={{ fontFamily: 'Inter', fontSize: 13, fill: 'currentColor' }}
              tickFormatter={(value) => `${value}%`}
              tickMargin={10}
            />

            <Tooltip
              content={(props) => <ForecastAccuracyTooltip sellerTitleMap={sellerTitleMap} {...props as any} />}/>

            {sellers.map(seller => (
              <Line
                key={seller.id}
                type="monotone"
                dataKey={seller.title}
                stroke={seller.color}
                dot={{ 'aria-label': 'test' }}
                activeDot={{ r: 8 }}
              />
            ))}

            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Customized component={({ xAxisMap }) => {
              if (!xAxisMap || !xAxisMap[0] || typeof xAxisMap[0].scale !== 'function') {
                return undefined;
              }
              const xScale = xAxisMap[0].scale;
              return <InnerAnomalyMarkers anomalies={anomalyDots} xScale={xScale}/>;
            }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
