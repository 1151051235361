import { EditIcon } from '@/Icon';
import { ActionAppType, ActionContext, ActionPost, BaseAction } from '~/Actions/BaseAction';
import { BuyerRoutes } from '~/hooks/useRoutes';
import { Issue } from '~/types/types';
import UserInput = App.Domain.User.UserInput;

export class JoinOrganization extends BaseAction {
  public static icon = EditIcon;
  public static analyticsName = 'Join organization';
  public static type: ActionAppType = 'buyer';
  public static toast = {
    titleKey: 'joined_organization',
  };

  constructor(public context: ActionContext<Issue>, public post: ActionPost) {
    super(context, post);
  }

  public nameKey() {
    return 'join_organization_as_buyer';
  }

  public disabled() {
    return !this.context.user.superadmin;
  }

  public async execute(data: UserInput) {
    const routes = this.context.routes as BuyerRoutes;
    const route = routes.joinOrganization;
    //
    await this.post(route(this.context.target.id), data);
  }
}
