import { FunnelIcon } from '@heroicons/react/24/outline';
import { Pencil2Icon } from '@radix-ui/react-icons';
import { t } from 'i18next';
import { ReactNode, useState } from 'react';

import { Button } from '@/Button';
import { ForecastAccuracyIcon, Icon, IconedField } from '@/Icon';
import { Breadcrumbs, LayoutBody, LayoutHeader } from '@/Layout';
import { NumericIndicator } from '@/NumericIndicator';
import { MultiFilterBar } from '@/Pickers/MultiFilterBar';
import { PopoverContent, PopoverPortal, PopoverRoot, PopoverTrigger } from '@/Popover';
import { MutedText, PageTitle } from '@/Text';
import { useAction } from '~/hooks/useAction';
import { usePageProps } from '~/hooks/usePageProps';
import { useRoutes } from '~/hooks/useRoutes';
import { Organization } from '~/types/types';
import { formatPicker } from '~/utils/formatPicker';

import { DashboardLayout } from '../../DashboardLayout';
import { ReportProduceFilter } from '../ReportProduceFilter';
import { ReportSellersFilter } from '../ReportSellersFilter';
import { EditReportModal } from '../ReportsPage/EditReportModal';
import { ForecastAccuracyPageProps } from './helpers';

interface ForecastAccuracyLayoutProps {
  children: ReactNode;
}

export const ForecastAccuracyLayout = ({ children }: ForecastAccuracyLayoutProps) => {
  const { user, report, options, parameters } = usePageProps<ForecastAccuracyPageProps>();
  const { editReportName, reports } = useRoutes();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const sellers = options.reportSellers;
  const filteredSellers = (parameters.sellers || []).length > 0 ? parameters.sellers : [...sellers.map(seller => seller.id)];

  const sellersFiltersCount = parameters.sellers?.length ?? 0;
  const producesFiltersCount = parameters.produces?.length ?? 0;
  const activeFiltersCount = sellersFiltersCount + producesFiltersCount;

  const ownsReport = user.id === report.user_id;

  return (
    <DashboardLayout>
      <LayoutHeader isSticky>
        <div className="flex items-center justify-between md:flex-row">
          {/* Left side */}
          <div>
            <Breadcrumbs backLink={{ type: 'link', name: 'Reports', href: reports() }}/>
            <div>
              <div>
                <PageTitle>
                  {report.name}
                </PageTitle>
              </div>

              <div className="flex gap-2 mt-2">
                <div>
                  <IconedField icon={ForecastAccuracyIcon}>
                    <MutedText>
                      {t('forecast_accuracy')}
                    </MutedText>
                  </IconedField>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-2 items-center">
            <div>
              {/*<PopoverRoot*/}
              {/*  open={isFilterOpen}*/}
              {/*  onOpenChange={setIsFilterOpen}*/}
              {/*>*/}
              {/*  <PopoverTrigger asChild>*/}
              {/*    <Button variant="white">*/}
              {/*      <Icon className="mr-1">*/}
              {/*        <FunnelIcon className="w-4 h-4"/>*/}
              {/*      </Icon>*/}

              {/*      {activeFiltersCount > 0 && (*/}
              {/*        <div className="mr-1">*/}
              {/*          <NumericIndicator brand="blue" size="sm">*/}
              {/*            {activeFiltersCount}*/}
              {/*          </NumericIndicator>*/}
              {/*          {' '}*/}
              {/*        </div>*/}
              {/*      )}*/}
              {/*      {t('filters')}*/}
              {/*    </Button>*/}
              {/*  </PopoverTrigger>*/}
              {/*  <PopoverPortal>*/}
              {/*    <PopoverContent*/}
              {/*      collisionPadding={10}*/}
              {/*      sideOffset={5}*/}
              {/*      className="flex flex-col sm:data-[side=top]:max-h-[var(--radix-popover-content-available-height)] p-0 overflow-y-auto sm:rounded shadow bg-white min-w-[600px] w-full sm:h-auto sm:max-w-sm"*/}
              {/*    >*/}
              {/*      <MultiFilterBar*/}
              {/*        filterItems={[*/}
              {/*          {*/}
              {/*            name: t('sellers'),*/}
              {/*            renderChild: (*/}
              {/*              <ReportSellersFilter*/}
              {/*                sellers={formatPicker(options.reportSellers, 'id', 'title')}*/}
              {/*                selected={filteredSellers}*/}
              {/*                onClose={() => setIsFilterOpen(false)}*/}
              {/*              />*/}
              {/*            ),*/}
              {/*            activeFiltersCount: sellersFiltersCount,*/}
              {/*          },*/}
              {/*          {*/}
              {/*            name: t('produce'),*/}
              {/*            renderChild: (*/}
              {/*              <ReportProduceFilter produces={formatPicker(options.produces, 'id', 'name') ?? []}*/}
              {/*                selected={parameters.produces ?? []}/>*/}
              {/*            ),*/}
              {/*            activeFiltersCount: producesFiltersCount,*/}
              {/*          },*/}
              {/*        ]}*/}
              {/*      />*/}
              {/*    </PopoverContent>*/}
              {/*  </PopoverPortal>*/}
              {/*</PopoverRoot>*/}
            </div>

            {ownsReport && (
              <EditReportModal
                isOpen={isEditModalOpen}
                setIsOpen={(res) => setIsEditModalOpen(res.isOpen)}
                report={report}
                regions={[]}
                sellers={options.reportSellers}
              >
                <Button variant="gray">
                  <Icon className="mr-1">
                    <Pencil2Icon className="w-4 h-4"/>
                  </Icon>
                  {t('edit_report')}
                </Button>
              </EditReportModal>
            )}
          </div>
        </div>
      </LayoutHeader>
      <LayoutBody>
        {children}
      </LayoutBody>
    </DashboardLayout>
  );
};
