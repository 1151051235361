// noinspection UnnecessaryLocalVariableJS

import { ChartConfig } from '@/Chart';
import { DateFilter, EntityScopeType, TimeSeries } from '~/types/types';

export const neutralAxisColor = '#E5E7EB';
export const axisClass = 'fill-gray-500';

export const getChartNames = (chartData: any[] = [], ignoreKey: string[]) => {
  const chartNames: string[] = [];

  chartData.forEach((data) => {
    Object.keys(data).forEach((key) => {
      if (!ignoreKey.includes(key)) {
        chartNames.push(key);
      }
    });
  });

  // Filter out duplicates
  return chartNames.filter((value, index, self) => self.indexOf(value) === index);
};

export const calcCeiling = (data: any[], ignoreKey = 'name', paddingTopFraction = 0.2) => {
  // Find the max for each month
  const yMax = data.reduce((_, month) => {
    const monthMax = Object.keys(month).filter(key => key !== ignoreKey).reduce((acc, key) => {
      return Math.max(acc, month[key]);
    }, 0);

    return Math.max(0, monthMax);
  });

  return yMax + yMax * paddingTopFraction;
};

interface TimeSeriesDataProps extends Record<string, any> {
  date: string;
  value: any;
  start_date?: string;
  end_date?: string;
}

interface EntityTimeSeries {
  entity: Record<string, any>
  entityScopeType: EntityScopeType;
  timeSeries: TimeSeries;
}

/**
 * Given a list of entityTimeSeries, we build a dynamic chart config.
 *
 *
 * @param entityTimeSeriesCollection
 * @param formatValue
 */
export const buildDynamicChartConfig = (
  entityTimeSeriesCollection: EntityTimeSeries[],
  formatValue?: (value: number) => string,
): ChartConfig => {
  const config = entityTimeSeriesCollection.reduce((acc, entityTimeSeries) => {
    const entity = entityTimeSeries.entity;
    const isComparison = !!entityTimeSeries.timeSeries.dateConfig.asComparisonType;

    const entityIdentifier = entity?.id;
    const entityLabel = entity?.label || entity?.name || entityTimeSeries?.timeSeries?.label;
    const color = entityTimeSeries?.timeSeries?.color || entity?.color || 'red';

    return {
      ...acc,
      [entityIdentifier]: {
        isComparison,
        color,
        label: entityLabel,
        formatValue: formatValue ? formatValue : undefined,
      },
    };
  }, {});

  return config;
};

/**
 * Fuses an Entity Time Series array into a single time series array.
 *
 * Example:
 * Input: [
 *  {
 *    entity: { 'id', 'title': 'Farm Greens', ... },
 *    entityScopeType: 'seller',
 *    timeSeries: {
 *      events: [{ 'date': '2021-01-01', 'value': 100, 'otherValue': 'abc' }, ...]
 *      ...
 *
 *      ...
 *
 *
 * Output:
 * [{
 *   'date': '2021-01-01',
 *   'start_date': '2021-01-01',
 *   'end_date': '2021-01-01',
 *   'Farm Greens': 100,
 *   'Farm Greens data': {
 *   ...
 *
 * ]
 * @param entityTimeSerieses
 */
export const fuseEntityTimeSeries = (entityTimeSerieses: EntityTimeSeries[]) => {
  const fusedData: Record<string, any> = {};

  entityTimeSerieses.forEach((entityTimeSeries) => {
    const { entity, entityScopeType, timeSeries } = entityTimeSeries;

    const entityTitle = entity?.id || entity?.label || entity?.name || 'Unknown';

    timeSeries.events.forEach((event) => {
      const { date, value, ...rest } = event;

      if (!fusedData[date]) {
        fusedData[date] = {
          date,
          start_date: date,
          end_date: date,
        };
      }

      fusedData[date][entityTitle] = value;
      fusedData[date][`${entityTitle} data`] = {
        ...entity,
        ...rest,
        entityScopeType,
      };
    });
  });

  return Object.values(fusedData).sort((a, b) => a.date.localeCompare(b.date));
};

/**
 * Fuses the time series data into a Recharts parseable format. Fuse on `date` field, and
 * add the `title` as the key for the value. Also adds the `title` as a key for the data.
 *
 * Example:
 * Input: {
 *   timeSeries: [
 *   {
 *     id: '1',
 *     title: 'Title 1',
 *     time_series: [{
 *        date: '2021-01-01',
 *        start_date: '2021-01-01',
 *        end_date: '2021-01-01',
 *        value: 100,
 *        otherValue: 'abc',
 *     }],
 *   },
 *   {
 *     id: '2',
 *     title: 'Title 2',
 *     time_series: [{
 *        date: '2021-01-01',
 *        value: 200,
 *        otherValue: 'def',
 *     }],
 *   },
 *   ]
 * }
 *
 * Output: [
 *  {
 *    timeSeries: [{
 *      'date': '2021-01-01',
 *      'start_date': '2021-01-01',
 *      'end_date': '2021-01-01',
 *      'Title 1': 100,
 *      'Title 2': 200,
 *      'Title 1 data': {
 *        id: '1',
 *        title: 'Title 1',
 *        'otherValue': 'abc'
 *      },
 *      'Title 2 data': {
 *        id: '2',
 *        title: 'Title 2',
 *        'otherValue': 'def'
 *        }
 *    }]
 *  date: '2021-01-01',
 */
export const fuseTimeSeries = (timeSeries: any[]) => {
  const fusedData = timeSeries.reduce((acc, item) => {
    if (!item.time_series) {
      return acc;
    }
    item.time_series.forEach((data: TimeSeriesDataProps) => {
      const date = data.date;
      if (!acc[date]) {
        acc[date] = {};
      }

      acc[date][item.title] = data.value;
      // Exclude from the `data` field the item.time_series fields
      acc[date][`${item.title} data`] = data;

      // Add the rest of the fields if they exist
      if (data['start_date'] && data['end_date']) {
        acc[date]['start_date'] = data['start_date'];
        acc[date]['end_date'] = data['end_date'];
        acc[date]['anomaly'] = data['anomaly'];
      }
    });

    return acc;
  }, {} as any);

  return Object.keys(fusedData).map((key) => ({
    date: key,
    ...fusedData[key],
  }));
};

export const formatDate = (date: Date, dateFilter: Partial<DateFilter> | null): string => {
  if (!date) return '';

  if (!dateFilter) return date.toLocaleDateString();

  if (dateFilter.granularity === 'hour') {
    return date.toLocaleDateString('en-US', {
      hour: 'numeric',
      day: 'numeric',
    });
  }

  if (dateFilter.granularity === 'day') {
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }

  if (dateFilter.granularity === 'week') {
    return date.toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    });
  }

  if (dateFilter.granularity === 'month') {
    return date.toLocaleDateString('en-US', {
      month: 'short',
      year: 'numeric',
    });
  }

  if (dateFilter.granularity === 'quarter') {
    return date.toLocaleDateString('en-US', {
      month: 'short',
      year: 'numeric',
    });
  }

  if (dateFilter.granularity === 'year') {
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
    });
  }

  return date.toLocaleDateString();
};

export const extractIdentifiers = (config: ChartConfig) => {
  return Object.keys(config);
};

export const extractValues = (data: any[], identifier: string) => {
  return data.map((item) => item[identifier]);
};

/**
 * Extract all possible categories from all the data.
 * @param chartData
 */
export const extractCategories = (chartData: any, xLabel = 'identifier') => {
  const categories = new Set<string>();

  chartData.forEach((data: any) => {
    Object.keys(data).forEach((key) => {
      if (key !== xLabel) {
        categories.add(key);
      }
    });
  });

  return Array.from(categories);
};
