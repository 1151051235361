import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";

import { cn } from "~/utils/cn";

import { MutedText, Strong } from "./Text";

interface RadioButtonCardProps {
  label?: string;
  subLabel?: string;
  id: string;
  value: string;
  disabled?: boolean;
}

export const RadioGroup = RadioGroupPrimitive.Root;

export const RadioGroupItem = RadioGroupPrimitive.Item;

export const RadioGroupItemIndicator = RadioGroupPrimitive.Indicator;

// TODO: Extract only necessities out of RadioGroupPrimitive
export const RadioButtonCard = ({
  label,
  subLabel,
  id,
  value,
  disabled,
}: RadioButtonCardProps) => {
  return (
    <div className={"relative flex items select-none"}>
      <RadioGroupPrimitive.Item
        className={cn(
          "cursor-pointer disabled:cursor-not-allowed mt-5 peer ml-4 absolute bg-white w-[20px] h-[20px] border rounded-full outline-none"
        )}
        value={value}
        id={id}
        disabled={disabled}
      >
        <RadioGroupPrimitive.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-[11px] after:h-[11px] after:rounded-full after:bg-blue-700" />
      </RadioGroupPrimitive.Item>
      <label
        htmlFor={id}
        aria-disabled={disabled}
        className={cn(
          "cursor-pointer flex flex-col w-full p-4 border rounded pl-14 peer-aria-checked:text-blue-700 peer-aria-checked:ring-blue-400 peer-focus:ring-blue-200 peer-focus:border-transparent peer-focus:ring-1 peer-focus:ring-offset-0 peer-focus:ring-offset-white",
          "aria-disabled:cursor-not-allowed aria-disabled:opacity-50"
        )}
      >
        <Strong>{label}</Strong>
        {subLabel && <MutedText>{subLabel}</MutedText>}
      </label>
    </div>
  );
};
