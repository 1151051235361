import { OTPInput } from 'input-otp';

import { cn } from '~/utils/cn';

interface PinInputProps {
  length: number;
  onChange: (value: string) => void;
  id?: string;
  name?: string;
  autoFocus?: boolean;
  required?: boolean;
  value?: string;
  defaultValue?: string;
  onBlur?: () => void;
}

export const PinInput = ({ length, ...props }: PinInputProps) => {

  return (
    <OTPInput
      maxLength={length}
      {...props}
      autoComplete='off'
      // Disable for 1Password, etc.
      data-1p-ignore
      containerClassName="group flex items-center has-[:disabled]:opacity-30"
      render={({ slots }) => (
        <>
          <div className="flex">
            {slots.slice(0, 3).map((slot, idx) => (
              <Slot key={idx} {...slot} />
            ))}
          </div>

          <FakeDash />

          <div className="flex">
            {slots.slice(3).map((slot, idx) => (
              <Slot key={idx} {...slot} />
            ))}
          </div>
        </>
      )}
    />
  );
};

function Slot(props: { char: string | null; isActive: boolean }) {
  return (
    <div
      className={cn(
        'relative w-10 h-14 text-[2rem]',
        'flex items-center justify-center',
        'transition-all duration-300',
        'border-border border-y border-r first:border-l first:rounded-l-md last:rounded-r-md',
        'group-hover:border-accent-foreground/20 group-focus-within:border-accent-foreground/20',
        'outline outline-0 outline-accent-foreground/20',
        { 'outline-4 outline-accent-foreground': props.isActive },
      )}
    >
      {props.char !== null && <div>{props.char}</div>}
      {props.char === null && props.isActive && <FakeCaret />}
    </div>
  );
}

function FakeCaret() {
  return (
    <div className="absolute inset-0 flex items-center justify-center pointer-events-none animate-caret-blink">
      <div className="w-px h-8 bg-white" />
    </div>
  );
}

// Inspired by Stripe's MFA input.
function FakeDash() {
  return (
    <div className="flex items-center justify-center w-10">
      <div className="w-3 h-1 rounded-full bg-border" />
    </div>
  );
}
