import * as Sentry from '@sentry/react';

import { logInfo } from './logger';

export const initSentry = () => {
  if (import.meta.env.VITE_ENVIRONMENT !== 'local' && import.meta.env.VITE_ENVIRONMENT !== 'test') {
    Sentry.init({
      dsn: 'https://baae0b1df56f5092a9a97864a1169bb6@o4506275874603008.ingest.sentry.io/4506275875782656',
      integrations: [
        new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        }),
        new Sentry.Replay(),
        new Sentry.InboundFilters({
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      tracesSampleRate: 1.0,
      denyUrls: ['localhost'],

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https?:\/\/app\.agstacked\.com/],
      // tracePropagationTargets: [/^https?:\/\/app\.agstacked\.com/],
      // Enable for localhost instead

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      enabled: false,
    });

    logInfo(`Sentry enabled, environment: ${import.meta.env.VITE_ENVIRONMENT}`);
  } else {
    logInfo(`Sentry not enabled, environment: ${import.meta.env.VITE_ENVIRONMENT}`);
  }
};
