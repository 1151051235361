import { AddIcon, DetectionIcon, EditIcon, ErrorIcon, MapFieldIcon, SpecFileIcon } from '@/Icon';
import { StatusMap } from '@/Labels/Status';
import SaveSpecState = App.Domain.Specs.SaveSpecState;

/**
 * Status-map for spec versions, displaying how to render the various states of a spec version.
 */
export const specVersionStatusMap: StatusMap<SaveSpecState> = {
  'created': {
    icon: SpecFileIcon,
    textKey: 'created_spec',
    descriptionKey: 'created_spec_description',
    theme: 'green',
  },
  'errored': {
    icon: ErrorIcon,
    textKey: 'errored_spec',
    descriptionKey: 'errored_spec_description',
    theme: 'red',
  },
  'found': {
    icon: EditIcon,
    textKey: 'edited_spec',
    descriptionKey: 'edited_spec_description',
    theme: 'green',
  },
  'created_first_base_type': {
    icon: DetectionIcon,
    textKey: 'created_first_base_type',
    descriptionKey: 'created_first_base_type_description',
    theme: 'red',
  },
  'mapped_field_to_field': {
    icon: MapFieldIcon,
    textKey: 'mapped_field_to_field',
    descriptionKey: 'mapped_field_to_field_description',
    theme: 'blue',
  },
  'updated_field': {
    icon: EditIcon,
    textKey: 'edited_field',
    descriptionKey: 'edited_field_description',
    theme: 'green',
  },
  'created_new_field': {
    icon: AddIcon,
    textKey: 'created_new_field',
    descriptionKey: 'created_new_field_description',
    theme: 'blue',
  },
  'deleted_field': {
    icon: ErrorIcon,
    textKey: 'deleted_field',
    descriptionKey: 'deleted_field_description',
    theme: 'red',
  },
  'no_change': {
    theme: 'gray',
  },
  created_unknown_field: {
    theme: 'red',
  },
  linked_field_by_integration: {
    theme: 'blue',
  },
  updated_existing_field: {
    theme: 'green',
  },
  updated_fields: {
    theme: 'green',
  },
  updated_varieties: {
    theme: 'green',
  },
};
