/**
 * Render the country name from the country code.
 *
 * Note: Must be uppercased.
 * 
 * For now, we also support the edge case where the country code is the same as the country name.
 */
export const CountryName = ({ countryCode }: { countryCode: string }) => {
  let regionNames = countryCode;

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    regionNames = new Intl.DisplayNames(['en'], { type: 'region' }).of(countryCode);
  } catch (error) {
    // TODO: Log to sentry or something
    console.log(error);
  }

  return (
    <>
      {regionNames}
    </>
  );
};
