import { BooleanBreakdown } from '~/Components/Analytics/BooleanBreakdown';
import { BoxPlot } from '~/Components/Analytics/BoxPlot';

// import { MeasurementDistribution, PercentageDistribution } from '~/types/types';

interface FormatDistributionProps {
  // distribution: MeasurementDistribution | PercentageDistribution;
  distribution: any;
  isCompact?: boolean;
}

export const FormatDistribution = ({ distribution, isCompact }: FormatDistributionProps) => {
  if (distribution && 'median' in distribution) {
    return (
      <BoxPlot
        min={0}
        q1={distribution.minValue}
        median={distribution.median}
        q3={distribution.percentile75}
        max={distribution.maxValue}
        domainMin={distribution.minDomainValue}
        domainMax={distribution.maxDomainValue}
        isCompact={isCompact}
      />
    );
  }

  if (distribution && 'countTrue' in distribution) {
    return (
      <BooleanBreakdown truePercentage={distribution.fractionTrue * 100}
        falsePercentage={distribution.fractionFalse * 100}
        isCompact={isCompact}
        trueCount={distribution.countTrue}
        falseCount={distribution.countFalse}
      />
    );
  }

  return (
    <div>

    </div>
  );
};
