import * as Collapsible from '@radix-ui/react-collapsible';
import React from 'react';

import { Card, CardBody } from './Card';
import Styles from './ExpandableCard.module.css';

interface ExpandableCardProps {
  preview: React.ReactNode;
  body: React.ReactNode;
  size: 'sm' | 'md' | 'lg';
  manualOpen?: boolean;
  onManualOpen?: () => void;
}

/**
 * A card that can be expanded to show more content.
 *
 * @example
 * <ExpandableCard
 *   size="sm"
 *   preview={<div>Preview Content</div>}
 *   body={<div>Body Content</div>}
 * />
 */
export const ExpandableCard = ({ preview, body, size = 'md', manualOpen, onManualOpen }: ExpandableCardProps) => {
  const [open, setOpen] = React.useState(false);

  const isOpened = manualOpen ?? open;
  const onOpenChange = onManualOpen ?? setOpen;

  return (
    <Card className={`rounded-md ${open ? 'shadow-lg' : 'shadow-md'}`}>
      <Collapsible.Root open={isOpened} onOpenChange={onOpenChange}>
        <Collapsible.Trigger asChild className='cursor-pointer'>
          <CardBody size={size}>
            {preview}
          </CardBody>
        </Collapsible.Trigger>

        <Collapsible.Content className={Styles.ExpandableCard}>
          <div className="border-t border-gray-100">
            {body}
          </div>
        </Collapsible.Content>
      </Collapsible.Root>
    </Card>
  );
};
