import { SpecFileIcon } from '@/Icon';
import { cn } from '~/utils/cn';
import { DefaultTheme } from '~/utils/colors';

export interface SpecIconProps {
  theme: DefaultTheme;
}

const mapThemeToColor: Record<DefaultTheme, string> = {
  slate: 'bg-slate-100 text-slate-400 ring-slate-400',
  indigo: 'bg-indigo-100 text-indigo-400 ring-indigo-400',
  gray: 'bg-gray-100 text-gray-400 ring-gray-400',
  emerald: 'bg-emerald-100 text-emerald-500 ring-emerald-400',
  yellow: 'bg-yellow-100 text-yellow-400 ring-yellow-400',
  orange: 'bg-orange-100 text-orange-400 ring-orange-400',
  green: 'bg-green-100 text-green-400 ring-green-400',
  red: 'bg-red-100 text-red-400 ring-red-400',
  blue: 'bg-blue-100 text-blue-400 ring-blue-400',
  purple: 'bg-purple-100 text-purple-400 ring-purple-400',
  pink: 'bg-pink-100 text-pink-400 ring-pink-400',
};

/**
 * Spec
 */
export const SpecIcon = ({ theme }: SpecIconProps) => {
  const color = mapThemeToColor[theme];
  const className = cn`
    flex items-center justify-center justify-center w-4 h-4 rounded-full ring-1 ring-inset
    ${color}
  `;

  return (
    <span className={className}>
      <span className="inline-block w-full h-full p-[2px]">
        <SpecFileIcon className="w-3 h-3 text-inherit"/>
      </span>
    </span>
  );
};
