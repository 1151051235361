/* eslint-disable react/prop-types */
import * as PopoverPrimitive from '@radix-ui/react-popover';
import * as React from 'react';
import { w } from 'windstitch';

import { cn } from '../utils/cn';
import { TextLabel } from './Text';

export const PopoverRoot = PopoverPrimitive.Root;

export const PopoverPortal = PopoverPrimitive.Portal;

export const PopoverArrow = PopoverPrimitive.Arrow;

export const PopoverClose = PopoverPrimitive.Close;

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

export const PopoverHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col space-y-1 border-muted px-8 text-center rounded-t-lg sm:text-left -mx-4 pb-4 border-b',
      className
    )}
    {...props}
  />
);
PopoverHeader.displayName = 'DialogHeader';

export const PopoverTitle = w(TextLabel, { className: '!text-md' });
export const PopoverDescription = w.span(`
  text-xs text-gray-700 inline-block mb-1
`);

export const PopoverBody = w.div(`
  px-4 py-4
`);

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
// eslint-disable-next-line react/prop-types
>(({ className, align = 'center', sideOffset = 4, ...props }, ref) => (
  // <PopoverPrimitive.Portal>
  <PopoverPrimitive.Content
    ref={ref}
    align={align}
    sideOffset={sideOffset}
    className={cn(
      'z-50 w-72 rounded-md ring-1 ring-gray-900/5 bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className
    )}
    {...props}
  />
  // </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverContent, PopoverTrigger };
