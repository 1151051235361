import { useTranslation } from 'react-i18next';

import { IconButton } from '@/Button';
import {
  DropdownCheckboxItem,
  DropdownContent,
  DropdownGroup, DropdownRoot,
  DropdownSeparator, DropdownTrigger,
} from '@/DropdownMenu';
import Sort = App.Domain.Common.Sort;
import { IconButtonIcon, SortIcon } from '@/Icon';
import SortOrder = App.Domain.Common.SortOrder;

interface SortButtonProps {
  sort: Sort;
  onSortChange: (sort: SortOrder) => void;
}

export const SortButton = ({ sort, onSortChange }: SortButtonProps) => {
  const { t } = useTranslation();

  return (
    <DropdownRoot>
      <DropdownTrigger asChild>
        <IconButton label="Sort by">
          <IconButtonIcon icon={SortIcon}/>
        </IconButton>
      </DropdownTrigger>
      <DropdownContent className="w-56">
        <DropdownSeparator/>

        <DropdownGroup>
          <DropdownCheckboxItem
            checked={sort.direction === 'asc'}
            onCheckedChange={() => onSortChange('asc')}
          >
            {t('ascending')}
          </DropdownCheckboxItem>
          <DropdownCheckboxItem
            checked={sort.direction === 'desc'}
            onCheckedChange={() => onSortChange('desc')}
          >
            {t('descending')}
          </DropdownCheckboxItem>
        </DropdownGroup>

        <DropdownSeparator/>

      </DropdownContent>
    </DropdownRoot>

  );
};
