import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ActionbarBlock, ActionbarItem, ActionbarRoot, ActionbarSeparator } from '@/Actionbar';
import { Button } from '@/Button';
import { Card, CardBody, CardHeaderContainer } from '@/Card';
import { Form } from '@/Form';
import { ActionbarIcon, AddIcon, ButtonIcon, DiscardIcon, EditIcon, MiniLabelIcon, SaveIcon } from '@/Icon';
import { MiniLabel } from '@/Label';
import { CardTitle } from '@/Text';
import { useAction } from '~/hooks/useAction';
import { BuyerRoutes, useRoutes } from '~/hooks/useRoutes';
import { ProduceVariety, SaveSpecInput, Spec } from '~/types/types';

import { formatVarietiesModelToForm, formatVarietyInputsToApi } from './helpers/formatters';
import { SpecVarietiesFormInputs, varietiesFormSchema } from './helpers/schemas';

export const SpecVarietiesCard = ({ spec }: SpecVarietiesCardProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();

  const routes = useRoutes<BuyerRoutes>();

  const form = useForm<SpecVarietiesFormInputs>({
    resolver: zodResolver(varietiesFormSchema),
    defaultValues: formatVarietiesModelToForm(spec),
  });

  const { fields: fieldVarieties, append } = useFieldArray({
    control: form.control,
    name: 'varieties',
  });

  const { post: saveSpec, loading } = useAction<SaveSpecInput>(routes.saveSpec(), {
    onSuccess: () => {
      setIsEdit(false);
    },
  });

  const handleSubmit = async (values: SpecVarietiesFormInputs) => {
    const apiInput = formatVarietyInputsToApi(spec, values);

    await saveSpec(apiInput);
  };

  const handleDiscardChanges = () => {
    form.reset(formatVarietiesModelToForm(spec));
    setIsEdit(false);
  };

  const hasChanges = true;

  const handleAddField = (value: string) => {
    append({
      public_name: value,
    });
  };

  // TODO: Re-enable once the UX is better
  // const disabled = isEdit as any;
  const disabled = true as any;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <div>
          <Card>
            <CardHeaderContainer className="bg-gray-50">
              <div className="flex justify-between items-center">
                <CardTitle>
                  {t('varieties')}
                </CardTitle>

                <div>
                  <Button type="button" size="xs" variant="white" onClick={() => setIsEdit(true)}
                    disabled={disabled}>
                    <ButtonIcon icon={EditIcon}/>
                    {t('edit_varieties')}
                  </Button>
                </div>
              </div>
            </CardHeaderContainer>

            <CardBody>
              <div className="flex flex-wrap gap-2">
                {fieldVarieties.map((variety, index) => (
                  <div key={variety.id}>
                    <Controller
                      name={`varieties.${index}.public_name`}
                      control={form.control}
                      render={({ field }) => (
                        <MiniLabel
                          enableEdit={isEdit}
                          value={field.value}
                          onChange={field.onChange}
                          variant="default"
                          isEdit={isEdit}
                          onSave={field.onChange}
                        />
                      )}
                    />
                  </div>
                ))}

                {isEdit && (
                  <MiniLabel variant="add" isEdit enableEdit onSave={handleAddField}>
                    <MiniLabelIcon icon={AddIcon}/>
                    {t('add')}
                  </MiniLabel>
                )}
              </div>
            </CardBody>
          </Card>

          <ActionbarRoot show={hasChanges && isEdit}>
            <ActionbarBlock>
              {fieldVarieties.length}
              {' '}
              {t('varieties')}
            </ActionbarBlock>

            <ActionbarSeparator/>

            <ActionbarItem type="submit" disabled={!form.formState.isValid || loading}>
              <ActionbarIcon icon={SaveIcon}/>
              {t('save_spec')}
            </ActionbarItem>

            <ActionbarSeparator/>

            <ActionbarItem onClick={handleDiscardChanges}>
              <ActionbarIcon icon={DiscardIcon}/>
              {t('discard_changes')}
            </ActionbarItem>
          </ActionbarRoot>
        </div>
      </form>
    </Form>
  );
};

interface SpecVarietiesCardProps {
  spec: Spec;
  varieties: ProduceVariety[];
}
