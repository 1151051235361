import { AnimatePresence, motion } from 'framer-motion';
import React, { ExoticComponent, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonGutter } from '@/Button';
import { Card, CardBody, CardFooter, SimpleCardHeader } from '@/Card';
import { ChevronDownIcon, IconBox, IconBoxIcon, IssueIcon, SimpleCardHeaderIcon } from '@/Icon';
import { Helper, MutedText, SimpleCardTitle, Strong } from '@/Text';
import { IssueActions } from '~/Components/Orders/IssueActions';
import { DateFormat, useDate } from '~/hooks/useDate';
import { Issue } from '~/types/types';
import { cn } from '~/utils/cn';
import IssueState = App.Domain.Issues.IssueState;

interface IssueCardProps {
  title: string;
  children: ReactNode;
}

export const IssueCard = ({ title, children }: IssueCardProps) => {
  return (
    <Card>
      <SimpleCardHeader variant="red">
        <SimpleCardHeaderIcon icon={IssueIcon}/>
        <SimpleCardTitle>
          {title}
        </SimpleCardTitle>
      </SimpleCardHeader>
      <div className="divide-y divide-gray-200">
        {children}
      </div>
    </Card>
  );
};

type IssueItemType = 'explanation' | 'ai' | 'breakdown';

interface IssueCardItemProps {
  type: IssueItemType;
  icon?: ExoticComponent;
  renderContext?: React.ReactNode;
  children: React.ReactNode;
}

export const IssueCardActions = ({ children }: { children: ReactNode }) => {
  return (
    <CardFooter>
      {children}
    </CardFooter>
  );
};

interface IssueBreakdownProps {
  type: IssueItemType;
  children: ReactNode;
  renderContext?: ReactNode;
  defaultIsOpen?: boolean;
}

export const IssueCardBreakdown = ({
  children,
  renderContext,
  defaultIsOpen,
}: IssueBreakdownProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const toggleContent = () => setIsOpen(!isOpen);

  return (
    <div className="bg-gray-50">
      <CardBody>
        <div>
          <div className="flex items-center cursor-pointer" onClick={toggleContent}>
            <Helper>
              {isOpen ? t('hide_issues') : t('show_issues')}
            </Helper>
            <motion.div
              animate={{ rotate: isOpen ? 180 : 0 }}
              transition={{ duration: 0.3 }}
              className="ml-2"
            >
              <ChevronDownIcon
                className="h-5 w-5 text-zinc-500 dark:text-zinc-400"
              />
            </motion.div>
          </div>
        </div>

        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ type: 'spring', bounce: 0.1, duration: 0.3 }}
            >
              <div className="flex-grow">
                <div>
                  {children}
                </div>

                {!!renderContext && (
                  <div className="bg-gray-50 border-2 px-2 py-2 my-2 rounded">
                    <div className="mb-1">
                      <Helper className="text-xs flex !text-slate-800">
                        {t('context')}
                      </Helper>
                    </div>

                    <div>
                      {renderContext}
                    </div>
                  </div>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </CardBody>
    </div>
  );
};

export const IssueCardItem = ({ type, icon, children, renderContext }: IssueCardItemProps) => {
  const { t } = useTranslation();

  return (
    <div className={cn(type === 'breakdown' && 'bg-gray-50')}>
      <CardBody>
        <div className="mb-2">
          <Helper>
            {t(`issues_explainable_type_${type}`)}
          </Helper>
        </div>

        <div className="flex gap-4">
          {icon ? (
            <IconBox
              className={cn(
                type === 'explanation' && '!bg-rose-50 !text-rose-700 ',
                type === 'ai' && '!bg-blue-50 !text-blue-700 ',
                '!min-w-8 !h-8 flex items-center justify-center !border-0'
              )}
            >
              <IconBoxIcon icon={icon}/>
            </IconBox>
          ) : (
            <div/>
          )}

          <div className="flex-grow">
            <div>
              {children}
            </div>

            {!!renderContext && (
              <div className="bg-gray-50 border-2 px-2 py-2 my-2 rounded">
                <div className="mb-1">
                  <Helper className="text-xs flex !text-slate-800">
                    {t('context')}
                  </Helper>
                </div>

                <div>
                  {renderContext}
                </div>
              </div>
            )}
          </div>
        </div>
      </CardBody>
    </div>
  );
};

interface GenericIssueBreakdownProps {
  issues: Issue[];
}

export const GenericIssueBreakdown = ({ issues }: GenericIssueBreakdownProps) => {
  const { t } = useTranslation();

  const [showHidden, setShowHidden] = useState(false);

  const relevantIssues = useMemo(() => {
    if (showHidden) {
      return issues;
    }

    return issues.filter((issue) => (issue.status as IssueState) !== 'read');
  }, [issues, showHidden]);

  const hiddenIssues = issues.filter((issue) => (issue.status as IssueState) === 'read');
  const toggleHidden = () => setShowHidden(!showHidden);

  return (
    <div>
      <div className="divide-y divide-gray-200 w-full">
        {relevantIssues.map((issue) => (
          <div key={issue.id}>
            <GenericIssueBreakdownItem
              issue={issue}
            />
          </div>
        ))}
      </div>

      {hiddenIssues.length > 0 && (
        <div className="mt-2 w-full text-center">
          <Button onClick={toggleHidden} role="button" variant="gray" className="w-full !text-center justify-center">
            {showHidden ? t('hide_read') : t('show_read')} ({hiddenIssues.length})
          </Button>
        </div>
      )}
    </div>
  );
};

export const GenericIssueBreakdownItem = ({ issue }: { issue: Issue }) => {
  const { parseOrFormat } = useDate();

  return (
    <div className="py-4">
      <div className="flex">
        <div className="flex-grow">
          <div className="flex justify-between items-start gap-10">
            <div className="relative">

              <Strong className="mr-2 !leading-one  !text-gray-500">
                {issue.title}
              </Strong>
            </div>
            <div className="!leading-none mt-0.5 flex-shrink-0">
              <MutedText>
                {parseOrFormat(issue.created_at, DateFormat.HumanMonthDay)}
              </MutedText>
            </div>
          </div>

        </div>
      </div>

      <div className="mt-2">
        <ButtonGutter>
          <IssueActions issue={issue}/>
        </ButtonGutter>
      </div>
    </div>
  );
};
