import { Link } from '@inertiajs/react';
import { forwardRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Avatar from '@/Avatar';
import {
  DropdownBlock,
  DropdownCheckboxItem,
  DropdownContent,
  DropdownGroup, DropdownGroupHeader, DropdownItem,
  DropdownRoot,
  DropdownSeparator, DropdownSubMenu, DropdownSubMenuContent, DropdownSubMenuTrigger,
  DropdownTrigger,
} from '@/DropdownMenu';
import {
  DashboardIcon,
  DropdownIcon, ExpandMenuIcon,
  LanguageIcon,
  LogoutIcon,
  SimpleIcon,
} from '@/Icon';
import { MutedText, Strong } from '@/Text';
import { Tooltipped } from '@/Tooltip';
import { CountryFlag } from '~/Components/Locations/CountryFlag';
import { useAction } from '~/hooks/useAction';
import { usePageProps } from '~/hooks/usePageProps';
import { Organization } from '~/types/types';

interface UserMenuProps {
  children: ReactNode;
  logoutRoute: string;
  organization?: Organization;
}

/**
 * An Avatar, used to open up the user-nav.
 */
export const UserMenuAvatar = forwardRef<HTMLButtonElement, any>(
  ({ user, ...props }, ref) => {
    return (
      <button
        ref={ref}
        type="button"
        className="flex items-center max-w-xs text-xs rounded-full bg-main-600 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-main-600"
        {...props}
      >
        <span className="sr-only">Open user menu</span>
        <Avatar imageSrc="" initials={user.name[0]}/>
      </button>
    );
  }
);

UserMenuAvatar.displayName = 'UserMenuAvatar';

export const Selectable = forwardRef<HTMLButtonElement, any>(
  ({ children, label, ...props }, ref) => {
    return (
      <Tooltipped label={label}>
        <span
          ref={ref}
          type="button"
          className="flex items-center border-gray-100 text-xs border justify-between w-full px-2.5 py-1.5 text-gray-600 rounded cursor-pointer hover:bg-gray-100"
          {...props}
        >
          {children}

          <span>
            <SimpleIcon className="!max-w-3 ml-3 !text-gray-400 stroke-[3]" icon={ExpandMenuIcon}/>
          </span>
        </span>
      </Tooltipped>
    );
  }
);
Selectable.displayName = 'Selectable';

/**
 * A Selectable span which indicates something can be opened.
 *
 * Used as Trigger for hte UserMenu
 */
export const UserSelectable = forwardRef<HTMLButtonElement, any>(
  ({ user, ...props }, ref) => {
    return (
      <Selectable {...props} ref={ref}>
        <span className="flex items-center space-x-3">
          <Avatar theme="green" imageSrc="" size="xs" initials={user.name[0]}/>

          <div className="hidden md:block">
            <span>
              <Strong className="text-xs !text-inherit">
                {user.name}
              </Strong>
            </span>
          </div>
        </span>
      </Selectable>
    );
  }
);
UserSelectable.displayName = 'UserSelectable';

/**
 * Menu for a user to navigate.
 * @param children
 * @param organization
 * @param logoutRoute
 * @constructor
 */
export const UserMenu = ({ children, logoutRoute }: UserMenuProps) => {
  const { post: logout } = useAction(logoutRoute);

  const {
    user,
  } = usePageProps();
  const { t, i18n } = useTranslation();

  if (!user) return null;

  return (
    <>
      <DropdownRoot>
        <DropdownTrigger aria-label='Open user menu' asChild>
          {children}
        </DropdownTrigger>
        <DropdownContent>
          <DropdownBlock breakout className="bg-gray-50">
            <div>
              <div className="flex items-center space-x-3">
                <Avatar theme="green" imageSrc="" initials={user.name[0]}/>
                <div>
                  <Strong className="!leading-none">
                    {user.name}
                  </Strong>
                  <div className="leading-none">
                    <MutedText>
                      {user.email}
                    </MutedText>
                  </div>
                </div>
              </div>
            </div>
          </DropdownBlock>

          <DropdownSeparator/>

          {(
            <DropdownGroup>
              <DropdownSubMenu>
                <DropdownSubMenuTrigger>
                  <DropdownIcon icon={LanguageIcon}/>
                  {t('Language')}
                </DropdownSubMenuTrigger>

                <DropdownSubMenuContent>
                  <DropdownGroupHeader>
                    Language
                  </DropdownGroupHeader>
                  <DropdownCheckboxItem
                    onClick={() => i18n.changeLanguage('en')}
                    checked={i18n.language.includes('en')}
                  >
                    <CountryFlag countryCode="US"/>
                    <span className="ml-2">
                      English
                    </span>
                  </DropdownCheckboxItem>

                  <DropdownCheckboxItem
                    onClick={() => i18n.changeLanguage('es')}
                    checked={i18n.language.includes('es')}
                  >
                    <CountryFlag countryCode="ES"/>

                    <span className="ml-2">
                      Español
                    </span>
                  </DropdownCheckboxItem>
                </DropdownSubMenuContent>
              </DropdownSubMenu>
            </DropdownGroup>
          )}

          {user.superadmin && (
            <DropdownGroup aria-label="admin nav items">
              <DropdownSeparator/>

              <DropdownGroupHeader>
                Admin
              </DropdownGroupHeader>

              <DropdownItem>
                <Link className="w-full" href={'/b/admin'}>
                  <DropdownIcon icon={DashboardIcon}/>
                  {t('admin_dashboard')}
                </Link>
              </DropdownItem>
            </DropdownGroup>
          )}

          <DropdownGroup>
            <DropdownSeparator/>
            <DropdownItem
              onClick={() => logout({})}
            >
              <DropdownIcon icon={LogoutIcon}/>
              {t('log_out')}
            </DropdownItem>
          </DropdownGroup>
        </DropdownContent>
      </DropdownRoot>
    </>
  );
};
