import React from 'react';

import { usePageProps } from '../../../../Shared/hooks/usePageProps';
import { PageProps } from '../../../../Shared/types/types';
import SettingsLayout from '../SettingsLayout';

type IntegrationOrganizationSettingsProps = PageProps

const IntegrationOrganizationSettingsPage = () => {
  const { organization } = usePageProps<IntegrationOrganizationSettingsProps>();

  return (
    <div></div>
  );
};

IntegrationOrganizationSettingsPage.layout = (page: React.ReactNode) => <SettingsLayout>{page}</SettingsLayout>;

export default IntegrationOrganizationSettingsPage;
