
export const StatusDot = ({ success }: { success: boolean; }) => {
  if (!success) {
    return (
      <div className="flex-none p-1 rounded-full bg-red-500/20">
        <div className="h-1.5 w-1.5 rounded-full bg-red-500"></div>
      </div>
    );
  }

  return (
    <div className="flex-none p-1 rounded-full bg-emerald-500/20">
      <div className="h-1.5 w-1.5 rounded-full bg-emerald-500"></div>
    </div>
  );
};
