import { Recommendation } from '~/Components/AI/Recommendations/RecommendationPrompt';

type EntityRole = 'retailer' | 'supplier' | 'grower' | 'buyer' | 'seller';

interface AccuracyEntity {
  id: string;
  name: string;
  role: string;
}

interface MeasurementValues {
  q1: number;
  q2: number;
  q3: number;
  q4: number;
  min: number;
  max: number;
  mean: number;
}

interface PercentageValues {
  positives: number;
  negatives: number;
  total: number;
}

interface AccuracyEntry {
  entity: AccuracyEntity;
  values: MeasurementValues | PercentageValues;
}

interface AccuracyField {
  name: string;
  type: string;
  category: string;
  unit?: string;
}

interface ReportingAccuracyItem {
  field: AccuracyField;
  entries: AccuracyEntry[];
  recommendation?: Recommendation
}

export const sampleData: ReportingAccuracyItem[] = [
  {
    field: {
      name: 'Dry Bruising',
      type: 'Percentage',
      category: 'Condition',
    },
    entries: [
      {
        entity: {
          id: '3',
          name: 'Sunripe',
          role: 'grower',
          postHarvest: 3,
        },
        values: {
          countTrue: 80,
          countFalse: 920,
          fractionTrue: 0.08,
          fractionFalse: 0.92,
        },
      },
      {
        entity: {
          id: '2',
          name: 'Barbaros',
          role: 'supplier',
          postHarvest: 21,
        },
        values: {
          countTrue: 120,
          countFalse: 880,
          fractionTrue: 0.12,
          fractionFalse: 0.88,
        },
      },
      {
        entity: {
          id: '1',
          name: 'Greenfresh Supermarkets',
          role: 'retailer',
          postHarvest: 28,
        },
        values: {
          countTrue: 150,
          countFalse: 850,
          fractionTrue: 0.15,
          fractionFalse: 0.85,
        },
      },

    ],
  },
  {
    field: {
      name: 'Brix',
      type: 'Measurement',
      category: 'Quality',
    },
    entries: [
      {
        entity: {
          id: '3',
          name: 'Sunripe',
          role: 'grower',
          postHarvest: 3,
        },
        values: {
          minValue: 9.0,
          maxValue: 13.5,
          median: 10.5,
          percentile75: 11.2,
          minDomainValue: 8.5,
          maxDomainValue: 14.0,
        },
      },
      {
        entity: {
          id: '2',
          name: 'Barbaros',
          role: 'supplier',
          postHarvest: 21,
        },
        values: {
          minValue: 6.5,
          maxValue: 10.8,
          median: 7.9,
          percentile75: 8.7,
          minDomainValue: 6.0,
          maxDomainValue: 11.0,
        },
      },
      {
        entity: {
          id: '1',
          name: 'Greenfresh Supermarkets',
          role: 'retailer',
          postHarvest: 28,
        },
        values: {
          minValue: 7.8,
          maxValue: 12.5,
          median: 9.2,
          percentile75: 10.1,
          minDomainValue: 7.0,
          maxDomainValue: 13.0,
        },
      },

    ],
  },
  {
    field: {
      name: 'Rots',
      type: 'Percentage',
      category: 'Disease',
      criticalLevel: 'critical',
    },
    recommendation: {
      title: 'Critical increase in rots mismeasurements',
      description: 'The percentage of rots has increased significantly. "Rots" has been previously flagged to reject purchase orders, with Incoterm DAP. Please review the supplier\'s quality control procedures and consider rejecting the shipment.',
    },
    entries: [
      {
        entity: {
          id: '3',
          name: 'Sunripe',
          role: 'grower',
          postHarvest: 3,
        },
        values: {
          countTrue: 25,
          countFalse: 975,
          fractionTrue: 0.025,
          fractionFalse: 0.975,
        },
      },
      {
        entity: {
          id: '2',
          name: 'Barbaros',
          role: 'supplier',
          postHarvest: 21,
        },
        values: {
          countTrue: 40,
          countFalse: 960,
          fractionTrue: 0.04,
          fractionFalse: 0.96,
        },
      },
      {
        entity: {
          id: '1',
          name: 'Greenfresh Supermarkets',
          role: 'retailer',
          postHarvest: 28,
        },
        values: {
          countTrue: 50,
          countFalse: 950,
          fractionTrue: 0.05,
          fractionFalse: 0.95,
        },
      },
    ],
  },
  {
    field: {
      name: 'Windrub',
      type: 'Percentage',
      category: 'Condition',
    },
    entries: [
      {
        entity: {
          id: '2',
          name: 'Barbaros',
          role: 'supplier',
          postHarvest: 21,
        },
        values: {
          countTrue: 75,
          countFalse: 925,
          fractionTrue: 0.075,
          fractionFalse: 0.925,
        },
      },
    ],
  },
];
