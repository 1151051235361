import { UserGroupIcon } from '@heroicons/react/20/solid';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { ButtonIcon, UsersIcon } from '@/Icon';
import { LayoutBody, LayoutHeader } from '@/Layout';
import { PageDescription, PageTitle } from '@/Text';
import { EditUser } from '~/Actions/Users/EditUser';
import { InviteUser } from '~/Actions/Users/Settings/InviteUser';
import { SaveUserGroup } from '~/Actions/Users/Settings/SaveUserGroup';
import Section from '~/Components/Common/Section';
import { useCommandAction } from '~/hooks/useCommandAction';
import { usePageProps } from '~/hooks/usePageProps';
import { PageProps, PaginatedItems, User, UserGroup } from '~/types/types';

import { InviteUserModal } from '../Organization/Components/InviteUserModal';
import { UsersTable } from '../Organization/Components/UsersTable';
import SettingsLayout from '../SettingsLayout';
import { UserGroupOverview } from './UserGroupOverview';
import PickerOption = App.Domain.Common.PickerOption;

interface UsersSettingsProps extends PageProps {
  users: PaginatedItems<User>;
  options: {
    produces: PickerOption[];
  }
  groups: UserGroup[];
}

const UsersSettingsPage = () => {
  const { t } = useTranslation();
  const [inviteUser] = useCommandAction(InviteUser, undefined, undefined);
  const { users, organization, groups, options } = usePageProps<UsersSettingsProps>();

  return (
    <>
      <LayoutHeader>
        <div className="flex justify-between items-center gap-4">
          <div>
            <PageTitle>
              Users
            </PageTitle>
            <PageDescription>
              Manage your users
            </PageDescription>
          </div>

          <div>
            <InviteUserModal organization={organization} action={InviteUser}>
              <Button variant="white">
                <ButtonIcon icon={InviteUser.icon}/>
                {t(inviteUser.nameKey())}
              </Button>
            </InviteUserModal>
          </div>
        </div>
      </LayoutHeader>

      <LayoutBody>
        <div className="space-y-12">
          <Section title="User groups" icon={UserGroupIcon}>
            <div className="space-y-12">
              <div>
                <UserGroupOverview
                  defaultUserGroups={groups}
                  saveAction={SaveUserGroup}
                  produceOptions={options?.produces ?? []}
                />
              </div>
            </div>
            <div/>
          </Section>
          <Section title="Users" icon={UsersIcon}>
            <UsersTable
              organization={organization}
              availableGroups={groups}
              usersConnection={users}
              actions={{ editUser: EditUser, inviteUser: InviteUser }}
            />
          </Section>
        </div>
      </LayoutBody>
    </>
  );
};
UsersSettingsPage.layout = (page: ReactNode) => <SettingsLayout>{page}</SettingsLayout>;

export default UsersSettingsPage;
