import { ErrorBoundary } from '@sentry/react';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';

import { DebugJSONModal } from '@/JSON';
import { Nav } from '@/Nav/Nav';
import { makeSellerTopLinks } from '@/Nav/nav-items';
import { MainNavItem, Navbar } from '@/Nav/Navbar';
import { DebugProvider, LocalDebugModal } from '~/Components/Common/LocalDebugModal';
import { FAB } from '~/Components/FAB';
import { ServerErrorProvider } from '~/hooks/ServerErrorProvider';
import { usePageProps } from '~/hooks/usePageProps';
import { usePageTracking } from '~/hooks/usePageTracking';
import { Toaster } from '~/hooks/useToast';
import { OrganizationStatus } from '~/types/types';
import { logDebug, logInfo } from '~/utils/logger';

import { GeneratingModal } from '../Dashboard/GeneratingModal.tsx.tsx';

// import { OnboardingModal } from './OnboardingModal';

interface SellerLayoutProps {
  children: React.ReactNode;
}

export const SellerLayout = ({ children }: SellerLayoutProps) => {
  const posthog = usePostHog();
  const { organization, user, ...props } = usePageProps();

  const topLinks = makeSellerTopLinks(organization.id);

  useEffect(() => {
    if (!posthog) {
      return console.warn('Posthog is not initialized');
    }
    posthog.identify(user.id);
  }, [posthog]);

  return (
    <div className="min-h-screen flex flex-col bg-gray-50 [&>*]:w-full">
      <DebugProvider pageProps={props}>
        <ServerErrorProvider>
          <LocalDebugModal/>
          <Navbar
            user={user}
            organization={organization}
            renderNavLinks={(
              <div className="flex gap-3">
                {topLinks.map((link) => (
                  <MainNavItem key={link.name} link={link}/>
                ))}
              </div>
            )}
          />
          <Toaster/>
          <FAB/>
          {/* <OnboardingModal user={user} organization={organization} show={show_onboard_modal ?? false} /> */}
          {organization.status === OrganizationStatus.GENERATING && (
            <GeneratingModal/>
          )}

          {organization.status === OrganizationStatus.GENERATING ? (
            <ErrorBoundary onError={() => {
              console.log('ignore');
            }} fallback={(
              <div>
                Generating new data
              </div>
            )}>
              {children}
            </ErrorBoundary>
          ) : (
            <>
              {children}
            </>
          )}
        </ServerErrorProvider>
      </DebugProvider>

    </div>
  );
};
