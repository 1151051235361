import { useMemo } from "react";
import { useOrder } from "../../pages/Dashboard/Order/OrderProvider";

type Nullable<T> = T | null;

type OrganizationNameResult<K> = {
  organizationName: K extends undefined ? never : Nullable<string>;
  getOrganizationName: (organizationId: Nullable<string>) => Nullable<string>;
};

export const useOrganizationName = <
  K extends Nullable<string> | undefined = undefined
>(
  organizationId?: K
): OrganizationNameResult<K> => {
  const { order } = useOrder();

  const getOrganizationName = (organizationId: K) => {
    if (!organizationId) return null;

    return organizationId === order.buyer?.id
      ? order.buyer.title
      : organizationId === order.seller?.id
      ? order.seller.title
      : null;
  };

  return {
    organizationName: useMemo(
      () => (organizationId ? getOrganizationName(organizationId) : null),
      [organizationId]
    ),
    getOrganizationName,
  } as OrganizationNameResult<K>;
};
