import { AcceptIssueIcon } from '@/Icon';
import { ActionContext, ActionPost, BaseAction } from '~/Actions/BaseAction';
import ResolveIssueInput = App.Domain.Issues.Inputs.ResolveIssueInput;
import { BuyerRoutes } from '~/hooks/useRoutes';
import { Issue } from '~/types/types';

/**
 * Mark issue as read
 */
export class MarkIssueAsAccepted extends BaseAction {
  public static icon = AcceptIssueIcon;
  public static analyticsName = 'Mark as accepted';

  constructor(public context: ActionContext<Issue>, public post: ActionPost) {
    super(context, post);
  }

  public nameKey() {
    return 'accept';
  }

  public disabled() {
    return (this.context.target as Issue).status === 'accepted';
  }

  public async execute(data: ResolveIssueInput) {
    const routes = this.context.routes as BuyerRoutes;
    await this.post(routes.resolveIssue(this.context.target.id), data);
  }
}
