import React, { FC } from 'react';

interface FormatStringListProps {
  strings: string[];
}

export const FormatStringList: FC<FormatStringListProps> = ({ strings }) => {
  let formattedString;

  switch (strings.length) {
  case 1:
    formattedString = `${strings[0]}`;
    break;
  case 2:
    formattedString = `${strings[0]} and ${strings[1]}`;
    break;
  default:
    if (strings.length > 2) {
      formattedString = `${strings[0]}, ${strings[1]}, + ${strings.length - 2}`;
    }
    break;
  }

  return (
    <span>{formattedString}</span>
  );
};
