/**
 * I want to convert <Icon>
 <ChevronDownIcon className="w-3 h-3 ml-2" />
 </Icon>
 *
 to <PickerIcon as={<ChevronDownIcon />} />
 */

import ChevronDownIcon from '@heroicons/react/20/solid/ChevronDownIcon';

import { Icon as IconContainer } from '@/Icon';
import { cn } from '~/utils/cn';

export const PickerIcon = ({ as: Icon, ...props }: any) => {
  return <Icon className="w-4 h-4 mr-2 stroke-gray-400" {...props} />;
};

export const PickerChevron = ({ className }: { className?: string }) => (
  <IconContainer className={className}>
    <ChevronDownIcon className={cn('w-3 h-3 ml-2')}/>
  </IconContainer>
);
