import { useTranslation } from 'react-i18next';

import { StatusLabel } from '@/StatusLabel';
import { OrderStatusType } from '~/types/types';

export const OrderStatusLabel = ({ status }: { status: OrderStatusType }) => {
  const { t } = useTranslation();
  if (status === 'sellers_input_required') {
    return (
      <StatusLabel status="info">{t('qa_input_required')}</StatusLabel>
    );
  }

  if (status === 'sellers_input_missing') {
    return (
      <StatusLabel status="pending">{t('qa_input_missing')}</StatusLabel>
    );
  }
};
