import { ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Alert, AlertDescription, AlertTitle } from '@/Alert';
import { Button } from '@/Button';
import { Card, CardBody, CardFooter, CardHeader } from '@/Card';
import { Form, FormField, FormGrid, FormItem } from '@/Form';
import { SideRadioLayout } from '@/Form/FormLayouts';
import { NotificationIcon, UserIcon } from '@/Icon';
import { Input } from '@/Input';
import { LayoutBody, LayoutHeader } from '@/Layout';
import { SideLabeledField } from '@/SideLabeledField';
import { CardDescription, CardTitle, PageDescription, PageTitle } from '@/Text';
import { SaveUser } from '~/Actions/Users/Settings/SaveUser';
import Section from '~/Components/Common/Section';
import { useCommandAction } from '~/hooks/useCommandAction';
import { usePageProps } from '~/hooks/usePageProps';

import SettingsLayout from '../SettingsLayout';
import { UserFormatter, UserFormSchema } from './factories';

const AboutSettingsPage = () => {
  const { user } = usePageProps();
  const { t } = useTranslation();

  const form = useForm({
    defaultValues: UserFormatter.modelToForm(user),
  });

  const enableMailNotifications = form.watch('active_mail_recipient');
  const [saveUser, { loading }] = useCommandAction(SaveUser, user);

  const handleSubmit = async (data: UserFormSchema) => {
    await saveUser.execute(UserFormatter.formToApi(data));
  };

  return (
    <>
      <LayoutHeader>
        <PageTitle>
          About
        </PageTitle>
        <PageDescription>
          Manage your settings
        </PageDescription>
      </LayoutHeader>

      <LayoutBody>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className="space-y-12">
              <Section icon={UserIcon} title="General">
                <Card>
                  <CardHeader>
                    <CardTitle>
                      {t('general')}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <FormGrid>
                      <div>
                        <SideLabeledField
                          label="Name"
                          renderValue={(
                            <Input status="disabled" type="text" {...form.register('name')} disabled/>
                          )}
                        />
                      </div>
                      <div>
                        <SideLabeledField
                          label="Email"
                          renderValue={(
                            <Input status="disabled" type="text" {...form.register('email')} disabled/>
                          )}
                        />
                      </div>
                    </FormGrid>
                  </CardBody>
                  <CardFooter>
                    <Button disabled={true as any}>
                      {t('save')}
                    </Button>
                  </CardFooter>
                </Card>
              </Section>

              <Section title="Notifications" icon={NotificationIcon}>
                <Card isLoading={loading}>
                  <CardHeader>
                    <CardTitle>
                      {t('notifications')}
                    </CardTitle>
                    <CardDescription>
                      {t('notifications_description')}
                    </CardDescription>
                  </CardHeader>
                  <CardBody>
                    {!enableMailNotifications && (
                      <Alert className="mb-12" variant="warning">
                        <AlertTitle>
                          Notifications are disabled
                        </AlertTitle>
                        <AlertDescription>
                          You have disabled notifications. Enable them to toggle the different types of notifications.
                        </AlertDescription>
                      </Alert>
                    )}

                    <FormGrid>
                      <FormField
                        name="active_mail_recipient"
                        render={({ field: controlledField }) => (
                          <FormItem>
                            <SideRadioLayout title={t('enable_email_notifications')}
                              description={t('enable_email_notifications_description')} {...controlledField} />
                          </FormItem>
                        )}
                      />

                      <FormField
                        name="active_daily_digest_mail_recipient"
                        render={({ field: controlledField }) => (
                          <FormItem>
                            <SideRadioLayout
                              disabled={!enableMailNotifications}
                              title={t('enable_daily_digest_title')}
                              description={t('enable_daily_digest_description')}
                              {...controlledField}
                            />
                          </FormItem>
                        )}
                      />

                      <FormField
                        name="active_issues_mail_recipient"
                        render={({ field: controlledField }) => (
                          <FormItem>
                            <SideRadioLayout
                              disabled={!enableMailNotifications}
                              title={t('enable_issues_notifications_title')}
                              description={t('enable_issues_notifications_description')}
                              {...controlledField}
                            />
                          </FormItem>
                        )}
                      />

                    </FormGrid>
                  </CardBody>

                  <CardFooter>
                    <Button disabled={(saveUser.disabled() || loading) as any} type="submit" variant="primary">
                      {t('save')}
                    </Button>
                  </CardFooter>
                </Card>
              </Section>
            </div>
          </form>
        </Form>
      </LayoutBody>
    </>
  );
};
AboutSettingsPage.layout = (page: ReactNode) =>
  <SettingsLayout>{page}</SettingsLayout>;
export default AboutSettingsPage;
