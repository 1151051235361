import { EscalateIcon } from '@/Icon';
import { ActionContext, ActionPost, BaseAction } from '~/Actions/BaseAction';
import ResolveIssueInput = App.Domain.Issues.Inputs.ResolveIssueInput;
import { BuyerRoutes } from '~/hooks/useRoutes';
import { Issue } from '~/types/types';

/**
 * Mark issue as read
 */

export class MarkIssueAsEscalated extends BaseAction {
  public static icon = EscalateIcon;
  public static analyticsName = 'Mark as escalated';
  public static toast = {
    titleKey: 'general_mark_as_escalated',
  };

  constructor(public context: ActionContext<Issue>, public post: ActionPost) {
    super(context, post);
  }

  public nameKey() {
    return 'escalate';
  }

  public disabled() {
    return (this.context.target as Issue).status === 'escalated';
  }

  public async execute(data: ResolveIssueInput) {
    const routes = this.context.routes as BuyerRoutes;
    await this.post(routes.resolveIssue(this.context.target.id), data);
  }
}

