import { useMemo } from "react";
import { useOrder } from "../../pages/Dashboard/Order/OrderProvider";
import { Inspection } from "~/types/types";

type Nullable<T> = T | null;

type UseInspectionResult<K> = {
  inspection: K extends undefined ? never : Nullable<Inspection>;
  getInspection: (organizationId: Nullable<string>) => Nullable<Inspection>;
};

export const useInspection = <K extends string | undefined = undefined>(
  inspectionId?: K
): UseInspectionResult<K> => {
  const { order } = useOrder();

  const getInspection = useMemo(
    () => (inspectionId: string) => {
      if (!order.inspections) console.warn("No inspections found in order");
      if (!inspectionId) return null;

      return (
        order.inspections?.find((step) => step.id === inspectionId) || null
      );
    },
    [order.inspections]
  );

  return {
    inspection: useMemo(
      () => (inspectionId ? getInspection(inspectionId) : null),
      [inspectionId]
    ),
    getInspection,
  } as UseInspectionResult<K>;
};
