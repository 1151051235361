import { ClockIcon } from '@heroicons/react/24/outline';
import { ReactNode } from 'react';

import { Icon } from './Icon';
import { Label } from './Label';

type Status = 'success' | 'pending' | 'error' | 'info' | 'default' | 'warning';

export const StatusLabel = ({ status, children, ariaLabel }: {
  status: Status;
  children: ReactNode,
  ariaLabel?: string
}) => {
  switch (status) {
  case 'success':
    return (
      <Label theme="emerald" aria-label={ariaLabel}>
        {children}
      </Label>
    );

  case 'warning':
    return (
      <Label theme="orange" aria-label={ariaLabel}>
        {children}
      </Label>
    );

  case 'info':
    return (
      <Label theme="blue" aria-label={ariaLabel}>
        {children}
      </Label>
    );
  case 'pending':
    return (
      <Label theme="gray" aria-label={ariaLabel}>
        <Icon className="mr-1">
          <ClockIcon className="fill-gray-500 stroke-white"/>
        </Icon>
        {children}
      </Label>
    );
  case 'error':
    return (
      <Label theme="red" aria-label={ariaLabel}>
        {children}
      </Label>
    );
  case 'default':
    return (
      <Label theme="gray" aria-label={ariaLabel}>
        {children}
      </Label>
    );
  default:
    return (
      <Label theme="gray" aria-label={ariaLabel}>
        {children}
      </Label>
    );
  }
};
