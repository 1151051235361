import { SimpleIcon } from '@/Icon';
import { cn } from '~/utils/cn';

type StatusTheme = 'gray' | 'red' | 'yellow' | 'green' | 'blue';

interface StatusConfig<T = any> {
  label?: string | null;
  textKey?: string;
  descriptionKey?: string;
  theme: StatusTheme;
  icon?: any;
  metaData?: T;
}

/**
 * StatusMap is a map of various states, how to represent them and what to display.
 *
 * - Used in the StatusBadge component to display a status badge.
 * - Used in other downstream tasks occasionally.
 */
export type StatusMap<K extends string = string, T = any> = Record<K, StatusConfig<T>>;

/**
 * StatusBadge is a component that displays a Label-like component, with an icon, and a label. It is mostly used in components
 * like ListDetails. Think of it as how Notion would render a status badge in their Databases.
 *
 * @param config
 * @constructor
 */
export const StatusBadge = ({ config }: { status: string; config: StatusConfig }) => {
  return (
    <span className={cn(
      'inline-flex items-center px-1.5 py-0.5 rounded-md text-xs font-medium border',
      {
        'bg-gray-200 text-gray-700 border-gray-300': config.theme === 'gray',
        'bg-rose-50 border-rose-300 text-red-700': config.theme === 'red',
        'bg-yellow-100 text-yellow-700': config.theme === 'yellow',
        'bg-emerald-50 border-emerald-300 text-green-700': config.theme === 'green',
        'bg-blue-100 text-blue-700': config.theme === 'blue',
      }
    )}>
      {config.icon && (
        <SimpleIcon icon={config.icon} className="w-4 h-4 mr-1 !text-inherit"/>
      )}
      {config.label}
    </span>
  );
};

export const getStatusFromMap = (status: string, map: StatusMap): StatusConfig | null => {
  return status && status in map ? map[status] : null;
};
