/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DocumentIcon } from '@heroicons/react/24/outline';
import { ReactNode, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalDescription,
  ModalHeader,
  ModalIcon,
  ModalTitle,
  ModalTrigger,
} from '@/Modal';
import { Spinner } from '@/Spinner';
import { Organization, SavedReport } from '~/types/types';

import { CreateReportForm } from './CreateReportForm/CreateReportForm';

interface EditReportModalProps {
  children: ReactNode;
  isOpen: boolean;
  report: SavedReport;
  setIsOpen: (value: SetStateAction<{ isOpen: boolean; id: number | null }>) => void;
  data?: SavedReport;
  sellers?: Organization[];
  regions?: any[];
}

/**
 * Creates a saved report.
 *
 * Preconditions:
 * - This is used in the SavedReports page.
 */
export const EditReportModal = ({
  children,
  isOpen,
  setIsOpen,
  sellers,
  regions,
  report,
}: EditReportModalProps) => {
  const { t } = useTranslation();
  const reportData = report;

  return (
    <Modal
      open={isOpen}
      onOpenChange={(isOpen) => setIsOpen({ isOpen, id: null })}
    >
      <ModalTrigger asChild>{children}</ModalTrigger>
      <ModalContent className="max-w-[900px]">
        <ModalHeader>
          <div className="flex items-center space-x-3">
            <ModalIcon icon={<DocumentIcon className="w-7 h-7"/>}/>
            <div>
              <ModalTitle>{t('edit_report_title')}</ModalTitle>
              <ModalDescription>
                {t('edit_report_description')}
              </ModalDescription>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          {isOpen && !reportData && (
            <div className="flex justify-center items-center min-h-[200px]">
              <Spinner className="w-10 h-10"/>
            </div>
          )}

          {reportData && (
            <CreateReportForm
              isEditing
              data={reportData}
              sellers={sellers}
              regions={regions}
              onSubmit={() => setIsOpen({ isOpen: false, id: null })}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
