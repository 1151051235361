import { ClipboardIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { JsonEditor } from 'json-edit-react';
import { useCallback, useState } from 'react';

import { ActionbarIcon } from '@/Icon';
import { AnimatedFinishedLabel, FinishedLabel, LoadingLabel } from '@/Label';
import { cn } from '~/utils/cn';

import { ActionbarItem, ActionbarRoot } from './Actionbar';
import { LocalOnly } from './LocalOnly';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalTrigger } from './Modal';

export interface JSONProps {
  json: any;
}

const countNodes = (obj: any): number => {
  if (Array.isArray(obj)) {
    return obj.reduce((sum, item) => sum + countNodes(item), 0);
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.values(obj).reduce((sum, value) => sum + countNodes(value), 0 as number) ?? 0;
  }
  return 1;
};

/**
 * View JSON as a preformatted block
 */
export const DebugJSON = ({ json }: JSONProps) => {
  return (
    <div className="p-4 bg-gray-100 rounded-lg">
      <pre className="text-xs">
        {JSON.stringify(json, null, 2)}
      </pre>
    </div>
  );
};

/**
 * Render a JSON object in a modal. Useful for debugging. Only available in local environments.
 */
export const DebugJSONModal = ({ json }: JSONProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const totalNodes = countNodes(json);
  const useJsonEditor = totalNodes <= 1000;
  const [showCopiedLabel, setShowCopiedLabel] = useState(false);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(JSON.stringify(json, null, 2))
      .then(() => {
        // Optionally, you can show a toast notification here
        console.log('JSON copied to clipboard');
        setShowCopiedLabel(true);
        setTimeout(() => setShowCopiedLabel(false), 2000); // Hide after 2 seconds
      })
      .catch((err) => {
        console.error('Failed to copy JSON:', err);
      });
  }, [json]);

  return (
    <LocalOnly>
      <Modal open={isOpen} onOpenChange={setIsOpen}>
        <ModalTrigger asChild>
          <div className="fixed !w-12 z-100 bottom-4 left-4">
            <button
              type="button"
              className="flex items-center justify-center w-12 h-12 text-white bg-gray-900 rounded-full focus:outline-none"
              onClick={() => setIsOpen(true)}
            >
              <MagnifyingGlassIcon className="h-7 w-7"/>
            </button>
          </div>
        </ModalTrigger>
        <ModalContent className="max-w-7xl h-[90vh] flex flex-col">
          <div
            className="absolute bottom-0 max-w-lg right-0 z-10">
            <div>
              <ActionbarRoot show={isOpen}>
                <AnimatedFinishedLabel show={showCopiedLabel}/>
                <ActionbarItem className="w-full" onClick={handleCopy} shortcut="⌘C">
                  <ActionbarIcon icon={ClipboardIcon}/>
                  Copy JSON
                </ActionbarItem>
              </ActionbarRoot>
            </div>
          </div>
          <ModalBody className="flex-grow overflow-auto">
            {useJsonEditor ? (
              <JsonEditor data={json}/>
            ) : (
              <DebugJSON json={json}/>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </LocalOnly>
  );
};
