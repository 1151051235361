import { motion } from 'framer-motion';

import { SimpleIcon } from '@/Icon';
import { Label } from '@/Label';
import { StatusBadge, StatusMap } from '@/Labels/Status';
import { Helper, Strong } from '@/Text';
import { Tooltipped } from '@/Tooltip';
import { cn } from '~/utils/cn';

type DetailDataType = 'text' | 'count' | 'entries' | 'status';

interface ListDetailProps {
  icon?: any;
  label: string | undefined | null;
  value: any;
  className?: string;
  description?: string | undefined | null;
  dtype?: DetailDataType;
  layout?: 'normal' | 'space-between';
  statusMap?: StatusMap;
}

const DetailValue = ({ value, dtype, statusMap }: { value: any; dtype: DetailDataType, statusMap?: StatusMap; }) => {
  switch (dtype) {
  case 'text':
    return (
      <Strong className="!text-xs !text-gray-500 flex !items-center">
        {value}
      </Strong>
    );

  case 'count':
    return (
      <div className="flex items-center !text-xs !text-gray-400 gap-2">
          #
        <Strong className="!text-xs !text-gray-500 flex !items-center">
          {value}
        </Strong>
      </div>
    );

  case 'entries':
    return (
      <div className="space-y-2">
        <div className="flex items-center gap-4">
          <Strong className="!text-xs">
            {Number(value).toString()}
          </Strong>
        </div>
      </div>
    );

  case 'status':
    if (!statusMap || !statusMap[value]) {
      return (
        <Strong className="!text-xs !text-gray-500 flex !items-center">
          {value}
        </Strong>
      );
    }
    return <StatusBadge status={value} config={statusMap[value]}/>;

  default:
    return <span>{String(value)}</span>;
  }
};

export const ListDetail = ({
  icon,
  label,
  value,
  className,
  description,
  dtype = 'text',
  layout = 'normal',
  statusMap,
}: ListDetailProps) => {
  if (value === undefined || value === null) {
    return null;
  }

  return (
    <motion.div
      aria-label={`detail ${label}`}
      initial={{ opacity: 0, y: 5 }}
      animate={{ opacity: 1, y: 0 }}
      className={cn('flex items-center gap-2', className, layout === 'space-between' && 'justify-between')}
    >
      <div>
        <Tooltipped label={description}>
          <Label className="!flex items-center" theme="tooltip">
            <div className="flex items-center">
              {icon && (
                <SimpleIcon className="mr-1.5 flex items-center" icon={icon}/>
              )}

              <Helper className="!text-xs !text-gray-500 !flex !items-center">
                {label}
              </Helper>
            </div>
          </Label>
        </Tooltipped>
      </div>

      <div aria-label={'value for detail'}>
        <DetailValue statusMap={statusMap} value={value} dtype={dtype}/>
      </div>
    </motion.div>
  );
};

interface InlineDetailsProps {
  children: React.ReactNode;
  separator?: boolean;
}

export const InlineDetails = ({
  children,
  separator,
}: InlineDetailsProps) => {
  return (
    <div className={cn('flex flex-wrap gap-4', separator && 'divide-x divide-gray-200')}>
      {children}
    </div>
  );
};
