import { ClockIcon } from '@radix-ui/react-icons';
import { useTranslation } from 'react-i18next';

import { Modal, ModalBody, ModalContent, ModalDescription, ModalHeader, ModalIcon, ModalTitle } from '@/Modal';
import { Text } from '@/Text';
import { useAction } from '~/hooks/useAction';
import useInterval from '~/hooks/useInterval';

export const GeneratingModal = () => {
  const { t } = useTranslation();

  const { reload } = useAction('');

  // Every 10 seconds, reload the page (to remove the generating check); and clear interval on component unmount
  useInterval(() => {
    reload({});
  }, 10 * 1000);

  return (
    <Modal open>
      <ModalContent className="!max-w-[600px]">
        <ModalHeader>
          <div className="flex items-center space-x-3">
            <ModalIcon icon={<ClockIcon className="h-7 w-7" />} />
            <div>
              <ModalTitle>
                {t('org_maintenance')}
              </ModalTitle>
              <ModalDescription>
                {t('org_maintenance_description')}
              </ModalDescription>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <Text className="text-slate-600">
            {t('org_maintenance_body')}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
