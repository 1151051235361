/**
 * Find index of an value in an array. If not found, return null.
 */
export const findIndex = (arr: any[], value: any) => {
  if (value === null || value === undefined) {
    return null;
  }

  const index = arr.findIndex((item => item === value));

  if (index !== -1) {
    return index;
  }

  return null;
}
