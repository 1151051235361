import { useTranslation } from 'react-i18next';

import { WithHoverCard } from '@/HoverCard';
import { ProduceIcon } from '@/Icon';
import { Label } from '@/Label';
import { PickerIcon } from '@/Pickers/PickerIcon';
import { CardTitle } from '@/Text';
import { FormatProduceVariety } from '~/Components/Produce/FormatProduce';
import { FieldConstraint, ProduceVariety } from '~/types/types';

import { FormatStringList } from '../../../pages/Dashboard/Orders/FormatStringList';

interface ConstraintScopeLabelProps {
  constraint: FieldConstraint;
}

/**
 * ConstraintScopeLabel
 *
 * - This component is used to display the label for the constraint scope (such as varieties, regions, sellers, etc.)
 * - It also includes a Hover-card that expands on the scope of the constraint.
 * @param children
 * @constructor
 */
export const ConstraintScopeLabel = ({ constraint }: ConstraintScopeLabelProps) => {
  const { t } = useTranslation();

  const hasNoScope = !constraint?.produce_varieties?.length;

  return (
    <div>
      {hasNoScope && (
        <Label>
          {t('applied_to_all_varieties')}
        </Label>
      )}

      {constraint.produce_varieties && constraint.produce_varieties.length > 0 && (
        <WithHoverCard renderContent={(
          <div className="min-w-48">
            <CardTitle>
              Scoped
            </CardTitle>
            <div className="divide-y">
              {constraint.produce_varieties.map(variety => (
                <div key={variety.id}>
                  <div className="flex items-center py-2">
                    <PickerIcon as={ProduceIcon}/>
                    <FormatProduceVariety produceVariety={variety as ProduceVariety}/>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}>
          <Label>
            <FormatStringList strings={constraint.produce_varieties?.map(variety => variety.public_name)}/>
          </Label>
        </WithHoverCard>
      )}
    </div>
  );
};
