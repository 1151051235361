import { UserGroupIcon } from '@/Icon';
import { ActionAppType, ActionContext, ActionPost, BaseAction } from '~/Actions/BaseAction';
import { BuyerRoutes } from '~/hooks/useRoutes';
import { User } from '~/types/types';
import UserInput = App.Domain.User.UserInput;

export class SaveUser extends BaseAction {
  constructor(public context: ActionContext<User>, public post: ActionPost) {
    super(context, post);
  }

  /** Default icon for this action */
  public static icon = UserGroupIcon;

  /** Name for posthog analytics */
  public static analyticsName = 'saved_user_group';

  /** Execute for `buyer` or `seller` */
  public static type: ActionAppType = 'buyer';

  /** Message to show on `toast` */
  public static toast = {
    titleKey: 'saved_user_group',
  };

  /** Default translation key */
  public nameKey = () => 'save_user_group';

  /** Callback to check if this should be disabled (using `context.auth` auth, etc) */
  public disabled = () => {
    return this.context.user?.id !== this.context.target.id;
  };

  /**
   * Execute the mutation
   * @param data
   */
  public execute = async (data: UserInput) => {
    const routes = this.context.routes as BuyerRoutes;
    const route = routes.editUser;

    await this.post(route(this.context.target.id), data);
  };
}
