import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { BreadcrumbsSettings } from '@/Breadcrumbs';

export const SettingsHeader = ({ children }: { children: ReactNode }) => {
  return (
    <div>
      <div className='md:hidden mb-4'>
        <BreadcrumbsSettings/>
      </div>
      {children}
    </div>
  );

};
