import { w } from 'windstitch';

/**
 * Used for general text.
 */
export const Text = w.span(`
  text-default text-gray-600 block font-light
`);

/**
 * Used when displaying identifiers.
 */
export const IdentifierText = w.code('text-xs font-light text-muted-foreground');

export const MutedText = w.span(`
  text-xs leading-5 text-gray-500 font-light
`);

/**
 * Used for strong text in Muted sections (like gray.50 backgrounds).
 */
export const MutedStrong = w.strong(`
  text-sm leading-5 text-gray-500 font-semibold
`);

export const DescriptionText = w.span(`
  text-xs leading-none block text-gray-500 font-light mb-1
`);

export const H1 = w.h1(`
  text-gray-800 text-3xl font-bold
`);

export const H2 = w.h1(`
  text-gray-800 text-xl font-bold
`);

export const H3 = w.h1(`
  text-gray-800 text-md font-semibold
`);

export const TextLabel = w.span(`
  text-slate-600 text-sm font-semibold
`);

export const NumericValue = w.span(`
font-mono font-medium tabular-nums text-xs text-gray-800
`);

export const Strong = w.strong(`
  text-sm font-medium leading-6 text-gray-800
`);

/**
 * Titles used to indicate sections (on the gray backgrounds)
 */
export const SectionTitle = w.h3(`
  font-semibold leading-6 text-gray-700 text-lg flex gap-1 items-center
`);

/**
 * Descriptions (sub-texts) used to indicate sections (on the gray backgrounds)
 */
export const SectionDescription = w.span(`
  text-gray-500 font-normal text-sm block
`);

/**
 * Title for "The Simple Card" title variant.
 */
export const SimpleCardTitle = w.div(`
  text-xs leading-5 text-inherit font-light
`);

/**
 * Titles used for cards and modals (work well on white backgrounds).
 */
export const CardTitle = w.h3(`
  text-base font-semibold leading-6 text-gray-700
`);

export const CardDescription = w.span(`
  text-sm text-gray-500 block font-light
`);

/**
 * SubHeaders used to help describe sections.
 */
export const CardSubtitle = w.span(`
  text-sm text-gray-500 block font-light
`);

/**
 * Helper text, used as labels.
 */
export const Helper = w.span(`
  font-normal text-gray-600 text-xs uppercase tracking-wide inline-block
`);

/**
 * Helper text, used as labels.
 */
export const HelperSmall = w.span(`
  text-gray-500 text-xxs uppercase font-light tracking-wide inline-block
`);

/**
 * Small text that is used below helpers.
 */
export const HelperDescription = w.span(`
  text-xs text-gray-700 inline-block mb-1
`);

/**
 * Used for page headers.
 */
export const PageTitle = w.h2(`
  block text-2xl font-semibold text-token-heading
`);

/**
 * Used as subheaders.
 */
export const PageDescription = w.span(`
  block text-md text-gray-500 font-light
`);

export const ValidationMessage = ({ error }: { error?: string }) => (
  <>
    {error && (
      <span className="text-sm text-red-600">
        {error}
      </span>
    )}
  </>
);
